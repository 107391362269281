import { QueryStatus, useQuery } from 'react-query';
import {
  User,
  UserInput,
  UserInputResponse,
  UserRolloutInput,
} from 'model/user';
import { getMe, getMeRollout } from 'data/requests/me';
import { useState, useEffect } from 'react';
import { QueryConfing } from 'data/types';
import { enrichMe, transformMe, transformRollout } from 'data/transform/user';
import { transformUserWorkspace } from 'data/transform/workspace';

import { useGoogleProfile } from './useGoogleProfile';

export function useMe(options?: Omit<QueryConfing<UserInput, User>, 'select'>) {
  const { data, status, ...rest } = useQuery(['me'], getMe, {
    ...(options ?? {}),
    refetchOnMount: options?.refetchOnMount ?? false,
    retry: options?.retry ?? false,
    select: transformMe,
  });

  const [content, setContent] = useState<{
    me: User | null;
    status: QueryStatus;
  }>({
    me: data ?? null,
    status,
  });

  const { data: googleProfile } = useGoogleProfile();

  useEffect(() => {
    setContent({
      me: data ? enrichMe(data, googleProfile) : null,
      status,
    });
  }, [data, status, googleProfile]);

  return {
    data: content.me,
    status: content.status,
    ...rest,
  };
}

export const useUserWorkspace = (workspaceId?: string) => {
  const { data, ...rest } = useQuery(['me'], getMe, {
    refetchOnMount: false,
    retry: false,
    select: (result) => transformUserWorkspace(result, workspaceId),
  });

  return {
    data: data ?? null,
    ...rest,
  };
};

export const useUserCounters = () => {
  return useQuery(['me'], getMe, {
    refetchOnMount: false,
    retry: false,
    select: (result) => result?.counters,
  });
};

export const useRollout = (
  options?: Omit<QueryConfing<UserInputResponse, UserRolloutInput>, 'select'>,
) => {
  const { data, ...rest } = useQuery(['meRollout'], getMeRollout, {
    ...(options ?? {}),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retryOnMount: false,
    select: transformRollout,
  });

  return {
    data: data ?? null,
    ...rest,
  };
};
