import { EmptyTitle, EmptySubtitle } from 'components/ResponsiveTable/Empty';
import React from 'react';

const WorkspaceMembersEmpty = () => {
  return (
    <>
      <EmptyTitle>Nothing to see here...</EmptyTitle>
      <EmptySubtitle>You don’t have any space members.</EmptySubtitle>
    </>
  );
};

export default WorkspaceMembersEmpty;
