import {
  ButtonLink,
  Link,
  RouterLinkProps,
  ButtonRouterLinkProps,
  Tab,
} from '@scriptaddicts/yamm-ui-components';
import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import useRouterLink from './useRouterLink';

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ children, to, replace, onClick, ...props }, ref) => {
    const routerProps = useRouterLink({ to, replace, onClick });

    return (
      <Link ref={ref} {...{ ...props, ...routerProps }}>
        {children}
      </Link>
    );
  },
);

export const ButtonRouterLink = forwardRef<
  HTMLAnchorElement,
  ButtonRouterLinkProps
>(({ children, to, replace, onClick, ...props }, ref) => {
  const routerProps = useRouterLink({ to, replace, onClick });

  return (
    <ButtonLink ref={ref} {...{ ...props, ...routerProps }}>
      {children}
    </ButtonLink>
  );
});

export const RouterTab = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { to: string; exact?: boolean }
>(({ children, to, onClick, exact, ...props }, ref) => {
  const routerProps = useRouterLink({ to, replace: false, onClick, exact });

  return (
    <Tab ref={ref} {...{ ...props, ...routerProps }}>
      {children}
    </Tab>
  );
});
