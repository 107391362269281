import { CheckoutUser } from '../../../model/checkout';
import {
  BillingInterval,
  BillingWorkspacePlan,
  WorkspaceSubscription,
} from '../../../model/workspaceBilling';
import { FlowType } from '../../../utils/checkout';

export const hasUserChangedState = (user: CheckoutUser) =>
  (user.isPaid && user.plan === 'FREE') ||
  (!user.isPaid && user.plan !== 'FREE');

export const getUserUpdateProjection = (
  users: CheckoutUser[],
  updatedUserId: string,
  isPaid: boolean,
) =>
  users.map((user) => ({
    ...user,
    isPaid: user.id === updatedUserId ? isPaid : user.isPaid,
  }));

export const getNextLink = (flowType: FlowType) => {
  if (flowType === 'UPGRADE' || flowType === 'DOWNGRADE') {
    return '/checkout/summary';
  }

  if (
    flowType === 'UPGRADE_LEGACY_BE' ||
    flowType === 'UPGRADE_LEGACY_BE_INDIVIDUAL'
  ) {
    return undefined;
  }

  return '/checkout/information';
};

export const hasChanged = (
  flowType: FlowType,
  activeSubscription: WorkspaceSubscription,
  plan: BillingWorkspacePlan,
  billingInterval: BillingInterval,
  users: CheckoutUser[],
) => {
  if (flowType === 'PURCHASE') {
    return true;
  }

  if (activeSubscription) {
    if (plan !== activeSubscription.workspacePlan) {
      return true;
    }
    if (billingInterval !== activeSubscription.billingInterval) {
      return true;
    }
  }

  const changedUser = users.find(hasUserChangedState);

  return changedUser != null;
};

export const getPlanOptions = (
  currentWorkspacePlan?: BillingWorkspacePlan,
  isPremiumPlanRestricted?: boolean,
) => [
  {
    label: currentWorkspacePlan === 'PRO' ? 'Pro (current plan)' : 'Pro',
    value: 'PRO',
  },
  ...(isPremiumPlanRestricted
    ? []
    : [
        {
          label:
            currentWorkspacePlan === 'PREMIUM'
              ? 'Premium (current plan)'
              : 'Premium',
          value: 'PREMIUM',
        },
      ]),
];

export type BillingOptionsSelect = { label: string; value: BillingInterval };
export const getBillingOptions = (
  currentInterval: BillingInterval | undefined,
  isMonthlyPlanRestricted: boolean,
): BillingOptionsSelect[] => {
  const MONTHLY: BillingOptionsSelect = {
    label: `Billed monthly${
      currentInterval === 'MONTHLY' ? ' (current cycle)' : ''
    }`,
    value: 'MONTHLY',
  };
  const YEARLY: BillingOptionsSelect = {
    label: `Billed annually${isMonthlyPlanRestricted ? '' : ' - save 50%'}${
      currentInterval === 'YEARLY' ? ' (current cycle)' : ''
    }`,
    value: 'YEARLY',
  };

  return isMonthlyPlanRestricted ? [YEARLY] : [MONTHLY, YEARLY];
};

export const getDisableComputeEndpoint = ({
  usersInitializedFlag,
  isLoading,
  isLoadingActiveSubscription,
  paidUsers,
  activeSubscription,
}: {
  usersInitializedFlag?: boolean;
  isLoading: boolean;
  isLoadingActiveSubscription: boolean;
  paidUsers?: CheckoutUser[];
  activeSubscription?: WorkspaceSubscription;
}): boolean => {
  if (!activeSubscription) {
    return (
      !usersInitializedFlag ||
      isLoading ||
      isLoadingActiveSubscription ||
      !paidUsers?.length
    );
  }

  return isLoading || isLoadingActiveSubscription;
};
