import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WorkspaceSubscription } from '../../../../model/workspaceBilling';
import useQueryParams from '../../../../utils/useQueryParams';
import { useWorkspaceContext } from '../../WorkspaceContext';
import { CHANGE_BILLING_CYCLE, INTERVAL, PLAN } from '../../../queryParams';

export const useCheckIfCheckoutPossible = (
  activeSubscription?: WorkspaceSubscription,
) => {
  const queryParams = useQueryParams();
  const { replace } = useHistory();
  const { urlPrefix } = useWorkspaceContext();

  useEffect(() => {
    if (activeSubscription) {
      const hasNewPlan =
        queryParams.get(PLAN) !== activeSubscription.workspacePlan;
      const hasNewInterval =
        queryParams.get(INTERVAL) !== activeSubscription.billingInterval;
      if (hasNewPlan && hasNewInterval) {
        replace(
          `${urlPrefix}/space/billing?checkout-error=cannot-update-subscription-plan-and-interval`,
        );
      }
      if (
        (hasNewInterval || queryParams.has(CHANGE_BILLING_CYCLE)) &&
        activeSubscription.stripeSubscriptionScheduleId != null
      ) {
        replace(
          `${urlPrefix}/space/billing?checkout-error=cannot-change-billing-cycle-when-scheduled`,
        );
      }
    }
  }, [activeSubscription]);
};
