import {
  InputTag,
  useAutocompleteTagInput,
} from '@scriptaddicts/yamm-ui-components';
import { queryClient } from 'data/queryClient';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import { useSuggestUser } from 'data/hooks/useUsers';
import {
  useInviteMembers,
  useValidateInviteMembers,
} from 'data/hooks/useWorkspaceInvite';
import {
  Dispatch,
  DispatchWithoutAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { from, useMediaQuery } from 'styles/media';

export const useConnect = ({
  onClose,
  onSuccess,
  workspaceId,
}: {
  onClose: DispatchWithoutAction;
  onSuccess?: Dispatch<{ inviteCount: number }>;
  workspaceId: string | undefined;
}) => {
  const [members, setMembers] = useState<InputTag[]>([]);
  const [validCount, setValidCount] = useState<number>(0);

  const {
    tagInput: { tags: inviteMembers, setTags: setInviteMembers, ...tagInput },
    autocomplete: { setValue, search, ...autocomplete },
  } = useAutocompleteTagInput({
    autocomplete: { openOn: 2, timeout: 500 },
  });

  const { data: me } = useMe();
  const { details, user, restrictions } = useWorkspaceContext();

  const { data: suggestion } = useSuggestUser(
    {
      workspaceId,
      searchKeyword: search,
    },
    !!me && !me.isPersonal,
  );

  const {
    mutate: invite,
    status: inviteStatus,
    reset: resetInvite,
  } = useInviteMembers();
  const {
    data: validationData,
    isFetching: isValidating,
  } = useValidateInviteMembers(workspaceId, {
    userEmails: inviteStatus === 'idle' ? inviteMembers : [],
  });

  const errors = useMemo(
    () =>
      (validationData ?? []).reduce(
        (map, { email, errorMsg }) => ({
          ...map,
          ...(email && inviteMembers.includes(email)
            ? {
                [email]: {
                  tip: errorMsg,
                  variant: errorMsg ? 'error' : undefined,
                },
              }
            : {}),
        }),
        [] as Record<string, any>,
      ),
    [validationData, inviteMembers],
  );

  // Validate on email addresses change
  useEffect(() => {
    setMembers(
      inviteMembers.map((member) =>
        errors[member] ? { text: member, ...errors[member] } : { text: member },
      ),
    );
  }, [inviteMembers, errors]);

  useEffect(() => {
    if (isValidating) return;
    setValidCount(
      members.length > 0 ? members.length - Object.keys(errors).length : 0,
    );
  }, [members, errors, isValidating]);

  const hasError = useMemo(
    () => ({
      partial: Object.keys(errors).length > 0,
      all: !!members.length && Object.keys(errors).length === members.length,
    }),
    [errors, members],
  );

  const [autoInvite, setAutoInvite] = useState<boolean>(false);
  const onInvite = useCallback(() => {
    setAutoInvite(false);
    const userEmails = inviteMembers.filter((email) => !errors[email]);
    if (!userEmails.length) return;

    invite({
      workspaceId,
      params: { userEmails },
    });
  }, [invite, setAutoInvite, workspaceId, inviteMembers, errors]);

  useEffect(() => {
    if (isValidating || !autoInvite) return;
    onInvite();
  }, [onInvite, autoInvite, isValidating]);

  const resetForm = useCallback(() => {
    setInviteMembers([]);
    setValue('');
  }, [setInviteMembers, setValue]);

  // Process result of invite
  useEffect(() => {
    if (inviteStatus === 'success') {
      resetInvite();
      if (onSuccess) {
        onSuccess({ inviteCount: validCount });
      }
      onClose();
      resetForm();
      queryClient.removeQueries(['users', 'suggestion']);
    }
  }, [resetInvite, onSuccess, onClose, resetForm, inviteStatus, validCount]);

  const isMobile = !useMediaQuery(from.tablet);

  return {
    isLoading: inviteStatus === 'loading' || autoInvite,
    resetForm,
    hasMultiDomain: details?.features.FREE.hasMultiDomain,
    isMultiDomainRestricted: !!restrictions?.PREMIUM_PLAN,
    role: user?.role,
    domain: me?.domain,
    search: {
      items: suggestion,
      ...autocomplete,
    },
    tags: {
      members,
      hasError,
      setAutoInvite,
      ...tagInput,
    },
    invite: {
      onClick: onInvite,
      count: validCount,
      disabled: !validCount && !search,
      isValidating,
    },
    isMobile,
  };
};
