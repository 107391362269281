import { useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decodeBase64UUID } from '../../../utils/uid';

export const PATH = '/:workspaceId/:workspaceSlug';

export const useActiveWorkspaceId = () => {
  const pathMatch = useRouteMatch<{
    workspaceId: string;
    workspaceSlug: string;
  }>(PATH);

  const matchedId = decodeBase64UUID(pathMatch?.params.workspaceId);
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    string | undefined
  >(matchedId);

  useEffect(() => {
    setSelectedWorkspace(matchedId);
  }, [matchedId]);

  return {
    selectedWorkspace,
    setSelectedWorkspace,
  };
};
