import {
  BillingCurrency,
  BillingInterval,
  BillingWorkspacePlan,
  ComputeStripePaymentInfo,
  WorkspaceSubscription,
} from '../../model/workspaceBilling';
import { isGmailAddress } from '../userUtils';
import { getSubscriptionBasePrice, getSubscriptionPriceWithTax } from './index';
import { CheckoutUser } from '../../model/checkout';
import { Prices } from '../paymentPlans';

export type PlanUpdateDelta<T> = {
  from: T;
  to: T;
};

export type PlanUpdateDeltas = {
  plan?: PlanUpdateDelta<BillingWorkspacePlan>;
  billingCycle?: PlanUpdateDelta<BillingInterval>;
  paidUsers?: PlanUpdateDelta<number>;
  gmailPaidUsers?: PlanUpdateDelta<number>;
  basePrice?: PlanUpdateDelta<number>;
  taxedPrice?: PlanUpdateDelta<number>;
  tax?: PlanUpdateDelta<number>;
  currency?: PlanUpdateDelta<BillingCurrency>;
};

export type GetPlanUpdateDeltasProps = {
  plan: BillingWorkspacePlan;
  currentSubscription: WorkspaceSubscription;
  paymentInfo: ComputeStripePaymentInfo;
  prices: Prices;
  users: CheckoutUser[];
};
export const getPlanUpdateDeltas = ({
  users,
  plan,
  currentSubscription,
  paymentInfo,
}: GetPlanUpdateDeltasProps): PlanUpdateDeltas => {
  const deltas: PlanUpdateDeltas = {};

  // plan
  if (currentSubscription.workspacePlan !== plan) {
    deltas.plan = {
      from: currentSubscription.workspacePlan,
      to: plan,
    };
  }

  // billing cycle
  const fromCycle = paymentInfo.currentBillingInterval;
  const toCycle = paymentInfo.targetBillingInterval;
  if (fromCycle != null && toCycle != null && fromCycle !== toCycle) {
    deltas.billingCycle = {
      from: fromCycle,
      to: toCycle,
    };
  }

  // paid users count
  const oldGSuitePaidUsers = users.filter(
    (user) => !isGmailAddress(user.email) && user.plan !== 'FREE',
  );
  const newGSuitePaidUsers = users.filter(
    (user) => !isGmailAddress(user.email) && user.isPaid,
  );
  if (oldGSuitePaidUsers.length !== newGSuitePaidUsers.length) {
    deltas.paidUsers = {
      from: oldGSuitePaidUsers.length,
      to: newGSuitePaidUsers.length,
    };
  }

  // @gmail.com paid users
  const oldGMailPaidUsers = users.filter(
    (user) => isGmailAddress(user.email) && user.plan !== 'FREE',
  );
  const newGMailPaidUsers = users.filter(
    (user) => isGmailAddress(user.email) && user.isPaid,
  );
  if (oldGMailPaidUsers.length !== newGMailPaidUsers.length) {
    deltas.gmailPaidUsers = {
      from: oldGMailPaidUsers.length,
      to: newGMailPaidUsers.length,
    };
  }

  const fromPrice = paymentInfo.currentAmount;
  const toPrice = getSubscriptionBasePrice(paymentInfo);
  if (fromPrice !== null && toPrice != null && fromPrice !== toPrice) {
    deltas.basePrice = {
      from: fromPrice,
      to: toPrice,
    };
    deltas.taxedPrice = {
      from: fromPrice + (fromPrice * (currentSubscription?.taxRate || 0)) / 100,
      to: getSubscriptionPriceWithTax(paymentInfo),
    };
    deltas.tax = {
      from: currentSubscription?.taxRate || 0,
      to: paymentInfo.taxRate,
    };
    deltas.currency = {
      from: currentSubscription.currency,
      to: paymentInfo.currency,
    };
  }

  return deltas;
};
