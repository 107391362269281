import React from 'react';
import { Alert, Text } from '@scriptaddicts/yamm-ui-components';

export const LegacyPlanUpgradeSuccess = () => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Your plan switch has been successfully done!
    </Text>
    <Text type="paragraph">
      Your previous subscription has been automatically canceled.
    </Text>
  </Alert>
);

export const SubscriptionStarted = () => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Well done! Your YAMM subscription has started.
    </Text>
  </Alert>
);

export const SubscriptionUpdated = () => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Your subscription has been updated.
    </Text>
  </Alert>
);
