import React from 'react';
import { Alert, FlexContainer } from '@scriptaddicts/yamm-ui-components';
import { useConnect } from './connect';
import FreeUsersDisclaimer from './FreeUsersDisclaimer';
import CheckoutLayout from '../Layout';
import BillingOptionsSidebar from '../BillingOptionsSidebar';
import { StyledTitle, StyledTitleWrapper } from '../common/styles';
import { MainContainerLoader } from '../../../components/MainContainer';
import { CheckoutUsersTable } from './CheckoutUsersTable';

const BillingOptions = () => {
  const {
    isLoading,
    workspaceDetails,
    changed,
    flowType,
    submitError,
    onSubmit,
  } = useConnect();

  const title = flowType === 'DOWNGRADE' ? 'Downgrading' : 'Upgrading';
  return (
    <CheckoutLayout.Container>
      <CheckoutLayout.Main>
        {isLoading || !workspaceDetails ? (
          <MainContainerLoader />
        ) : (
          <>
            {submitError && (
              <Alert style={{ marginBottom: '1rem' }} type="error">
                An error occurred.
              </Alert>
            )}
            <StyledTitleWrapper>
              <StyledTitle>
                {title} {workspaceDetails.name}
              </StyledTitle>
            </StyledTitleWrapper>
            <FlexContainer flex="vertical" gap="large">
              <FreeUsersDisclaimer />
              <CheckoutUsersTable />
            </FlexContainer>
          </>
        )}
      </CheckoutLayout.Main>
      <CheckoutLayout.Sidebar>
        <BillingOptionsSidebar
          onSubmit={() => onSubmit()}
          disabled={!changed}
        />
      </CheckoutLayout.Sidebar>
    </CheckoutLayout.Container>
  );
};

export default BillingOptions;
