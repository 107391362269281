import React, { useRef, useCallback, memo } from 'react';
import debounce from 'lodash.debounce';
import SearchBar from 'components/SearchBar';
import { FilterComponent } from 'components/ResponsiveTable/types';

const Search: FilterComponent = ({ onChange }) => {
  const searchRef = useRef<HTMLInputElement | null>(null);
  const debouncedChange = debounce(onChange, 200);

  const handleChange = useCallback(
    (value: string | undefined) => {
      const change = value || searchRef.current?.value || '';
      debouncedChange(
        change.length > 0 ? encodeURIComponent(change) : undefined,
      );
    },
    [debouncedChange],
  );

  return <SearchBar onChange={handleChange} ref={searchRef} />;
};

export default memo(Search);
