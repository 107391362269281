import {
  FormControl,
  FormLabel,
  Input,
} from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { useField } from 'formik';
import { OptionalLabel, StyledFromGroup } from './styles';

type InputContainerProps = {
  label: string;
  name: string;
  // eslint-disable-next-line react/require-default-props
  maxLength?: number;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isOptional?: boolean;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
};

export const InputField = ({
  label,
  name,
  maxLength,
  disabled,
  isOptional,
  placeholder,
}: InputContainerProps) => {
  const [{ onBlur, onChange, value }, { error, touched }] = useField<string>(
    name,
  );

  return (
    <StyledFromGroup>
      <FormLabel htmlFor={name}>
        {label}
        {isOptional === true && <OptionalLabel> (optional)</OptionalLabel>}
      </FormLabel>
      <FormControl errorMessage={!!error && touched ? error : undefined}>
        <Input
          placeholder={placeholder}
          id={name}
          name={name}
          maxLength={maxLength}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          hasError={!!error && touched}
        />
      </FormControl>
    </StyledFromGroup>
  );
};
