import { UserWorkspace } from 'model/workspace';
import { User } from '../../model/user';
import { getUserIdFromEmail, isGmailAddress } from '../userUtils';

export type StackDriverLogUser = {
  user_id?: string;
  user_installation_timestamp?: number;
  plan?: string;
  is_biz_user?: boolean;
  domain?: string;
};

export const getUserDataForLogging = (
  user: User | null,
  workspace: UserWorkspace | null,
): StackDriverLogUser => {
  if (!user || !workspace) {
    return {};
  }

  return {
    user_id: getUserIdFromEmail(user.id),
    user_installation_timestamp: user.installationTimestamp,
    plan: workspace.plan,
    is_biz_user: !isGmailAddress(user.email),
    domain: user.email?.split('@')?.[1],
  };
};
