import { useWorkspaceContext } from '../../context/WorkspaceContext';
import { useMe } from '../useMe';
import { restrictBouncesPage, restrictUserPage } from './restrictionsFns';

/**
 * This hook is used to determine if the user can access certain pages
 * false means no restriction, and thus the user can access the page
 * true means it is restricted and the user cannot access the page
 * ()
 */
export type PageRestrictions = {
  userPage: boolean;
  bouncesPage: boolean;
  ready: boolean;
};

export const usePageRestrictions = (): PageRestrictions => {
  const { data: me } = useMe();
  const { restrictions, details, user } = useWorkspaceContext();

  return {
    ready: restrictions != null && details != null && user != null, // in case you need to make sure restrictions are available and not fallbacks
    userPage: restrictUserPage(restrictions, details, me),
    bouncesPage: restrictBouncesPage(details, user),
  };
};

export type FeatureRestrictions = {
  ready: boolean;
  monthlyBilling: boolean;
};
export const useFeatureRestrictions = (): FeatureRestrictions => {
  const { restrictions } = useWorkspaceContext();

  return {
    ready: restrictions != null,
    monthlyBilling: restrictions?.MONTHLY_BILLING || false,
  };
};
