import React from 'react';
import { Column } from 'components/ResponsiveTable/types';
import { formatMonthDay } from 'utils/dates';
import {
  TableCell,
  TableCellValue,
  TableCellDateTimeValue,
  Avatar,
} from '@scriptaddicts/yamm-ui-components';
import { TeamMember } from 'model/team';
import { TableCellValueGroup } from '../styles';

export const getColumns = ({ isMobile }: { isMobile: boolean }) =>
  [
    ...(isMobile
      ? [
          {
            accessor: 'responsiveLeft',
            colSpan: 1,
            float: 'left',
            disableGlobalFilter: true,
            disableSortBy: true,
            canSort: false,
            Cell: ({ row }) => {
              const { id, photo, initials } = row.original;
              return <Avatar src={photo} initials={initials} identifier={id} />;
            },
          } as Column,
        ]
      : []),
    {
      Header: 'Name',
      accessor: 'name',
      colSpan: 2,
      group: isMobile,
      Cell: ({ value, row }) => {
        const { id, photo, initials } = row.original;

        return isMobile ? (
          <TableCell width="medium" colSpan={2}>
            {value}
          </TableCell>
        ) : (
          <TableCellValueGroup width="medium">
            <Avatar src={photo} initials={initials} identifier={id} />
            {value}
          </TableCellValueGroup>
        );
      },
    },
    {
      Header: 'Last Email',
      accessor: 'lastTimeUsed',
      colSpan: 1,
      float: 'right',
      Cell: ({ value }) => (
        <TableCellDateTimeValue
          size={isMobile ? 'extra-small' : undefined}
          variant={isMobile ? 'gray' : undefined}
          value={value}
          formatFunction={formatMonthDay}
        />
      ),
    },
    {
      Header: 'Sent this month',
      accessor: 'counters.totalEmailsSentThisMonth',
      alignment: 'right',
      colSpan: 1,
      defaultSorted: 'desc',
      Cell: ({ value }) => (
        <TableCellValue
          size={isMobile ? 'extra-small' : undefined}
          variant={isMobile ? 'gray' : undefined}
        >
          {`${value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}${
            isMobile ? ' this month' : ''
          }`}
        </TableCellValue>
      ),
    } as Column,
    {
      Header: 'Sent all time',
      accessor: 'counters.totalEmailsSent',
      alignment: 'right',
      colSpan: 1,
      Cell: ({ value }) => (
        <TableCellValue
          size={isMobile ? 'extra-small' : undefined}
          variant={isMobile ? 'gray' : undefined}
        >
          {`${value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}${
            isMobile ? ' all time' : ''
          }`}
        </TableCellValue>
      ),
    } as Column,
    ...(!isMobile
      ? [
          {
            accessor: 'spacer',
            colSpan: 1,
            disableGlobalFilter: true,
            disableSortBy: true,
            canSort: false,
            Cell: () => <span />,
          } as Column,
        ]
      : []),
  ] as Column<TeamMember>[];
