import React from 'react';
import {
  TableHeader,
  TableColumn,
  TableHeaderRow,
} from '@scriptaddicts/yamm-ui-components';
import { Props } from './types';
import { getSort } from './utils';

const Columns = ({
  headers,
  originalColumns,
  sortBy,
  setSortBy,
  isAllRowsSelected,
  toggleAllRowsSelected,
}: Props) => {
  return (
    <TableHeader
      allSelected={isAllRowsSelected}
      onAllSelected={toggleAllRowsSelected}
      sortBy={sortBy && sortBy.length > 0 ? sortBy[0].id : undefined}
      sortOrder={getSort(sortBy)}
      onSorted={(value, order) => {
        if (!setSortBy) return;
        setSortBy(!order ? [] : [{ id: value, desc: order === 'desc' }]);
      }}
    >
      <TableHeaderRow>
        {headers.map((column, index) => {
          const alignment = originalColumns[index].alignment ?? 'left';
          const { key } = column.getHeaderProps();
          const { render, canSort } = column;
          const { colSpan, canSort: canSortOriginal } = originalColumns[index];

          return (
            <TableColumn
              key={key}
              value={key.toString().substring(7)}
              align={alignment}
              sortable={canSort && canSortOriginal}
              colSpan={colSpan}
            >
              {render('Header')}
            </TableColumn>
          );
        })}
      </TableHeaderRow>
    </TableHeader>
  );
};

export default Columns;
