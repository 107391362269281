import axios, { AxiosRequestConfig } from 'axios';
import { Token } from './context/AuthContext/token';

const useBearerToken = () => (config: AxiosRequestConfig) => {
  const { token } = Token.get();
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE}`,
});

client.interceptors.request.use(useBearerToken());

export const clientV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE_V2}`,
});

clientV2.interceptors.request.use(useBearerToken());

export const clientWorkspaceBilling = axios.create({
  baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}`,
});

clientWorkspaceBilling.interceptors.request.use(useBearerToken());
