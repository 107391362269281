import React, { FC } from 'react';
import {
  CheckoutMainContainer,
  ContentMain,
  StyledContainer,
  StyledLayoutGrid,
} from './styles';
import CheckoutTopbar from './CheckoutTopbar';
import CheckoutSidebar from './CheckoutSidebar';

const CheckoutLayout: Record<'Container' | 'Main' | 'Sidebar', FC> = {
  Container: ({ children }) => (
    <StyledContainer>
      <CheckoutTopbar />
      <StyledLayoutGrid>{children}</StyledLayoutGrid>
    </StyledContainer>
  ),
  Main: ({ children }) => (
    <CheckoutMainContainer>
      <ContentMain>{children}</ContentMain>
    </CheckoutMainContainer>
  ),
  Sidebar: ({ children }) => <CheckoutSidebar>{children}</CheckoutSidebar>,
};

export default CheckoutLayout;
