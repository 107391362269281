import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useDeleteUnsubscription } from 'data/hooks/useUnsubscriptions';
import { useCallback, useEffect } from 'react';
import { Props } from './types';

export const useConnect = ({ emailAddress, onClose }: Props) => {
  const { id, user } = useWorkspaceContext();

  const {
    mutateAsync: deleteUnsubscription,
    status,
    reset,
  } = useDeleteUnsubscription(id);

  const onRemove = useCallback(() => {
    if (!emailAddress) return;
    deleteUnsubscription({
      emailAddress,
    });
  }, [emailAddress, deleteUnsubscription]);

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      reset();
      onClose(status);
    }
  }, [status, reset]);

  return {
    userSharedUnsubs: !!user?.features.sharedUnsubscribes,
    isLoading: status === 'loading',
    onRemove,
  };
};
