import styled from 'styled-components';
import {
  Card as DefaultCard,
  Text as DefaultText,
} from '@scriptaddicts/yamm-ui-components';
import { from } from 'styles/media';

export const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled(DefaultCard).attrs({
  variant: 'white',
  padding: 'big',
  flex: 'vertical',
})`
  border-radius: 0;
  position: relative;

  ${from.tablet} {
    border-radius: 6px;
  }
`;

export const CardTitle = styled(DefaultText)`
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray800};
`;
