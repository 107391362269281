import styled from 'styled-components';
import { Text } from '@scriptaddicts/yamm-ui-components';

export const StyledListItem = styled(Text).attrs({
  type: 'paragraph',
})`
  display: flex;
  align-items: center;
  gap: 4px;
`;
