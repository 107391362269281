import styled from 'styled-components';
import { from } from 'styles/media';
import { Text as DefaultText } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const InfoContainer = styled.div`
  padding: 0px 24px;

  ${from.tablet} {
    padding: 0px;
  }
`;

export const TableContainer = styled.div`
  margin-top: 10px;
  ${from.tablet} {
    margin-top: 8px;
  }
`;

export const ActionContainer = styled.div`
  flex: none !important;
  justify-self: flex-end;
`;

export const PlainText = styled(DefaultText).attrs({
  type: 'span',
})`
  line-height: 100% !important;
`;
