import styled from 'styled-components';
import { DropdownListItem } from '../styles';

export const ActiveSpaceDropdownListItem = styled(DropdownListItem)`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray900};
`;

export const ActiveSpaceDropdownInfo = styled.div`
  position: relative;
  gap: 24px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray900};
`;

export const DropDownSpaceDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;
