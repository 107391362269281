import { captureException } from '@sentry/react';
import { useRollout } from 'data/hooks/useMe';
import { NewUser } from 'model/user';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  getRegisterFlag,
  removeRegisterFlag,
  setDelegateId,
  setRegisterFlag,
} from 'utils/cookiesUtils';
import { pushSignUp } from 'utils/dataLayer';
import { decodeBase64UUID } from 'utils/uid';
import { Token } from './token';
import { ErrorType } from './types';

const normalizePath = (path: string) => {
  if (!path) return path;

  const parts = path.split('/');
  if (parts.length >= 3 && !!decodeBase64UUID(parts[1])) {
    if (parts.length === 3) return '/';
    return path.replace(`${parts[1]}/${parts[2]}/`, '');
  }

  return path;
};

export const useDelegateLogin = (
  enabled: boolean | undefined | null,
  createUser: (params: { userEmail: string }) => Promise<NewUser>,
  setRefreshing: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<ErrorType | null>>,
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { data, refetch } = useRollout({
    enabled: !!enabled,
    onSuccess: (responseData) => {
      if (responseData.isEligible === false) {
        const { backendIdToken } = Token.get();
        if (backendIdToken) {
          setDelegateId(backendIdToken);
        }
        const urlParams = new URLSearchParams(window.location.search);
        if (sessionStorage.getItem('useNewDns') === 'true') {
          urlParams.set('useNewDns', 'true');
        }

        window.location.replace(
          `${process.env.REACT_APP_LEGACY_URL}${normalizePath(
            window.location.pathname,
          )}?${urlParams.toString()}`,
        );
      }
    },
  });

  const checkDelegate = useCallback(
    async (isRegister?: boolean) => {
      setLoading(true);
      const { data: delegate } = await refetch();
      if (delegate?.isEligible === false) {
        setLoading(true);
        if (isRegister) {
          setRegisterFlag('register');
        }
      } else {
        setLoading(false);
      }

      return delegate?.isEligible === false;
    },
    [setLoading, refetch],
  );

  const delegateRegister = useCallback(
    async (userEmail: string) => {
      if (getRegisterFlag() === 'register') {
        removeRegisterFlag();
        try {
          const { data: delegate } = await refetch();
          if (!delegate?.isRegistered) {
            await createUser({ userEmail });
            pushSignUp();
          }
        } catch (e) {
          captureException(e);
          setRefreshing(false);
          setError({ type: 'can-not-create-user' });
          Token.clear();
          return false;
        }
      }
      return true;
    },
    [createUser, setError, setRefreshing],
  );

  return {
    delegateLoading:
      (enabled && (!data || data.isEligible === false)) || loading,
    loginEnabled: data?.isEligible === true,
    checkDelegate,
    delegateRegister,
  };
};
