import styled from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import { from } from 'styles/media';
import { Text as DefaultText } from '@scriptaddicts/yamm-ui-components';

export const CheckmarkIcon = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  opacity: 0;
  transition: all 0.2s;

  ${from.tablet} {
    width: 16px;
  }

  path {
    fill: ${({ theme }) => theme.colors.white};
  }

  &.checked {
    opacity: 1;
  }
`;

export const ProgressWrapper = styled.div`
  height: 1.875rem;
  width: 100%;
  max-width: 30rem;
  margin-bottom: 5rem;
  padding: 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${from.tablet} {
    flex-direction: row;
  }
`;

export const Label = styled(DefaultText)`
  font-size: 0.875rem;
  line-height: 17px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray700};
  transition: all 0.2s;

  &.current {
    color: ${({ theme }) => theme.colors.blue500};
  }

  &.clickable {
    cursor: pointer;
    user-select: none;
  }

  ${from.tablet} {
    position: absolute;
    top: calc(100% + 0.938rem);
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const CircleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
  flex: 0 0 auto;
  min-height: 100%;
  gap: 8px;
`;

export const Circle = styled.div`
  position: relative;
  min-width: 11px;
  min-height: 11px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  transition: all 0.2s;

  &.current {
    border: 2px solid ${({ theme }) => theme.colors.blue500};
  }

  &.checked {
    min-width: 16px;
    min-height: 16px;
    border: 2px solid ${({ theme }) => theme.colors.blue500};
    background: ${({ theme }) => theme.colors.blue500};

    ${from.tablet} {
      min-width: 30px;
      min-height: 30px;
    }
  }

  &.clickable {
    cursor: pointer;
  }
`;

export const LineWrapper = styled.div`
  display: none;
  flex: 1 1 auto;
  min-height: 1px;
  background: ${({ theme }) => theme.colors.gray300};
  ${from.tablet} {
    display: block;
  }
`;

export const Line = styled.div`
  min-height: 1px;
  width: 0;
  background: ${({ theme }) => theme.colors.blue500};
  transition: all 0.2s;

  &.checked,
  &.current {
    min-width: 100%;
  }
`;
