import {
  Button,
  Drawer,
  FlexContainer,
  FlexSpacer,
  Color,
  Tooltip,
  Alert,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import ResponsiveTable from 'components/ResponsiveTable';
import { WorkspaceMember } from 'model/workspaceMembers';
import React from 'react';
import { plurify } from 'utils/plurify';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerTitle,
} from 'components/MainContainer';
import { useConnect } from './connect';
import { InviteUsers } from '../../../../widgets/InviteUsers';
import { AddUsers } from '../../../../widgets/AddUsers';
import { ManageUser } from './ManageUser';
import {
  ActionContainer,
  Container,
  InfoContainer,
  PlainText,
  TableContainer,
} from './styles';
import Empty from './table/empty';
import NotFound from './table/notFound';
import UserAlerts from './UserAlerts';

const UserSettings = () => {
  const {
    id,
    name,
    isLoading,
    isBusy,
    promote,
    counts,
    members,
    columns,
    processRow,
    tableStateManager,
    invite,
    add,
    manage,
    userAlerts,
    isSupportSubscription,
    onContactUs,
    canManageUsers,
  } = useConnect();
  return (
    <MainContainer title="User settings">
      <MainContainerHeader direction="vertical">
        <MainContainerTitle append={name}>Users</MainContainerTitle>
      </MainContainerHeader>
      <Container>
        {!isLoading && (
          <InfoContainer>
            <FlexContainer flex="vertical" gap="large">
              {isSupportSubscription && (
                <Alert
                  type="info"
                  append={
                    <Button
                      aria-label="contact us"
                      onClick={onContactUs}
                      variant="info"
                    >
                      Contact us
                    </Button>
                  }
                >
                  <Text>
                    You are on an offline managed subscription. Please contact{' '}
                    us if you need changes.
                  </Text>
                </Alert>
              )}
              <UserAlerts {...userAlerts} />
              {invite.feature && (
                <FlexContainer
                  flex="horizontal"
                  style={{ alignItems: 'center' }}
                >
                  <FlexContainer flex="vertical" gap="base">
                    <PlainText size="large" width="bold">
                      {`${counts.total} ${plurify(
                        counts.total,
                        'user',
                        'users',
                      )}`}
                    </PlainText>
                    <PlainText size="base" width="regular">
                      {!!counts.paid && (
                        <Color variant="success">{counts.paid} paid</Color>
                      )}
                      {counts.paid &&
                      (counts.gmailPaid ||
                        counts.trial ||
                        counts.free ||
                        counts.disabled)
                        ? ', '
                        : undefined}
                      {!!counts.gmailPaid && (
                        <Color variant="success">
                          {counts.gmailPaid} @gmail.com paid
                        </Color>
                      )}
                      {counts.gmailPaid &&
                      (counts.trial || counts.free || counts.disabled)
                        ? ', '
                        : undefined}
                      {!!counts.trial && (
                        <Color variant="success">{counts.trial} trial</Color>
                      )}
                      {counts.trial && (counts.free || counts.disabled)
                        ? ', '
                        : undefined}
                      {!!counts.free && (
                        <Color variant="info">{counts.free} free</Color>
                      )}
                      {counts.free && counts.disabled ? ', ' : undefined}
                      {!!counts.disabled && (
                        <Color variant="warning">
                          {counts.disabled} disabled
                        </Color>
                      )}
                    </PlainText>
                  </FlexContainer>
                  {!invite.restricted && canManageUsers && (
                    <>
                      <FlexSpacer />
                      <InviteUsers
                        workspaceId={id}
                        {...invite}
                        onSuccess={(props) => invite.setUserAlerts(props)}
                      />
                      <Button
                        data-log-event="open_invitation_modal"
                        data-log-event-data={JSON.stringify({
                          displayName: 'Invite users',
                        })}
                        aria-label="Invite users"
                        variant={promote ? 'secondary' : 'primary'}
                        icon="users"
                        onClick={invite.onOpen}
                        disabled={isLoading || isBusy}
                      >
                        Invite users
                      </Button>
                    </>
                  )}
                </FlexContainer>
              )}
              {add.feature && (
                <FlexContainer
                  flex="horizontal"
                  style={{ alignItems: 'center' }}
                >
                  <PlainText size="large" width="bold">
                    {`${counts.total} paid ${plurify(
                      counts.total,
                      'user',
                      'users',
                    )}`}
                    {add.isTeam && ` (out of ${add.limit} max)`}
                  </PlainText>
                  <FlexSpacer />
                  <AddUsers
                    {...add}
                    onSuccess={(props) => add.setUserAlerts(props)}
                  />
                  {add.warning ? (
                    <Tooltip
                      tip={
                        add.warning === 'TYPE'
                          ? 'Your current plan does not allow you to add other users.'
                          : `Your current plan does not allow more than ${
                              add.limit
                            } ${plurify(add.limit, 'user', 'users')}.`
                      }
                    >
                      <ActionContainer>
                        <Button
                          aria-label="Add users"
                          variant="primary"
                          icon="users"
                          onClick={add.onOpen}
                          disabled
                        >
                          Add users
                        </Button>
                      </ActionContainer>
                    </Tooltip>
                  ) : (
                    <Button
                      aria-label="Add users"
                      variant="primary"
                      icon="users"
                      onClick={add.onOpen}
                      disabled={isLoading || isBusy}
                    >
                      Add users
                    </Button>
                  )}
                </FlexContainer>
              )}
            </FlexContainer>
          </InfoContainer>
        )}
        <Drawer
          side={<ManageUser {...manage} />}
          show={manage.show}
          close="none"
        >
          <TableContainer>
            <ResponsiveTable<WorkspaceMember>
              selectable={canManageUsers}
              sortable
              data={members}
              loading={isLoading}
              columns={columns}
              empty={<Empty />}
              notFound={<NotFound />}
              processRow={processRow}
              tableStateManager={tableStateManager}
              disabled={isBusy}
            />
          </TableContainer>
        </Drawer>
      </Container>
    </MainContainer>
  );
};

export default UserSettings;
