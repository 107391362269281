import { useDisclosure } from '@scriptaddicts/yamm-ui-components';
import { useAuth } from 'data/context/AuthContext';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useCannon } from 'data/hooks/useCannon';
import { useMe } from 'data/hooks/useMe';
import { useTutorial } from 'data/hooks/useTutorial';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, from } from 'styles/media';
import useQueryDisclosure from 'utils/router/useQueryDisclosure';
import { usePageRestrictions } from '../../../data/hooks/restrictions/useRestrictions';

export const useConnect = () => {
  const { pathname } = useLocation();

  const { data: me } = useMe({ keepPreviousData: true });
  const { user, details, isLoading, urlPrefix } = useWorkspaceContext();
  const { onSwitchAccount, logout } = useAuth();
  const { cannon } = useCannon('sidebar');
  const {
    bouncesPage,
    userPage,
    ready: restrictionsLoaded,
  } = usePageRestrictions();

  const newWorkspace = useQueryDisclosure('new-space');
  const mobileMenu = useDisclosure({});
  const isMobile = !useMediaQuery(from.tablet);

  const shouldShowUpgradePlanButton = useMemo(
    () =>
      !pathname.endsWith('/space/billing') &&
      details &&
      details.plan === 'FREE' &&
      user &&
      user.role !== 'MEMBER',
    [pathname, details, user],
  );

  const shouldShowBillingButton = useMemo(
    () => user && user.role !== 'MEMBER',
    [user],
  );

  const canAccessTeamReport = useMemo(
    () => !!user?.features.canAccessTeamReport,
    [user],
  );

  const canAccessWorkspace = useMemo(
    () => !!user?.features.canAccessWorkspace,
    [user],
  );

  const disabled = useMemo(() => !isLoading && user?.status === 'DISABLED', [
    user,
    isLoading,
  ]);

  const showTutorial = useTutorial();

  return {
    menu: {
      cannon,
      canAccessTeamReport,
      canAccessWorkspace,
      canAccessBounces: restrictionsLoaded && !bouncesPage,
      canAccessUsers: restrictionsLoaded && !userPage,
      userRole: user?.role || 'MEMBER',
      showTutorial,
      disabled,
      url: urlPrefix,
      ...(isMobile ? mobileMenu : {}),
    },
    bar: {
      ...me,
      shouldShowUpgradePlanButton,
      shouldShowBillingButton,
      onAccountSwitch: onSwitchAccount,
      onLogout: logout,
      onOpen: isMobile ? mobileMenu.onOpen : undefined,
      spaceUrlPrefix: urlPrefix,
    },
    newWorkspace,
  };
};
