import {
  Text as DefaultText,
  Button as DefaultButton,
} from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';

export const Text = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraSmall',
})`
  color: ${({ theme }) => theme.colors.gray700};
  line-height: 1.0625rem;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.gray900};
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.orange900};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-top: 1rem;
`;

export const LoginMessage = styled.p`
  margin-bottom: 3rem;
`;

export const ConfirmButton = styled(DefaultButton)`
  height: 2.5rem;
`;
