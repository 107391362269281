import { clientWorkspaceBilling } from 'data/client';
import { PaymentPlan } from '../../model/paymentPlans';

export const getPaymentPlans = async () => {
  const { data } = await clientWorkspaceBilling.get<{ items: PaymentPlan[] }>(
    `/workspace-billing/payment-plans/v1`,
  );

  return data ? data.items : undefined;
};
