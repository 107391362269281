import styled from 'styled-components';
import { from } from 'styles/media';
import { Text as DefaultText } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  padding: 24px 32px 24px 32px;
  flex-direction: column;

  ${from.tablet} {
    width: 500px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
})`
  font-weight: 700;
  text-align: center;
`;
