import {
  Dispatch,
  DispatchWithoutAction,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';

interface Output {
  show: boolean;
  onClose: DispatchWithoutAction;
  onOpen: Dispatch<string>;
  focusRef: MutableRefObject<any>;
  value?: string;
}

export default function useQueryDisclosure(query: string): Output {
  const { replace } = useHistory();

  const params = useQueryParams();

  const show = useMemo(() => params.has(query), [params, query]);

  const onClose = useCallback(() => {
    params.delete(query);
    replace({ search: params.toString() });
  }, [replace, params, query]);

  const onOpen = useCallback(
    (value: string) => {
      params.set(query, value);
      replace({ search: params.toString() });
    },
    [replace, params, query],
  );

  const focusRef = useRef<any>(null);
  useEffect(() => {
    if (!show) return;
    if (typeof focusRef?.current?.focus === 'function') {
      focusRef.current.focus();
    }
  }, [show, focusRef.current]);

  return {
    show,
    onClose,
    onOpen,
    focusRef,
    value: params.get(query) ?? undefined,
  };
}
