import { useImportDraft } from 'data/hooks/useImportDraft';
import { useTemplates } from 'data/hooks/useTemplates';
import { PostImportDraftDto } from 'data/requests/templates';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { up } from 'utils/Paths';
import { useAuth } from 'data/context/AuthContext';
import { useImportedDrafts } from 'data/context/TemplatesContext';
import { useMe } from 'data/hooks/useMe';
import { getAuthUserIndex } from '../../../utils/cookiesUtils';
import { useLogic } from './logic';

export const useConnect = () => {
  const { url } = useRouteMatch();
  const { templates } = useLogic(useTemplates());
  const { importedDrafts, setImportedDrafts } = useImportedDrafts();

  const { data: me } = useMe();
  const {
    askForComposePermission,
    composePermissionGranted,
    noAuthError,
  } = useAuth();
  const loggedUserIndex = getAuthUserIndex();

  const matches = useRouteMatch<{ id: string }>(`${url}/:id`);

  const activeTemplate = useMemo(() => {
    return matches
      ? templates?.find((template) => template.id === matches.params.id)
      : undefined;
  }, [matches, templates]);

  const {
    mutate,
    reset,
    status: importStatus,
    data: importData,
  } = useImportDraft();
  const [importingId, setImportingId] = useState<string | undefined>();

  const [permissionAlreadyGiven, setPermissionAlreadyGiven] = useState<
    boolean | null | undefined
  >(false);

  useEffect(() => {
    composePermissionGranted().then((permission) => {
      setPermissionAlreadyGiven(permission);
    });
  }, [composePermissionGranted, setPermissionAlreadyGiven]);

  const [alert, setAlert] = useState<
    | {
        status: 'success' | 'error';
        message?: string;
      }
    | undefined
  >();

  const onImport = useCallback(
    async ({ fromTemplate }: Pick<PostImportDraftDto, 'fromTemplate'>) => {
      setAlert(undefined);
      if (!permissionAlreadyGiven) {
        try {
          askForComposePermission();
        } catch {
          return;
        }
      }
      setImportingId(fromTemplate);
    },
    [permissionAlreadyGiven, askForComposePermission, setAlert],
  );

  useEffect(() => {
    if (importingId && noAuthError) {
      setAlert({ status: 'error' });
      setImportingId(undefined);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (!importingId || !permissionAlreadyGiven || !me?.email) return;

    mutate({
      userId: me.email,
      fromTemplate: importingId,
      source: 'GALLERY',
    });
  }, [importingId, permissionAlreadyGiven, mutate, me, noAuthError, setAlert]);

  useEffect(() => {
    if (!importingId) return;

    if (importStatus === 'success') {
      if (!importData?.messageId) return;
      setImportedDrafts((props) => ({
        ...props,
        [importingId]: importData?.messageId,
      }));
      setAlert({
        status: 'success',
        message: templates?.find((template) => template.id === importingId)
          ?.subject,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setImportingId(undefined);
      reset();
    }
    if (importStatus === 'error') {
      setAlert({ status: 'error' });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setImportingId(undefined);
      reset();
    }
  }, [
    setImportingId,
    setImportedDrafts,
    setAlert,
    reset,
    importStatus,
    importData,
    importingId,
  ]);

  const { push, goBack } = useHistory();

  const onClose = useCallback(() => {
    const currentUrl = matches?.url;
    if (!currentUrl) {
      goBack();
    } else {
      push(up(currentUrl));
    }
  }, [push, goBack, matches]);

  return {
    templates,
    importing: {
      id: importingId,
      onClick: onImport,
    },
    dialog: {
      show: !!matches,
      onClose,
    },
    url,
    activeTemplate,
    importedDrafts,
    loggedUserIndex,
    alert,
    dismissAlert: () => setAlert(undefined),
  };
};
