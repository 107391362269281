import { WorkspaceSubscription } from '../../../../../model/workspaceBilling';

const isPaid = (subscription: WorkspaceSubscription) => {
  return subscription?.additionalStripeInfo?.latestInvoice.paid === true;
};

export const checkIsSubscriptionCanceled = (
  subscriptions?: WorkspaceSubscription[],
): boolean => {
  if (!subscriptions || subscriptions.length < 1) {
    return false;
  }

  return subscriptions.every((sub) => sub.subscriptionStatus === 'CANCELED');
};

export const checkIsLastPaymentAttemptFailed = (
  subscription?: WorkspaceSubscription,
): boolean => {
  if (
    subscription &&
    subscription.additionalStripeInfo &&
    !isPaid(subscription)
  ) {
    return (
      subscription.additionalStripeInfo.latestInvoice.paymentIntent.status ===
      'requires_payment_method'
    );
  }

  return false;
};

export const checkIsLastPaymentAttemptRequiresConfirmation = (
  subscription?: WorkspaceSubscription,
): boolean => {
  if (
    subscription &&
    subscription.additionalStripeInfo &&
    !isPaid(subscription)
  ) {
    const {
      status,
    } = subscription.additionalStripeInfo.latestInvoice.paymentIntent;
    return status === 'requires_action' || status === 'requires_confirmation';
  }

  return false;
};

export const getEndDate = (subscription?: WorkspaceSubscription): number => {
  if (subscription) {
    return subscription.endTimestamp;
  }

  return 0;
};

export const getHostedInvoiceUrl = (
  subscription?: WorkspaceSubscription,
): string | undefined => {
  if (subscription && subscription.additionalStripeInfo) {
    return subscription.additionalStripeInfo.latestInvoice.hostedInvoiceUrl;
  }

  return undefined;
};

export const getActiveSubscription = (subscriptions: WorkspaceSubscription[]) =>
  subscriptions.find((sub) => sub.subscriptionStatus === 'ACTIVE');
