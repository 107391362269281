import { EmptyTitle, EmptySubtitle } from 'components/ResponsiveTable/Empty';
import React from 'react';

const UsersNotFound = () => {
  return (
    <>
      <EmptyTitle>No results found</EmptyTitle>
      <EmptySubtitle>
        Sorry, there is no user that fits with your search.
      </EmptySubtitle>
    </>
  );
};

export default UsersNotFound;
