import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoadingScreen from 'components/LoadingScreen';

function GoogleAuthContextProvider({ children }: { children: ReactNode }) {
  const clientId = useRef(process.env.REACT_APP_GOOGLE_CLIENT_ID).current;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onScriptLoadSuccess = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onScriptLoadError = useCallback(() => {
    throw new Error('Google auth provider script couldnt be loaded');
  }, []);

  return (
    <GoogleOAuthProvider
      onScriptLoadSuccess={onScriptLoadSuccess}
      onScriptLoadError={onScriptLoadError}
      clientId={clientId as string}
    >
      {isLoading ? <LoadingScreen /> : children}
    </GoogleOAuthProvider>
  );
}

export default GoogleAuthContextProvider;
