import React, { memo } from 'react';
import { useMe } from 'data/hooks/useMe';
import { Chevron, Text } from '../styles';
import { SpacePlanBadge } from '../../../utils/workspace/styles';
import {
  getBadgeClassForWorkspace,
  getPrettyUserPlan,
  getRecipientsQuotaPerDay,
  toTitleCase,
} from '../../../utils/workspace';
import {
  OtherWorkspace,
  OtherWorkspaceDecorator,
} from '../../../data/context/WorkspaceContext';
import { DropDownSpaceDetail } from './styles';

const AccountDropdownSpace = ({
  activeWorkspace,
  showChevron,
}: {
  activeWorkspace: OtherWorkspace | undefined;
  showChevron: boolean;
}) => {
  const { data: me } = useMe();
  const workspacePlan = activeWorkspace?.workspaceUserFeature?.workspacePlan;
  const userPlan = activeWorkspace?.workspaceUserFeature?.userPlan;

  const isSpace = new OtherWorkspaceDecorator(activeWorkspace).hasSpacePlan();

  return (
    <>
      <DropDownSpaceDetail>
        <Text style={{ fontWeight: 500 }}>Active space:</Text>
        <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Text>{activeWorkspace?.name}</Text>
          {isSpace && (
            <SpacePlanBadge
              className={getBadgeClassForWorkspace(activeWorkspace)}
            >
              <Text>{toTitleCase(workspacePlan)}</Text>
            </SpacePlanBadge>
          )}
        </span>
        <Text>{getPrettyUserPlan(userPlan)} user</Text>
        <Text>
          Up to {getRecipientsQuotaPerDay(userPlan, me?.freeUserQuota)}{' '}
          recipients / day
        </Text>
      </DropDownSpaceDetail>
      {showChevron && <Chevron />}
    </>
  );
};

export default memo(AccountDropdownSpace);
