import React from 'react';

import {
  MainContainerLoader,
  MainPromptContainer,
} from 'components/MainContainer';
import { Prompt } from 'components/Prompt';
import { useConnect } from './connect';

export const Invitations = () => {
  const { isRestricted } = useConnect();

  if (isRestricted) {
    return (
      <MainPromptContainer>
        <Prompt
          variant="warning"
          title="Sorry, this invitation link is no longer valid."
        />
      </MainPromptContainer>
    );
  }

  return <MainContainerLoader />;
};
