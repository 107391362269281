import { AxiosError } from 'axios';
import { queryClient } from 'data/queryClient';
import {
  deleteScheduledCampaign,
  getScheduledCampaignCount,
  getScheduledCampaignDetail,
  getScheduledCampaigns,
} from 'data/requests/scheduledCampaign';
import { CampaignCount } from 'model/campaign';
import { BackendError } from 'model/error';
import { InputParams } from 'model/parameters';
import {
  DeleteScheduledCampaignParams,
  ScheduledCampaign,
  ScheduledCampaignParams,
} from 'model/scheduledCampaign';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { createFilter, createSort } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';

export const useScheduledCampaigns = (
  workspaceId?: string,
  params?: ScheduledCampaignParams,
) => {
  const { isEnabled, queryParams } = useMemo(
    () => ({
      isEnabled: !!workspaceId && !!params?.limit,
      queryParams: {
        filterBy: createFilter({
          workspaceId,
          ...(params?.filters ?? {}),
        }),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [workspaceId, params],
  );

  const { data, ...rest } = useQuery(
    ['scheduled-campaign', 'list', queryParams],
    () => getScheduledCampaigns(queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: false,
      staleTime: 1000,
      onSuccess: (result) => {
        queryClient.setQueryData<CampaignCount>(
          ['scheduled-campaign', 'count', workspaceId],
          { campaignCount: (result ?? []).length },
        );
      },
    },
  );

  const fetchAll = useCallback(async () => {
    const predicates = [
      'scheduled-campaign',
      'list',
      {
        filterBy: createFilter({ workspaceId }),
        limit: 30000,
      },
    ];
    return queryClient.fetchQuery(
      predicates,
      () => getScheduledCampaigns(predicates[2] as InputParams),
      {
        staleTime: Infinity,
      },
    );
  }, [queryClient, workspaceId]);

  return {
    data: useDefaultArray<ScheduledCampaign>(data),
    ...rest,
    fetchAll,
  };
};

export const useScheduledCampaignDetail = (
  userId?: string,
  scheduledMergeId?: string,
) => {
  return useQuery(
    ['scheduled-campaign', 'detail', userId, scheduledMergeId],
    () => getScheduledCampaignDetail(scheduledMergeId!),
    {
      enabled: !!userId && !!scheduledMergeId,
    },
  );
};

export const useCancelScheduledCampaign = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    DeleteScheduledCampaignParams
  >(deleteScheduledCampaign, {
    onSuccess: async () =>
      queryClient.invalidateQueries(['scheduled-campaign']),
  });
};

export const useScheduledCampaignCount = (workspaceId?: string) => {
  return useQuery(
    ['scheduled-campaign', 'count', workspaceId],
    () => getScheduledCampaignCount(workspaceId!),
    {
      enabled: !!workspaceId,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );
};
