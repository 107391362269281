import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useChangeActiveWorkspace } from 'data/hooks/useWorkspace';
import { useCallback } from 'react';
import { useMe } from '../../../../data/hooks/useMe';
import { useWorkspaceActiveSubscription } from '../../../../data/hooks/useWorkspaceBilling';
import { isSupportGivenSubscription } from '../../../../utils/subscriptions';

export const useConnect = () => {
  const {
    id,
    details,
    otherWorkspaces,
    isLoading,
    isPreviousWorkspace,
  } = useWorkspaceContext();
  const { data: user } = useMe();
  const { data: subscription } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: false,
  });

  const { changeWorkspace } = useChangeActiveWorkspace();

  const onChange = useCallback(
    (workspaceId?: string) => {
      if (!workspaceId || workspaceId === id) return;
      changeWorkspace(workspaceId);
    },
    [id, changeWorkspace],
  );

  return {
    isLoading: isLoading || isPreviousWorkspace,
    id,
    name: details?.name,
    plan: details?.plan,
    legacyType: details?.legacyType,
    memberCount: details?.memberCount,
    workspaces: otherWorkspaces,
    isSupportSub: !!subscription && isSupportGivenSubscription(subscription),
    onChange,
    addonWorkspaceId: user?.addonWorkspaceId,
  };
};
