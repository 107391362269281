import React from 'react';

import StaticLayout from 'containers/Common/StaticLayout';
import testingVar from 'utils/testingVar';
import { Prompt } from 'components/Prompt';

const Success = () => {
  return (
    <StaticLayout data-test-id={testingVar('success')}>
      <Prompt
        variant="success"
        title="Unsubscription was successful!"
        description="We have informed the sender of this email that you don’t want to receive more emails from them."
      />
    </StaticLayout>
  );
};

export default Success;
