import { Icon } from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import { StyledListItem } from './styles';

const ListItem: FC<{ onClick?: () => void }> = ({
  children,
  onClick,
  ...props
}) => (
  <StyledListItem onClick={onClick} {...props}>
    <Icon name="chevron-right" />
    {children}
  </StyledListItem>
);

export default ListItem;
