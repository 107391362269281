import { useMemo } from 'react';
import { useCheckoutContext } from '../../../../data/context/CheckoutContext';

export const useAvailableUsers = () => {
  const { users: allUsers, isLoading } = useCheckoutContext();

  const availableUsers = useMemo(
    () =>
      allUsers.filter(
        (user) =>
          !user.toBeRemoved &&
          (user.status === 'ACTIVE' || user.status === 'DISABLED'),
      ),
    [allUsers],
  );

  return {
    availableUsers,
    isLoading,
  };
};
