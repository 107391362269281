import { AxiosError } from 'axios';
import { queryClient } from 'data/queryClient';
import {
  deleteMember,
  getWorkspaceMemberPromotionValidity,
  getWorkspaceMembers,
  putMemberRole,
  putMemberStatus,
  putWorkspaceOwner,
} from 'data/requests/workspaceMembers';
import { optimisticUpdateWorkspaceMemberCount } from 'data/updates/workspace';
import { BackendError } from 'model/error';
import {
  WorkspaceMemberParams,
  WorkspaceMember,
  WorkspaceMemberRoleParams,
  WorkspaceMemberActivateParams,
  WorkspaceMemberRemoveParams,
  TransferWorkspaceParams,
} from 'model/workspaceMembers';
import { useMemo } from 'react';
import { useQuery, useMutation } from 'react-query';
import { createSort, createFilter } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';

type UseWorkspaceMembersOptions = {
  refetchOnWindowFocus: boolean;
};
export const useWorkspaceMembers = (
  workspaceId?: string,
  params?: WorkspaceMemberParams,
  options?: UseWorkspaceMembersOptions,
) => {
  const { isEnabled, queryParams } = useMemo(
    () => ({
      isEnabled: !!workspaceId && !!params?.limit,
      queryParams: {
        filterBy: createFilter(params?.filters),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [workspaceId, params],
  );

  const { data, ...rest } = useQuery(
    ['space', workspaceId, 'members', queryParams],
    () => getWorkspaceMembers(workspaceId!, queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: true,
      staleTime: 1000,
      refetchOnWindowFocus: options ? options.refetchOnWindowFocus : true,
    },
  );

  const sortedData = useMemo(
    () =>
      data && !queryParams.sortBy
        ? [
            ...data
              .filter(({ status }) => status !== 'PENDING')
              .sort((a, b) => a.createTimestamp - b.createTimestamp),
            ...data
              .filter(({ status }) => status === 'PENDING')
              .sort((a, b) => a.createTimestamp - b.createTimestamp),
          ]
        : data,
    [data, queryParams.sortBy],
  );

  return {
    data: useDefaultArray<WorkspaceMember>(sortedData),
    ...rest,
  };
};

export const useWorkspaceOwner = (workspaceId?: string) => {
  return useQuery(
    ['space', workspaceId, 'owner'],
    () =>
      getWorkspaceMembers(workspaceId!, {
        filterBy: 'userRole:OWNER',
        limit: 1,
      }).then((data) => (data && data.length ? data[0] : undefined)),
    {
      enabled: !!workspaceId,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );
};

export const useTransferWorkspace = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: TransferWorkspaceParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId) throw new Error('WorkspaceID is required');
      return putWorkspaceOwner(params);
    },
    {
      onSuccess: async () =>
        Promise.all([
          queryClient.invalidateQueries('space'),
          queryClient.invalidateQueries(['me']),
        ]),
    },
  );
};

export const useRemoveMember = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: WorkspaceMemberRemoveParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return deleteMember(params);
    },
    {
      onSuccess: async (_result, params) =>
        Promise.all([
          queryClient.invalidateQueries([
            'space',
            params.workspaceId,
            'members',
          ]),
          optimisticUpdateWorkspaceMemberCount(
            params.workspaceId,
            -1 * (params.params?.userIds?.length ?? 0),
          ),
        ]),
    },
  );
};

export const useChangeMemberRole = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: WorkspaceMemberRoleParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return putMemberRole(params);
    },
    {
      onSuccess: async (_result, params) =>
        queryClient.invalidateQueries(['space', params.workspaceId, 'members']),
    },
  );
};

export const useActivateMember = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: WorkspaceMemberActivateParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return putMemberStatus(params);
    },
    {
      onSuccess: async (_result, params) =>
        Promise.all([
          queryClient.invalidateQueries([
            'space',
            params.workspaceId,
            'members',
          ]),
          optimisticUpdateWorkspaceMemberCount(
            params.workspaceId,
            params.params?.userIds?.length,
          ),
        ]),
    },
  );
};

export const useValidatePromoteMembers = (workspaceId?: string) => {
  return useQuery(
    ['space', workspaceId, 'members', 'promotion'],
    () =>
      getWorkspaceMemberPromotionValidity({
        workspaceId,
      }).catch(() => []),
    {
      enabled: !!workspaceId,
      refetchOnMount: false,
      refetchOnWindowFocus: ({ state }) => {
        return !state.data || !!state.data.length;
      },
      staleTime: 1000,
      keepPreviousData: true,
    },
  );
};
