import {
  BillingCurrency,
  ComputeStripePaymentInfo,
  StripePlan,
} from '../../../model/workspaceBilling';
import { formatPriceSignOnly } from '../../../utils/priceFormat';
import { getChargedToday, getPaidToday } from '../../../utils/checkout';
import { PurchasePlanDisplay } from './types';
import { PaymentPlan } from '../../../model/paymentPlans';

export const getFormattedChargedToday = (
  paymentInfo: ComputeStripePaymentInfo,
): string =>
  formatPriceSignOnly(getChargedToday(paymentInfo), paymentInfo.currency, 2);

export const getFormattedTotalDue = (
  paymentInfo: ComputeStripePaymentInfo,
): string => {
  let paidToday = getPaidToday(paymentInfo);
  if (paymentInfo.taxRate > 0) {
    paidToday += (paidToday * paymentInfo.taxRate) / 100;
  }

  return formatPriceSignOnly(paidToday, paymentInfo.currency, 2);
};

export const getFormattedTaxAmountPaidToday = (
  paymentInfo: ComputeStripePaymentInfo,
) =>
  formatPriceSignOnly(
    getPaidToday(paymentInfo) * (paymentInfo.taxRate / 100),
    paymentInfo.currency,
    2,
  );

export const getFormattedPaidToday = (paymentInfo: ComputeStripePaymentInfo) =>
  formatPriceSignOnly(getPaidToday(paymentInfo), paymentInfo.currency, 2);

export const getPurchasePlansDisplay = (
  stripePlans: StripePlan[],
  paymentPlans: PaymentPlan[],
  currency: BillingCurrency,
) => {
  const purchasePlans: PurchasePlanDisplay[] = [];

  let stripePlan;
  for (let i = 0; i < stripePlans.length; i += 1) {
    stripePlan = stripePlans[i];

    for (let j = 0; j < paymentPlans.length; j += 1) {
      if (paymentPlans[j].stripePriceId === stripePlan.stripePriceId) {
        purchasePlans.push({
          totalAmount: stripePlan.totalAmount,
          quantity: stripePlan.quantity,
          isGmail: paymentPlans[j].userPlan === 'PRO_PERSONAL',
          currency,
        });
        break;
      }
    }
  }

  return purchasePlans;
};
