import React, { useRef, useCallback, memo } from 'react';
import debounce from 'lodash.debounce';
import SearchBar from 'components/SearchBar';
import { DateFilter, FilterComponent } from 'components/ResponsiveTable/types';
import { Option } from '@scriptaddicts/yamm-ui-components';
import { nanoid } from 'nanoid';
import { SelectContainer, Select } from './styles';

const filterValues = [
  { value: 'LAST_30_DAYS', label: 'Last 30 days' },
  { value: 'LAST_MONTH', label: 'Last month' },
  { value: 'THIS_MONTH', label: 'This month' },
  { value: 'LAST_12_MONTHS', label: 'Last 12 months' },
  { value: 'THIS_YEAR', label: 'This year' },
];

const Search: FilterComponent = ({
  onChange,
  currentDateFilter,
  onDateFilterChange,
  goToPage,
}) => {
  const searchRef = useRef<HTMLInputElement | null>(null);
  const debouncedChange = debounce(onChange, 200);

  const handleChange = useCallback(
    (value: string | undefined) => {
      const change = value || searchRef.current?.value || '';
      debouncedChange(
        change.length > 0 ? encodeURIComponent(change) : undefined,
      );
    },
    [debouncedChange],
  );

  const handleFilterChange = useCallback(
    (value: DateFilter) => {
      if (!onDateFilterChange) return;
      onDateFilterChange(value);
      if (goToPage) {
        goToPage(0);
      }
    },
    [onDateFilterChange, goToPage],
  );

  return (
    <SearchBar
      onChange={handleChange}
      ref={searchRef}
      select={
        <SelectContainer>
          <Select
            onChange={(v) => handleFilterChange(v as DateFilter)}
            value={currentDateFilter}
          >
            {filterValues.map((filter) => (
              <Option key={nanoid()} value={filter.value}>
                {filter.label}
              </Option>
            ))}
          </Select>
        </SelectContainer>
      }
    />
  );
};

export default memo(Search);
