import {
  Button,
  Dialog,
  FlexContainer,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import { useConnect } from './connect';
import { Title, Container, ActionContainer } from './styles';
import { Props } from './types';

export const RemoveValidation: FC<Props> = ({ onClose, emailAddress }) => {
  const { userSharedBounces, isLoading, onRemove } = useConnect({
    onClose,
    emailAddress,
  });
  return (
    <Dialog show={!!emailAddress} onClose={() => !isLoading && onClose()}>
      <Container>
        <FlexContainer flex="vertical" gap="medium">
          <Title>Remove this recipient from your bounces list?</Title>
          {userSharedBounces && (
            <Text
              type="paragraph"
              size="base"
              width="regular"
              style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            >
              It will be removed from your bounces list and the bounces lists of
              all your spaces.
            </Text>
          )}
        </FlexContainer>
        <ActionContainer>
          <Button
            aria-label="remove bounce"
            size="base"
            variant="primary"
            onClick={onRemove}
            disabled={isLoading}
            loading={isLoading}
          >
            Yes, proceed
          </Button>
          <Button
            aria-label="cancel"
            size="base"
            variant="secondary"
            disabled={isLoading}
            onClick={onClose}
          >
            No, cancel
          </Button>
        </ActionContainer>
      </Container>
    </Dialog>
  );
};
