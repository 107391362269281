import { clientV2 } from 'data/client';
import { transformConsolidatedBounces } from 'data/transform/bounce';
import {
  ConsolidatedBounceInput,
  DeleteBounce,
  PostBouncesInput,
  PostBouncesParams,
} from 'model/bounce';
import { InputParams } from 'model/parameters';

export async function getBounces(workspaceId: string, params?: InputParams) {
  const { data } = await clientV2.get<{
    items: ConsolidatedBounceInput[];
  }>(`/users/me/workspaces/${workspaceId}/bounces`, {
    params,
  });

  return transformConsolidatedBounces(data.items);
}

export async function postBouncesOnActivation(
  workspaceId: string,
  params: PostBouncesParams,
) {
  const { data } = await clientV2.post<PostBouncesInput>(
    `/users/workspace-activation-add-bounces/me`,
    { ...params, workspaceId },
  );

  return data.updated;
}

export async function postBounces(
  workspaceId: string,
  params: PostBouncesParams,
) {
  const { data } = await clientV2.post<PostBouncesInput>(
    `/users/me/workspaces/${workspaceId}/bounces`,
    params,
  );

  return data.updated;
}

export async function deleteBounceOnActivation(
  workspaceId: string,
  { emailAddress }: DeleteBounce,
) {
  const { data } = await clientV2.delete(
    `/users/workspace-activation-delete-bounces/me/${workspaceId}/${encodeURIComponent(
      emailAddress,
    )}`,
  );

  return data;
}

export async function deleteBounce(
  workspaceId: string,
  { emailAddress }: DeleteBounce,
) {
  const { data } = await clientV2.delete(
    `/users/me/workspaces/${workspaceId}/bounces/${encodeURIComponent(
      emailAddress,
    )}`,
  );

  return data;
}
