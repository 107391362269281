import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

export default function useUrlGroupDisclosure(to: string) {
  const match = useRouteMatch(to);
  const [show, setShow] = useState<boolean>();

  useEffect(() => {
    if (!match) {
      setShow(false);
    }
  }, [setShow, match]);

  const onClick = useCallback(() => {
    setShow((status) => !status);
  }, [setShow]);

  return useMemo(
    () => ({
      onClick,
      'data-active': match ? 'active' : undefined,
      'data-show': true,
    }),
    [onClick, match, show],
  );
}
