import React from 'react';
import { Column } from 'components/ResponsiveTable/types';
import { formatShortMonthDayYear } from 'utils/dates';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import testingVar from 'utils/testingVar';
import { Unsubscription } from 'model/unsubscription';
import {
  TableCell,
  TableCellValue,
  TableCellDateTimeValue,
} from '@scriptaddicts/yamm-ui-components';
import { DeleteButton, Spinner } from '../styles';

export const getColumns = ({
  isMobile,
  hasPermission,
  onDelete,
  deleteEmail,
}: {
  isMobile: boolean;
  hasPermission: boolean;
  onDelete: (emailAddress: string) => void;
  deleteEmail?: string;
}) =>
  [
    {
      Header: 'Email address',
      accessor: 'email',
      defaultSorted: 'asc',
      colspan: 5,
      group: isMobile,
      Cell: ({ value }) =>
        isMobile ? (
          <TableCell width="medium" colSpan={5}>
            {value}
          </TableCell>
        ) : (
          <TableCellValue width="medium">{value}</TableCellValue>
        ),
    },
    {
      Header: 'Date',
      accessor: 'unsubscriptionTime',
      colspan: 1,
      filter: 'unix',
      sortType: 'datetime',
      Cell: ({ value }) => (
        <TableCellDateTimeValue
          size={isMobile ? 'extra-small' : undefined}
          variant={isMobile ? 'gray' : undefined}
          value={value}
          formatFunction={formatShortMonthDayYear}
        />
      ),
    },
    {
      Header: 'Delete',
      accessor: 'responsiveRight',
      id: 'delete-action',
      float: 'right',
      canSort: false,
      alignment: 'right',
      Cell: ({ row }) => {
        const { email, type } = row.original as Unsubscription;
        return (
          <DeleteButton
            $size={isMobile ? 'large' : 'small'}
            data-test-id={testingVar('submit-delete')}
            type="button"
            onClick={() => onDelete(email)}
            disabled={!!deleteEmail || (!hasPermission && type === 'WORKSPACE')}
          >
            {email === deleteEmail ? (
              <Spinner size={isMobile ? 20 : 16} />
            ) : (
              <TrashIcon />
            )}
          </DeleteButton>
        );
      },
    } as Column,
  ] as Column<Unsubscription>[];
