import {
  BillingBusinessType,
  BillingInterval,
  BillingWorkspacePlan,
  WorkspaceSubscription,
} from '../../../model/workspaceBilling';
import { CheckoutUser } from '../../../model/checkout';
import { ComputeStripePaymentInfoRequest } from '../../../data/requests/workspaceBilling';
import { mapTableUserToPaidUser } from '../../../utils/checkout/mappers';
import {
  FlowType,
  getNotRemovedUsers,
  getPaidUsers,
} from '../../../utils/checkout';

const DEFAULT_COUNTRY = 'US';

export const getCountry = (sub: WorkspaceSubscription): string =>
  sub?.additionalStripeInfo?.country || DEFAULT_COUNTRY;

export const getPurchaseType = (
  sub: WorkspaceSubscription,
): BillingBusinessType =>
  sub?.additionalStripeInfo?.companyName ? 'B2B' : 'B2C';

export type GetComputeParamsFlow = 'OPTIONS' | 'INFO'; // info is not used, information section performs different flow

export const getComputeParams = (
  computeParamsFlow: GetComputeParamsFlow,
  activeSubscription: WorkspaceSubscription | undefined,
  users: CheckoutUser[],
  billingInterval: BillingInterval,
  changeBillingCycle: boolean,
  plan?: BillingWorkspacePlan,
): ComputeStripePaymentInfoRequest => {
  if (activeSubscription != null) {
    // update
    const isLegacy = activeSubscription.workspacePlan === 'LEGACY';
    return {
      country:
        computeParamsFlow === 'INFO'
          ? getCountry(activeSubscription)
          : DEFAULT_COUNTRY, // in case of options use default country (US) to not calculate tax
      purchaseType: getPurchaseType(activeSubscription),
      userUpdates: changeBillingCycle
        ? undefined
        : getNotRemovedUsers(users).map(mapTableUserToPaidUser),
      billingInterval:
        changeBillingCycle || isLegacy ? billingInterval : undefined,
      workspacePlan: changeBillingCycle ? undefined : plan,
      upgradeLegacySubscription: isLegacy,
    };
  }

  // create
  return {
    country: DEFAULT_COUNTRY,
    purchaseType: 'B2C',
    userUpdates: getPaidUsers(users).map(mapTableUserToPaidUser),
    workspacePlan: plan,
    billingInterval,
  };
};

export const getNextButtonLabel = (flowType: FlowType): string => {
  if (
    flowType === 'PURCHASE' ||
    flowType === 'UPGRADE_LEGACY_US' ||
    flowType === 'UPGRADE_LEGACY_US_INDIVIDUAL'
  ) {
    return 'Next: Billing information';
  }

  if (
    flowType === 'UPGRADE_LEGACY_BE' ||
    flowType === 'UPGRADE_LEGACY_BE_INDIVIDUAL'
  ) {
    return 'Confirm the plan switch';
  }

  return 'Next: Review';
};
