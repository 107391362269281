import React, { FC } from 'react';

import { ReactComponent as ExternalLinkIcon } from 'assets/images/icons/external-link.svg';

import { Route, Switch } from 'react-router-dom';
import testingVar from 'utils/testingVar';
import { Dialog, Tooltip } from '@scriptaddicts/yamm-ui-components';
import { RouterTab } from 'components/Link';
import {
  Container,
  TitleContainer,
  Title,
  TitlePlaceholder,
  Subtitle,
  SubtitlePlaceholder,
  NoSubject,
  Divider,
  Content,
  Info,
  Column,
  Label,
  Data,
  DataPlaceholder,
  Tabs,
  PollSupport,
  Help,
  Link,
  IconWrapper,
  LinkIcon,
  Placeholder,
} from './styles';

import { useConnect } from './connect';
import { Props } from './types';
import MailResults from './MailResults';
import PollResults from './PollResults';

const CampaignDetail: FC<Props> = () => {
  const {
    isLoading,
    url,
    dialog,
    mailMerge,
    lastEmailSent,
    poll,
  } = useConnect();

  return (
    <Dialog close="both" {...dialog}>
      <Container>
        <TitleContainer>
          {mailMerge?.title ? (
            <Title>{mailMerge?.title}</Title>
          ) : (
            <TitlePlaceholder />
          )}
          {isLoading ? (
            <SubtitlePlaceholder />
          ) : (
            <>
              {mailMerge?.emailSubject ? (
                <Subtitle>{mailMerge?.emailSubject}</Subtitle>
              ) : (
                <NoSubject>
                  Subject is unavailable because this mail merge has been sent
                  by someone else
                </NoSubject>
              )}
            </>
          )}
        </TitleContainer>
        <Divider />
        <Content data-test-id={testingVar('campaign-details')}>
          <Info>
            <Column>
              <Label>Emails sent</Label>
              {!!mailMerge?.trackingInfos.sent ||
              mailMerge?.trackingInfos.sent === 0 ? (
                <Data>{mailMerge?.trackingInfos.sent}</Data>
              ) : (
                <DataPlaceholder />
              )}
            </Column>
            <Column>
              <Label>Last email sent</Label>
              {lastEmailSent ? (
                <Data>{lastEmailSent}</Data>
              ) : (
                <DataPlaceholder />
              )}
            </Column>
          </Info>
          <Tabs size="small">
            <RouterTab exact to={`${url}`}>
              Mail results
            </RouterTab>
            {poll ? (
              <RouterTab
                data-test-id={testingVar('view-poll-results')}
                exact
                to={`${url}/poll`}
              >
                Poll results
              </RouterTab>
            ) : (
              <Tooltip
                tip="Read about YAMM polls and their set up"
                placement="right-end"
              >
                <PollSupport
                  data-test-id={testingVar('no-poll-results')}
                  href="https://support.yet-another-mail-merge.com/hc/en-us/articles/115002407565"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Poll Results
                  <Help />
                </PollSupport>
              </Tooltip>
            )}
          </Tabs>
          <Switch>
            <Route exact path={`${url}`}>
              <MailResults campaign={mailMerge} />
            </Route>
            <Route exact path={`${url}/poll`}>
              {poll ? <PollResults poll={poll} /> : <Placeholder />}
            </Route>
          </Switch>
        </Content>
        <Link
          href={mailMerge?.spreadsheetLink || '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper>
            <ExternalLinkIcon />
          </IconWrapper>
          Open in Google Sheets
          <LinkIcon />
        </Link>
      </Container>
    </Dialog>
  );
};

export default CampaignDetail;
