import { Text as DefaultText } from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';

import { Card as GlobalCard } from '../styles';

export const Card = styled(GlobalCard)`
  width: 100%;
  max-width: 29.5rem;
`;

export const Title = styled(DefaultText).attrs({
  type: 'paragraph',
  width: 'semiBold',
})``;

export const Text = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'small',
  width: 'regular',
})`
  max-width: 392px;
`;

export const Video = styled.video`
  margin-top: 0.75rem;
  width: 21.875rem;
  min-height: 12.406rem;
  border: 0.055rem solid #d9e0e3;
  filter: drop-shadow(0 0.273rem 0.82rem rgba(0, 0, 0, 0.15));
  border-radius: 0.328rem;
  overflow: hidden;
`;

export const ButtonLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  text-decoration: none;
`;
