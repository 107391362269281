import styled from 'styled-components';
import { ReactComponent as LogoImage } from 'assets/images/icons/yamm-logo.svg';
import { NavLink } from 'react-router-dom';
import { from } from 'styles/media';
import { Icon as DefaultIcon } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;

  position: sticky;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(31, 35, 48, 0.2);
  ${from.mobile} {
    width: 256px;
    min-width: 256px;
    overflow-y: auto;
    box-shadow: none;
  }
  z-index: 4000;
  background-color: ${({ theme }) => theme.colors.blue1000};
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${from.mobile} {
    gap: 20px;
  }
`;

export const BottomSection = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 72px;
  padding: 20px 32px;
`;

export const Logo = styled(LogoImage)``;

export const Navigation = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0px;
  &[data-disabled] {
    > a,
    > button,
    > div > a {
      pointer-events: none;
      opacity: 0.5;
    }
  }
`;

export const ItemIcon = styled.div``;

export const CannotAccessSpacesText = styled.p`
  color: ${({ theme }) => theme.colors.blue100};
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: -0.195px;
  padding: 0 12px;
`;

export const Item = styled(NavLink).attrs(() => ({
  activeClassName: 'active',
}))`
  display: flex;
  align-items: center;
  gap: 18px;

  padding: 10px 16px;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.blue100};
  transition: background 0.1s, box-shadow 0.1s;

  > ${ItemIcon} {
    color: ${({ theme }) => theme.colors.blue200};
  }

  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.blue950};
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.colors.blue200};
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};

    > span {
      font-weight: 600;
    }

    > ${ItemIcon} {
      color: ${({ theme }) => theme.colors.orangeRed400};
    }
  }
`;

export const LightBlueDown = styled(DefaultIcon).attrs({
  name: 'chevron-down',
})`
  justify-self: flex-end;
  align-self: center;
  margin-left: auto;

  svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${({ theme }) => theme.colors.blue200};
    }
  }
  transition: transform 300ms;
`;

export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0 0 16px 0;
  background-color: ${({ theme }) => theme.colors.blue950};
`;

export const ItemGroupTrigger = styled.button`
  display: flex;
  align-items: center;
  gap: 18px;

  padding: 10px 16px;
  border: none;
  background-color: ${({ theme }) => theme.colors.blue950};
  font: inherit;
  margin: 0;

  color: ${({ theme }) => theme.colors.blue100};
  transition: background 0.1s, box-shadow 0.1s;

  > ${ItemIcon} {
    color: ${({ theme }) => theme.colors.blue200};
  }

  + ${ItemGroup} {
    display: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.blue200};
  }

  &[data-active] {
    color: ${({ theme }) => theme.colors.white};

    > span {
      font-weight: 600;
    }

    > ${ItemIcon} {
      color: ${({ theme }) => theme.colors.orangeRed400};
    }
  }

  &[data-active],
  &[data-show] {
    > ${LightBlueDown} {
      transform: rotate(-180deg);
    }

    + ${ItemGroup} {
      display: flex;
    }
  }
`;

export const ItemGroupItem = styled(NavLink).attrs(() => ({
  activeClassName: 'active',
}))`
  display: flex;
  align-items: center;
  gap: 28px;

  padding: 4px 20px 4px 42px;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.blue100};

  > ${ItemIcon} {
    color: ${({ theme }) => theme.colors.blue200};
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blue200};
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};

    > span {
      font-weight: 600;
    }

    > ${ItemIcon} {
      color: ${({ theme }) => theme.colors.orangeRed400};
    }
  }
`;
