import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useChangeActiveWorkspace } from 'data/hooks/useWorkspace';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPathForSpace } from '../../utils/workspace';

function useChangeSelectedWorkspace() {
  const { push } = useHistory();
  const {
    id,
    otherWorkspaces,
    isLoading: isWorkspaceLoading,
    isPreviousWorkspace,
  } = useWorkspaceContext();

  const { changeWorkspace } = useChangeActiveWorkspace();
  const currentWorkspace = useMemo(
    () => otherWorkspaces.find((a) => a.id === id),
    [id, otherWorkspaces],
  );

  const [requestedWorkspaceId, setRequestedWorkspaceId] = useState<
    string | undefined
  >(undefined);

  const navigateToSpacePage = () => {
    const pathForSpace = getPathForSpace(currentWorkspace);
    push(pathForSpace);
  };

  const isLoading = useMemo(() => {
    return isWorkspaceLoading || isPreviousWorkspace;
  }, [isWorkspaceLoading, isPreviousWorkspace]);

  const changeSelectedWorkspaceThenNavigate = useCallback(
    (workspaceId?: string) => {
      if (!workspaceId || workspaceId === id) {
        navigateToSpacePage();
      } else {
        changeWorkspace(workspaceId);
        setRequestedWorkspaceId(workspaceId);
      }
    },
    [id, requestedWorkspaceId],
  );

  useEffect(() => {
    if (!isLoading && requestedWorkspaceId === id && requestedWorkspaceId) {
      setTimeout(() => {
        navigateToSpacePage();
      }, 0);
    }
  }, [requestedWorkspaceId, id, isLoading]);

  return {
    changeSelectedWorkspaceThenNavigate,
  };
}

export default useChangeSelectedWorkspace;
