import {
  PaidUser,
  StripePlan,
  UpdateWorkspaceSubscriptionUserPlan,
} from '../../model/workspaceBilling';
import { getAvailablePlanForEmail } from './index';
import { CheckoutFormValues } from '../../data/context/CheckoutContext/types';
import { CreateStripeCheckoutSessionRequest } from '../../data/requests/workspaceBilling';
import { CheckoutUser } from '../../model/checkout';

export const mapTableUserToPaidUser = (user: CheckoutUser): PaidUser => ({
  userId: user.id,
  // email: user.email,
  userPlan: user.isPaid ? getAvailablePlanForEmail(user.email) : 'FREE',
});

export type MapCheckoutFormToCheckoutRequestProps = {
  workspaceId: string;
  checkoutForm: CheckoutFormValues;
  stripePlans: StripePlan[];
};
export const mapCheckoutFormToCheckoutRequest = ({
  workspaceId,
  checkoutForm,
  stripePlans,
}: MapCheckoutFormToCheckoutRequestProps): CreateStripeCheckoutSessionRequest & {
  workspaceId: string;
} => ({
  workspaceId,
  addressInfo: {
    country: checkoutForm.country,
    state: checkoutForm.state,
    city: checkoutForm.city,
    postalCode: checkoutForm.code,
    street: checkoutForm.street,
  },
  companyName: checkoutForm.companyName,
  customerName: checkoutForm.name,
  isB2BTransaction: checkoutForm.isBusinessPurchase,
  vatNumber: checkoutForm.vatNumber,
  goBackUrl: window.location.href,
  userName: checkoutForm.name,
  plans: stripePlans,
});

export const mapStripePlanToUpsertPlan = (
  stripePlan: StripePlan,
): UpdateWorkspaceSubscriptionUserPlan => ({
  stripePriceId: stripePlan.stripePriceId,
  userIds: Array.from(stripePlan.userIds),
  downgradeToFree: false,
});
