import { MouseEventHandler, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface LinkProps {
  onClick?: MouseEventHandler<any>;
  to:
    | string
    | {
        pathname?: string;
        search?: string;
        hash?: string;
        key?: string;
      };
  replace?: boolean;
  exact?: boolean;
}

export default function useRouterLink({
  to,
  replace: replaceFlag,
  onClick: onClickDefault,
  exact,
}: LinkProps) {
  const matches = useRouteMatch(exact ? `*${to}` : `*${to}*`);
  const { push, replace, createHref } = useHistory();
  const onClick = useCallback(
    (e: any) => {
      e.preventDefault();
      if (replaceFlag) {
        replace(to);
      } else {
        push(to);
      }
      if (onClickDefault) {
        onClickDefault(e);
      }
    },
    [onClickDefault, push, replace, replaceFlag, to],
  );

  const href = useMemo(() => (typeof to === 'string' ? to : createHref(to)), [
    createHref,
    to,
  ]);

  return { href, onClick, active: exact ? !!matches?.isExact : !!matches };
}
