import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe, useUserWorkspace } from 'data/hooks/useMe';
import { useCreateWorkspace } from 'data/hooks/useWorkspace';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { from, useMediaQuery } from 'styles/media';
import { Props } from './types';

export const useConnect = ({ onClose }: Props) => {
  const {
    setAlerts,
    setKeepPreviousWorkspace,
    otherWorkspaces,
  } = useWorkspaceContext();
  const { replace } = useHistory();
  const { data: me } = useMe();
  const { mutate: createWorkspace, status, reset } = useCreateWorkspace();

  const [workspaceName, setWorkspaceName] = useState('');
  const [error, setError] = useState<
    'REQUIRED' | 'EMPTY' | 'TOO_SHORT' | 'TOO_LONG' | undefined
  >(undefined);

  const onCreate = useCallback(() => {
    if (!workspaceName) {
      setError('REQUIRED');
      return;
    }
    if (workspaceName && !workspaceName.trim()) {
      setError('EMPTY');
      return;
    }
    if (workspaceName.trim().length < 5) {
      setError('TOO_SHORT');
      return;
    }
    if (workspaceName.trim().length > 255) {
      setError('TOO_LONG');
      return;
    }
    createWorkspace({ workspaceName: workspaceName.trim(), userId: me?.id });
  }, [workspaceName, me?.id]);

  const resetForm = useCallback(() => {
    setWorkspaceName('');
    setError(undefined);
  }, [setWorkspaceName]);

  const { data: newWorkspace } = useUserWorkspace();
  useEffect(() => {
    if (status === 'success') {
      reset();
      onClose();
      resetForm();
      replace(`${newWorkspace?.urlPrefix}/mail-merges/sent`);
      setAlerts({
        create: {
          name: newWorkspace?.workspaceName,
        },
      });
    }
    if (status === 'error') {
      setAlerts({ error: { type: 'create' } });
    }
    setKeepPreviousWorkspace(status);
  }, [
    reset,
    onClose,
    resetForm,
    replace,
    setAlerts,
    setKeepPreviousWorkspace,
    status,
    newWorkspace?.workspaceName,
    newWorkspace?.urlPrefix,
  ]);

  const isMobile = !useMediaQuery(from.tablet);

  const maximumNumberOfWorkspaces = 5;
  const hasTooManyWorkspaces = useMemo(
    () =>
      otherWorkspaces.filter((w) => w.role === 'OWNER').length >=
      maximumNumberOfWorkspaces,
    [otherWorkspaces],
  );

  return {
    isLoading: status === 'loading',
    resetForm,
    onCreate,
    workspaceName,
    setWorkspaceName,
    error,
    isMobile,
    hasTooManyWorkspaces,
    maximumNumberOfWorkspaces,
  };
};
