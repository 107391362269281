export type CheckoutMarkedUser = {
  id: string;
  isPaid: boolean;
};
export type CheckoutMarkedUsers = {
  users?: CheckoutMarkedUser[];
};

const KEY = 'checkout-users';

export const readCheckoutSessionUsers = (): CheckoutMarkedUsers | undefined => {
  try {
    const users = sessionStorage.getItem(KEY);
    if (users) {
      return JSON.parse(users) as CheckoutMarkedUsers;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const writeCheckoutSessionUsers = (users: CheckoutMarkedUsers) => {
  try {
    sessionStorage.setItem(KEY, JSON.stringify(users));
  } catch (e) {
    // ignore
  }
};
