import React from 'react';
import { Placeholder, Text } from '@scriptaddicts/yamm-ui-components';
import { useConnect } from './connect';
import LoadingScreen from '../../../components/LoadingScreen';
import {
  StyledPriceSection,
  StyledSeparator,
  StyledTotalSection,
  StyledNextButton,
  StyledStripeLogo,
  StyledPriceSectionPlaceholder,
  StyledBillingCycle,
} from '../common/styles';
import { getFormattedTotalDue } from '../common/utils';
import { from, useMediaQuery } from '../../../styles/media';
import { BillingPurchasePlans, PriceFooter } from '../common/components';
import { getNextButtonLabel } from './utils';
import { getSubscriptionPriceWithTax } from '../../../utils/checkout';
import { getNextLink } from '../BillingOptions/utils';

const BillingOptionsSidebar = ({
  onSubmit,
  disabled,
}: {
  onSubmit: () => void;
  disabled: boolean;
}) => {
  const {
    billingInterval,
    paymentInfo,
    isFetchingPaymentInfo,
    isLoading,
    flowType,
    purchasePlansDisplay,
    plan,
    paidUsersCount,
  } = useConnect();
  const isTablet = useMediaQuery(from.tablet);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const nextUrl = getNextLink(flowType);
  const nextButtonLabel = getNextButtonLabel(flowType);
  return (
    <>
      <BillingPurchasePlans workspacePlan={plan} plans={purchasePlansDisplay} />

      <StyledSeparator />

      <StyledTotalSection>
        <div>
          <Text type="paragraph" size="extraLarge" width="bold">
            Total due
          </Text>
          <StyledBillingCycle>Before taxes</StyledBillingCycle>
        </div>

        {isFetchingPaymentInfo && !paymentInfo ? (
          <StyledPriceSectionPlaceholder>
            <Placeholder size="extra-large" type="paragraph" width={66} />
            <Placeholder size="base" type="paragraph" width={91} />
          </StyledPriceSectionPlaceholder>
        ) : (
          <StyledPriceSection>
            {/* PLAN UPDATE, PAYMENT INFO COMPUTED ON SERVER */}
            {paymentInfo && (
              <>
                <Text type="paragraph" size="extraLarge" width="bold">
                  {getFormattedTotalDue(paymentInfo)}
                </Text>
                <PriceFooter
                  displayPrice={false}
                  billingInterval={billingInterval}
                  currency={paymentInfo.currency}
                  renewalPrice={getSubscriptionPriceWithTax(paymentInfo)}
                />
              </>
            )}
          </StyledPriceSection>
        )}
      </StyledTotalSection>

      <StyledNextButton
        aria-label={nextButtonLabel}
        data-log-event={
          nextUrl ? `go_to${nextUrl.replace(/\//g, '_')}` : 'go_to_undefined'
        }
        data-log-event-data={JSON.stringify({
          displayName: nextButtonLabel,
        })}
        onClick={onSubmit}
        disabled={disabled || (flowType === 'PURCHASE' && paidUsersCount < 1)}
      >
        {nextButtonLabel}
      </StyledNextButton>
      {isTablet && <StyledStripeLogo />}
    </>
  );
};

export default BillingOptionsSidebar;
