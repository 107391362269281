import { client } from 'data/client';
import { transformTeamMembers, transformTeamReport } from 'data/transform/team';
import { InputParams } from 'model/parameters';
import { TeamMemberInput, TeamReportInput } from 'model/team';

export async function getTeamMembers(
  workspaceId: string,
  params?: InputParams,
) {
  const { data } = await client.get<{ items: TeamMemberInput[] }>(
    `/workspaces/${workspaceId}/team-report/members`,
    {
      params,
    },
  );
  return transformTeamMembers(data.items);
}

export async function getTeamReport(workspaceId: string) {
  const { data } = await client.get<TeamReportInput | undefined | null>(
    `/workspaces/${workspaceId}/team-report/summary`,
  );
  return transformTeamReport(data);
}
