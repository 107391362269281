import { useDisclosure } from '@scriptaddicts/yamm-ui-components';
import { RowProps } from 'components/ResponsiveTable/types';
import useTableStateManager from 'components/ResponsiveTable/useTableStateManager';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import { useWorkspaceMembers } from 'data/hooks/useWorkspaceMembers';
import { WorkspaceMember } from 'model/workspaceMembers';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { from, useMediaQuery } from 'styles/media';
import { useLocation } from 'react-router-dom';
import { logDisplayModal } from 'utils/userJourneyLogging';
import useManageUser from './ManageUser/useManageUser';
import { getColumns } from './table/columns';
import { FETCH_WORKSPACE_USERS_MAX } from '../../../../data/constants';
import { useWorkspaceActiveSubscription } from '../../../../data/hooks/useWorkspaceBilling';
import { isSupportGivenSubscription } from '../../../../utils/subscriptions';
import { Links } from '../../../../data/links';

const countMembersStats = (members: WorkspaceMember[]) => {
  const temp = {
    total: members.length,
    paid: 0,
    gmailPaid: 0,
    trial: 0,
    free: 0,
    disabled: 0,
  };
  members.forEach((member) => {
    if (member.plan === 'PRO' && !member.trial) {
      temp.paid += 1;
    }
    if (member.plan === 'PRO_PERSONAL' && !member.trial) {
      temp.gmailPaid += 1;
    }
    if (member.trial) {
      temp.trial += 1;
    }
    if (member.plan === 'FREE' && member.status !== 'DISABLED') {
      temp.free += 1;
    }
    if (member.status === 'DISABLED') {
      temp.disabled += 1;
    }
  });
  return temp;
};

export const useConnect = () => {
  const { data: me, status: meStatus } = useMe({ keepPreviousData: true });
  const {
    id,
    details,
    user,
    isLoading,
    restrictions,
    userAlerts,
    setUserAlerts,
  } = useWorkspaceContext();
  const { data: members, status: membersStatus } = useWorkspaceMembers(id, {
    filters: {
      memberStatus: ['ACTIVE', 'DISABLED', 'PENDING', 'ACCEPTED'],
    },
    limit: FETCH_WORKSPACE_USERS_MAX,
  });
  const { data: activeSubscription } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: false,
    retrieveAdditionalStripeInfo: false,
  });

  const canManageUsers = useMemo(() => {
    return user!.role === 'OWNER' || user!.role === 'ADMIN';
  }, [user]);

  const counts = useMemo(() => countMembersStats(members), [members]);

  const limits = useMemo(() => {
    return {
      freeMember: details?.features.FREE.allowedUserCount ?? 0,
      domain: details?.features.FREE.hasMultiDomain ? undefined : me?.domain,
      paidMember: details?.features.PRO.allowedUserCount ?? 0,
    };
  }, [me, details]);

  const availability = useMemo(() => {
    const add = (() => {
      if (
        details?.legacyType !== 'TEAM' &&
        details?.legacyType !== 'UNLIMITED'
      ) {
        return 'TYPE';
      }
      if (limits.paidMember !== -1 && counts.paid >= limits.paidMember) {
        return 'LIMIT';
      }

      return undefined;
    })();

    return { add };
  }, [details, limits, counts]);

  const isMobile = !useMediaQuery(from.tablet);
  const columns = useMemo(
    () =>
      getColumns({
        isMobile,
        domain: limits.domain,
        usersDowngradeDate: activeSubscription?.endTimestamp || 0,
      }),
    [isMobile, limits, availability, activeSubscription],
  );

  const processRow = useCallback((row: WorkspaceMember):
    | RowProps
    | undefined => {
    if (row.status === 'DISABLED')
      return {
        variant: 'warning',
      };
    if (row.status === 'PENDING')
      return {
        variant: 'disabled',
      };

    return undefined;
  }, []);

  const {
    state: { selection },
    manager: tableStateManager,
  } = useTableStateManager({
    idField: 'email',
  });
  const { pathname, search } = useLocation();
  const invite = useDisclosure({});
  const add = useDisclosure({});
  const manage = useManageUser({ members, ...selection });

  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setUserAlerts({});
    };
  }, [id]);

  const isSupportSubscription =
    activeSubscription && isSupportGivenSubscription(activeSubscription);

  const onContactUs = () => {
    window.open(Links.CONTACT_US, '_blank');
  };

  return {
    id,
    name: details?.name,
    isLoading:
      isLoading || meStatus === 'loading' || membersStatus === 'loading',
    isBusy,
    promote: details?.plan === 'FREE' && user?.role === 'OWNER',
    counts,
    members,
    columns,
    processRow,
    tableStateManager,
    isSupportSubscription,
    canManageUsers,
    invite: {
      ...invite,
      setUserAlerts,
      feature: details?.plan !== 'LEGACY' && !isSupportSubscription,
      restricted: !!restrictions?.INVITATION,
      limit: limits.freeMember,
      onOpen: () => {
        invite.onOpen();
        setTimeout(() =>
          logDisplayModal('send_invitation', pathname, search, id),
        );
      },
    },
    add: {
      ...add,
      setUserAlerts,
      feature: details?.plan === 'LEGACY',
      isTeam:
        details?.legacyType === 'TEAM' || details?.legacyType !== 'UNLIMITED',
      warning: availability.add,
      limit: limits.paidMember,
      paidUsersCount: counts.paid, // member.plan === 'PRO' && !member.trial
    },
    manage: {
      ...manage,
      setUserAlerts,
      setBusy: setIsBusy,
      limit: {
        domain: limits.domain,
        freeUsersMax: limits.freeMember,
      },
    },
    userAlerts,
    onContactUs,
  };
};
