import { useMemo } from 'react';
import { useStackDriverLogging } from '../../data/context/StackDriverLogging';
import { Promotion } from '../../model/promotion';
import {
  generateLogObject,
  SMALL_CANNON_EVENT_TYPE,
} from '../../utils/smallCannonUtils';
import { productValidationSchema } from './constants';
import { Props } from './types';

export const useLogic = ({ cannon }: Props) => {
  const title = 'Enjoying YAMM?';
  const subtitle = 'Discover our other products:';

  const promotions: Promotion[] | null = useMemo(() => {
    if (cannon && cannon.promotions != null) {
      const arr: Promotion[] = [];
      const validator = productValidationSchema();

      cannon.promotions.forEach((promo) => {
        if (validator.isValidSync(promo)) {
          arr.push(promo);
        }
      });

      if (arr.length > 0) {
        return arr;
      }
    }

    return null;
  }, [cannon]);

  const { logToStackDriver, getUser, getWorkspace } = useStackDriverLogging();
  const logClick = (product: Promotion): void => {
    logToStackDriver(
      generateLogObject(
        SMALL_CANNON_EVENT_TYPE.AD_CLICK,
        product,
        getUser(),
        getWorkspace(),
      ),
    );
  };

  const logDisplay = () => {
    if (promotions) {
      logToStackDriver(
        generateLogObject(
          SMALL_CANNON_EVENT_TYPE.AD_LIST_DISPLAY,
          {
            listHead: `${title} ${subtitle}`,
          },
          getUser(),
          getWorkspace(),
        ),
      );

      promotions.forEach((promo) => {
        logToStackDriver(
          generateLogObject(
            SMALL_CANNON_EVENT_TYPE.AD_DISPLAY,
            promo,
            getUser(),
            getWorkspace(),
          ),
        );
      });
    }
  };

  return {
    title,
    subtitle,
    logClick,
    logDisplay,
    promotions,
  };
};
