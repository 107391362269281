import React, { FC, HTMLAttributes } from 'react';
import { Props } from './types';
import { Container, ForegroundContainer, Subtitle, Title } from './styles';

export const EmptyTitle: FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return <Title {...props} />;
};

export const EmptySubtitle: FC<HTMLAttributes<HTMLParagraphElement>> = (
  props,
) => {
  return <Subtitle {...props} />;
};

export const Empty: FC<Props> = ({ background, foreground, ...props }) => {
  return (
    <Container {...props}>
      {background}
      <ForegroundContainer>
        {foreground || <EmptyTitle>No results found</EmptyTitle>}
      </ForegroundContainer>
    </Container>
  );
};
