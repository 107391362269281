import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';
import {
  DropdownListItem as DefaultDropdownListItem,
  Text as DefaultText,
} from '@scriptaddicts/yamm-ui-components';

export const Trigger = styled.button`
  all: unset;
  cursor: pointer;

  :focus {
    outline-offset: 2px;
    outline: 2px auto;
    border-radius: 50%;
  }
`;

export const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  flex: 1;
`;

export const Name = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
  width: 'medium',
})`
  line-height: 160% !important;
`;

export const Email = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
})`
  line-height: 160% !important;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const AccountDropdownListItem = styled(DefaultDropdownListItem).attrs({
  disabled: true,
})`
  display: inline-flex;
  gap: 16px;
  padding: 16px;
  user-select: unset;

  :disabled {
    background-color: ${({ theme }) => theme.colors.white};
    color: inherit;
    cursor: unset;
  }
`;

export const DropdownListItem = styled(DefaultDropdownListItem)`
  position: relative;

  display: inline-flex;
  gap: 24px;
  flex: 1;

  color: ${({ theme }) => theme.colors.gray900};
  padding: 20px 25px;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray800};
`;

export const Text = styled(DefaultText).attrs({
  type: 'span',
  size: 'extraLarge',
})`
  flex: 1;
  line-height: 24px !important;
`;

export const Chevron = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.gray200};
`;
