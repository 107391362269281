import {
  ConsolidatedUnsubscriptionInput,
  Unsubscription,
} from 'model/unsubscription';

export const transformConsolidatedUnsubscriptions = (
  unsubscriptions: ConsolidatedUnsubscriptionInput[],
): Unsubscription[] => {
  return unsubscriptions.map((unsubscription) => ({
    email: unsubscription.emailAddress,
    unsubscriptionTime: new Date(
      Number(
        unsubscription.lastUpdateTimestamp ??
          unsubscription.createTimestamp ??
          Date.now(),
      ),
    ).toString(),
    type: unsubscription.type,
  }));
};
