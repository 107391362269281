import React from 'react';
import StaticLayout from 'containers/Common/StaticLayout';
import { Prompt } from 'components/Prompt';
import { Link } from './styles';

// eslint-disable-next-line consistent-return
function getCurrentBrowser() {
  const { userAgent } = window.navigator;
  if (userAgent.includes('Firefox/')) {
    return 'firefox';
  }
  if (userAgent.includes('Chrome/') && userAgent.includes('Safari/')) {
    return userAgent.includes('Edg/') ? 'edge' : 'chrome';
  }
  if (!userAgent.includes('Chrome/') && userAgent.includes('Safari/')) {
    return 'safari';
  }
}

const supportLinksForEachBrowser: {
  [browser in NonNullable<ReturnType<typeof getCurrentBrowser>>]: string;
} = {
  chrome:
    'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en#zippy=%2Callow-or-block-cookies',
  firefox:
    'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop#w_adjust-your-global-enhanced-tracking-protection-settings',
  safari:
    'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac',
  edge:
    'https://answers.microsoft.com/en-us/edge/forum/edge_other-edge_win10/how-do-i-enable-third-party-cookies-in-microsoft/02617812-72de-473b-8ccf-025d29ec0bce',
};

function IncognitoMode() {
  const currentBrowser = getCurrentBrowser();
  const supportLink = currentBrowser
    ? supportLinksForEachBrowser[currentBrowser]
    : undefined;
  const browserName =
    currentBrowser && currentBrowser[0].toUpperCase() + currentBrowser.slice(1);
  return (
    <StaticLayout>
      <Prompt
        variant="warning"
        title="Whoops"
        description={
          <>
            <p>
              First and third party cookies must be enabled to use the YAMM
              dashboard.
            </p>
            {supportLink && (
              <Link
                href={supportLink}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Learn how to enable cookies in {browserName}
              </Link>
            )}
          </>
        }
      />
    </StaticLayout>
  );
}

export default IncognitoMode;
