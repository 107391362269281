import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useEffect, useMemo } from 'react';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import { matchPath, useLocation } from 'react-router-dom';

function canShowLegacy(pathname: string): boolean {
  const match = matchPath(pathname, {
    path: '/:slug/:name/space/billing',
  });
  return !match;
}

export const useConnect = () => {
  const {
    id,
    isPreviousWorkspace,
    alerts,
    setAlerts,
    setAlertsSeen,
    user,
  } = useWorkspaceContext();
  const { workspaceId } = useWorkspaceLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isPreviousWorkspace) return;
    if (id !== workspaceId) return;
    setAlertsSeen();
  }, [alerts, id, workspaceId, isPreviousWorkspace]);

  return useMemo(
    () => ({
      ...alerts,
      workspaceId: id,
      legacy:
        alerts.legacy && canShowLegacy(pathname)
          ? {
              onDismiss: () => {
                setAlerts({ legacy: undefined });
              },
            }
          : undefined,
      join: alerts.join
        ? {
            ...alerts.join,
            onDismiss: () => {
              setAlerts({ join: undefined });
            },
          }
        : undefined,
      error: alerts.error
        ? {
            ...alerts.error,
            onDismiss: () => {
              setAlerts({ error: undefined });
            },
          }
        : undefined,
      showBillingWarnings:
        user && (user.role === 'ADMIN' || user.role === 'OWNER'),
    }),
    [alerts, pathname, id, user, setAlerts],
  );
};
