import React, { FC } from 'react';
import {
  Alert as AlertContainer,
  Button,
} from '@scriptaddicts/yamm-ui-components';

export const Alert: FC<{
  onClose: () => void;
  type: 'error' | 'warning';
}> = ({ onClose, children, type }) => (
  <AlertContainer
    type={type}
    append={
      <Button
        aria-label="close"
        icon="x"
        variant={type === 'error' ? 'danger' : 'warning'}
        onClick={onClose}
      />
    }
  >
    {children}
  </AlertContainer>
);
