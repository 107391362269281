import React, { FC, useCallback } from 'react';
import { ScheduledCampaign } from 'model/scheduledCampaign';
import { RowLink } from 'components/ResponsiveTable/Rows/styles';
import ResponsiveTable from 'components/ResponsiveTable';
import { Button, Text } from '@scriptaddicts/yamm-ui-components';
import Search from './table/filter';
import Exporter from './table/exporter';
import Empty from './table/empty';
import NotFound from './table/notFound';
import { useConnect } from './connect';
import ScheduledCampaignDetail from './ScheduledCampaignDetail';
import { Alert, AlertContainer } from './styles';

const ScheduledMailMerges: FC = () => {
  const {
    scheduledCampaigns,
    getScheduledCampaigns,
    isLoading,
    tableStateManager,
    columns,
    scheduledTestingVar,
    url,
    alert,
    setAlert,
  } = useConnect();
  const rowLink = useCallback(
    (props: { item: ScheduledCampaign }) => (
      <RowLink tabIndex={-1} to={`${url}/${props.item.id}`} />
    ),
    [url],
  );
  return (
    <>
      {alert && (
        <AlertContainer>
          <Alert
            type={alert.status}
            append={
              <Button
                aria-label="dismiss"
                icon="x"
                variant="tertiary"
                size="small"
                onClick={() => setAlert(undefined)}
              />
            }
          >
            <Text type="paragraph" size="base" width="regular">
              {alert.status === 'success' && 'Mail merge cancelled.'}
              {alert.status === 'error' &&
                'An error occurred. Please try again.'}
            </Text>
          </Alert>
        </AlertContainer>
      )}
      <ResponsiveTable<ScheduledCampaign>
        data={scheduledCampaigns}
        getData={getScheduledCampaigns}
        loading={isLoading}
        tableStateManager={tableStateManager}
        columns={columns}
        rowLink={rowLink}
        empty={<Empty />}
        notFound={<NotFound />}
        filter={Search}
        exporter={Exporter}
        header="float"
        sortable
        float="right"
        disableFilter
      />
      {scheduledTestingVar ? <span data-test-id={scheduledTestingVar} /> : null}
      <ScheduledCampaignDetail setAlert={setAlert} />
    </>
  );
};

export default ScheduledMailMerges;
