import React, { FC } from 'react';
import { FlexContainer, Strong, Text } from '@scriptaddicts/yamm-ui-components';
import { PlanUpdateDelta } from 'utils/checkout/planUpdateDeltas';
import { StyledDifferenceText, StyledFromText, StyledToText } from './styles';
import {
  BillingInterval,
  BillingWorkspacePlan,
} from '../../../model/workspaceBilling';
import { getBillingIntervalLabel } from '../../../utils/checkout';
import { plurify } from '../../../utils/plurify';

const DifferenceText: FC<{ title: string }> = ({ title, children }) => (
  <FlexContainer flex="vertical" gap="none">
    <Text type="paragraph" width="bold" size="base">
      {title}
    </Text>
    <StyledDifferenceText>{children}</StyledDifferenceText>
  </FlexContainer>
);

const UsersDowngradeText = ({
  users,
  isGmail,
  isUpgrade,
}: {
  users: number;
  isGmail: boolean;
  isUpgrade: boolean;
}) => {
  if (isUpgrade) {
    return (
      <Text type="paragraph" size="extraLarge">
        You are upgrading{' '}
        <Strong>
          {users} {isGmail ? '@gmail.com ' : ''}free {plurify(users, 'user')}
        </Strong>{' '}
        to <Strong>paid</Strong>.
      </Text>
    );
  }

  return (
    <Text type="paragraph" size="extraLarge">
      You are downgrading{' '}
      <Strong>
        {users} {isGmail ? '@gmail.com ' : ''}paid {plurify(users, 'user')}
      </Strong>{' '}
      to <Strong>free</Strong>.
    </Text>
  );
};

const FromToText = ({
  title,
  from,
  to,
}: {
  title: string;
  from: string;
  to: string;
}) => {
  return (
    <DifferenceText title={title}>
      From <StyledFromText>{from}</StyledFromText> to{' '}
      <StyledToText>{to}</StyledToText>
    </DifferenceText>
  );
};

export const PlanDelta = ({
  plan,
}: {
  plan: PlanUpdateDelta<BillingWorkspacePlan>;
}) => <FromToText title="Plan:" from={plan.from} to={plan.to} />;

export const BillingCycleDelta = ({
  billingInterval,
}: {
  billingInterval: PlanUpdateDelta<BillingInterval>;
}) => (
  <FromToText
    title="Billing cycle:"
    from={`billed ${getBillingIntervalLabel(billingInterval.from, true)}`}
    to={`billed ${getBillingIntervalLabel(billingInterval.to, true)}`}
  />
);

export const PaidUsersDelta = ({
  paidUsers,
}: {
  paidUsers: PlanUpdateDelta<number>;
}) => {
  const delta = paidUsers.from - paidUsers.to;

  return (
    <UsersDowngradeText
      isUpgrade={delta < 0}
      users={Math.abs(delta)}
      isGmail={false}
    />
  );
};

export const GmailPaidUsersDelta = ({
  gmailPaidUsers,
}: {
  gmailPaidUsers: PlanUpdateDelta<number>;
}) => {
  const delta = gmailPaidUsers.from - gmailPaidUsers.to;

  return (
    <UsersDowngradeText isUpgrade={delta < 0} users={Math.abs(delta)} isGmail />
  );
};
