import React from 'react';
import { Button, Strong, Text } from '@scriptaddicts/yamm-ui-components';
import { plurify } from '../../../utils/plurify';
import { StyledStripeLogo } from '../common/styles';
import { from, useMediaQuery } from '../../../styles/media';
import { StyledContainer } from './styles';

export type LegacyUsersSidebarProps = {
  selectedUsersCount: number;
  maxUsersCount: number;
  onNext: () => void;
};
const LegacyUsersSidebar = ({
  maxUsersCount,
  selectedUsersCount,
  onNext,
}: LegacyUsersSidebarProps) => {
  const isTablet = useMediaQuery(from.tablet);

  return (
    <StyledContainer>
      <Text>
        <Strong>{selectedUsersCount}</Strong>{' '}
        {plurify(selectedUsersCount, 'user')} (out of {maxUsersCount} max.)
      </Text>
      <Button
        aria-label="Next: Billing options"
        onClick={onNext}
        disabled={selectedUsersCount > maxUsersCount || selectedUsersCount < 1}
      >
        Next: Billing options
      </Button>
      {isTablet && <StyledStripeLogo />}
    </StyledContainer>
  );
};

export default LegacyUsersSidebar;
