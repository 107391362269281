import React, { useMemo } from 'react';
import { Text } from '@scriptaddicts/yamm-ui-components';
import {
  ComputeStripePaymentInfo,
  WorkspaceSubscription,
} from '../../../model/workspaceBilling';
import { getFormattedChargedToday } from '../common/utils';
import { getChargedToday } from '../../../utils/checkout';
import { StyledGreenText } from '../common/styles';
import { PlanUpdateDeltas } from '../../../utils/checkout/planUpdateDeltas';
import { formatShortMonthDayYear } from '../../../utils/dates';

export type SummaryConfirmMessageProps = {
  paymentInfo: ComputeStripePaymentInfo;
  deltas?: PlanUpdateDeltas;
  activeSubscription: WorkspaceSubscription;
};
export const SummaryConfirmMessage = ({
  paymentInfo,
  deltas,
  activeSubscription,
}: SummaryConfirmMessageProps) => {
  const displayMessage = useMemo(() => {
    if (
      deltas &&
      deltas.billingCycle &&
      deltas.billingCycle.from === 'YEARLY' &&
      deltas.billingCycle.to === 'MONTHLY'
    ) {
      return 'DOWNGRADE_CYCLE';
    }

    const chargedToday = getChargedToday(paymentInfo);
    if (chargedToday != null && chargedToday > 0) return 'CHARGED_TODAY';

    return 'NEXT_INVOICE';
  }, [paymentInfo, deltas]);

  return (
    <>
      {displayMessage === 'DOWNGRADE_CYCLE' && (
        <Text type="paragraph" size="small" width="semiBold">
          If you confirm, your changes will be automatically applied at the end
          of your current billing cycle:{' '}
          <StyledGreenText>
            {formatShortMonthDayYear(activeSubscription.endTimestamp)}
          </StyledGreenText>
          .
        </Text>
      )}
      {displayMessage === 'NEXT_INVOICE' && (
        <Text type="paragraph" size="small" width="semiBold">
          These changes will be applied at renewal day on{' '}
          {formatShortMonthDayYear(activeSubscription.endTimestamp)}.
        </Text>
      )}
      {displayMessage === 'CHARGED_TODAY' && (
        <Text type="paragraph" size="small" width="semiBold">
          If you confirm, you will be charged{' '}
          <StyledGreenText>
            {getFormattedChargedToday(paymentInfo)}
          </StyledGreenText>{' '}
          (pro-rated based on your billing cycle).
        </Text>
      )}
    </>
  );
};
