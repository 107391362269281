import { useDisclosure } from '@scriptaddicts/yamm-ui-components';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import {
  useCancelInviteMembers,
  useResendInviteMembers,
} from 'data/hooks/useWorkspaceInvite';
import {
  useActivateMember,
  useChangeMemberRole,
} from 'data/hooks/useWorkspaceMembers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserRole, ActiveUsers, Props } from './types';
import { useActiveUsers } from './hooks/useActiveUsers';
import { useDisabledUsers } from './hooks/useDisabledUsers';
import { usePendingUsers } from './hooks/usePendingUsers';
import { useWorkspaceActiveSubscription } from '../../../../../data/hooks/useWorkspaceBilling';
import { useMaxUsersCheck } from './hooks/useMaxUsersCheck';
import { writeCheckoutSessionUsers } from '../../../../../utils/checkoutSessionUsers';
import { useUpgradeUsers } from './hooks/useUpgradeUsers';

const hasMemberRoleChanged = (
  active: ActiveUsers,
  selectedRole: UserRole,
): boolean =>
  active.features.change.role &&
  !active.disabled.change.role &&
  active.list.filter((member) => member.role !== selectedRole).length > 0;

const hasMemberPlanChanged = (active: ActiveUsers, currentPlan: string) =>
  active.features.change.type &&
  !active.disabled.change.type &&
  active.list.filter((member) => member.plan !== currentPlan).length > 0;

export const useConnect = ({
  selected,
  setUserAlerts,
  setBusy,
  onClose,
  limit,
  allUsers,
}: Props) => {
  const { data: me } = useMe({ keepPreviousData: true });
  const { id, details, user, urlPrefix, restrictions } = useWorkspaceContext();
  const { push } = useHistory();
  const { data: activeSubscription } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });

  const upgrade = useUpgradeUsers({
    details,
    user,
    selected,
  });

  const active = useActiveUsers({
    workspaceId: id,
    selected,
    allUsers,
    me,
    workspace: details,
    workspaceUser: user,
    isMultiplePaymentRestricted: !!restrictions?.MULTIPLE_PAYMENT,
    activeSubscription,
  });

  const disabled = useDisabledUsers({
    selected,
    allUsers,
    limit,
  });

  const pending = usePendingUsers({
    selected,
  });

  const [selectedRole, setSelectedRole] = useState<'MEMBER' | 'ADMIN'>(
    'MEMBER',
  );
  const {
    status: roleStatus,
    mutateAsync: changeRole,
    reset: roleReset,
  } = useChangeMemberRole();

  useEffect(() => {
    if (roleStatus === 'success') {
      roleReset();
      setUserAlerts({
        roleChangedMembers: active.list.map(({ name }) => name),
      });
      onClose();
    }
  }, [roleReset, setUserAlerts, onClose, roleStatus, active.list]);

  const [selectedType, setSelectedType] = useState<
    'TRIAL' | 'FREE' | 'PRO' | 'PRO_PERSONAL'
  >('FREE');

  const activeFormChanged = useMemo<Record<string, boolean>>(
    () => ({
      role: active.list.some(
        ({ role }) => role !== 'OWNER' && role !== selectedRole,
      ),
      type: active.list.some(
        ({ plan, trial }) =>
          (trial && selectedType !== 'TRIAL') || plan !== selectedType,
      ),
    }),
    [active.list, selectedRole, selectedType],
  );

  const changeMemberButtonRef = useRef<HTMLButtonElement>(null);
  const changeMember = useCallback(async () => {
    if (hasMemberRoleChanged(active, selectedRole)) {
      await changeRole({
        workspaceId: id,
        params: {
          memberRoles: active.list.map(({ id: userId }) => ({
            userId,
            userRole: selectedRole,
          })),
        },
      });
    }

    if (hasMemberPlanChanged(active, selectedType)) {
      const checkoutSessionUsers = {
        users: active.list.map((u) => ({
          id: u.id,
          isPaid: selectedType === 'PRO' || selectedType === 'PRO_PERSONAL',
        })),
      };
      writeCheckoutSessionUsers(checkoutSessionUsers);
      push(`${urlPrefix}/checkout/summary?from=users`);
    }
  }, [
    changeRole,
    id,
    active,
    active.list,
    selectedRole,
    selectedType,
    urlPrefix,
  ]);

  const cancelMember = useCallback(() => {
    setSelectedType(() => (!active.type ? 'FREE' : active.type));
    setSelectedRole(
      !active.role || active.role === 'OWNER' ? 'MEMBER' : active.role,
    );
  }, [setSelectedType, setSelectedRole, active.type, active.role]);
  useEffect(() => {
    cancelMember();
  }, [active.role, active.type, cancelMember]);

  const removeActiveMembers = useDisclosure({});

  const [resendCount, setResendCount] = useState<number>(0);
  const {
    status: resendStatus,
    mutate: resend,
    reset: resendReset,
  } = useResendInviteMembers();
  useEffect(() => {
    if (resendStatus === 'success') {
      resendReset();
      setUserAlerts({ reInviteCount: resendCount });
      onClose();
    }
  }, [resendReset, setUserAlerts, onClose, resendStatus, resendCount]);

  const resendInvite = useCallback(() => {
    setResendCount(pending.list.length);
    resend({
      workspaceId: id,
      params: {
        invitedUserEmails: pending.list.map(({ email }) => email),
      },
    });
  }, [resend, id, pending.list, setResendCount]);

  const [cancelCount, setCancelCount] = useState<number>(0);
  const {
    status: cancelStatus,
    mutate: cancel,
    reset: cancelReset,
  } = useCancelInviteMembers();
  useEffect(() => {
    if (cancelStatus === 'success') {
      cancelReset();
      setUserAlerts({ cancelInviteCount: cancelCount });
      onClose();
    }
  }, [cancelReset, setUserAlerts, onClose, cancelStatus, cancelCount]);

  const cancelInvite = useCallback(() => {
    setCancelCount(pending.list.length);
    cancel({
      workspaceId: id,
      params: {
        invitedUserEmails: pending.list.map(({ email }) => email),
      },
    });
  }, [cancel, id, pending.list, setCancelCount]);

  const [activateList, setActivateList] = useState<string[]>([]);
  const {
    status: activateStatus,
    mutate: activate,
    reset: activateReset,
  } = useActivateMember();
  useEffect(() => {
    if (activateStatus === 'success') {
      activateReset();
      setUserAlerts({ activatedMembers: activateList });
      onClose();
    }
  }, [activateReset, setUserAlerts, onClose, activateStatus, activateList]);

  const activateMember = useCallback(() => {
    setActivateList(disabled.list.map(({ name }) => name));
    activate({
      workspaceId: id,
      params: {
        userIds: disabled.list.map(({ id: userId }) => userId),
      },
    });
  }, [activate, id, disabled.list, setActivateList]);

  const removeDisabledMembers = useDisclosure({});

  const isLoading = useMemo(() => {
    return (
      roleStatus === 'loading' ||
      resendStatus === 'loading' ||
      cancelStatus === 'loading' ||
      activateStatus === 'loading'
    );
  }, [roleStatus, resendStatus, cancelStatus, activateStatus]);
  useEffect(() => {
    setBusy(isLoading);
  }, [isLoading]);

  const { isMaxUsersReached } = useMaxUsersCheck({
    selected,
    allUsers,
    activeSubscription,
  });

  const upgradeSelectedUsersButtonRef = useRef<HTMLButtonElement>(null);
  const onUpgradeSelectedUsers = () => {
    writeCheckoutSessionUsers({
      users: upgrade.availableUsers.map((u) => ({
        id: u.id,
        isPaid: true,
      })),
    });
    push(`${urlPrefix}/space/billing?from=users`);
  };

  return {
    upgrade,
    active,
    disabled,
    pending,
    isLoading,
    activeFormChanged,
    isMaxUsersReached,
    onUpgradeSelectedUsers,
    upgradeSelectedUsersButtonRef,
    disablePlanUpdate: isMaxUsersReached || active.disabled.change.type,
    removeActiveMembers: {
      ...removeActiveMembers,
      members: active.list,
      setUserAlerts,
      onCloseDrawer: onClose,
    },
    changeMembers: {
      role: {
        value: selectedRole,
        onChange: (v: any) => setSelectedRole(v as 'MEMBER' | 'ADMIN'),
      },
      type: {
        value: selectedType,
        onChange: (v: any) =>
          setSelectedType(v as 'TRIAL' | 'FREE' | 'PRO' | 'PRO_PERSONAL'),
      },
      onSave: changeMember,
      onCancel: () => {
        cancelMember();
        onClose();
      },
      isLoading: roleStatus === 'loading',
    },
    changeMemberButtonRef,
    reInvite: {
      onClick: resendInvite,
      isLoading: resendStatus === 'loading',
    },
    cancelInvite: {
      onClick: cancelInvite,
      isLoading: cancelStatus === 'loading',
    },
    activateMember: {
      onClick: activateMember,
      isLoading: activateStatus === 'loading',
    },
    removeDisabledMembers: {
      ...removeDisabledMembers,
      members: disabled.list,
      setUserAlerts,
      onCloseDrawer: onClose,
    },
    memberLimit: limit.freeUsersMax,
    urlPrefix,
  };
};
