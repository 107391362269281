import React from 'react';
import { AgreeToSText, TosLink } from './styles';

const TosAgreementText = () => (
  <AgreeToSText>
    By clicking this button, you indicate that you have read and agree to be
    bound by the{' '}
    <TosLink
      href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/terms-of-service/`}
    >
      Terms of use
    </TosLink>{' '}
    and that you have read the{' '}
    <TosLink
      href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/privacy-policy/`}
    >
      Privacy policy
    </TosLink>
    .
  </AgreeToSText>
);

export default TosAgreementText;
