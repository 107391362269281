import { EmptyTitle, EmptySubtitle } from 'components/ResponsiveTable/Empty';
import React from 'react';

const BouncesEmpty = () => {
  return (
    <>
      <EmptyTitle>Nothing to see here...</EmptyTitle>
      <EmptySubtitle>There are no bounces</EmptySubtitle>
    </>
  );
};

export default BouncesEmpty;
