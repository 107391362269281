import React from 'react';
import { Link, Text } from '@scriptaddicts/yamm-ui-components';
import { useConnect } from './connect';
import { RouterLink } from '../../../../../components/Link';
import { StyledContainer } from './styles';
import { Alert } from './components';
import { formatShortDateTime } from '../../../../../utils/dates';
import Strong from '../../../../../components/Strong';

const BillingWarnings = () => {
  const {
    isSubscriptionCanceled,
    isPaymentAttemptRequiresConfirmation,
    isPaymentAttemptFailed,
    urlPrefix,
    onClose,
    endDate,
    hostedInvoiceUrl,
    displayAlert,
    alertKey,
  } = useConnect();

  return (
    <StyledContainer
      className={
        displayAlert &&
        (isSubscriptionCanceled ||
          isPaymentAttemptFailed ||
          isPaymentAttemptRequiresConfirmation)
          ? 'has-items'
          : ''
      }
    >
      {displayAlert && alertKey && isPaymentAttemptFailed && (
        <Alert type="warning" onClose={() => onClose(alertKey)}>
          <Text type="paragraph" size="large" width="semiBold">
            Your last payment attempt failed
          </Text>
          <Text type="paragraph" size="base" width="regular">
            Your subscription is still active but will be suspended on{' '}
            <Strong>
              {endDate > 0 ? `${formatShortDateTime(endDate)} ` : '-- '}
            </Strong>
            unless a successful payment is made.{' '}
            <Link href={hostedInvoiceUrl} target="_blank">
              Update your payment preferences
            </Link>
          </Text>
        </Alert>
      )}

      {displayAlert && alertKey && isPaymentAttemptRequiresConfirmation && (
        <Alert type="warning" onClose={() => onClose(alertKey)}>
          <Text type="paragraph" size="large" width="semiBold">
            Your last payment attempt requires confirmation
          </Text>
          <Text type="paragraph" size="base" width="regular">
            Your subscription is still active but will be suspended on{' '}
            <Strong>
              {endDate > 0 ? `${formatShortDateTime(endDate)} ` : '-- '}
            </Strong>
            unless a successful payment is made.{' '}
            <Link href={hostedInvoiceUrl} target="_blank">
              Confirm your payment
            </Link>
          </Text>
        </Alert>
      )}

      {displayAlert && alertKey && isSubscriptionCanceled && (
        <Alert type="error" onClose={() => onClose(alertKey)}>
          <Text type="paragraph" size="large" width="semiBold">
            All payment attempts failed
          </Text>
          <Text type="paragraph">
            Your subscription has been canceled.{' '}
            <RouterLink
              target="_blank"
              to={`${urlPrefix}/space/billing?show-plans=1`}
            >
              Purchase a new subscription
            </RouterLink>{' '}
            to upgrade your space.
          </Text>
        </Alert>
      )}
    </StyledContainer>
  );
};

export default BillingWarnings;
