import styled from 'styled-components';
import { Text as DefaultText } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ForegroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  gap: 8px;
`;

export const Title = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
  width: 'medium',
})`
  color: ${({ theme }) => theme.colors.gray4};
`;

export const Subtitle = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'base',
  width: 'regular',
})`
  color: ${({ theme }) => theme.colors.gray800};
`;
