import { EmptyTitle, EmptySubtitle } from 'components/ResponsiveTable/Empty';
import React from 'react';

const UnsubscriptionsEmpty = () => {
  return (
    <>
      <EmptyTitle>Nothing to see here...</EmptyTitle>
      <EmptySubtitle>There are no unsubscriptions</EmptySubtitle>
    </>
  );
};

export default UnsubscriptionsEmpty;
