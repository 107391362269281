import useTableStateManager from 'components/ResponsiveTable/useTableStateManager';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useScheduledCampaigns } from 'data/hooks/useScheduledCampaign';
import { ScheduledCampaignParams } from 'model/scheduledCampaign';
import { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { from, useMediaQuery } from 'styles/media';
import testingVar from 'utils/testingVar';
import { getColumns } from './table/columns';

export const useConnect = () => {
  const { id: workspaceId } = useWorkspaceContext();
  const { url } = useRouteMatch();

  const {
    params,
    manager: tableStateManager,
  } = useTableStateManager<ScheduledCampaignParams>({
    paginationKey: workspaceId,
    globalFilter: 'mergeSourceSheetTitle',
    sortBy: {
      mergeDate: 'createTimestamp',
      mergeDateTime: 'nextScheduledRun',
      title: 'mergeSourceSheetTitle',
    },
  });

  const {
    data: scheduledCampaigns,
    status,
    fetchAll: getScheduledCampaigns,
  } = useScheduledCampaigns(workspaceId, params);

  const isMobile = !useMediaQuery(from.tablet);
  const columns = useMemo(() => getColumns({ isMobile }), [isMobile]);
  const scheduledTestingVar = testingVar('scheduled-merges');

  const [alert, setAlert] = useState<
    | {
        status: 'success' | 'error';
      }
    | undefined
  >();

  return {
    scheduledCampaigns,
    getScheduledCampaigns,
    isLoading: status === 'loading',
    tableStateManager,
    columns,
    scheduledTestingVar,
    url,
    alert,
    setAlert,
  };
};
