import React from 'react';

import { MainPromptContainer } from 'components/MainContainer';
import { Prompt } from 'components/Prompt';

export const DisabledUser = () => {
  return (
    <MainPromptContainer>
      <Prompt
        variant="warning"
        title="Sorry, your account is disabled"
        description="Contact your space administrator to solve this issue."
      />
    </MainPromptContainer>
  );
};
