import styled from 'styled-components';
import { from } from 'styles/media';
import { Alert as DefaultAlert } from '@scriptaddicts/yamm-ui-components';
import { MainContainer } from 'components/MainContainer';

export const Alert = styled(DefaultAlert).attrs({
  stroke: '300',
})``;

export const Container = styled(MainContainer)`
  margin-bottom: 16px;
  :not(:empty) {
    margin-bottom: 32px;
  }

  ${Alert} {
    border-radius: 0px;
    :first-of-type {
      border-top-width: 0px;
    }
    :not(:first-of-type) {
      margin-top: 16px;
    }
  }

  ${from.tablet} {
    margin-bottom: 32px;
    :not(:empty) {
      margin-bottom: 16px;
      margin-top: 32px;
    }

    ${Alert} {
      border-radius: 6px;
      :first-of-type {
        border-top-width: 1px;
      }
    }
  }
`;
