/* eslint-disable import/no-webpack-loader-syntax */
import React, { memo } from 'react';
import { ExporterComponent } from 'components/ResponsiveTable/types';
import ExportToCsv from 'components/ExportToCsv';
import transformerWorkerUrl from 'worker-plugin/loader?name=bounces!../transformer';
import { useWorker } from 'utils/useWorker';
import { captureException } from '@sentry/minimal';
import { Bounce } from 'model/bounce';
import { BouncesWorker } from '../transformer';
import { useStackDriverLogging } from '../../../../data/context/StackDriverLogging';
import { getUserDataForLogging } from '../../../../utils/logging';

const Exporter: ExporterComponent<Bounce[]> = ({ data, getData }) => {
  const { worker: transformer } = useWorker<BouncesWorker>(
    transformerWorkerUrl,
    'BouncesWorker',
  );
  const { getUser, logToStackDriver, getWorkspace } = useStackDriverLogging();
  const onDone = (result: any[]) => {
    // log to the stack driver bounced emails
    try {
      const user = getUserDataForLogging(getUser(), getWorkspace());
      logToStackDriver({
        timestamp: Date.now(),
        user_id: user.user_id,
        user_installation_timestamp: user.user_installation_timestamp,
        plan: user.plan,
        is_biz_user: user.is_biz_user,
        domain: user.domain,
        event_type: 'csv_export',
        export_type: 'bounce',
        message: 'User exported bounce list (csv_export)',
        list_of_merge_ids: result.map((d) => d.email),
      });
    } catch (e) {
      captureException(e);
    }
  };
  return (
    <ExportToCsv
      data={data}
      getData={getData}
      transformer={transformer}
      fileName={`Exported_Bounces_${new Date().toDateString()}`}
      onDone={onDone}
    />
  );
};

export default memo(Exporter);
