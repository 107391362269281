import { queryClient } from 'data/queryClient';
import { UserInput } from 'model/user';
import { UpdateWorkspaceParams, Workspace } from 'model/workspace';

export const optimisticUpdateChangeWorkspace = async (workspaceId?: string) => {
  const isFetching = queryClient.getQueryState(['me'])?.isFetching;
  if (isFetching) {
    return queryClient.invalidateQueries(['me'], undefined, {
      cancelRefetch: true,
    });
  }

  const userWorkspaces = queryClient.getQueryData<UserInput>(['me'])
    ?.userWorkspaces;

  const isWorkspaceExists = userWorkspaces?.some(
    (w) => w.workspaceId === workspaceId,
  );

  if (isWorkspaceExists) {
    queryClient.setQueryData<UserInput>(['me'], (prev) => ({
      ...prev!,
    }));
  } else {
    return queryClient.invalidateQueries(['me']);
  }

  return Promise.resolve();
};

export const optimisticUpdateWorkspace = async (
  workspaceId?: string,
  params?: UpdateWorkspaceParams,
) => {
  const isFetching =
    queryClient.getQueryState(['me'])?.isFetching ||
    queryClient.getQueryState(['space', workspaceId])?.isFetching;
  if (isFetching) {
    return Promise.all([
      queryClient.invalidateQueries(['me'], undefined, { cancelRefetch: true }),
      queryClient.invalidateQueries(['space', workspaceId], undefined, {
        cancelRefetch: true,
      }),
    ]);
  }

  queryClient.setQueryData<Workspace>(['space', workspaceId], (prev) => ({
    ...prev!,
    name: params?.workspaceName ?? prev!.name,
    features: {
      ...prev!.features,
      isMemberAllowedToInvite:
        params?.memberInviteUser ?? prev!.features.isMemberAllowedToInvite,
    },
  }));

  queryClient.setQueryData<UserInput>(['me'], (prev) => ({
    ...prev!,
    userWorkspaces: [
      ...prev!.userWorkspaces.map((workspace) =>
        workspace.workspaceId === workspaceId
          ? {
              ...workspace,
              workspaceName: params?.workspaceName ?? workspace.workspaceName,
            }
          : workspace,
      ),
    ],
  }));

  return Promise.resolve();
};

export const optimisticUpdateRemoveWorkspace = async (workspaceId?: string) => {
  const userWorkspaces = queryClient.getQueryData<UserInput>(['me'])
    ?.userWorkspaces;

  const isWorkspaceExists = userWorkspaces?.some(
    (w) => w.workspaceId === workspaceId,
  );

  if (!isWorkspaceExists) return Promise.resolve();

  const isFetching = queryClient.getQueryState(['me'])?.isFetching;
  if (isFetching) {
    return queryClient.invalidateQueries(['me'], undefined, {
      cancelRefetch: true,
    });
  }

  return queryClient.invalidateQueries(['me']);
};

export const optimisticUpdateWorkspaceMemberCount = async (
  workspaceId?: string,
  memberCountDelta?: number,
) => {
  const isFetching = queryClient.getQueryState(['space', workspaceId])
    ?.isFetching;
  if (isFetching) {
    return queryClient.invalidateQueries(['space', workspaceId], undefined, {
      cancelRefetch: true,
    });
  }

  queryClient.setQueryData<Workspace>(['space', workspaceId], (prev) => ({
    ...prev!,
    memberCount: prev!.memberCount + (memberCountDelta ?? 0),
  }));

  return Promise.resolve();
};
