import React, { memo } from 'react';
import { FormLabel } from '@scriptaddicts/yamm-ui-components';
import useLogic from './logic';
import {
  Component,
  Container,
  ErrorText,
  Icon as DropdownIndicator,
  Optional,
} from './styles';
import { Props } from './types';

const CountrySelect = ({
  className,
  isDisabled,
  isOptional,
  label,
  name,
  placeholder,
  defaultValue,
  onChange,
}: Props) => {
  const { error, handleChange, hasError, options, value, onBlur } = useLogic({
    name,
    onChange,
    defaultValue,
  });

  return (
    <Container className={className}>
      <FormLabel htmlFor={name}>
        {label} {isOptional ? <Optional>(optional)</Optional> : null}
      </FormLabel>
      <Component
        classNamePrefix="react-select"
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        $hasValue={!!value}
        $hasError={hasError}
        id={name}
        isClearable={false}
        isDisabled={options.length === 0 || isDisabled}
        name={name}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
      />
      {hasError && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default memo(CountrySelect);
