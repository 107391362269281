import StatsCard from 'components/StatsCard';
import React from 'react';
import testingVar from 'utils/testingVar';
import { Link, Strong, Tooltip } from '@scriptaddicts/yamm-ui-components';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerSubtitle,
  MainContainerTitle,
  MainContainerLoader,
} from 'components/MainContainer';
import ResponsiveTable from 'components/ResponsiveTable';
import {
  InfoIcon,
  EmailsSentText,
  StatsContainer,
} from 'components/StatsCard/styles';
import { TeamMember } from 'model/team';
import { nanoid } from 'nanoid';
import { useConnect } from './connect';
import Empty from './table/empty';
import NotFound from './table/notFound';
import Exporter from './table/exporter';
import Search from './table/filter';
import { plurify } from '../../../utils/plurify';

const TeamReport = () => {
  const {
    isLoading,
    isNotAuthorized,
    legacy,
    workspace,
    report,
    users,
    getUsers,
    columns,
    tableStateManager,
  } = useConnect();

  if (isNotAuthorized) return <MainContainerLoader />;

  return (
    <MainContainer title="Team report">
      <MainContainerHeader direction="vertical">
        <MainContainerTitle>Team Report</MainContainerTitle>
        {legacy && (
          <MainContainerSubtitle>
            This lists anyone who has used YAMM in the{' '}
            {plurify(legacy.domains.length, 'domain', 'domains')}{' '}
            {legacy.domains.map((domain, index) => (
              <>
                <Link key={nanoid()} href={`http://${domain}`}>
                  {domain}
                </Link>
                {index + 1 !== legacy.domains.length && ', '}
              </>
            ))}
          </MainContainerSubtitle>
        )}
        {workspace && (
          <MainContainerSubtitle>
            This lists all active members of <Strong>{workspace.name}</Strong>
          </MainContainerSubtitle>
        )}
      </MainContainerHeader>
      <StatsContainer>
        <StatsCard
          variant="team"
          number={report?.totalMembers}
          text={`Active ${plurify(report?.totalMembers, 'user')}`}
          data-test-id={testingVar('active-users')}
        />
        <StatsCard
          variant="sent-this-month"
          number={report?.counters.totalEmailsSentThisMonth}
          content={
            <EmailsSentText type="span" size="extraSmall" width="regular">
              {plurify(report?.counters.totalEmailsSentThisMonth, 'Email')} sent
              the last 30 days
              <Tooltip
                tip="Excluding form submission notifications."
                placement="bottom"
              >
                <InfoIcon />
              </Tooltip>
            </EmailsSentText>
          }
          data-test-id={testingVar('sent-this-month')}
        />
        <StatsCard
          variant="emails"
          number={report?.counters.totalEmailsSent}
          content={
            <EmailsSentText type="span" size="extraSmall" width="regular">
              {plurify(report?.counters.totalEmailsSent, 'Email')} sent all time
              <Tooltip
                tip="Excluding form submission notifications."
                placement="bottom"
              >
                <InfoIcon />
              </Tooltip>
            </EmailsSentText>
          }
          data-test-id={testingVar('emails-all')}
        />
      </StatsContainer>
      <ResponsiveTable<TeamMember>
        data={users}
        getData={getUsers}
        loading={isLoading}
        tableStateManager={tableStateManager}
        columns={columns}
        empty={<Empty />}
        notFound={<NotFound />}
        filter={Search}
        exporter={Exporter}
        header="float"
        sortable
        float="both"
        disableFilter
      />
    </MainContainer>
  );
};

export default TeamReport;
