import React, { FC, memo } from 'react';
import { from, useMediaQuery } from 'styles/media';

import { Props } from './types';
import {
  TemplateCardContainer,
  Thumb,
  Image,
  ImagePlaceholder,
  Header,
  Title,
  TitlePlaceholder,
  ButtonLink,
  ButtonPlaceholder,
  Spinner,
} from './styles';

const TemplateCard: FC<Props> = ({
  messageId,
  loading,
  disabled,
  image,
  title,
  url,
  onImport,
  id,
  loggedUserIndex,
  imgLoaded,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);

  return (
    <TemplateCardContainer {...props}>
      <Thumb to={url || ''}>
        {image !== undefined ? (
          <Image $loaded={imgLoaded} src={image} />
        ) : (
          <ImagePlaceholder />
        )}
      </Thumb>
      <Header>
        {title !== undefined ? <Title>{title}</Title> : <TitlePlaceholder />}
        {!isMobile && (
          <>
            {url !== undefined ? (
              <>
                {loading ? (
                  <Spinner size={16} />
                ) : (
                  <>
                    {messageId ? (
                      <ButtonLink
                        aria-label="Open"
                        forwardedAs="a"
                        href={`https://mail.google.com/mail/u/${loggedUserIndex}/#drafts?compose=${messageId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open
                      </ButtonLink>
                    ) : (
                      <ButtonLink
                        variant="secondary"
                        aria-label="Import"
                        onClick={() => {
                          if (id && onImport) onImport({ fromTemplate: id });
                        }}
                        disabled={disabled}
                      >
                        Use
                      </ButtonLink>
                    )}
                  </>
                )}
              </>
            ) : (
              <ButtonPlaceholder />
            )}
          </>
        )}
      </Header>
    </TemplateCardContainer>
  );
};

export default memo(TemplateCard);
