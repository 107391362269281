import React from 'react';
import { Alert, Text } from '@scriptaddicts/yamm-ui-components';
import ResponsiveTable from '../../../../components/ResponsiveTable';
import { BlueLink, StyledTableContainer } from '../styles';
import { useConnect } from './connect';
import { Links } from '../../../../data/links';
import { MAX_USERS_PER_SPACE } from '../../../../data/constants';

export const CheckoutUsersTable = () => {
  const { availableUsers, tableColumns, reachedMaxUsers } = useConnect();

  return (
    <StyledTableContainer>
      {reachedMaxUsers && (
        <Alert type="warning" style={{ marginBottom: '1rem' }}>
          <Text width="semiBold" type="paragraph" size="large">
            Your current plan allows a maximum of {MAX_USERS_PER_SPACE} paid
            users.
          </Text>
          <Text width="semiBold" type="paragraph">
            <BlueLink href={Links.CONTACT_US}>
              Need more users? Contact us
            </BlueLink>
          </Text>
        </Alert>
      )}
      <ResponsiveTable
        columns={tableColumns}
        data={availableUsers}
        header="none"
        float="right"
      />
    </StyledTableContainer>
  );
};
