// eslint-disable-next-line import/no-cycle
import { client } from 'data/client';
import { Cannon } from '../../model/cannon';

export async function getCannon(project: string, placement: string) {
  const { data } = await client.get<Cannon>('/cannon', {
    params: { project, placement },
  });
  return data;
}
