import { Color, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardTitle, Card } from '../styles';
import {
  StyledListItem,
  LinksSection,
  StyledPlanContainer,
  PayPalIcon,
} from './styles';
import Strong from '../../../../../components/Strong';
import {
  BillingCurrency,
  BillingInterval,
} from '../../../../../model/workspaceBilling';
import { formatPriceSignOnly } from '../../../../../utils/priceFormat';
import { getBillingIntervalLabel } from '../../../../../utils/checkout';
import { RenewalText } from './components';
import { plurify } from '../../../../../utils/plurify';
import {
  PageRestrictions,
  FeatureRestrictions,
} from '../../../../../data/hooks/restrictions/useRestrictions';
import { Links } from '../../../../../data/links';

const getUsersLabel = ({
  paidUsers,
  freeUsers,
}: {
  paidUsers: number;
  freeUsers: number;
}) => {
  const users = [];
  if (paidUsers > 0) {
    users.push(
      <Color variant="success">
        <Strong>{paidUsers}</Strong> paid
      </Color>,
    );
  }
  if (freeUsers > 0) {
    users.push(
      users.length > 0 ? ', ' : undefined,
      <Color variant="info">
        <Strong>{freeUsers}</Strong> free
      </Color>,
    );
  }
  return users;
};

export function calculatePrecision(value: number): number {
  return Math.round(value) !== value ? 2 : 0;
}

export type PlanProps = {
  name: string;
  paidUsers: number;
  freeUsers: number;
  price: number;
  newPrice?: number;
  currency: BillingCurrency;
  billingCycle: BillingInterval;
  endDate: Date;
  isRenewal: boolean;
  isPayPal: boolean;
  isLegacy: boolean;
  tax: number;
  manageSubscriptionLink?: string;
  workspaceUrlPrefix: string;
  newCycle?: BillingInterval;
  onCancelCycleChange?: () => void;
  pageRestrictions: PageRestrictions;
  featureRestrictions: FeatureRestrictions;
};
const Plan = ({
  name,
  paidUsers,
  freeUsers,
  currency,
  price,
  newPrice,
  billingCycle,
  tax,
  endDate,
  isRenewal,
  isLegacy,
  isPayPal,
  manageSubscriptionLink,
  workspaceUrlPrefix,
  newCycle,
  onCancelCycleChange,
  pageRestrictions,
  featureRestrictions,
}: PlanProps) => {
  const { push } = useHistory();
  const totalPrice = price + price * (tax / 100);

  const onChangeBillingCycle = () => {
    push(`${workspaceUrlPrefix}/checkout?change-billing-cycle=1`);
  };

  const onManageUsers = () => {
    push(`${workspaceUrlPrefix}/space/users`);
  };

  const onManageSubscription = () =>
    window.open(manageSubscriptionLink, '_blank');

  const onManagePayPalSubscription = () =>
    window.open(Links.MANAGE_PAYPAL_PLAN, '_blank');

  const isUserPageRestricted =
    pageRestrictions.ready && pageRestrictions.userPage;
  const isMonthlyPlanRestricted =
    featureRestrictions.ready && featureRestrictions.monthlyBilling;
  const isLegacyPaypal = isLegacy && isPayPal;

  const getMonthlyPrice = (
    subscriptionPrice: number,
    cur: BillingCurrency,
    cycle: BillingInterval,
  ) => {
    const monthlyPrice =
      cycle === 'YEARLY' ? subscriptionPrice / 12 : subscriptionPrice;
    return formatPriceSignOnly(monthlyPrice, cur, 2);
  };

  return (
    <StyledPlanContainer>
      <Card>
        <CardTitle>Current plan: {name}</CardTitle>
        <Text type="paragraph">
          <Strong>{paidUsers + freeUsers}</Strong>{' '}
          {plurify(paidUsers + freeUsers, 'user')}{' '}
          {!isLegacy && <>({getUsersLabel({ freeUsers, paidUsers })})</>}
        </Text>
        {!isUserPageRestricted && (
          <LinksSection>
            <StyledListItem onClick={onManageUsers}>
              <Color variant="info">Manage users</Color>
            </StyledListItem>
          </LinksSection>
        )}
      </Card>
      <Card>
        {isLegacyPaypal && <PayPalIcon />}

        <CardTitle>
          {getMonthlyPrice(totalPrice, currency, billingCycle)}
          {' / '}
          month
        </CardTitle>
        <Text type="paragraph">
          <span style={{ fontWeight: 600 }}>
            {formatPriceSignOnly(
              totalPrice,
              currency,
              calculatePrecision(totalPrice),
            )}
          </span>
          {' billed '}
          {getBillingIntervalLabel(billingCycle, true)},{' '}
          {tax > 0 ? 'including taxes' : 'excluding taxes'}
        </Text>

        <RenewalText
          date={endDate}
          isRenewal={isRenewal}
          newCycle={newCycle}
          currency={currency}
          newPrice={newPrice}
          onCancelCycleChange={onCancelCycleChange}
        />

        <LinksSection>
          {manageSubscriptionLink && (
            <>
              <StyledListItem onClick={onManageSubscription} key="key1">
                <Color variant="info">Manage subscription</Color>
              </StyledListItem>
              {!isLegacy && (
                <StyledListItem onClick={onManageSubscription} key="key2">
                  <Color variant="info">Update payment info</Color>
                </StyledListItem>
              )}
            </>
          )}
          {!isLegacy && (
            <>
              {isRenewal && !newCycle && !isMonthlyPlanRestricted && (
                <StyledListItem onClick={onChangeBillingCycle} key="key3">
                  <Color variant="info">Change billing cycle</Color>
                </StyledListItem>
              )}
              <StyledListItem onClick={onManageSubscription} key="key4">
                <Color variant="info">Invoice history</Color>
              </StyledListItem>
            </>
          )}
          {isLegacyPaypal && (
            <StyledListItem onClick={onManagePayPalSubscription} key="key5">
              <Color variant="info">Manage subscription</Color>
            </StyledListItem>
          )}
        </LinksSection>
      </Card>
    </StyledPlanContainer>
  );
};

export default Plan;
