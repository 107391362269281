import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'data/context/AuthContext';
import testingVar from 'utils/testingVar';

import TrustpilotScore from 'assets/images/icons/trustpilot-score.png';
import { GoogleButton, Text } from '@scriptaddicts/yamm-ui-components';
import { RouterLink } from 'components/Link';
import TosAgreementText from '../../TosAgreementText';

import { stepOneLoad, stepOneSignUp } from '../events';

import {
  Title,
  ImageWrapper,
  YammImage,
  LinkWrapper,
  LoginCard,
} from '../../styles';
import { pushFunnel } from '../../../../utils/dataLayer';

const StepOne = () => {
  const { onSignUp, clientId } = useAuth();
  const history = useHistory();

  useEffect(() => {
    stepOneLoad();
    history.replace('signup');
    pushFunnel('Create your account', '1');
  }, []);

  return (
    <>
      <LoginCard>
        <ImageWrapper>
          <YammImage />
        </ImageWrapper>
        <Title>Welcome to YAMM</Title>
        {clientId ? (
          <GoogleButton
            data-test-id={testingVar('submit-login')}
            onClick={() => {
              stepOneSignUp();
              onSignUp();
            }}
          >
            Create a new account with Google
          </GoogleButton>
        ) : null}
        <TosAgreementText />
      </LoginCard>
      <Text type="paragraph" size="extraSmall" width="regular">
        Already have a YAMM account?{' '}
        <RouterLink style={{ fontWeight: 500 }} to="/login">
          Sign in
        </RouterLink>
      </Text>
      <LinkWrapper href="https://www.trustpilot.com/review/yamm.com">
        <img alt="trustpilot-score" src={TrustpilotScore} />
      </LinkWrapper>
    </>
  );
};

export default StepOne;
