import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import YammVideo from 'assets/video/yamm-instruction.webm';

import { Strong } from '@scriptaddicts/yamm-ui-components';
import { Button, Link } from '../styles';
import { Card, Title, Text, Video, ButtonLink } from './styles';

import {
  stepThreeLoad,
  stepThreeSheets,
  stepThreeDashboard,
  onboardingSend,
} from '../events';
import { pushFunnel } from '../../../../utils/dataLayer';

const StepThree = () => {
  const history = useHistory();
  useEffect(() => {
    onboardingSend();
    stepThreeLoad();
    history.replace('get-started');
    pushFunnel('Send your first merge', '3');
  }, []);

  return (
    <>
      <Card>
        <Title>Open Google Sheets to launch YAMM</Title>
        <Text>
          From Google Sheets, open the <Strong>Extensions</Strong> menu, look
          for
          <Strong>&nbsp;Yet Another Mail Merge</Strong> and then hit{' '}
          <Strong>Start Mail Merge</Strong>.
        </Text>
        <Video muted autoPlay loop src={YammVideo} />
        <ButtonLink onClick={stepThreeSheets} href="https://sheets.new">
          <Button style={{ marginTop: '16px' }} aria-label="Open Google Sheets">
            Open Google Sheets
          </Button>
        </ButtonLink>
      </Card>
      <Link onClick={stepThreeDashboard} to="/">
        Take me to my YAMM dashboard
      </Link>
    </>
  );
};

export default StepThree;
