import {
  useAddUnsubscriptions,
  useUnsubscriptions,
} from 'data/hooks/useUnsubscriptions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { captureException } from '@sentry/minimal';
import { UnsubscriptionParams } from 'model/unsubscription';
import { InputTag, useTagInput } from '@scriptaddicts/yamm-ui-components';
import { from, useMediaQuery } from 'styles/media';
import useTableStateManager from 'components/ResponsiveTable/useTableStateManager';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useWorkspaceOwner } from 'data/hooks/useWorkspaceMembers';
import { MutationStatus } from 'react-query';
import { getColumns } from './table/columns';
import { useStackDriverLogging } from '../../../data/context/StackDriverLogging';
import { getUserDataForLogging } from '../../../utils/logging';
import { isValidEmail } from '../../../utils/userUtils';

export const useConnect = () => {
  const {
    id: workspaceId,
    user: workspaceUser,
    details: workspaceDetails,
    restrictions: workspaceRestrictions,
  } = useWorkspaceContext();
  const { data: workspaceOwner } = useWorkspaceOwner(workspaceId);
  const { logToStackDriver, getUser, getWorkspace } = useStackDriverLogging();

  // Unsubscribe
  const {
    mutateAsync: addUnsubscriptions,
    status: addStatus,
    reset: resetAdd,
    data: addResult,
  } = useAddUnsubscriptions(workspaceId);

  const {
    tags: unsubscribes,
    value: fieldValue,
    setTags: setUnsubscribes,
    ...tagInput
  } = useTagInput({});
  const errors = useMemo<Record<string, string | undefined>>(() => {
    return unsubscribes.reduce(
      (map, email) => ({
        ...map,
        [email]: (() => {
          if (!isValidEmail(email)) return 'Email is invalid';
          return undefined;
        })(),
      }),
      {},
    );
  }, [unsubscribes]);
  const hasError = useMemo(
    () => ({
      partial: Object.values(errors).some((error) => !!error),
      all:
        !!unsubscribes.length &&
        Object.values(errors).filter((e) => !!e).length === unsubscribes.length,
    }),
    [errors, unsubscribes],
  );

  const tags = useMemo<InputTag[]>(() => {
    return unsubscribes.map((email) => {
      return {
        text: email,
        variant: errors[email] ? 'error' : undefined,
        tip: errors[email],
      };
    });
  }, [unsubscribes, errors]);

  const [alert, setAlert] = useState<
    | {
        size?: number;
        status: 'success' | 'error' | 'warning';
        type: 'add' | 'remove';
      }
    | undefined
  >();
  useEffect(() => {
    setAlert(undefined);
  }, [workspaceId]);

  const [autoUnsubscribe, setAutoUnsubscribe] = useState<boolean>(false);
  const onUnsubscribe = useCallback(() => {
    setAutoUnsubscribe(false);
    const emailAddresses = unsubscribes.filter((email) => !errors[email]);
    if (!emailAddresses.length) return;

    addUnsubscriptions({
      emailAddresses,
    });
  }, [
    addUnsubscriptions,
    workspaceId,
    unsubscribes,
    errors,
    workspaceUser?.features,
  ]);
  useEffect(() => {
    if (addStatus === 'success') {
      resetAdd();
      setUnsubscribes([]);
      setAlert({
        status: addResult ? 'success' : 'warning',
        type: 'add',
        size: addResult ?? 0,
      });

      // log to the stack driver unsubscribed emails
      try {
        const user = getUserDataForLogging(getUser(), getWorkspace());
        logToStackDriver({
          emails_count: addResult ?? 0,
          timestamp: Date.now(),
          user_id: user.user_id,
          user_installation_timestamp: user.user_installation_timestamp,
          plan: user.plan,
          is_biz_user: user.is_biz_user,
          domain: user.domain,
          event_type: 'manual_unsubscribe',
          message: 'User manually unsubscribed emails (manual_unsubscribe)',
        });
      } catch (e) {
        captureException(e);
      }
    }
    if (addStatus === 'error') {
      setAlert({ status: 'error', type: 'add' });
    }
  }, [addStatus, resetAdd, addResult]);

  useEffect(() => {
    if (!autoUnsubscribe) return;
    onUnsubscribe();
  }, [onUnsubscribe, autoUnsubscribe]);

  // Delete Unsubscribe
  const [deleteEmail, setDeleteEmail] = useState<string | undefined>();
  const onDelete = useCallback(
    (emailAddress?: string) => {
      if (!emailAddress) return;
      setDeleteEmail(emailAddress);
    },
    [setDeleteEmail],
  );
  const onCloseRemove = useCallback((status?: MutationStatus) => {
    setDeleteEmail(undefined);
    if (status === 'success' || status === 'error') {
      setAlert({ status, type: 'remove' });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const isMobile = !useMediaQuery(from.tablet);

  const columns = useMemo(
    () =>
      getColumns({
        isMobile,
        hasPermission:
          !workspaceUser?.features.sharedUnsubscribes ||
          workspaceUser?.role !== 'MEMBER',
        onDelete,
        deleteEmail,
      }),
    [
      isMobile,
      workspaceUser?.features,
      workspaceUser?.role,
      onDelete,
      deleteEmail,
    ],
  );

  const {
    params,
    manager: tableStateManager,
  } = useTableStateManager<UnsubscriptionParams>({
    paginationKey: workspaceId,
    sortBy: {
      email: 'emailAddress',
      unsubscriptionTime: 'createTimestamp',
    },
  });
  const {
    data: unsubscriptions,
    status,
    fetchAll: getUnsubscriptions,
  } = useUnsubscriptions(workspaceId, params);

  return {
    isMobile,
    description: {
      isSharedUnsubsRestricted: !!workspaceRestrictions?.SHARED_UNSUBS,
      userSharedUnsubs: !!workspaceUser?.features.sharedUnsubscribes,
      workspaceSharedUnsubs: workspaceDetails?.plan === 'PREMIUM',
      isOwner: workspaceUser?.role === 'OWNER',
      ownerEmail: workspaceOwner?.email,
    },
    unsubscribe: {
      input: {
        tags,
        hasError: hasError.all,
        ...tagInput,
      },
      onClick: onUnsubscribe,
      setAutoUnsubscribe,
      isLoading: addStatus === 'loading' || autoUnsubscribe,
      disabled:
        (tags.length === 0 && !fieldValue) || hasError.all || !!deleteEmail,
    },
    remove: {
      emailAddress: deleteEmail,
      onClose: onCloseRemove,
    },
    unsubscriptions,
    isLoading: status === 'loading',
    columns,
    tableStateManager,
    getUnsubscriptions,
    alert,
    dismissAlert: () => setAlert(undefined),
  };
};
