import {
  Button,
  Dialog,
  FlexContainer,
  FormControl,
  FormGroup,
  FormLabel,
  Heading,
  Input,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import { useConnect } from './connect';
import { Alert, Container } from './styles';
import { Props } from './types';

export const NewWorkspace: FC<Props> = ({ show, onClose, focusRef }) => {
  const {
    isLoading,
    resetForm,
    onCreate,
    workspaceName,
    setWorkspaceName,
    error,
    isMobile,
    hasTooManyWorkspaces,
    maximumNumberOfWorkspaces,
  } = useConnect({
    show,
    onClose,
    focusRef,
  });
  return (
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) return;
        onClose();
        resetForm();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <FlexContainer flex="horizontal">
          <Heading size="large">New space</Heading>
        </FlexContainer>
        {hasTooManyWorkspaces && (
          <Alert type="warning">
            <Text type="paragraph" size="large" width="semiBold">
              You cannot create a new space
            </Text>
            <Text type="paragraph" size="base" width="regular">
              You are already the owner of {maximumNumberOfWorkspaces} spaces
              which is the limit of the number of spaces you can own.
            </Text>
          </Alert>
        )}
        <FormGroup disabled={hasTooManyWorkspaces}>
          <FormLabel htmlFor="workspace-name">Space’s name</FormLabel>
          <FormControl
            errorMessage={(() => {
              if (error === 'REQUIRED') {
                return 'Please fill in this field.';
              }
              if (error === 'EMPTY') {
                return 'Please fill in this field.';
              }
              if (error === 'TOO_SHORT') {
                return 'Space name should contain at least 5 characters.';
              }
              if (error === 'TOO_LONG') {
                return 'Space name could contain at most 255 characters.';
              }
              return undefined;
            })()}
          >
            <Input
              innerRef={focusRef}
              id="workspace-name"
              placeholder="Enter the space’s name"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              onSubmit={onCreate}
              disabled={isLoading}
              hasError={!!error}
              variant="gray"
            />
          </FormControl>
        </FormGroup>
        <FlexContainer flex="horizontal" gap="base">
          <Button
            aria-label="create"
            variant="primary"
            onClick={onCreate}
            disabled={isLoading || hasTooManyWorkspaces}
            loading={isLoading}
          >
            Create
          </Button>
          <Button
            aria-label="cancel"
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </FlexContainer>
      </Container>
    </Dialog>
  );
};
