import { useMutation, useQuery } from 'react-query';
import { queryClient } from '../queryClient';

export const useAlertsDismiss = (alertId?: string) => {
  return useQuery<boolean>(
    ['alerts-dismiss', alertId],
    () => localStorage.getItem(`alerts-dismiss-${alertId}`) === 'true',
    {
      enabled: alertId != null,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
};

export type UseDismissAlert = {
  alertId: string;
  dismiss: boolean;
};
export const useDismissAlert = () => {
  return useMutation(
    async (props: UseDismissAlert) => {
      return localStorage.setItem(
        `alerts-dismiss-${props.alertId}`,
        props.dismiss === false ? 'false' : 'true',
      );
    },
    {
      onSuccess: (_, { alertId }) => {
        queryClient.invalidateQueries(['alerts-dismiss', alertId]);
      },
    },
  );
};
