import {
  BillingInterval,
  BillingWorkspacePlan,
} from '../../../model/workspaceBilling';
import { CheckoutContextType } from './types';
import { DEFAULT_INTERVAL } from '../../constants';

export const DEFAULT_CHECKOUT_DATA: CheckoutContextType = {
  plan: 'PRO',
  billingInterval: DEFAULT_INTERVAL,
  isLoading: true,
  users: [],
  flowType: 'PURCHASE',
  checkoutForm: {
    city: '',
    code: '',
    companyName: '',
    country: '',
    isBusinessPurchase: false,
    name: '',
    street: '',
    state: '',
    vatNumber: '',
  },
  setPlan: () => {},
  setBillingInterval: () => {},
  setUserPaidStatus: () => {},
  setUsersPaidStatus: () => {},
  markUsersForRemoval: () => {},
  setCheckoutForm: () => {},
  setFlag: () => {},
  getFlag: () => false,
};

export const getDefaultPlan = (
  queryValue: string | null,
): BillingWorkspacePlan => {
  if (queryValue === 'PRO' || queryValue === 'PREMIUM') {
    return queryValue;
  }
  return DEFAULT_CHECKOUT_DATA.plan;
};

export const getDefaultInterval = (
  queryValue: string | null,
): BillingInterval => {
  if (queryValue === 'YEARLY' || queryValue === 'MONTHLY') {
    return queryValue;
  }
  return DEFAULT_CHECKOUT_DATA.billingInterval;
};
