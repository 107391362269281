import React, { FC, useEffect, useState, useCallback } from 'react';

import { Props } from './types';
import {
  ProgressWrapper,
  CircleWrapper,
  Circle,
  CheckmarkIcon,
  Label,
  LineWrapper,
  Line,
} from './styles';

const Progress: FC<Props> = ({ step, labels, setStep }) => {
  const [lastStep, setLastStep] = useState(0);
  const [progress, setProgress] = useState<string[]>([]);
  useEffect(() => {
    const result = [];
    const index = step - 1;

    if (index > lastStep) {
      setLastStep(index);
    }

    for (let i = 0; i < labels.length; i += 1) {
      const classNames = [''];

      if (i === index) {
        classNames.push('current');
      } else if (i < index) {
        classNames.push('checked');
      }

      if (i > 0 && i !== index && i <= lastStep) {
        classNames.push('clickable');
      }

      result.push(classNames.join(' '));
    }

    setProgress(result);
  }, [step, lastStep]);

  const handleClick = useCallback(
    (index: number) => {
      if (setStep && index !== 0 && lastStep >= index) {
        setStep(index + 1);
      }
    },
    [setStep, lastStep],
  );

  return (
    <ProgressWrapper>
      {progress.map((item, index) => (
        <>
          {index > 0 ? (
            <LineWrapper>
              <Line className={item} />
            </LineWrapper>
          ) : null}
          <CircleWrapper>
            <Circle
              onClick={() => {
                handleClick(index);
              }}
              className={item}
            >
              <CheckmarkIcon className={item} />
            </Circle>
            <Label
              className={item}
              onClick={() => {
                handleClick(index);
              }}
            >
              {labels[index]}
            </Label>
          </CircleWrapper>
        </>
      ))}
    </ProgressWrapper>
  );
};

export default Progress;
