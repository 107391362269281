import { CampaignInput, Campaign, CampaignDetail } from 'model/campaign';
import { PollResponseInput, PollResultInput } from 'model/poll';
import keysOf from '../../utils/keysOf';

const extractSheetData = (mergeSourceSheetKey: string) => {
  const breakPoint = mergeSourceSheetKey.lastIndexOf('-');
  const spreadsheetId = mergeSourceSheetKey.slice(0, breakPoint);
  const sheetId = mergeSourceSheetKey.slice(breakPoint + 1);

  return { spreadsheetId, sheetId };
};

export const transformCampaigns = (campaigns: CampaignInput[]): Campaign[] => {
  return campaigns.map((campaign) => {
    const { spreadsheetId, sheetId } = extractSheetData(
      campaign.mergeSourceSheetKey,
    );
    const lastUpdateTimestamp = Number(
      campaign.lastUpdateTimestamp ?? campaign.createTimestamp,
    );
    const lastEmailSentTimestamp = Number(campaign.lastEmailSentTimestamp);

    return {
      id: campaign.campaignId,
      title: `${campaign.mergeSourceSpreadSheetName} - ${campaign.mergeSourceSheetName}`,
      lastUpdateTimestamp,
      lastEmailSentTimestamp: lastEmailSentTimestamp || lastUpdateTimestamp,
      spreadsheetLink: `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=${sheetId}`,
      trackingInfos: {
        clicked: campaign.eventCounts?.clickedCount ?? 0,
        opened: campaign.eventCounts?.openedCount ?? 0,
        sent: campaign.eventCounts?.sentCount ?? 0,
        bounced: campaign.eventCounts?.bouncedCount ?? 0,
      },
    };
  });
};

export const transformCampaignDetail = (
  campaign: CampaignInput,
): CampaignDetail => {
  const { spreadsheetId, sheetId } = extractSheetData(
    campaign.mergeSourceSheetKey,
  );
  const lastTimeUsed = Number(
    campaign.lastUpdateTimestamp ?? campaign.createTimestamp,
  );
  const lastEmailSentTimestamp = Number(campaign.lastEmailSentTimestamp);

  return {
    id: campaign.campaignId,
    emailSubject: campaign.sentMessageSubject,
    title: `${campaign.mergeSourceSpreadSheetName} - ${campaign.mergeSourceSheetName}`,
    lastTimeUsed,
    lastEmailSentTimestamp: lastEmailSentTimestamp || lastTimeUsed,
    spreadsheetLink: `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit#gid=${sheetId}`,
    mergeSourceSheetKey: campaign.mergeSourceSheetKey,
    trackingInfos: {
      clicked: campaign.eventCounts?.clickedCount ?? 0,
      opened: campaign.eventCounts?.openedCount ?? 0,
      sent: campaign.eventCounts?.sentCount ?? 0,
      bounced: campaign.eventCounts?.bouncedCount ?? 0,
      unsubscribed: campaign.eventCounts?.unsubscribedCount ?? 0,
      responded: campaign.eventCounts?.respondedCount ?? 0,
    },
  };
};

export const percentage = (count: number, total: number) => {
  return Math.round((count * 100.0) / total);
};

export const transformPollResponses = (input: PollResponseInput[]) => {
  if (input.length === 0) return null;

  const groupedResults = input.reduce((acc, item) => {
    if (!acc[item.value]) {
      acc[item.value] = { count: 0, percentage: 0 };
    }
    acc[item.value].count += 1;
    return acc;
  }, {} as { [key: string]: { count: number; percentage: number } });

  const totalResults = input.length;

  keysOf(groupedResults).forEach((reply) => {
    groupedResults[reply].percentage = percentage(
      groupedResults[reply].count,
      totalResults,
    );
  });

  return groupedResults;
};

export const transformPollResults = (input: PollResultInput[]) => {
  let totalResults: number = 0;
  const groupedResults: {
    [p: string]: { count: number; percentage: number };
  } = {};

  input.forEach((pr) => {
    totalResults += pr.count;
  });

  input.forEach((pr) => {
    groupedResults[pr.info] = {
      count: pr.count,
      percentage: percentage(pr.count, totalResults),
    };
  });

  return groupedResults;
};
