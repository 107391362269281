import styled from 'styled-components';
import {
  Text as DefaultText,
  CloseButton as DefaultCloseButton,
} from '@scriptaddicts/yamm-ui-components';

export const TitleContainer = styled.div`
  padding: 17px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  width: 100%;
`;

export const PlainText = styled(DefaultText).attrs({
  type: 'span',
})`
  line-height: 100% !important;
`;

export const SettingContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 500px;
  align-self: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  & > * {
    flex: 1;
  }
`;

export const Divider = styled.hr`
  margin: 0px;
  border: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const LargeCloseButton = styled(DefaultCloseButton)`
  position: absolute;
  right: 24px;
  top: 25.5px;

  &[data-show-upgrade-button='true'] {
    right: 4px;
    top: 4px;
  }
`;

export const SmallCloseButton = styled(DefaultCloseButton)`
  position: absolute;
  right: 24px;
  top: 15px;

  &[data-show-upgrade-button='true'] {
    right: 4px;
    top: 4px;
  }
`;

export const ActivateUsersButtonContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const OptionText = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'small',
  width: 'regular',
})`
  color: ${({ theme }) => theme.colors.gray700};
  white-space: pre-wrap;
  line-height: 160% !important;
`;

export const BlueLink = styled.a`
  color: ${({ theme }) => theme.colors.blue700};
`;
