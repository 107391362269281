import React, { useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import { MainContainerLoader } from '../../../components/MainContainer';

const BillingRedirection = () => {
  const { id, otherWorkspaces, setSelectedWorkspace } = useWorkspaceContext();

  const location = useLocation();
  const queryParams = location.search;

  const isBillingPageRequested = useMemo(() => {
    const currentPath = location.pathname;
    return currentPath === `/settings/billing`;
  }, [location.pathname]);

  const destinationSpace = useMemo(() => {
    if (!id || !otherWorkspaces) return undefined;
    return (
      otherWorkspaces.find((workspace) => workspace.role === 'OWNER') ||
      otherWorkspaces.find((workspace) => workspace.role === 'ADMIN') ||
      otherWorkspaces.find((workspace) => workspace.id === id)
    );
  }, [otherWorkspaces, id]);

  useEffect(() => {
    if (destinationSpace && id && destinationSpace.id !== id) {
      setSelectedWorkspace(destinationSpace.id);
    }
  }, [destinationSpace, id]);

  if (
    destinationSpace &&
    isBillingPageRequested &&
    destinationSpace.id === id
  ) {
    const to = `/${destinationSpace.urlPrefix}/space/billing${queryParams}`;
    return <Redirect to={to} />;
  }
  return <MainContainerLoader />;
};

export default BillingRedirection;
