import { useEffect, useMemo, useState } from 'react';
import { useWorkspaceContext } from '../../../../../data/context/WorkspaceContext';
import {
  useCancelScheduledBillingIntervalChange,
  useGetManageStripeCustomerPortalData,
  useWorkspaceActiveSubscription,
} from '../../../../../data/hooks/useWorkspaceBilling';
import { useWorkspaceMembers } from '../../../../../data/hooks/useWorkspaceMembers';
import {
  BillingInterval,
  WorkspaceSubscription,
} from '../../../../../model/workspaceBilling';
import { NotificationMessagesHandlerHook } from '../../../../../model/notificationMessage';
import { formatLongMonthDayYear } from '../../../../../utils/dates';
import { FETCH_WORKSPACE_USERS_MAX } from '../../../../../data/constants';
import {
  useFeatureRestrictions,
  usePageRestrictions,
} from '../../../../../data/hooks/restrictions/useRestrictions';

const CYCLE_CHANGE_MESSAGE_ID = 'cycle-change-message-id';

export const useConnect = ({
  messagesHandler,
}: {
  messagesHandler: NotificationMessagesHandlerHook;
}) => {
  const { id, urlPrefix, details } = useWorkspaceContext();
  const {
    data: subscription,
    isLoading: isLoadingSubscription,
  } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });
  const pageRestrictions = usePageRestrictions();
  const featureRestrictions = useFeatureRestrictions();

  const {
    data: manageSubscriptionData,
    isLoading: isLoadingSubscriptionLink,
  } = useGetManageStripeCustomerPortalData({
    workspaceId:
      subscription != null && subscription?.paymentSource === 'STRIPE'
        ? id
        : undefined,
    returnUrl: window.location.href,
  });
  const {
    mutateAsync: confirmCancelIntervalChange,
    isLoading: isCancelingIntervalChange,
  } = useCancelScheduledBillingIntervalChange();

  const [confirmCancelCycleChange, setConfirmCancelCycleChange] = useState(
    false,
  );

  const { data: members, isLoading: isLoadingMembers } = useWorkspaceMembers(
    id,
    {
      filters: {
        memberStatus: ['ACTIVE', 'DISABLED', 'PENDING', 'ACCEPTED'],
      },
      limit: FETCH_WORKSPACE_USERS_MAX,
    },
  );

  const countMembers = useMemo(() => {
    const counts = { free: 0, paid: 0 };
    if (!isLoadingMembers && members) {
      members.forEach((member) => {
        if (member.plan === 'FREE') {
          counts.free += 1;
        } else {
          counts.paid += 1;
        }
      });
    }

    return counts;
  }, [members]);

  const newCycle = useMemo<BillingInterval | undefined>(() => {
    if (subscription?.scheduledChanges != null) {
      return subscription.scheduledChanges.targetBillingInterval;
    }

    return undefined;
  }, [subscription]);

  useEffect(() => {
    if (
      subscription != null &&
      subscription.scheduledChanges?.targetBillingInterval != null
    ) {
      const renewalDate = formatLongMonthDayYear(subscription.endTimestamp);
      const isFutureMonthly =
        subscription.scheduledChanges?.targetBillingInterval === 'MONTHLY';

      messagesHandler.addMessage({
        id: CYCLE_CHANGE_MESSAGE_ID,
        title: isFutureMonthly
          ? `You are currently billed annually, with a change to monthly planned on ${renewalDate}.`
          : `You are currently billed monthly, with a change to yearly planned on ${renewalDate}.`,
        type: 'success',
      });
    }
  }, [subscription]);

  const onConfirmCycleChangeCancel = () => {
    if (id && subscription?.subscriptionId) {
      confirmCancelIntervalChange({
        workspaceId: id,
        subscriptionId: subscription?.subscriptionId,
      })
        .then(() => {
          messagesHandler.removeMessage(CYCLE_CHANGE_MESSAGE_ID);
          setConfirmCancelCycleChange(false);
        })
        .catch((e: Error) => {
          messagesHandler.addMessage({
            type: 'error',
            id: e.message,
            title: 'Something went wrong.',
            message: 'Please try again later.',
            dismissible: false,
          });
        });
    }
  };

  return {
    workspaceId: id,
    isLoading:
      isLoadingMembers || isLoadingSubscription || isLoadingSubscriptionLink,
    manageSubscriptionData,
    subscription,
    countMembers,
    urlPrefix,
    newCycle,
    confirmCancelCycleChange,
    onConfirmCycleChangeCancel,
    setConfirmCancelCycleChange,
    isCanceling: isCancelingIntervalChange,
    isLegacy: subscription?.workspacePlan === 'LEGACY',
    isPayPal: subscription?.paymentSource === 'PAYPAL',
    pageRestrictions,
    featureRestrictions,
    legacyPlanType: details?.legacyType,
  };
};

export const useFuturePrice = (subscription?: WorkspaceSubscription) => {
  const futurePrice = useMemo(() => {
    return subscription?.additionalStripeInfo?.upcomingInvoice?.amountDue;
  }, [subscription]);

  return {
    futurePrice,
  };
};
