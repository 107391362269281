import { client } from 'data/client';
import { transformWorkspace } from 'data/transform/workspace';
import {
  NewWorkspaceInput,
  NewWorkspaceParams,
  UpdateWorkspaceParams,
  WorkspaceInput,
} from 'model/workspace';

export async function getWorkspace(
  workspaceId: string,
  params?: {
    includeDomains?: boolean;
    includeFeatures?: boolean;
    includeMembersCount?: boolean;
  },
) {
  const { data } = await client.get<WorkspaceInput>(
    `/workspaces/${workspaceId}`,
    {
      params: {
        includeDomains: params?.includeDomains ?? true,
        includeFeatures: params?.includeFeatures ?? true,
        includeMembersCount: params?.includeMembersCount ?? true,
      },
    },
  );

  return transformWorkspace(data);
}

export async function postWorkspace(params: NewWorkspaceParams) {
  const { data } = await client.post<NewWorkspaceInput>(`/workspaces`, params);

  return data;
}

export async function putWorkspace({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: UpdateWorkspaceParams;
}) {
  const { data } = await client.put(`/workspaces/${workspaceId}`, params);

  return data;
}

export async function deleteWorkspace({
  workspaceId,
}: {
  workspaceId: string;
}) {
  const { data } = await client.delete(`/workspaces/${workspaceId}`);

  return data;
}
