import React, { FC } from 'react';
import testingVar from 'utils/testingVar';
import keysOf from 'utils/keysOf';
import { Props } from './types';
import { Container, ProgressBar } from './styles';
import { List, Item, ResultsLabel, Progress } from '../styles';

const PollResults: FC<Props> = ({ poll }) => {
  if (!poll) return null;
  return (
    <Container data-test-id={testingVar('poll-results')}>
      <List>
        {keysOf(poll).map((result) => (
          <Item key={result}>
            <ResultsLabel>
              {result}
              <Progress>
                <span>{poll[result].percentage}%</span>{' '}
                <span>({poll[result].count})</span>
              </Progress>
            </ResultsLabel>
            <ProgressBar fill={poll[result].percentage} />
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default PollResults;
