import { useCallback, useRef, useState } from 'react';
import {
  NotificationMessagesHandlerHook,
  NotificationMessage,
} from '../../model/notificationMessage';

export const useNotificationMessages = (): NotificationMessagesHandlerHook => {
  const [messages, setMessages] = useState<NotificationMessage[]>([]);
  const existingIds = useRef<{ [key: string]: boolean }>({});

  const addMessage = useCallback((message: NotificationMessage) => {
    if (!existingIds.current[message.id]) {
      existingIds.current[message.id] = true;
      setMessages((prevState) => [...prevState, message]);
    }
  }, []);

  const removeMessage = useCallback((messageId: string) => {
    existingIds.current[messageId] = false;
    setMessages((prevState) =>
      prevState.filter((entry) => entry.id !== messageId),
    );
  }, []);

  return {
    messages,
    addMessage,
    removeMessage,
  };
};
