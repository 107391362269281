import styled from 'styled-components';
import { Text } from '@scriptaddicts/yamm-ui-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const ActiveStep = styled(Text).attrs({
  size: 'small',
  type: 'span',
  width: 'semiBold',
})`
  color: ${({ theme }) => theme.colors.white};
  cursor: default;
`;

export const FutureStep = styled(Text).attrs({
  size: 'small',
  type: 'span',
  width: 'semiBold',
})`
  color: ${({ theme }) => theme.colors.blue200};
  cursor: default;
`;

export const StepsSeparator = styled.div`
  width: 22px;
  opacity: 0.12;
  border: 1px solid ${({ theme }) => theme.colors.white};
`;
