import TemplateCard from 'components/TemplateCard';
import React, { useMemo } from 'react';
import { from, useMediaQuery } from 'styles/media';
import { nanoid } from 'nanoid';
import testingVar from 'utils/testingVar';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerSubtitle,
  MainContainerTitle,
} from 'components/MainContainer';
import { Strong, Text, Button, Link } from '@scriptaddicts/yamm-ui-components';
import { Grid, Alert, AlertContainer, Dialog } from './styles';
import { useConnect } from './connect';

const Templates = () => {
  const {
    templates,
    importing,
    dialog,
    url,
    activeTemplate,
    importedDrafts,
    loggedUserIndex,
    alert,
    dismissAlert,
  } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);
  const placeholder = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map(() => <TemplateCard loading={false} key={nanoid(3)} />),
    [],
  );

  return (
    <MainContainer data-test-id={testingVar('templates')} title="Templates">
      <MainContainerHeader direction="vertical">
        <MainContainerTitle>Templates</MainContainerTitle>
        <MainContainerSubtitle>
          Select a template, and it will save as a Gmail draft for you to use
          how you want.{'\n'}You can also start in Google Docs.{' '}
          <Link
            href="https://support.yet-another-mail-merge.com/hc/en-us/articles/214119185"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Learn how
          </Link>
        </MainContainerSubtitle>
      </MainContainerHeader>
      <AlertContainer>
        {alert && (
          <Alert
            type={alert?.status}
            append={
              <Button
                aria-label="close"
                icon="x"
                variant="tertiary"
                size="small"
                onClick={dismissAlert}
              />
            }
          >
            <Text>
              {alert.status === 'error' && 'Gmail draft could not be created'}
              {alert.status === 'success' && (
                <>
                  <Strong>{alert.message}</Strong> Gmail draft created
                </>
              )}
            </Text>
          </Alert>
        )}
        {isMobile && (
          <Alert
            data-test-id={testingVar('desktop-only-message')}
            type="warning"
          >
            <Text type="paragraph" size="base" width="regular">
              To use one of these templates, please visit the desktop version.
            </Text>
          </Alert>
        )}
      </AlertContainer>
      <Grid>
        {templates
          ? templates.map((template) => (
              <TemplateCard
                key={nanoid()}
                id={template.id}
                onImport={importing.onClick}
                messageId={importedDrafts[template.id]}
                loggedUserIndex={loggedUserIndex}
                loading={template.id === importing.id}
                disabled={importing.id !== undefined}
                image={template.thumbnail}
                title={template.subject}
                url={`${url}/${template.id}`}
              />
            ))
          : placeholder}
      </Grid>
      <Dialog {...dialog}>
        <TemplateCard
          id={activeTemplate?.id}
          onImport={(props) => {
            importing.onClick(props);
            dialog.onClose();
          }}
          messageId={
            activeTemplate?.id != null
              ? importedDrafts[activeTemplate.id]
              : undefined
          }
          loggedUserIndex={loggedUserIndex}
          loading={activeTemplate?.id === importing.id}
          disabled={importing.id !== undefined}
          image={activeTemplate?.preview}
          imgLoaded={!!activeTemplate?.isPreviewLoaded}
          title={activeTemplate?.subject}
          url=""
        />
      </Dialog>
    </MainContainer>
  );
};

export default Templates;
