import { nanoid } from 'nanoid';
import React, { FC, useMemo } from 'react';
import { Container, Row, Cell } from './styles';
import { Props } from './types';

const Placeholder: FC<Props> = ({
  columns,
  numberOfRows = 5,
  animated = false,
  ...props
}) => {
  const rowIds = useMemo(
    () =>
      Array(numberOfRows)
        .fill(0)
        .map(() => nanoid(4)),
    [numberOfRows],
  );
  return (
    <Container {...props}>
      {rowIds.map((rowId) => (
        <Row key={rowId}>
          {columns.map((column) => (
            <Cell
              key={nanoid()}
              animate={animated}
              colSpan={column.colSpan ?? 1}
            />
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Placeholder;
