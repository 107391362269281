import { client, clientWorkspaceBilling } from 'data/client';
import {
  BillingBusinessType,
  BillingInterval,
  BillingWorkspacePlan,
  PaidUser,
  ComputeStripePaymentInfo,
  BillingAddressInfo,
  StripePlan,
  StripeCheckoutSession,
  WorkspaceSubscription,
  UpdateWorkspaceSubscriptionUserPlan,
  WorkspaceSubscriptionBase,
} from 'model/workspaceBilling';

/**
 * getWorkspaceSubscriptions
 */
export type WorkspaceSubscriptionResponse = WorkspaceSubscriptionBase & {
  subscriptionTimestamp: string;
  startTimestamp: string;
  endTimestamp: string;
  createTimestamp: string;
  lastUpdateTimestamp?: string | null;
};

const mapWorkspaceSubscriptionResponseToEntity = (
  subscriptionResponse: WorkspaceSubscriptionResponse,
): WorkspaceSubscription => ({
  ...subscriptionResponse,
  subscriptionTimestamp: parseInt(
    subscriptionResponse.subscriptionTimestamp,
    10,
  ),
  startTimestamp: parseInt(subscriptionResponse.startTimestamp, 10),
  endTimestamp: parseInt(subscriptionResponse.endTimestamp, 10),
  createTimestamp: parseInt(subscriptionResponse.createTimestamp, 10),
  lastUpdateTimestamp: subscriptionResponse.lastUpdateTimestamp
    ? parseInt(subscriptionResponse.lastUpdateTimestamp, 10)
    : null,
});

export async function getWorkspaceSubscriptions(
  workspaceId: string,
  includePlans: boolean,
  retrieveAdditionalStripeInfo: boolean,
): Promise<WorkspaceSubscription[] | undefined> {
  const { data } = await client.get<{
    items?: WorkspaceSubscriptionResponse[];
  }>(`/workspace-billing/workspaces/${workspaceId}/subscriptions/v1`, {
    params: {
      includePlans,
      retrieveAdditionalStripeInfo,
    },
    baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}`,
  });
  if (data.items) {
    return data.items.map(mapWorkspaceSubscriptionResponseToEntity);
  }
  return data.items;
}

/**
 * computeStripePaymentInfo
 */
export type ComputeStripePaymentInfoRequest = {
  country?: string;
  purchaseType?: BillingBusinessType;
  workspacePlan?: BillingWorkspacePlan;
  billingInterval?: BillingInterval;
  userUpdates?: PaidUser[];
  upgradeLegacySubscription?: boolean;
};
export const computeStripePaymentInfo = async (
  workspaceId: string,
  data: ComputeStripePaymentInfoRequest,
  previewProratedChanges: boolean = false,
): Promise<ComputeStripePaymentInfo> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.post<ComputeStripePaymentInfo>(
    `/workspace-billing/workspaces/${workspaceId}/stripe/checkout/v1/info?previewProratedChanges=${previewProratedChanges}`,
    data,
  );
  return response;
};

/**
 * createStripeCheckoutSession
 */
export type CreateStripeCheckoutSessionRequest = {
  userName: string;
  vatNumber?: string;
  isB2BTransaction: boolean;
  companyName?: string;
  customerName?: string;
  addressInfo: BillingAddressInfo;
  plans: StripePlan[];
  goBackUrl: string;
  stripeTestClockId?: string;
};
export const createStripeCheckoutSession = async (
  workspaceId: string,
  data: CreateStripeCheckoutSessionRequest,
): Promise<StripeCheckoutSession> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.post<StripeCheckoutSession>(
    `/workspace-billing/workspaces/${workspaceId}/stripe/checkout/v1/session`,
    data,
  );
  return response;
};

export type UpdateWorkspaceSubscriptionRequest = {
  billingInterval?: BillingInterval;
  workspacePlan?: BillingWorkspacePlan;
  workspaceUserPlans?: UpdateWorkspaceSubscriptionUserPlan[];
  cancelScheduledBillingIntervalChange?: boolean;
  upgradeLegacySubscription?: boolean;
};
export type UpdateWorkspaceSubscriptionResponse = {
  updatedWorkspaceSubscription: WorkspaceSubscriptionResponse;
  demotedToFreeSubscription: boolean;
  paymentFailed: boolean;
  latestInvoicePaymentUrl: string;
};
export const updateWorkspaceSubscription = async (
  workspaceId: string,
  subscriptionId: string,
  data: UpdateWorkspaceSubscriptionRequest,
): Promise<UpdateWorkspaceSubscriptionResponse> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.put<UpdateWorkspaceSubscriptionResponse>(
    `/workspace-billing/workspaces/${workspaceId}/subscriptions/v1/${subscriptionId}`,
    data,
  );
  return response;
};

export type GetStripeCustomerPortalResponse = {
  stripeCustomerPortalUrl: string;
};
export const getManageStripeCustomerPortalData = async (
  workspaceId: string,
  returnUrl: string,
): Promise<GetStripeCustomerPortalResponse> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.get<GetStripeCustomerPortalResponse>(
    `/workspace-billing/workspaces/${workspaceId}/stripe/customer/v1/portal`,
    {
      params: {
        returnUrl,
      },
    },
  );
  return response;
};

export type RemoveUsersFromWorkspaceProps = {
  workspaceId: string;
  userIds: string[];
};
export type RemoveUsersFromWorkspaceResponse = {
  userId: string;
  errorMsg: string;
  errorCode: string;
}[];
export const removeUsersFromWorkspace = async (
  props: RemoveUsersFromWorkspaceProps,
): Promise<RemoveUsersFromWorkspaceResponse> => {
  const {
    data: response,
  } = await clientWorkspaceBilling.delete<RemoveUsersFromWorkspaceResponse>(
    `/workspace-billing/workspaces/${props.workspaceId}/subscriptions/v1`,
    {
      data: {
        userIds: props.userIds,
      },
    },
  );
  return response;
};
