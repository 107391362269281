import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useDeleteBounce } from 'data/hooks/useBounces';
import { useCallback, useEffect } from 'react';
import { Props } from './types';

export const useConnect = ({ emailAddress, onClose }: Props) => {
  const { id, user } = useWorkspaceContext();

  const { mutateAsync: deleteBounce, status, reset } = useDeleteBounce(id);

  const onRemove = useCallback(() => {
    if (!emailAddress) return;
    deleteBounce({
      emailAddress,
    });
  }, [emailAddress, deleteBounce]);

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      reset();
      onClose(status);
    }
  }, [status, reset]);

  return {
    userSharedBounces: !!user?.features.sharedBounces,
    isLoading: status === 'loading',
    onRemove,
  };
};
