import { client } from 'data/client';
import {
  InviteMembersParams,
  InviteMember,
  ResendInviteMembersParams,
  CancelInviteMembersParams,
  AddMember,
  AddMembersParams,
  AcceptInvite,
} from 'model/workspaceInvite';

export async function postInviteMembers({
  workspaceId,
  params,
  onlyCheckValidity = false,
}: {
  workspaceId?: string;
  params?: InviteMembersParams;
  onlyCheckValidity?: boolean;
}) {
  const { data } = await client.post<{ items: InviteMember[] }>(
    `/workspaces/${workspaceId}/invitations`,
    params,
    {
      params: {
        onlyCheckValidity,
      },
    },
  );

  return data.items;
}

export async function putInviteMembers({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: ResendInviteMembersParams;
}) {
  const { data } = await client.put(
    `/workspaces/${workspaceId}/invitations/resend`,
    params,
  );

  return data;
}

export async function deleteInviteMembers({
  workspaceId,
  params,
}: {
  workspaceId?: string;
  params?: CancelInviteMembersParams;
}) {
  const { data } = await client.delete(
    `/workspaces/${workspaceId}/invitations`,
    { data: params },
  );

  return data;
}

export async function postAddMembers({
  workspaceId,
  params,
  onlyCheckValidity = false,
}: {
  workspaceId?: string;
  params?: AddMembersParams;
  onlyCheckValidity?: boolean;
}) {
  const { data } = await client.post<{ items: AddMember[] }>(
    `/workspaces/${workspaceId}/members`,
    params,
    {
      params: {
        onlyCheckValidity,
      },
    },
  );

  return data.items;
}

export async function putAcceptInvite({
  invitationToken,
}: {
  invitationToken?: string;
}) {
  const { data } = await client.put<AcceptInvite>(
    `/invitations/${invitationToken}/accept`,
  );

  return data;
}
