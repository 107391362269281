import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';
import { ReactComponent as HelpIcon } from 'assets/images/icons/help-circle.svg';
import styled, { css } from 'styled-components';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';
import { prettyScrollbar } from 'styles/mixins';
import {
  Heading as DefaultHeading,
  Text as DefaultText,
  Placeholder as DefaultPlaceholder,
  Tabs as DefaultTabs,
} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  position: relative;
  ${from.mobile} {
    height: min(642px, calc(100% - 64px));
  }
  ${from.tablet} {
    max-width: 448px;
    min-width: 448px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 24px;
  padding-right: 0px;
  max-width: calc(100% - 24px - 20px);
  ${from.tablet} {
    max-width: calc(448px - 36px - 20px);
  }
`;

export const Title = styled(DefaultHeading).attrs({
  size: 'large',
})``;

export const TitlePlaceholder = styled(DefaultPlaceholder).attrs({
  type: 'heading',
  size: 'large',
  width: 250,
})``;

export const Subtitle = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
})`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const SubtitlePlaceholder = styled(DefaultPlaceholder).attrs({
  type: 'paragraph',
  size: 'extra-large',
  width: 175,
})``;

export const NoSubject = styled(DefaultText)`
  display: block;

  font-size: 12.8px;
  font-style: italic;
  margin-top: 4.8px;
  line-height: 17.6px;
`;

export const Divider = styled.hr`
  margin: 0px;
  border: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const Content = styled.div`
  flex: 1;

  padding: 24px;
  padding-right: calc(24px - 6px);
  margin-right: 6px;
  ${from.tablet} {
    max-height: 449.21px;
  }

  overflow-y: scroll;
  ${prettyScrollbar({})};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  padding-bottom: 24px;
`;

export const Column = styled.div`
  flex-basis: 50%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled(DefaultText).attrs({
  type: 'span',
  width: 'medium',
})`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.64px;

  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Data = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
  width: 'medium',
})``;

export const DataPlaceholder = styled(DefaultPlaceholder).attrs({
  type: 'paragraph',
  size: 'extra-large',
  width: 64,
})``;

export const Tabs = styled(DefaultTabs).attrs({
  size: 'small',
})`
  margin-bottom: 30px;
`;

export const PollSupport = styled.a`
  display: inline-flex;
  align-items: center;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const Help = styled(HelpIcon)`
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray800};

  border-top: 1px solid ${({ theme }) => theme.colors.gray100};
  padding: 18px 24px;
  transition: background-color 0.2s ease;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray50};
  }
  &:focus-visible {
    outline-offset: -8px;
  }
`;

export const IconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.gray700};
  height: 20px;
  width: 20px;
  margin-right: 1.375rem;
  svg {
    height: 100%;
    width: 100%;
  }
`;

export const LinkIcon = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.gray200};
  margin-left: auto;
`;

export const Placeholder = styled.span`
  height: 1.875rem;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 0.375rem;
  display: block;
`;

export const BasicLink = styled.a`
  text-transform: none !important;
  color: inherit;
`;

export const List = styled.ul``;

export const Item = styled.li`
  margin-bottom: 2rem;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ResultsLabel = styled(DefaultText)<{ $info?: boolean }>`
  display: flex;
  justify-content: space-between;

  font-size: 11px;
  font-weight: 500;
  line-height: 13px !important;
  letter-spacing: 0.04em !important;

  margin-bottom: 0.5625rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray700};

  ${({ $info }) =>
    !!$info &&
    css`
      > span:first-of-type::after {
        content: '*';
        color: ${({ theme }) => theme.colors.gray500};
        margin-left: 3px;
      }
    `};
`;

export const Progress = styled(DefaultText)`
  font-size: 11px;
  font-weight: 500;
  line-height: 13px !important;
  letter-spacing: 0.04em !important;

  > span:first-of-type {
    color: ${({ theme }) => theme.colors.gray800};
  }
  > span:last-of-type {
    color: ${({ theme }) => theme.colors.gray700};
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.0033em;
  margin-top: 0.9375rem;
`;
