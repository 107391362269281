import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useCheckoutContext } from '../../../data/context/CheckoutContext';
import { useComputeStripePaymentInfo } from '../../../data/hooks/useWorkspaceBilling';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import { CheckoutFormValues } from '../../../data/context/CheckoutContext/types';
import { mapTableUserToPaidUser } from '../../../utils/checkout/mappers';
import { CheckoutUser } from '../../../model/checkout';
import { getPurchasePlansDisplay } from '../common/utils';
import { getPaidUsers } from '../../../utils/checkout';
import { useAvailableUsers } from '../BillingOptions/hooks/useAvailableUsers';
import { usePrices } from '../../../data/hooks/usePaymentPlans';

export const useConnect = () => {
  const { id, details } = useWorkspaceContext();
  const { values, submitForm } = useFormikContext<CheckoutFormValues>();
  const { plan, billingInterval } = useCheckoutContext();
  const { availableUsers } = useAvailableUsers();
  const { paymentPlans } = usePrices();

  const paidUsers = useMemo<CheckoutUser[]>(
    () => getPaidUsers(availableUsers),
    [availableUsers],
  );
  const {
    data: paymentInfo,
    isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfo(
    id,
    {
      country: values.country || 'US',
      userUpdates: paidUsers.map(mapTableUserToPaidUser),
      billingInterval,
      purchaseType: values.isBusinessPurchase ? 'B2B' : 'B2C',
      workspacePlan: plan,
      upgradeLegacySubscription: details?.plan === 'LEGACY',
    },
    true,
  );

  const purchasePlansDisplay = useMemo(() => {
    if (paymentPlans && paymentInfo && paymentInfo.stripePlans) {
      return getPurchasePlansDisplay(
        paymentInfo.stripePlans,
        paymentPlans,
        paymentInfo.currency,
      );
    }
    return undefined;
  }, [paymentInfo, paymentPlans]);

  return {
    paidUsersCount: paidUsers.length,
    billingInterval,
    paymentInfo,
    showTaxesInfo: paymentInfo?.currency === 'EUR',
    isFetchingPaymentInfo,
    submitForm,
    purchasePlansDisplay,
    plan,
  };
};
