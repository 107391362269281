import React, { FC, useCallback } from 'react';
import { Campaign } from 'model/campaign';
import { RowLink } from 'components/ResponsiveTable/Rows/styles';
import ResponsiveTable from 'components/ResponsiveTable';
import Search from './table/filter';
import Exporter from './table/exporter';
import NotFound from './table/notFound';
import CampaignDetail from './CampaignDetail';
import Empty from './table/empty';
import { useConnect } from './connect';

const SentMailMerges: FC = () => {
  const {
    campaigns,
    getCampaigns,
    isLoading,
    tableStateManager,
    columns,
    mailMergesTestingVar,
    url,
  } = useConnect();
  const rowLink = useCallback(
    (props: { item: Campaign }) => (
      <RowLink tabIndex={-1} to={`${url}/${props.item.id}`} />
    ),
    [url],
  );

  return (
    <>
      <ResponsiveTable<Campaign>
        data={campaigns}
        getData={getCampaigns}
        loading={isLoading}
        tableStateManager={tableStateManager}
        columns={columns}
        rowLink={rowLink}
        empty={<Empty />}
        notFound={<NotFound />}
        filter={Search}
        exporter={Exporter}
        header="float"
        sortable
        float="right"
      />
      {mailMergesTestingVar ? (
        <span data-test-id={mailMergesTestingVar} />
      ) : null}
      <CampaignDetail />
    </>
  );
};

export default SentMailMerges;
