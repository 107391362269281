import {
  Autocomplete,
  Button,
  Dialog,
  FlexContainer,
  FormControl,
  FormGroup,
  FormLabel,
  Text,
  DropdownList,
  DropdownListItem,
  Strong,
  Heading,
  TagInput,
} from '@scriptaddicts/yamm-ui-components';
import { RouterLink } from 'components/Link';
import { nanoid } from 'nanoid';
import React, { FC } from 'react';
import { plurify } from 'utils/plurify';
import { useConnect } from './connect';
import { Container } from './styles';
import { Props } from './types';

export const InviteUsers: FC<Props> = ({
  show,
  onClose,
  focusRef,
  onSuccess,
  workspaceId,
}) => {
  const {
    isLoading,
    resetForm,
    hasMultiDomain,
    isMultiDomainRestricted,
    domain,
    role,
    search,
    tags,
    invite,
    isMobile,
  } = useConnect({
    onClose,
    onSuccess,
    workspaceId,
  });
  const sendInvitationButtonText = invite.count
    ? `Send ${invite.count} ${plurify(invite.count, 'invite', 'invites')}`
    : 'Send invite';
  return (
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) return;
        onClose();
        resetForm();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <FlexContainer flex="horizontal">
          <Heading size="large">Invite users</Heading>
        </FlexContainer>
        {hasMultiDomain === false && (
          <Text type="paragraph" size="base" width="regular">
            You can only invite <Strong>{`@${domain}`}</Strong> users.{' '}
            {!isMultiDomainRestricted && role !== 'MEMBER' && (
              <>
                <RouterLink to="/space/billing">Upgrade</RouterLink> to invite
                users from any.
              </>
            )}
          </Text>
        )}
        <FormGroup>
          <FormLabel htmlFor="workspace-mails">Email addresses</FormLabel>
          <FormControl
            errorMessage={
              tags.hasError.partial
                ? "We can't invite some email addresses. Hover over them to know why."
                : undefined
            }
          >
            <Autocomplete
              show={search.show}
              value={search.value}
              onChange={search.onChange}
              onOpen={search.onOpen}
              onClose={search.onClose}
              disabled={isLoading}
            >
              <DropdownList maxElement="auto" minElement={5}>
                {search.items.map((member) => (
                  <DropdownListItem key={nanoid()} value={member.email}>
                    {member.name
                      ? `${member.name} <${member.email}>`
                      : member.email}
                  </DropdownListItem>
                ))}
              </DropdownList>
              <TagInput
                innerRef={focusRef}
                id="workspace-mails"
                placeholder="Separate emails with a space or a comma"
                tagKeys={[' ', ',']}
                tags={tags.members}
                onTagAdd={tags.onTagAdd}
                onTagRemove={tags.onTagRemove}
                hasError={tags.hasError.all}
                variant="gray"
                submitClassName="autoTagCatch"
                onSubmit={() => tags.setAutoInvite(true)}
              />
            </Autocomplete>
          </FormControl>
        </FormGroup>
        <FlexContainer flex="horizontal" gap="base">
          <Button
            data-log-event="send_invitations"
            data-log-event-data={JSON.stringify({
              displayName: sendInvitationButtonText,
            })}
            aria-label="invite"
            variant="primary"
            className="autoTagCatch"
            onClick={invite.onClick}
            disabled={isLoading || invite.isValidating || invite.disabled}
            loading={isLoading || invite.isValidating}
          >
            {sendInvitationButtonText}
          </Button>
          <Button
            aria-label="cancel"
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </FlexContainer>
      </Container>
    </Dialog>
  );
};
