import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { useWorkspaceContext } from '../../../../data/context/WorkspaceContext';
import { PathsMapping } from './topBarPathMapping';
import { useCheckoutContext } from '../../../../data/context/CheckoutContext';
import { FlowType } from '../../../../utils/checkout';
import { isCannotDowngrade, isFromUsers, isSummary } from './navigationUtils';
import { useAvailableUsers } from '../../BillingOptions/hooks/useAvailableUsers';

const getActiveIndex = (
  path: string,
  flowType: FlowType,
  usersCount: number,
): number => {
  const paths = PathsMapping[flowType]({ usersCount });
  const index = paths.findIndex((element) => element.test(path));
  if (index < 0 || index >= paths.length) {
    return 0;
  }
  return index;
};

export const useConnect = () => {
  const { isLoading, urlPrefix } = useWorkspaceContext();
  const { flowType } = useCheckoutContext();
  const { availableUsers } = useAvailableUsers();

  const { location, push, goBack: navigateBack } = useHistory();

  const topBarInfo = useMemo<{ activeIndex: number; steps: string[] }>(() => {
    const steps = PathsMapping[flowType]({
      usersCount: availableUsers.length,
    });
    return {
      steps: steps.map((element) => element.title),
      activeIndex: getActiveIndex(
        location.pathname,
        flowType,
        availableUsers.length,
      ),
    };
  }, [flowType, availableUsers]);

  const goBack = () => {
    if (isSummary(location.pathname) && isFromUsers(location.search)) {
      push(`${urlPrefix}/space/users`);
    } else if (isCannotDowngrade(location.pathname)) {
      navigateBack();
    } else if (topBarInfo.activeIndex > 0) {
      navigateBack();
      // push(
      //   `${urlPrefix}${
      //     PathsMapping[flowType][topBarInfo.activeIndex - 1].suffix
      //   }${location.search}`,
      // );
    } else {
      push(`${urlPrefix}/space/billing`);
    }
  };

  return {
    goBack,
    isLoading,
    steps: topBarInfo.steps,
    activeIndex: topBarInfo.activeIndex,
  };
};
