import * as Yup from 'yup';

export const productValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    image: Yup.string().required(),
    type: Yup.string().required(),
    url: Yup.string().required(),
    productId: Yup.string().required(),
  });
