import { Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { StyledTitle, StyledTitleWrapper } from '../common/styles';
import { SummaryTitleType } from './types';

export const Title = ({
  type,
  spaceName,
}: {
  type: SummaryTitleType;
  spaceName: string;
}) => (
  <StyledTitleWrapper>
    {type === 'UPGRADE' && <StyledTitle>Upgrading {spaceName}</StyledTitle>}
    {type === 'DOWNGRADE' && <StyledTitle>Downgrading {spaceName}</StyledTitle>}
    {type === 'CYCLE_CHANGE' && (
      <StyledTitle>Changing {spaceName} billing cycle</StyledTitle>
    )}

    <Text type="paragraph" size="base">
      You are about to update your subscription. Review the changes below and
      confirm:
    </Text>
  </StyledTitleWrapper>
);
