export function getRootHost(host: string) {
  if (!host.includes('.')) {
    if (host.includes(':')) {
      return host.split(':')[0];
    }
    return host;
  }
  const root = host.split('.').reverse().slice(0, 2).reverse().join('.');
  return `.${root}`;
}
