import React, { useContext, createContext, ReactNode, useState } from 'react';

type TemplatesContextProps = {
  importedDrafts: {
    [templateId: string]: string;
  };
  setImportedDrafts: React.Dispatch<
    React.SetStateAction<TemplatesContextProps['importedDrafts']>
  >;
};

const TemplatesContext = createContext<TemplatesContextProps>({
  importedDrafts: {},
  setImportedDrafts: () => {},
});

export const useImportedDrafts = () => useContext(TemplatesContext);

function TemplatesProvider({ children }: { children: ReactNode }) {
  const [importedDrafts, setImportedDrafts] = useState<
    TemplatesContextProps['importedDrafts']
  >({});
  return (
    <TemplatesContext.Provider value={{ importedDrafts, setImportedDrafts }}>
      {children}
    </TemplatesContext.Provider>
  );
}

export default TemplatesProvider;
