import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAvailableUsers } from '../hooks/useAvailableUsers';
import { from, useMediaQuery } from '../../../../styles/media';
import { getColumns } from './table/columns';
import { useUserRestrictions } from '../hooks/useUserRestrictions';
import {
  getPaidUsers,
  hasPaidUsersFromExternalDomain,
} from '../../../../utils/checkout';
import { getUserUpdateProjection } from '../utils';
import { useCheckoutContext } from '../../../../data/context/CheckoutContext';
import { useWorkspaceContext } from '../../../../data/context/WorkspaceContext';
import useWorkspaceLocation from '../../../../utils/router/useWorkspaceLocation';
import { CheckoutUser } from '../../../../model/checkout';
import { MAX_USERS_PER_SPACE } from '../../../../data/constants';

const countPaidUsers = (users: CheckoutUser[]): number =>
  getPaidUsers(users).length;

const isOnlyPaidUser = (target: string, users: CheckoutUser[]) => {
  const paidUsers = getPaidUsers(users);
  if (paidUsers.length !== 1) {
    return false;
  }

  return paidUsers[0].email === target;
};

export const useConnect = () => {
  const { push } = useHistory();
  const {
    id: workspaceId,
    details: workspaceDetails,
    restrictions,
  } = useWorkspaceContext();

  const { url } = useWorkspaceLocation();
  const { plan, setUserPaidStatus } = useCheckoutContext();
  const { availableUsers } = useAvailableUsers();
  const owner = useMemo(() => availableUsers.find((u) => u.role === 'OWNER'), [
    availableUsers,
  ]);

  const reachedMaxUsers = useMemo(() => {
    return availableUsers.filter((u) => u.isPaid).length > MAX_USERS_PER_SPACE;
  }, [availableUsers]);

  const { userRestrictions } = useUserRestrictions({
    workspaceId,
    workspacePlan: workspaceDetails?.plan,
    rolloutRestrictions: restrictions,
  });

  const onUserPaymentUpdate = useCallback(
    (userId: string, isPaid: boolean) => {
      if (
        plan === 'PRO' &&
        hasPaidUsersFromExternalDomain(
          getUserUpdateProjection(availableUsers, userId, isPaid),
        )
      ) {
        push(`${url}/checkout/cannot-downgrade`);
      } else {
        setUserPaidStatus(userId, isPaid);
        if (!isPaid && countPaidUsers(availableUsers) <= 1) {
          setUserPaidStatus(owner?.id || '', true);
        }
      }
    },
    [plan, availableUsers, setUserPaidStatus, owner],
  );

  const isMobile = !useMediaQuery(from.tablet);
  const disableOwner = isOnlyPaidUser(owner?.email || '', availableUsers);

  const tableColumns = useMemo(
    () =>
      getColumns({
        isMobile,
        setUserPaidStatus: onUserPaymentUpdate,
        userRestrictions,
        disabledUsers: [disableOwner && owner ? owner.id : ''],
        reachedMaxUsers,
      }),
    [
      isMobile,
      userRestrictions,
      onUserPaymentUpdate,
      disableOwner,
      owner,
      reachedMaxUsers,
    ],
  );

  return {
    availableUsers,
    tableColumns,
    reachedMaxUsers,
  };
};
