import { releaseProxy, wrap } from 'comlink';
import { useMemo, useEffect } from 'react';

function getWorkerAndCleanup<T>(workerUrl: string, name: string) {
  const worker = new Worker(workerUrl, {
    name,
    type: 'classic',
  });

  const workerApi = wrap<T>(worker);

  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { worker: workerApi, cleanup };
}

export function useWorker<T>(workerUrl: string, name: string) {
  const workerAndCleanup = useMemo(
    () => getWorkerAndCleanup<T>(workerUrl, name),
    [workerUrl, name],
  );

  useEffect(() => {
    const { cleanup } = workerAndCleanup;
    return () => {
      cleanup();
    };
  }, [workerAndCleanup]);

  return workerAndCleanup;
}
