import styled from 'styled-components';
import { from } from 'styles/media';

export const Table = styled.table`
  width: 100%;
  margin-bottom: 2rem;
`;

export const FilterWrapper = styled.div`
  padding-top: 16px;
  height: 4.0625rem;
`;

export const FilterContainer = styled.div`
  margin: 0px 24px;
  display: flex;

  > :first-child {
    flex: 1;
  }

  > :not(:first-child) {
    display: none;
  }

  ${from.tablet} {
    margin: 0 auto;
    > :first-child {
      flex: 1;
    }

    > :not(:first-child) {
      display: flex;
    }
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  margin-top: 6px;

  > :not(:first-child) {
    position: absolute;
    top: 2px;
    right: 24px;
  }

  ${from.tablet} {
    margin-top: 8px;
    > :not(:first-child) {
      display: none;
    }
  }
`;

export const PaginationContainer = styled.div`
  margin-top: 24px;
`;
