import { BackendErrorContent } from 'model/error';
import {
  Dispatch,
  DispatchWithoutAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export type WorkspaceAlerts = Partial<{
  legacy: boolean;
  remove: Partial<{
    name: string;
    current: string;
  }>;
  create: Partial<{
    name: string;
  }>;
  transfer: Partial<{
    name: string;
    email?: string;
  }>;
  join: Partial<{
    name: string;
    new: boolean;
    id: string;
    error: BackendErrorContent;
  }>;
  error: {
    type: 'remove' | 'create' | 'transfer' | 'join';
  };
}>;

const autoDismissableAlerts = ['remove', 'create', 'transfer', 'join', 'error'];

export const useAlerts = (
  workspaceId?: string,
  defaultAlerts?: WorkspaceAlerts,
): {
  alerts: WorkspaceAlerts;
  setAlerts: Dispatch<WorkspaceAlerts>;
  setAlertsSeen: DispatchWithoutAction;
} => {
  const [seen, setSeen] = useState<string[]>([]);

  const [allAlerts, setAllAlerts] = useState<WorkspaceAlerts>(
    defaultAlerts ?? {},
  );

  const { pathname } = useLocation();
  const lastState = useRef<string | undefined>(pathname);
  useEffect(() => {
    if (pathname === lastState.current) return;
    lastState.current = pathname;

    setAllAlerts((state) =>
      Object.fromEntries(
        Object.entries(state).filter(
          ([key]) =>
            !autoDismissableAlerts.includes(key) || !seen.includes(key),
        ),
      ),
    );
    setSeen([]);
  }, [pathname, lastState.current, seen]);

  const setAlerts = useCallback(
    (input: WorkspaceAlerts) => {
      setAllAlerts((state) => ({
        ...Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !(key in input && !(input as Record<string, any>)[key]),
          ),
        ),
        ...input,
      }));
    },
    [setAllAlerts],
  );

  const setAlertsSeen = useCallback(() => {
    setSeen(Object.keys(allAlerts));
  }, [allAlerts]);

  return {
    alerts: allAlerts,
    setAlerts,
    setAlertsSeen,
  };
};
