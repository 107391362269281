import { useMemo } from 'react';
import {
  WorkspaceDetails,
  WorkspaceUser,
} from '../../../../../../data/context/WorkspaceContext';
import { WorkspaceMember } from '../../../../../../model/workspaceMembers';

export type UseUpgradeUsersProps = {
  details: WorkspaceDetails | undefined;
  user: WorkspaceUser | undefined;
  selected: WorkspaceMember[];
};
export const useUpgradeUsers = ({
  user,
  details,
  selected,
}: UseUpgradeUsersProps) => {
  const requireUpgrade = useMemo<boolean>(() => {
    return details?.plan === 'FREE' && user?.role !== 'MEMBER';
  }, [details, user]);

  const availableUsers = useMemo<WorkspaceMember[]>(
    () =>
      selected.filter(
        (u) => u.status === 'ACTIVE' || u.status === 'DISABLED',
      ) ?? [],
    [selected],
  );

  return {
    availableUsers,
    count: availableUsers.length,
    requireUpgrade,
  };
};
