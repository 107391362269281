import { addDays } from 'date-fns';
import Cookies from 'js-cookie';
import { getRootHost } from '../getRootHost';

const SESSIONN_ID_COOKIE_NAME = 'SESSION_ID';
const DELEGATE_ID_COOKIE_NAME = 'DELEGATE_ID';
const REGISTER_FLAG_COOKIE_NAME = 'REGISTER_FLAG';

export const getAuthUserIndex = () => {
  return Number(Cookies.get('G_AUTHUSER_H')) || 0;
};

// todo: add tests
export const CookiesOptions = {
  domain: getRootHost(window.location.host),
  path: '/',
  expires: addDays(new Date(), 730),
  secure: true,
};

export const getSessionId = () => {
  return Cookies.get(SESSIONN_ID_COOKIE_NAME);
};

export const getDelegateId = () => {
  return Cookies.get(DELEGATE_ID_COOKIE_NAME);
};

export const getRegisterFlag = () => {
  return Cookies.get(REGISTER_FLAG_COOKIE_NAME);
};

export const setSessionId = (sessionnId: string) => {
  Cookies.set(SESSIONN_ID_COOKIE_NAME, sessionnId, {
    ...CookiesOptions,
    expires: addDays(new Date(), 36),
  });
};

export const setDelegateId = (delegateId: string) => {
  Cookies.set(DELEGATE_ID_COOKIE_NAME, delegateId, {
    ...CookiesOptions,
    expires: addDays(new Date(), 1),
  });
};

export const setRegisterFlag = (registerFlag: string) => {
  Cookies.set(REGISTER_FLAG_COOKIE_NAME, registerFlag, {
    ...CookiesOptions,
    expires: addDays(new Date(), 1),
  });
};

export const removeSessionId = () => {
  Cookies.remove(SESSIONN_ID_COOKIE_NAME, { ...CookiesOptions });
};

export const removeDelegateId = () => {
  Cookies.remove(DELEGATE_ID_COOKIE_NAME, { ...CookiesOptions });
};

export const removeRegisterFlag = () => {
  Cookies.remove(REGISTER_FLAG_COOKIE_NAME, { ...CookiesOptions });
};
