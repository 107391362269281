import { clientV2 } from 'data/client';
import { transformSuggestUser } from 'data/transform/user';
import {
  NewUser,
  SuggestUserInput,
  SuggestUserParams,
  UpdateUser,
  UserInput,
} from 'model/user';

export async function getUser({
  includeWorkspaces = false,
  userId = 'me',
}: {
  includeWorkspaces?: boolean;
  userId?: string;
}) {
  const { data } = await clientV2.get<UserInput>(`/users/${userId}`, {
    params: {
      includeWorkspaces,
    },
  });

  return data;
}

export async function postUserOnActivation(newUser: NewUser) {
  const { data } = await clientV2.post(
    `/users/workspace-activation-create-user`,
    {
      ...newUser,
      createSource: 'WEBAPP',
    },
    {
      params: {
        checkPeopleApi: true,
      },
    },
  );

  return data;
}

export async function postUser(newUser: NewUser) {
  const { data } = await clientV2.post(
    `/users`,
    {
      ...newUser,
      createSource: 'WEBAPP',
    },
    {
      params: {
        checkPeopleApi: true,
      },
    },
  );

  return data;
}

export async function putUser(updateUser: UpdateUser, userId: string = 'me') {
  const { data } = await clientV2.put(`/users/${userId}`, updateUser);

  return data;
}

export async function getSuggestUser(params: SuggestUserParams) {
  const { data } = await clientV2.get<{ items: SuggestUserInput[] }>(
    `/users/suggest`,
    {
      params,
    },
  );

  return transformSuggestUser(data.items);
}
