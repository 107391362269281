import { useAuth } from 'data/context/AuthContext';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CheckoutMarkedUser,
  readCheckoutSessionUsers,
} from 'utils/checkoutSessionUsers';
import {
  extractPathParams,
  isCheckoutRoute,
  logPageView,
  pageViewRoutePatterns,
} from 'utils/userJourneyLogging';

const handlePageView = (
  pathname: string,
  search: string,
  initialUrl: string,
) => {
  pageViewRoutePatterns.some((pattern) => {
    const { isMatched, params, subPath } = extractPathParams(pattern, pathname);
    if (!isMatched) return false;

    let checkoutUsers: CheckoutMarkedUser[] = [];
    let nbPaidUsers: number | undefined;
    let nbFreeUsers: number | undefined;
    if (isCheckoutRoute(subPath)) {
      checkoutUsers = readCheckoutSessionUsers()?.users || [];
      nbPaidUsers = checkoutUsers.filter((user) => user.isPaid).length;
      nbFreeUsers = checkoutUsers.filter((user) => !user.isPaid).length;
    }
    logPageView(
      subPath,
      search,
      initialUrl,
      params.workspaceId,
      nbPaidUsers,
      nbFreeUsers,
    );
    return true;
  });
};

export const usePageViewLogger = () => {
  const history = useHistory();
  const location = useLocation();
  const { token } = useAuth();

  useEffect(() => {
    const initialUrl = window.location.href;
    if (token) {
      handlePageView(location.pathname, location.search, initialUrl);
    }
    const unlisten = history.listen((newLocation) => {
      setTimeout(() => {
        handlePageView(newLocation.pathname, newLocation.search, initialUrl);
      });
    });

    return () => {
      unlisten();
    };
  }, [history, token]);
};
