import { useMe, useUserWorkspace } from 'data/hooks/useMe';
import { UserWorkspace } from 'model/workspace';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { User } from '../../../model/user';
import { sendLog } from '../../../utils/logToStackdriver';

type StackDriverLoggingProps = {
  logToStackDriver: (payload: any) => void;
  getUser: () => User | null;
  getWorkspace: () => UserWorkspace | null;
};

export const StackDriverLoggingContext = createContext<StackDriverLoggingProps>(
  {
    logToStackDriver: () => {},
    getUser: () => null,
    getWorkspace: () => null,
  },
);

// DO NOT USE THIS
export const useStackDriverLogging = () =>
  useContext(StackDriverLoggingContext);

export const StackDriverLoggingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: me } = useMe({ keepPreviousData: true });
  const { data: userWorkspace } = useUserWorkspace();

  const context = useMemo(
    () => ({
      logToStackDriver: (payload: any) => sendLog(payload),
      getUser: () => me,
      getWorkspace: () => userWorkspace,
    }),
    [me, userWorkspace],
  );
  return (
    <StackDriverLoggingContext.Provider value={context}>
      {children}
    </StackDriverLoggingContext.Provider>
  );
};
