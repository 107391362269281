import { client, clientV2 } from 'data/client';
import {
  transformScheduledCampaigns,
  transformScheduledCampaignDetail,
} from 'data/transform/scheduledCampaign';
import { CampaignCount } from 'model/campaign';
import { InputParams } from 'model/parameters';
import {
  DeleteScheduledCampaignParams,
  ScheduledCampaignInput,
} from 'model/scheduledCampaign';

export async function getScheduledCampaigns(params: InputParams) {
  const { data } = await clientV2.get<{ items: ScheduledCampaignInput[] }>(
    `/users/me/scheduled-campaigns`,
    {
      params,
    },
  );
  return transformScheduledCampaigns(data.items);
}

export async function getScheduledCampaignDetail(campaignId: string) {
  const { data } = await clientV2.get<ScheduledCampaignInput>(
    `/users/me/scheduled-campaigns/${campaignId}`,
  );
  return transformScheduledCampaignDetail(data);
}

export async function deleteScheduledCampaign({
  scheduledMergeId,
}: DeleteScheduledCampaignParams) {
  const { data } = await client.delete(
    `/users/me/scheduledCampaigns/${scheduledMergeId}`,
  );

  return data;
}

export async function getScheduledCampaignCount(workspaceId: string) {
  const { data } = await clientV2.get<CampaignCount>(
    `/users/me/workspaces/${workspaceId}/campaigns/count`,
    {
      params: {
        isScheduled: true,
      },
    },
  );
  return data;
}
