import React from 'react';
import { Column } from 'components/ResponsiveTable/types';
import { Radio, Tooltip } from '@scriptaddicts/yamm-ui-components';
import { TableCellValue, TableCellGroup } from './styles';
import { CheckoutUser } from '../../../../../model/checkout';
import { MAX_USERS_PER_SPACE } from '../../../../../data/constants';

export type TableProps = {
  isMobile: boolean;
  reachedMaxUsers: boolean;
  setUserPaidStatus: (userId: string, isPaid: boolean) => void;
  userRestrictions?: Record<string, string>;
  disabledUsers: string[];
};

function getUserRestriction(
  user: CheckoutUser,
  userRestrictions: Record<string, string> | undefined,
  reachedMaxUsers: boolean,
) {
  if (!user.isPaid && reachedMaxUsers) {
    return 'max_users_reached';
  }

  return userRestrictions ? userRestrictions[user.id] : undefined;
}

function getRestrictionExplanation(restriction: string) {
  if (restriction === 'max_users_reached')
    return `Your current plan allows a maximum of ${MAX_USERS_PER_SPACE} paid users.`;
  if (restriction === 'already_paid_user')
    return 'User is already a paid user in another space.';
  return undefined;
}

type UserTypeCellProps = {
  userId: string;
  isPaid: boolean;
  disabled: boolean;
  setUserPaidStatus: (userId: string, isPaid: boolean) => void;
  restriction: string | undefined;
};
function UserTypeCell({
  userId,
  isPaid,
  disabled,
  setUserPaidStatus,
  restriction,
}: Readonly<UserTypeCellProps>) {
  return (
    <>
      <Radio
        label="Free user"
        radioGroup={`user-${userId}`}
        checked={!isPaid}
        disabled={disabled}
        onChange={(e) => setUserPaidStatus(userId, !e.currentTarget.checked)}
      />
      {restriction ? (
        <Tooltip tip={getRestrictionExplanation(restriction)}>
          <Radio
            label="Paid user"
            radioGroup={`user-${userId}`}
            checked={isPaid}
            onChange={(e) => setUserPaidStatus(userId, e.currentTarget.checked)}
            disabled
          />
        </Tooltip>
      ) : (
        <Radio
          label="Paid user"
          radioGroup={`user-${userId}`}
          checked={isPaid}
          onChange={(e) => setUserPaidStatus(userId, e.currentTarget.checked)}
        />
      )}
    </>
  );
}

const getMobileTable = ({
  setUserPaidStatus,
  userRestrictions,
  disabledUsers,
  reachedMaxUsers,
}: TableProps) =>
  [
    {
      Header: 'User',
      accessor: 'name',
      colSpan: 1,
      group: true,
      Cell: ({ value }) => (
        <TableCellValue width="semi-bold">{value}</TableCellValue>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      colSpan: 1,
      Cell: ({ value }) => <TableCellValue>{value}</TableCellValue>,
    },
    {
      Header: 'User type',
      accessor: 'isPaid',
      colSpan: 1,
      float: 'right',
      Cell: ({ value, row }) => {
        const user = row.original;
        const restriction = getUserRestriction(
          user,
          userRestrictions,
          reachedMaxUsers,
        );

        return (
          <TableCellGroup>
            <UserTypeCell
              userId={user.id}
              disabled={disabledUsers.includes(user.id)}
              isPaid={value}
              restriction={restriction}
              setUserPaidStatus={setUserPaidStatus}
            />
          </TableCellGroup>
        );
      },
    },
  ] as Column<CheckoutUser>[];

const getDesktopTable = ({
  setUserPaidStatus,
  userRestrictions,
  disabledUsers,
  reachedMaxUsers,
}: TableProps) =>
  [
    {
      Header: 'User',
      accessor: 'name',
      group: false,
      colSpan: 2,
      Cell: ({ value }) => (
        <TableCellValue width="semi-bold">{value}</TableCellValue>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      colSpan: 3,
      Cell: ({ value }) => <TableCellValue>{value}</TableCellValue>,
    },
    {
      Header: 'User type',
      accessor: 'isPaid',
      colSpan: 2,
      group: true,
      Cell: ({ value, row }) => {
        const user = row.original;
        const restriction = getUserRestriction(
          user,
          userRestrictions,
          reachedMaxUsers,
        );

        return (
          <UserTypeCell
            userId={user.id}
            disabled={disabledUsers.includes(user.id)}
            isPaid={value}
            restriction={restriction}
            setUserPaidStatus={setUserPaidStatus}
          />
        );
      },
    },
  ] as Column<CheckoutUser>[];

export const getColumns = (props: TableProps) =>
  props.isMobile ? getMobileTable(props) : getDesktopTable(props);
