import styled from 'styled-components';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';
import { Placeholder as DefaultPlaceholder } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => addAlpha(theme.colors.gray1, 0.7)},
      ${({ theme }) => theme.colors.gray1}
    );
  }

  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  height: 1rem;

  ${from.tablet} {
    gap: 16px;
  }
`;

export const Cell = styled(DefaultPlaceholder)`
  --placeholder-bg: ${({ theme }) => theme.colors.gray700};
  --placeholder-opacity: 0.3;
  --placeholder-animate: ${({ theme }) => theme.colors.gray900};
`;
