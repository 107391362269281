import {
  TableCellValueGroup as DefaultTableCellValueGroup,
  TableCellValue as DefaultTableCellValue,
  Badge as DefaultBadge,
} from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';

export const TrialContainer = styled(DefaultTableCellValueGroup)`
  gap: 8px;

  i {
    width: 18px;
    height: 18px;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: ${({ theme }) => theme.colors.green500};
      }
    }
  }
`;

export const TableCellValue = styled(DefaultTableCellValue)`
  z-index: 2;
`;

export const Badge = styled(DefaultBadge)`
  z-index: 2;
`;
