import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { PriceProps } from './types';

const PriceContainer = styled.div`
  display: flex;
`;

const CurrencyText = styled.span`
  align-self: start;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.blue900};
`;

const PriceText = styled.span`
  align-self: center;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.05em;
  color: ${({ theme }) => theme.colors.cowboy};
`;

const BillingPeriodText = styled.span`
  align-self: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.blue900};
`;

const Price: FC<PriceProps> = ({ price, currency, billingPeriod }) => (
  <PriceContainer>
    <CurrencyText>{currency}</CurrencyText>
    <PriceText>{price}</PriceText>
    <BillingPeriodText>/{billingPeriod}</BillingPeriodText>
  </PriceContainer>
);

export default memo(Price);
