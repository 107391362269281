import { Template } from 'model/template';
import { useLayoutEffect, useMemo, useState } from 'react';

export const useLogic = ({ data }: { data?: Template[] }) => {
  const [thumbnails, setThumbnails] = useState<
    Record<string, HTMLImageElement>
  >({});
  const [previews, setPreviews] = useState<Record<string, HTMLImageElement>>(
    {},
  );

  useLayoutEffect(() => {
    (data ?? []).forEach(({ id, thumbnail, preview }) => {
      if (!thumbnails[id] && thumbnail) {
        const image = new Image();
        image.onload = () => {
          setThumbnails((props) => ({ ...props, [id]: image }));
        };
        image.src = thumbnail;
      }
      if (!previews[id] && preview) {
        const image = new Image();
        image.onload = () => {
          setPreviews((props) => ({ ...props, [id]: image }));
        };
        image.src = preview;
      }
    });
  }, [data, thumbnails, previews]);

  const templates = useMemo(
    () =>
      data?.map((template) => ({
        ...template,
        thumbnail: thumbnails[template.id] ? template.thumbnail : undefined,
        isPreviewLoaded: !!thumbnails[template.id],
      })),
    [data, previews, thumbnails],
  );

  return {
    templates,
  };
};
