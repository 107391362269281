import styled from 'styled-components';
import { ReactComponent as DefaultCloseIcon } from 'assets/images/icons/close.svg';

export const Paragraph = styled.span`
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  display: block;

  color: ${(props) => props.theme.colors.cowboy};

  & > b {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding-left: 2.5rem;
  margin: 1rem 0;

  li::before {
    content: '\\2022';
    color: #b6b4b3;
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    margin-left: -1rem;
  }
`;

export const Li = styled.li`
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.4375rem;

  color: ${({ theme }) => theme.colors.cowboy};

  & > b {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;

export const CloseImage = styled(DefaultCloseIcon)`
  cursor: pointer;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 0.8rem;
  top: 0.8rem;
  color: #8991a1;
`;

export const Container = styled.div`
  padding: 1.875rem;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.orange600};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.orange600};
`;
