import styled from 'styled-components';
import { from } from 'styles/media';
import React from 'react';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px;
  padding-top: 28px;
  padding-bottom: 28px;

  ${from.tablet} {
    padding: 36px;
    width: 500px;
  }
`;

export const upgradeDialogSection: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
};
