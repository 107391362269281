import {
  Button,
  Dialog,
  FlexContainer,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import { plurify } from 'utils/plurify';
import { useConnect } from './connect';
import { Title, Container, ActionContainer } from './styles';
import { Props } from './types';

export const RemoveValidation: FC<Props> = ({
  show,
  onClose,
  members,
  setUserAlerts,
  onCloseDrawer,
}) => {
  const {
    workspaceName,
    isLoading,
    onRemove,
    memberNames,
    hasPaidUser,
  } = useConnect({
    show,
    onClose,
    members,
    setUserAlerts,
    onCloseDrawer,
  });

  return (
    <Dialog show={show} onClose={() => !isLoading && onClose()}>
      <Container>
        <FlexContainer flex="vertical" gap="medium">
          <Title>
            {memberNames.length > 1
              ? `Remove these users from ${workspaceName}?`
              : `Remove ${memberNames[0]} from ${workspaceName}?`}
          </Title>
          <Text
            type="paragraph"
            size="base"
            width="regular"
            style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
          >
            {hasPaidUser &&
              plurify(
                memberNames.length,
                'This is a Paid user.\n',
                'Some of them are Paid users.\n',
              )}
            {plurify(
              memberNames.length,
              'They will no longer have access to this space.\n',
              'These users will no longer have access to this space.\n',
            )}
            {/*
            <Link href={learnMoreUrl}>Learn more</Link>
            */}
          </Text>
        </FlexContainer>
        <ActionContainer>
          <Button
            aria-label="remove from space"
            size="base"
            variant="primary"
            onClick={onRemove}
            disabled={isLoading}
            loading={isLoading}
          >
            Yes, proceed
          </Button>
          <Button
            aria-label="cancel"
            size="base"
            variant="secondary"
            disabled={isLoading}
            onClick={onClose}
          >
            No, cancel
          </Button>
        </ActionContainer>
      </Container>
    </Dialog>
  );
};
