import styled from 'styled-components';
import { TableWrapper } from '../../../components/ResponsiveTable/styles';

export const StyledTableContainer = styled.div`
  ${TableWrapper} {
    margin-top: 0;
  }
`;

export const BlueLink = styled.a`
  color: ${({ theme }) => theme.colors.blue700};
`;
