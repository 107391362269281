import {
  TeamMemberInput,
  TeamMember,
  TeamReportInput,
  TeamReport,
} from 'model/team';

export const transformTeamMembers = (
  members: TeamMemberInput[],
): TeamMember[] => {
  return members.map(
    (member) =>
      ({
        id: member.userId,
        name: member.userName,
        email: member.userEmail,
        lastTimeUsed:
          !!member.lastEmailSentTimestamp || member.lastEmailSentTimestamp === 0
            ? Number(member.lastEmailSentTimestamp)
            : undefined,
        counters: {
          totalEmailsSentThisMonth: member.emailSentThisMonth ?? 0,
          totalEmailsSent: member.emailSentAllTime ?? 0,
        },
        photo: member.userProfilePictureUrl,
        initials: member.userName
          ?.split(' ')
          .map((word) => word[0].toUpperCase())
          .slice(0, 2)
          .join(''),
      } as TeamMember),
  );
};

export const transformTeamReport = (
  report?: TeamReportInput | null,
): TeamReport => {
  return {
    totalMembers: report?.activeUserCount ?? 0,
    counters: {
      totalEmailsSent: report?.emailSentAllTime ?? 0,
      totalEmailsSentThisMonth: report?.emailSentThisMonth ?? 0,
    },
  } as TeamReport;
};
