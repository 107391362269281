import {
  Button,
  Dialog,
  Text,
  Heading,
} from '@scriptaddicts/yamm-ui-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import React, { FC } from 'react';
import { ReactComponent as CopyToClipboardIcon } from 'assets/images/icons/copy-to-clipboard.svg';
import { Props } from './types';
import { useConnect } from './connect';
import LoadingScreen from '../../components/LoadingScreen';
import { Container, upgradeDialogSection } from './styles';

export const UpgradeUserDialog: FC<Props> = ({ show, close }) => {
  const { isLoading, ownerEmail, adminEmails, currentWorkspace } = useConnect();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Dialog show={show} close="both" onClose={close}>
      <Container>
        <Heading size="large">Upgrade</Heading>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '6px',
            alignSelf: 'stretch',
          }}
        >
          <div style={upgradeDialogSection}>
            <Text>
              You are part of{' '}
              <span style={{ fontWeight: 600 }}>{currentWorkspace?.name}</span>,
              owned by <span style={{ fontWeight: 600 }}>{ownerEmail}</span>
              <CopyToClipboard text={ownerEmail || ''}>
                <span
                  style={{
                    position: 'relative',
                    left: '5px',
                    bottom: '-3px',
                  }}
                >
                  <CopyToClipboardIcon />
                </span>
              </CopyToClipboard>
            </Text>
          </div>
          <div style={upgradeDialogSection}>
            {adminEmails.length > 0 && (
              <Text>The space also has the following admins:</Text>
            )}
            <ul
              style={{
                listStylePosition: 'inside',
                listStyleType: 'initial',
              }}
            >
              {adminEmails.map((email) => (
                <li
                  key={email}
                  style={{
                    fontSize: '14px',
                    display: 'list-item',
                    lineHeight: '160%',
                  }}
                >
                  {email}
                  <CopyToClipboard text={email || ''}>
                    <span
                      style={{
                        position: 'relative',
                        left: '5px',
                        bottom: '-3px',
                      }}
                    >
                      <CopyToClipboardIcon />
                    </span>
                  </CopyToClipboard>
                </li>
              ))}
            </ul>
          </div>
          <div style={upgradeDialogSection}>
            {adminEmails.length === 0 && (
              <Text>Contact them to request an upgrade.</Text>
            )}
            {adminEmails.length > 0 && (
              <Text>Contact any of them to request an upgrade.</Text>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '8px',
            }}
          >
            <Button
              aria-label="cancel"
              variant="secondary"
              onClick={() => {
                close();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Container>
    </Dialog>
  );
};
