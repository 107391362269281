import { AxiosError } from 'axios';
import { queryClient } from 'data/queryClient';
import {
  deleteUnsubscriptionOnActivation,
  getUnsubscriptions,
  postUnsubscriptionsOnActivation,
} from 'data/requests/unsubscriptions';
import { BackendError } from 'model/error';
import { InputParams } from 'model/parameters';
import {
  DeleteUnsubscription,
  PostUnsubscriptionsParams,
  Unsubscription,
  UnsubscriptionParams,
} from 'model/unsubscription';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { createSort, createFilter } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';

export const useUnsubscriptions = (
  workspaceId?: string,
  params?: UnsubscriptionParams,
) => {
  const { queryKey, isEnabled, queryParams } = useMemo(
    () => ({
      queryKey: ['space', workspaceId, 'unsubscriptions'],
      isEnabled: !!workspaceId && !!params?.limit,
      queryParams: {
        filterBy: createFilter(params?.filters),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [workspaceId, params],
  );

  const { data, ...rest } = useQuery(
    [...queryKey, queryParams],
    () => getUnsubscriptions(workspaceId!, queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );

  const fetchAll = useCallback(async () => {
    const predicates = [...queryKey, { limit: 30000 }];
    return queryClient.fetchQuery(
      predicates,
      () => getUnsubscriptions(workspaceId!, predicates[3] as InputParams),
      {
        staleTime: Infinity,
      },
    );
  }, [queryClient, workspaceId, queryKey]);

  return { data: useDefaultArray<Unsubscription>(data), ...rest, fetchAll };
};

export const useAddUnsubscriptions = (workspaceId?: string) => {
  return useMutation<
    number,
    AxiosError<BackendError>,
    PostUnsubscriptionsParams
  >(
    async (params) => {
      if (!workspaceId) {
        throw new Error('WorkspaceId is required');
      }
      if (params.emailAddresses.length === 0) {
        throw new Error('Email address is required');
      }
      return postUnsubscriptionsOnActivation(workspaceId, params);
    },
    {
      onSuccess: async () =>
        queryClient.invalidateQueries([
          'space',
          workspaceId,
          'unsubscriptions',
        ]),
    },
  );
};

export const useDeleteUnsubscription = (workspaceId?: string) => {
  return useMutation<any, AxiosError<BackendError>, DeleteUnsubscription>(
    async (params) => {
      if (!workspaceId) {
        throw new Error('WorkspaceId is required');
      }
      return deleteUnsubscriptionOnActivation(workspaceId, params);
    },
    {
      onSuccess: async () =>
        queryClient.invalidateQueries([
          'space',
          workspaceId,
          'unsubscriptions',
        ]),
    },
  );
};
