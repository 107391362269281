import styled from 'styled-components';
import { from } from 'styles/media';

import { ReactComponent as YammLogo } from 'assets/images/brand/YAMM-Logotype.svg';
import {
  Link as DefaultLink,
  Text as DefaultText,
  Heading as DefaultHeading,
} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div``;

export const ImageWrapper = styled.div`
  height: 20px;
`;

export const YammImage = styled(YammLogo)`
  height: 80px;
  width: 80px;
  transform: translateY(-100px);
`;

export const Main = styled.main`
  grid-area: main;
  align-self: center;
`;

export const Nav = styled.nav``;

export const NavLink = styled(DefaultLink).attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  display: inline-block;
  letter-spacing: -0.0528px;
  margin: 8px 10px;
  position: relative;

  ${from.mobile} {
    :not(:last-child) {
      &:after {
        content: '·';
        position: absolute;
        right: -12px;
      }
    }
  }
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  margin: 20px auto;
  max-width: 480px;
  text-align: center;
  box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.1);
`;

export const LoginCard = styled(Card)`
  padding: 60px 24px 24px;
  ${from.mobile} {
    padding: 60px 80px 40px;
  }
`;

export const SingUpCard = styled(Card)`
  padding: 24px;
  ${from.mobile} {
    padding: 40px 80px;
  }
`;

export const Title = styled(DefaultHeading).attrs({
  size: 'base',
})`
  margin-bottom: 24px;

  ${from.tablet} {
    font-size: 24px !important;
    line-height: 30px !important;
  }
`;

export const AgreeToSText = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraSmall',
  width: 'medium',
})`
  color: ${({ theme }) => theme.colors.gray700};
  margin-top: 16px !important;
  display: block;
`;

export const TosLink = styled(DefaultLink).attrs({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
})``;

export const LinkWrapper = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  margin-top: 50px;

  img {
    width: 120px;
  }
`;
