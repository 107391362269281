import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CHECKOUT,
  FROM,
  FROM_INTERVAL,
  NEW_GMAIL_USERS,
  NEW_WORKSPACE_USERS,
  TO,
  TO_INTERVAL,
} from '../../../../../data/queryParams';

export const CHECKOUT_RESULT = {
  NONE: -1,
  LEGACY_UPGRADE: 1,
  PLAN_CHANGED: 2,
  GMAIL_USERS_UPDATED: 3,
  WORKSPACE_USERS_UPDATED: 4,
  NEW_PLAN_PURCHASE: 5,
  BILLING_INTERVAL_CHANGE: 6,
};

const getNumber = (value: string | null): number => {
  if (!value) return 0;
  return parseInt(value, 10);
};

const checkPlanChanged = (from: string | null, to: string | null) =>
  from != null && from !== 'LEGACY' && from !== to;

const checkIntervalChange = (from: string | null, to: string | null) =>
  from != null && from !== to;

export const useConnect = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.get(CHECKOUT) === 'success') {
      const from = queryParams.get(FROM);
      const to = queryParams.get(TO);

      if (from === 'LEGACY' && from !== to) {
        return CHECKOUT_RESULT.LEGACY_UPGRADE;
      }

      if (from === 'FREE' && to != null) {
        return CHECKOUT_RESULT.NEW_PLAN_PURCHASE;
      }

      if (checkPlanChanged(from, to)) {
        return CHECKOUT_RESULT.PLAN_CHANGED;
      }

      const newPaidUsers = queryParams.get(NEW_WORKSPACE_USERS);
      if (newPaidUsers != null && getNumber(newPaidUsers) !== 0) {
        return CHECKOUT_RESULT.GMAIL_USERS_UPDATED;
      }

      const newGmailPaidUsers = queryParams.get(NEW_GMAIL_USERS);
      if (newGmailPaidUsers != null && getNumber(newGmailPaidUsers) !== 0) {
        return CHECKOUT_RESULT.GMAIL_USERS_UPDATED;
      }

      const billingIntervalFrom = queryParams.get(FROM_INTERVAL);
      const billingIntervalTo = queryParams.get(TO_INTERVAL);
      if (checkIntervalChange(billingIntervalFrom, billingIntervalTo)) {
        return CHECKOUT_RESULT.BILLING_INTERVAL_CHANGE;
      }
    }

    return CHECKOUT_RESULT.NONE;
  }, [search]);
};
