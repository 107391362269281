import { AxiosError } from 'axios';
import { queryClient } from 'data/queryClient';
import {
  deleteBounceOnActivation,
  getBounces,
  postBouncesOnActivation,
} from 'data/requests/bounces';
import {
  Bounce,
  BounceParams,
  DeleteBounce,
  PostBouncesParams,
} from 'model/bounce';

import { BackendError } from 'model/error';
import { InputParams } from 'model/parameters';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { createSort, createFilter } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';

export const useBounces = (workspaceId?: string, params?: BounceParams) => {
  const { queryKey, isEnabled, queryParams } = useMemo(
    () => ({
      queryKey: ['space', workspaceId, 'bounces'],
      isEnabled: !!workspaceId && !!params?.limit,
      queryParams: {
        filterBy: createFilter(params?.filters),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [workspaceId, params],
  );

  const { data, ...rest } = useQuery(
    [...queryKey, queryParams],
    () => getBounces(workspaceId!, queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );

  const fetchAll = useCallback(async () => {
    const predicates = [...queryKey, { limit: 30000 }];
    return queryClient.fetchQuery(
      predicates,
      () => getBounces(workspaceId!, predicates[3] as InputParams),
      {
        staleTime: Infinity,
      },
    );
  }, [queryClient, workspaceId, queryKey]);

  return { data: useDefaultArray<Bounce>(data), ...rest, fetchAll };
};

export const useAddBounces = (workspaceId?: string) => {
  return useMutation<any, AxiosError<BackendError>, PostBouncesParams>(
    async (params) => {
      if (!workspaceId) {
        throw new Error('WorkspaceId is required');
      }
      if (params.emailAddresses.length === 0) {
        throw new Error('Email address is required');
      }
      return postBouncesOnActivation(workspaceId, params);
    },
    {
      onSuccess: async () =>
        queryClient.invalidateQueries(['space', workspaceId, 'bounces']),
    },
  );
};

export const useDeleteBounce = (workspaceId?: string) => {
  return useMutation<any, AxiosError<BackendError>, DeleteBounce>(
    async (params) => {
      if (!workspaceId) {
        throw new Error('WorkspaceId is required');
      }
      return deleteBounceOnActivation(workspaceId, params);
    },
    {
      onSuccess: async () =>
        queryClient.invalidateQueries(['space', workspaceId, 'bounces']),
    },
  );
};
