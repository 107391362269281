import styled from 'styled-components';
import { from } from 'styles/media';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px;
  padding-top: 28px;
  padding-bottom: 28px;

  ${from.tablet} {
    padding: 36px;
    width: 500px;
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  padding-right: calc(1.5rem - 0.375rem);
  margin-right: 0.375rem;
  flex: 1;
`;
