import { Alert, Strong, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { Links } from '../../../../../data/links';
import { BlueLink } from './styles';
import { MAX_USERS_PER_SPACE } from '../../../../../data/constants';

export const MaxUsersReachedAlert = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="semiBold">
      Your current plan allows a maximum of {MAX_USERS_PER_SPACE} paid users.
    </Text>
    <Text type="paragraph" size="small" width="regular">
      <BlueLink href={Links.CONTACT_US} target="_blank">
        Need more users? Contact us
      </BlueLink>
    </Text>
  </Alert>
);

export const CannotDowngradeAlert = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="semiBold">
      You cannot downgrade
    </Text>
    <Text type="paragraph" size="small" width="regular">
      At least one user must be paid as long as a subscription is active.
    </Text>
  </Alert>
);

export const CannotRemovePaidUsers = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="regular">
      To remove paid users, you must downgrade them to free and then remove them
      during the next billing cycle.
    </Text>
  </Alert>
);

export const OwnerCannotBeRemoved = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="regular">
      The owner cannot be removed from the space
    </Text>
  </Alert>
);

export const UserAlreadyPaidInAnotherSpaceAlert = ({
  subjectEmails,
}: {
  subjectEmails: string[];
}) => (
  <Alert type="warning" stroke="300">
    {subjectEmails.length === 1 ? (
      <Text type="paragraph" size="small" width="regular">
        <Strong width="semiBold">{subjectEmails[0]}</Strong> is already a paid
        user in another space.
      </Text>
    ) : (
      <Text type="paragraph" size="small" width="regular">
        <Strong width="semiBold">{subjectEmails[0]}</Strong> and{' '}
        {subjectEmails.length - 1} others are already paid users in another
        space.
      </Text>
    )}
  </Alert>
);
