import {
  TableCellValueGroup as DefaultTableCellValueGroup,
  Icon as DefaultIcon,
} from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';

export const ErrorIcon = styled(DefaultIcon).attrs({
  name: 'alert-octogon-fill',
})`
  svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${({ theme }) => theme.colors.red500} !important;
    }
  }
`;

export const WarningIcon = styled(DefaultIcon).attrs({
  name: 'alert-triangle-fill',
})`
  svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${({ theme }) => theme.colors.orange500} !important;
    }
  }
`;

export const TableCellValueGroup = styled(DefaultTableCellValueGroup).attrs({
  width: 'medium',
})`
  z-index: 2;
  gap: 8px;
`;
