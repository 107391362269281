import styled, { css } from 'styled-components';

import {
  Text as DefaultText,
  Placeholder as DefaultPlaceholder,
  Icon as DefaultIcon,
} from '@scriptaddicts/yamm-ui-components';
import { from } from 'styles/media';
import { Props } from './types';

export const IconWrapper = styled.div`
  border-radius: 50%;
  height: 56px;
  width: 56px;
  flex-shrink: 0;
  padding: 16px;

  > svg {
    height: 100%;
    width: 100%;
  }
`;

export const StatsCardContainer = styled.div<{
  $variant: Props['variant'];
}>`
  display: flex;
  align-items: center;
  gap: 16px;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 20px 24px;

  ${({ $variant }) => {
    switch ($variant) {
      case 'sent-this-month':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) => theme.colors.blue100};
            svg path {
              fill: ${({ theme }) => theme.colors.blue700};
            }
          }
        `;
      case 'emails':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) => theme.colors.orange100};
            svg path {
              fill: ${({ theme }) => theme.colors.orange700};
            }
          }
        `;
      case 'team':
      case 'merges':
      default:
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) => theme.colors.green100};
            svg path {
              fill: ${({ theme }) => theme.colors.green700};
            }
          }
        `;
    }
  }}
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const NumberText = styled(DefaultText).attrs({
  width: 'semiBold',
})`
  font-size: 24px;
  line-height: 29px;
`;

export const NumberPlaceholder = styled(DefaultPlaceholder).attrs({
  width: 100,
  animate: false,
})`
  height: 24px;
  margin: 3px 0px;
`;

export const StatsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  grid-column: 1 / -1;

  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 24px;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    flex: 1 0 1rem;
  }

  ${from.tablet} {
    grid-column: 2;
    &::after {
      content: none;
    }
    margin-top: 32px;
    padding-left: 0;
  }

  > * {
    :not(:first-child) {
      margin-left: 24px;
    }
    flex: 1 0 18.5rem;
    scroll-snap-align: start;
    scroll-margin-inline-start: 24px;
    ${from.tablet} {
      flex: 1;
    }
  }
`;

export const EmailsSentText = styled(DefaultText)`
  display: inline-flex;
  align-items: center;
`;

export const InfoIcon = styled(DefaultIcon).attrs({ name: 'info' })`
  margin-left: 6.4px;
  svg {
    width: 15px;
    height: 15px;
  }
`;
