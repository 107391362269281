import { WorkspaceUserRole } from '../../model/workspace';

export const RoleValue: { [key in WorkspaceUserRole]: number } = {
  MEMBER: 1,
  ADMIN: 2,
  OWNER: 3,
};

export const getRoleValue = (role: WorkspaceUserRole): number => {
  if (!role) return -1;
  return RoleValue[role];
};
