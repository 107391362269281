import React from 'react';

import StaticLayout from 'containers/Common/StaticLayout';
import testingVar from 'utils/testingVar';
import { Prompt } from 'components/Prompt';
import { Link } from '@scriptaddicts/yamm-ui-components';

const Error = () => {
  return (
    <StaticLayout data-test-id={testingVar('error')}>
      <Prompt
        variant="warning"
        title="Sorry, an error ocurred"
        description={
          <>
            Please try again or visit our{' '}
            <Link
              href="https://support.yet-another-mail-merge.com/hc/en-us"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Help center
            </Link>
            .
          </>
        }
      />
    </StaticLayout>
  );
};

export default Error;
