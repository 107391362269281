import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useAcceptInvite } from 'data/hooks/useWorkspaceInvite';
import { transformError } from 'data/transform/error';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { decodeBase64UUID } from 'utils/uid';
import { queryClient } from '../../../../data/queryClient';
import { UserInput } from '../../../../model/user';
import { useMe } from '../../../../data/hooks/useMe';

function getWorkspaceInfo(id: string) {
  const userData = queryClient.getQueryData<UserInput>(['me']);
  const space = userData?.userWorkspaces?.find((s) => s.workspaceId === id);

  return {
    addonSpace: userData?.addonWorkspaceId,
    space,
  };
}

function removeInitialSlash(path: string) {
  if (path[0] === '/') {
    return path.slice(1);
  }
  return path;
}

export const useConnect = () => {
  const {
    setAlerts,
    setKeepPreviousWorkspace,
    restrictions,
    isLoading,
    urlPrefix,
  } = useWorkspaceContext();
  const { isFetching } = useMe();
  const { invitationToken, status } = useParams<{
    status?: 'accept';
    invitationToken: string;
  }>();

  const { replace } = useHistory();

  const {
    mutate: accept,
    status: acceptStatus,
    error: acceptError,
    data: acceptData,
  } = useAcceptInvite();

  useEffect(() => {
    if (isLoading) return;
    if (!!restrictions && restrictions.INVITATION) return;
    if (status === 'accept') {
      accept({ invitationToken: decodeBase64UUID(invitationToken) });
    }
  }, [status, invitationToken, restrictions, isLoading]);

  useEffect(() => {
    if (isFetching) return;
    if (acceptStatus === 'success') {
      setAlerts({
        join: {
          name: acceptData?.workspaceName,
          id: acceptData?.workspaceId,
          new: false,
        },
      });

      if (acceptData?.workspaceId) {
        const { addonSpace, space } = getWorkspaceInfo(acceptData?.workspaceId);
        if (space && addonSpace === space.workspaceId) {
          replace(`/${removeInitialSlash(space.urlPrefix)}/mail-merges/sent`);
        } else {
          replace(`/${removeInitialSlash(urlPrefix)}/mail-merges/sent`);
        }
      }
    } else if (acceptStatus === 'error') {
      const error = transformError(acceptError);
      setAlerts(
        error?.code === 500
          ? { error: { type: 'join' } }
          : {
              join: {
                error,
              },
            },
      );
      replace(`${urlPrefix}/mail-merges/sent`);
    }
    setKeepPreviousWorkspace(acceptStatus);
  }, [
    setAlerts,
    replace,
    setKeepPreviousWorkspace,
    acceptStatus,
    acceptError,
    acceptData,
    urlPrefix,
    isFetching,
  ]);

  return {
    isRestricted: restrictions?.INVITATION,
  };
};
