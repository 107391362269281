import { Icon } from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import {
  Card,
  IconWrapper,
  PromptContent,
  PromptTitle,
  PromptDescription,
} from './styles';

export const Prompt: FC<{
  variant?: 'warning' | 'success' | 'error';
  title: JSX.Element | string;
  description?: JSX.Element | string;
}> = ({ children, variant, title, description }) => {
  return (
    <Card>
      {!!variant && (
        <IconWrapper $variant={variant}>
          {variant === 'warning' && <Icon name="alert-triangle" />}
          {variant === 'success' && <Icon name="check" />}
          {variant === 'error' && <Icon name="x" />}
        </IconWrapper>
      )}
      <PromptContent>
        <PromptTitle>{title}</PromptTitle>
        {!!description && <PromptDescription>{description}</PromptDescription>}
        {children}
      </PromptContent>
    </Card>
  );
};
