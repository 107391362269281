import styled, { css } from 'styled-components';
import { Card, Button, Text } from '@scriptaddicts/yamm-ui-components';
import StripeStamp from '../../../components/StripeStamp';
import { from } from '../../../styles/media';

export const StyledTitle = styled(Text).attrs({
  type: 'paragraph',
})`
  ${({ theme }) => css`
    font-family: ${theme.fonts.lexendDeca};
    color: ${theme.colors.gray900};
  `}

  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px !important;
  text-align: center;

  ${from.tablet} {
    text-align: left;
  }
`;

export const StyledMobileSidePadding = styled.div`
  padding: 0 24px;

  ${from.tablet} {
    padding: 0;
  }
`;

export const StyledTitleWrapper = styled(StyledMobileSidePadding)`
  margin-bottom: 20px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;

  ${from.tablet} {
    text-align: left;
  }
`;

export const StyledTaxesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4px;
`;

export const StyledDifferenceText = styled(Text).attrs({
  type: 'paragraph',
})`
  display: flex;
  justify-content: space-between;
`;

export const StyledTaxesText = styled(Text).attrs({
  type: 'paragraph',
})`
  color: ${({ theme }) => theme.colors.gray600};
  display: flex;
  justify-content: space-between;
`;

export const StyledSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  margin: 24px 0;
`;

export const StyledTotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledPriceSection = styled.div`
  text-align: right;
`;

export const StyledPriceSectionPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledNextButton = styled(Button)`
  margin: 16px 0;
  height: 40px;
`;

export const StyledStripeLogo = styled(StripeStamp)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StyledGreenText = styled.span`
  color: ${({ theme }) => theme.colors.green700};
`;

export const StyledCard = styled(Card)`
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-color: ${({ theme }) => theme.colors.gray300};

  ${from.tablet} {
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: 6px;
  }
`;

export const StyledBillingCycle = styled(Text).attrs({
  type: 'paragraph',
  size: 'base',
})`
  color: #77716c;
`;

export const TermsAndServicesText = styled.p`
  margin-top: 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 20.8px */
  letter-spacing: -0.13px;
`;
