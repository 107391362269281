import styled, { css } from 'styled-components';
import { Text } from '@scriptaddicts/yamm-ui-components';

export const StyledDifferenceText = styled(Text).attrs({
  type: 'paragraph',
  size: 'small',
  width: 'semiBold',
})`
  ${({ theme }) => css`
    color: ${theme.colors.gray700};
  `}
`;

export const StyledFromText = styled(Text).attrs({
  type: 'span',
  size: 'small',
  width: 'semiBold',
})`
  ${({ theme }) => css`
    color: ${theme.colors.gray800};
  `}
`;

export const StyledToText = styled(Text).attrs({
  type: 'span',
  size: 'small',
  width: 'bold',
})`
  ${({ theme }) => css`
    color: ${theme.colors.green700};
  `}
`;

export const ChargedTodayProRatedLabel = styled.span`
  display: block;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: -0.0066em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.gray700};
`;
