import {
  BillingLegalEntity,
  WorkspaceSubscription,
} from '../../model/workspaceBilling';
import { OtherWorkspace } from '../../data/context/WorkspaceContext';
import { Plan } from '../../model/plan';

export const getSubscriptionConsole = (
  sub: WorkspaceSubscription,
): BillingLegalEntity | undefined => {
  if (sub.subscriptionPaymentPlans && sub.subscriptionPaymentPlans.length > 0) {
    return sub.subscriptionPaymentPlans[0].legalEntity;
  }

  return undefined;
};

export const getBadgeClassForWorkspace = (value?: OtherWorkspace) => {
  return value?.workspaceUserFeature?.workspacePlan === 'PRO'
    ? 'pro'
    : 'non-pro';
};

export const toTitleCase = (value: string | undefined) => {
  if (value === undefined) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
};

const prettyUserPlan = {
  FREE: 'Free',
  PRO: 'Pro',
  PRO_PERSONAL: 'Pro Personal',
  undefined: '',
};

export const getPrettyUserPlan = (
  userPlan: 'FREE' | 'PRO' | 'PRO_PERSONAL' | undefined,
) => {
  if (userPlan === undefined) {
    return undefined;
  }
  return prettyUserPlan[userPlan];
};

const quotaByUserPlan = {
  FREE: 50,
  PRO: 1500,
  PRO_PERSONAL: 400,
  undefined: 0,
};

export const getRecipientsQuotaPerDay = (
  plan: Plan | undefined,
  freeUserQuota?: number,
): number | undefined => {
  if (plan === undefined) {
    return undefined;
  }
  if (plan === 'FREE') {
    return freeUserQuota ?? 50;
  }
  return quotaByUserPlan[plan];
};

export const getPathForSpace = (value?: OtherWorkspace) => {
  const subPath = value?.role === 'OWNER' ? 'general' : 'users';
  return `/${value?.urlPrefix}/space/${subPath}`;
};

export const getPathForSpaceUsers = (value?: OtherWorkspace) => {
  const subPath = 'users';
  return `/${value?.urlPrefix}/space/${subPath}`;
};
