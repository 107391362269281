import {
  Text as DefaultText,
  Heading as DefaultHeading,
} from '@scriptaddicts/yamm-ui-components';
import { PaginationContainer } from 'components/ResponsiveTable/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/media';

export const LoaderContainer = styled.div`
  margin-top: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;

  * {
    --spinner-color: ${({ theme }) => theme.colors.blue800};
  }
`;

export const PromptContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: -16px;
  height: calc(100vh - 57px);
  padding-bottom: 57px;

  background-color: ${({ theme }) => theme.colors.white};

  ${from.tablet} {
    margin-top: -32px;
    height: calc(100vh - 73px);
    padding-bottom: 73px;

    background-color: unset;
  }
`;

export const Container = styled.div`
  --maxWidth: 58.5rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  color: ${({ theme }) => theme.colors.gray800};

  > * {
    grid-column: 1 / -1;
  }

  ${from.tablet} {
    grid-template-columns: auto var(--maxWidth) auto;

    > * {
      grid-column: 2;
    }

    ${PaginationContainer} {
      grid-column: 1 / -1;
    }
  }
`;

export const Header = styled.div<{
  $direction?: 'vertical' | 'horizontal';
}>`
  display: flex;
  align-items: center;
  padding: 5px 24px;
  ${from.tablet} {
    padding: 5px 0px;
  }

  ${({ $direction }) =>
    $direction === 'vertical' &&
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    `};
`;

export const Title = styled(DefaultHeading).attrs({
  size: 'large',
})`
  color: ${({ theme }) => theme.colors.gray900};
`;

export const Subtitle = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
  width: 'regular',
})`
  line-height: 160% !important;
  white-space: pre-wrap;
`;

export const TitleDivider = styled.span`
  font: inherit;
  color: ${({ theme }) => theme.colors.gray300};
  &::before {
    content: '•';
  }
`;

export const TitleAppend = styled.span`
  font: inherit;
  color: ${({ theme }) => theme.colors.gray500};
`;
