import {
  Alert as DefaultAlert,
  Dialog as DefaultDialog,
} from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';
import { from } from 'styles/media';

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin: 16px 24px 32px 24px;

  ${from.tablet} {
    margin: 16px 0px 32px 0px;
  }
`;

export const Alert = styled(DefaultAlert).attrs({
  stroke: '300',
  size: 'small',
})``;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;

  ${from.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
  }
  padding-bottom: 32px;
`;

export const Dialog = styled(DefaultDialog).attrs({
  close: 'both',
  closeProps: { size: 'large', circle: true },
})`
  > :last-child {
    > :first-child {
      z-index: 1;
    }
  }
  ${from.tablet} {
    > :last-child {
      min-width: 600px;
    }
  }
`;
