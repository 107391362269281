import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useChangeActiveWorkspace } from 'data/hooks/useWorkspace';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useWorkspaceLocation, { PATH } from './useWorkspaceLocation';

export default function useWorkspaceRouter() {
  const { replace } = useHistory();
  const { search, state } = useLocation();
  const { id, urlPrefix, isPreviousWorkspace } = useWorkspaceContext();
  const { changeWorkspace } = useChangeActiveWorkspace();

  const { ready, url, workspaceId, pathname, rules } = useWorkspaceLocation();
  const [lastId, setLastId] = useState<string | undefined>();

  const avoidPrefix = pathname in rules && !rules[pathname];

  useEffect(() => {
    if (!id || !urlPrefix || !ready) return;

    if (avoidPrefix) {
      setLastId(id);
      if (workspaceId) {
        replace({ pathname, search, state });
      }
      return;
    }

    if (!workspaceId) {
      setLastId(id);
      replace({ pathname: `${urlPrefix}${pathname}`, search, state });
      return;
    }

    // If direct redirection happened, change lastId
    if (lastId && lastId !== id && lastId !== workspaceId) {
      setLastId(id);
    }

    if (workspaceId !== id) {
      if (isPreviousWorkspace) return;
      if (lastId !== workspaceId) {
        setLastId(workspaceId);
        changeWorkspace(workspaceId);
      } else {
        setLastId(id);
        replace({ pathname: `${urlPrefix}${pathname}`, search, state });
      }
    } else {
      if (lastId) return;
      setLastId(id);
    }
  }, [
    changeWorkspace,
    setLastId,
    replace,
    id,
    urlPrefix,
    ready,
    workspaceId,
    pathname,
    avoidPrefix,
    search,
    state,
    lastId,
    isPreviousWorkspace,
  ]);

  return {
    url,
    path: PATH,
    isLoading: (!avoidPrefix && !workspaceId) || !lastId,
  };
}
