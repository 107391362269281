import styled from 'styled-components';
import { from } from 'styles/media';

import ContainerComponent from 'components/Container';
import { RouterLink } from 'components/Link';
import { Button as DefaultButton } from '@scriptaddicts/yamm-ui-components';
import { Card as CardComponent } from '../styles';

export const Main = styled.main`
  grid-area: main;
  padding-top: 24px;

  ${from.tablet} {
    padding-top: 50px;
  }
`;

export const Card = styled(CardComponent)`
  margin-top: 0;
  padding: 40px;
  text-align: left;
`;

export const Link = styled(RouterLink)`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.0066em;
  text-decoration-line: underline;
  font-feature-settings: 'tnum' on, 'lnum' on;
  text-decoration: none;
`;

export const Button = styled(DefaultButton)`
  height: 40px;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray1};
  display: grid;
  grid-template-areas:
    'main'
    'footer';
  grid-template-rows: 1fr auto;
  height: 100vh;

  ${from.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const Container = styled(ContainerComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Footer = styled.footer`
  margin: 1.5rem;
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-align: center;

  ${from.tablet} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const Badges = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    margin-top: 0;
  }

  ${from.mobile} {
    flex-direction: row;
  }
`;

export const FooterLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  margin-left: 0;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  & img {
    height: auto;
    width: 6.625rem;
    filter: drop-shadow(0 0.125rem 0.375rem rgba(99, 84, 77, 0.15));
  }

  ${from.mobile} {
    margin-left: 0.625rem;
    margin-bottom: 0;

    & img {
      height: 2.5rem;
    }
  }
`;

export const Text = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.suvaGrey};
  font-size: 0.75rem;
  line-height: 160%;
  letter-spacing: -0.0066em;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.orange600};
  }
`;
