import React from 'react';
import { Button } from '@scriptaddicts/yamm-ui-components';
import CheckoutSteps from './CheckoutSteps';
import { useConnect } from './connect';
import { StyledTopBarContainer } from '../styles';

const CheckoutBar = () => {
  const { goBack, isLoading, steps, activeIndex } = useConnect();

  return (
    <StyledTopBarContainer>
      <Button
        variant="transparent"
        aria-label="Back"
        icon="chevron-left"
        onClick={goBack}
      >
        Back
      </Button>
      {!isLoading && <CheckoutSteps steps={steps} active={activeIndex} />}
      <span />
    </StyledTopBarContainer>
  );
};

export default CheckoutBar;
