import React from 'react';

import StaticLayout from 'containers/Common/StaticLayout';
import testingVar from 'utils/testingVar';
import { Prompt } from 'components/Prompt';

const Success = () => {
  return (
    <StaticLayout data-test-id={testingVar('success')}>
      <Prompt
        variant="success"
        title="Thanks, your response has been recorded"
        description="Your assistant for greater and faster feedback, powered by Yet Another Mail Merge"
      />
    </StaticLayout>
  );
};

export default Success;
