import { Bounce, ConsolidatedBounceInput } from 'model/bounce';

export const transformConsolidatedBounces = (
  bounces: ConsolidatedBounceInput[],
): Bounce[] => {
  return bounces.map((bounce) => ({
    email: bounce.emailAddress,
    bounceTime: new Date(
      Number(
        bounce.lastUpdateTimestamp ?? bounce.createTimestamp ?? Date.now(),
      ),
    ).toString(),
    type: bounce.type,
  }));
};
