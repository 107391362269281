import { useRouteMatch } from 'react-router-dom';

import { useScheduledCampaignCount } from 'data/hooks/useScheduledCampaign';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMemo } from 'react';
import { useUserCounters } from '../../../data/hooks/useMe';
import { Counters } from '../../../model/counters';

const getCounters = (counters?: Counters) => {
  const today = new Date();
  const thisMonth = counters?.monthlySentMails?.find(
    (monthlySentMail) =>
      monthlySentMail.year === today.getFullYear() &&
      monthlySentMail.month === today.getMonth() + 1,
  );

  return {
    allTime: counters?.totalNumberOfMailsSent?.count ?? 0,
    thisMonth: thisMonth?.count ?? 0,
  };
};

export const useConnect = () => {
  const { url, path } = useRouteMatch();
  const { id: workspaceId, details } = useWorkspaceContext();
  const { data: countersData } = useUserCounters();

  const { data: scheduledCampaignCount } = useScheduledCampaignCount(
    workspaceId,
  );

  const counters = useMemo(() => {
    return getCounters(countersData);
  }, [countersData]);

  return {
    url,
    path,
    counters,
    scheduledCampaignCount,
    plan: details?.plan,
  };
};
