import { Spinner } from '@scriptaddicts/yamm-ui-components';
import React, { HTMLAttributes, FC } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Header,
  LoaderContainer,
  PromptContainer,
  Subtitle,
  Title,
  TitleDivider,
  TitleAppend,
} from './styles';

export const MainContainer: FC<
  HTMLAttributes<HTMLDivElement> & { title?: string }
> = ({ title, ...props }) => {
  if (!title) return <Container {...props} />;

  return (
    <>
      <Helmet>
        <title>{title} | YAMM</title>
      </Helmet>
      <Container {...props} />
    </>
  );
};

export const MainContainerHeader: FC<
  HTMLAttributes<HTMLDivElement> & {
    direction?: 'vertical' | 'horizontal';
  }
> = ({ direction, ...props }) => {
  return <Header $direction={direction} {...props} />;
};

export const MainContainerTitle: FC<
  HTMLAttributes<HTMLHeadingElement> & {
    append?: string;
  }
> = ({ children, append, ...props }) => {
  return (
    <Title {...props}>
      {children}
      {!!append && (
        <>
          &nbsp;
          <TitleDivider />
          &nbsp;
          <TitleAppend>{append}</TitleAppend>
        </>
      )}
    </Title>
  );
};

export const MainContainerSubtitle: FC<HTMLAttributes<HTMLParagraphElement>> = (
  props,
) => {
  return <Subtitle {...props} />;
};

export const MainContainerLoader: FC<HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  return (
    <LoaderContainer {...props}>
      <Spinner size={50} />
    </LoaderContainer>
  );
};

export const MainPromptContainer: FC<HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  return <PromptContainer {...props} />;
};
