import styled from 'styled-components';
import {
  Icon as DefaultIcon,
  Text as DefaultText,
  Placeholder as DefaultPlaceholder,
  DropdownListItem as DefaultDropdownListItem,
} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  margin-bottom: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
`;

export const TitleLabelContainer = styled.div`
  display: flex;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.colors.blue300};
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
`;

export const Title = styled(DefaultText).attrs({
  type: 'span',
  size: 'large',
  width: 'semiBold',
})`
  display: inline-block;
  min-width: 0;

  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TitlePlaceholder = styled(DefaultPlaceholder)`
  --placeholder-bg: ${({ theme }) => theme.colors.blue50};
  --placeholder-opacity: 0.2;
  --placeholder-animate: ${({ theme }) => theme.colors.blue100};
`;

export const Subtitle = styled(DefaultText).attrs({
  type: 'span',
  size: 'base',
  width: 'regular',
})`
  flex: 1;

  color: ${({ theme }) => theme.colors.blue100};
`;

export const SubtitlePlaceholder = styled(DefaultPlaceholder)`
  --placeholder-bg: ${({ theme }) => theme.colors.blue100};
  --placeholder-opacity: 0.2;
  --placeholder-animate: ${({ theme }) => theme.colors.blue200};
`;

export const LightBlueDown = styled(DefaultIcon).attrs({
  name: 'chevron-down',
})`
  svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${({ theme }) => theme.colors.blue200};
    }
  }
  transition: transform 300ms;
`;

export const Trigger = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;

  margin: 0px;
  text-align: left;
  overflow: auto;

  background-color: ${({ theme }) => theme.colors.blue800};
  border: 1px solid ${({ theme }) => theme.colors.blue600};
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 8px 12px 8px 12px;

  transition: background 300ms;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &[data-focus],
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.blue500};
    border: 1px solid ${({ theme }) => theme.colors.blue400};
  }
  &:hover:not([disabled]):not([data-focus]):not(:focus):not(:active) {
    background-color: ${({ theme }) => theme.colors.blue700};
    border: 1px solid ${({ theme }) => theme.colors.blue500};
  }

  &[data-show] {
    ${LightBlueDown} {
      transform: rotate(-180deg);
    }
  }
`;

export const Item = styled(DefaultDropdownListItem)`
  padding: 16px 13px;
  font-size: 15px;
  line-height: 125%;
`;

export const CustomItem = styled(Item)`
  position: relative;
  ::after {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  max-width: 240px;
  padding: 12px;

  & > div {
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: ${({ theme }) => theme.colors.gray400};
      }
    }

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    display: flex;
    gap: 4px;
    max-width: ${(props) => (props.selected ? 'calc(100% - 16px)' : '100%')};
  }
`;

export const AddonSpaceLabel = styled.span`
  color: ${({ theme }) => theme.colors.gray600};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 16.25px */
  letter-spacing: -0.195px;
`;

export const CreateItem = styled(Item)`
  color: ${({ theme }) => theme.colors.blue700};
  font-weight: 600;
`;
