import React from 'react';
import { useConnect } from './connect';
import { Container } from './styles';
import {
  CreateAlert,
  ErrorAlert,
  JoinErrorAlert,
  JoinSuccessAlert,
  LegacyAlert,
  RemoveAlert,
  TransferAlert,
} from './alerts';
import BillingWarnings from './BillingWarnings';

const WorkspaceAlerts = () => {
  const {
    create,
    remove,
    transfer,
    legacy,
    join,
    workspaceId,
    error,
    showBillingWarnings,
  } = useConnect();

  return (
    <Container>
      {showBillingWarnings && <BillingWarnings />}

      {legacy && <LegacyAlert onDismiss={legacy.onDismiss} />}
      {create && <CreateAlert name={create.name} />}
      {remove && <RemoveAlert name={remove.name} current={remove.current} />}
      {transfer && (
        <TransferAlert name={transfer.name} email={transfer.email} />
      )}
      {join && !join.error && (
        <JoinSuccessAlert
          onDismiss={join.onDismiss}
          name={join.name}
          isActive={workspaceId === join.id}
          new={join.new}
        />
      )}
      {join && join.error && (
        <JoinErrorAlert error={join.error} onDismiss={join.onDismiss} />
      )}
      {error && <ErrorAlert onDismiss={error.onDismiss} type={error.type} />}
    </Container>
  );
};

export default WorkspaceAlerts;
