import { TokenResponse } from '@react-oauth/google';
import { queryClient } from 'data/queryClient';
import {
  setSessionId,
  getSessionId,
  removeSessionId,
  getDelegateId,
  removeDelegateId,
} from '../../../utils/cookiesUtils/index';

export const TOKEN_LAST_LOGIN = 'yamm:auth-last-login';
export const TOKENS = 'yamm:tokens';

let cachedAccessToken: string | undefined;
let cachedBackendIdToken: string | undefined;
let cachedSessionId: string | undefined;
let cachedIdToken: string | undefined;
let cachedExpiresAt: number | undefined;

export type TokenQuery = {
  token?: string;
  idToken?: string;
  sessionId?: string;
  backendIdToken?: string;
  expiresAt?: number;
};

function getFromLocalStorage(item: string) {
  try {
    return localStorage.getItem(item);
  } catch (error) {
    throw new Error('cookie-error');
  }
}

function removeFromLocalStorage(item: string) {
  try {
    localStorage.removeItem(item);
  } catch (error) {
    throw new Error('cookie-error');
  }
}

function setCacheTokens(cachedData: TokenQuery | undefined) {
  if (cachedData?.token) {
    cachedAccessToken = cachedData.token;
  }
  if (cachedData?.backendIdToken) {
    cachedBackendIdToken = cachedData.backendIdToken;
  }
  if (cachedData?.sessionId) {
    cachedSessionId = cachedData.sessionId;
  }
  if (cachedData?.expiresAt) {
    cachedExpiresAt = cachedData.expiresAt;
  }
  if (cachedData?.idToken) {
    cachedIdToken = cachedData.idToken;
  }
}

function setLastLogin(loginTime: string) {
  localStorage.setItem(TOKEN_LAST_LOGIN, loginTime);
}

function clear() {
  cachedAccessToken = undefined;
  cachedBackendIdToken = undefined;
  cachedSessionId = undefined;
  cachedIdToken = undefined;
  cachedExpiresAt = undefined;
  removeFromLocalStorage(TOKENS);
  removeFromLocalStorage(TOKEN_LAST_LOGIN);
  removeSessionId();
  queryClient.setQueryData<TokenQuery>('token', {});
}

function set(tokenValues: TokenQuery, isLogin?: boolean) {
  setCacheTokens(tokenValues);
  const tokens = {
    token: cachedAccessToken,
    backendIdToken: cachedBackendIdToken,
    sessionId: cachedSessionId,
    idToken: cachedIdToken,
    expiresAt: cachedExpiresAt,
  };
  queryClient.setQueryData<TokenQuery>('token', tokens);
  localStorage.setItem(
    TOKENS,
    JSON.stringify({
      backendIdToken: tokens.backendIdToken,
    }),
  );
  setSessionId(tokens.sessionId as string);
  if (isLogin && tokenValues.expiresAt) {
    setLastLogin(tokenValues.expiresAt.toString());
  }
}

function get() {
  if (!cachedBackendIdToken) {
    const cachedData = queryClient.getQueryData<TokenQuery>('token');
    if (cachedData?.backendIdToken) {
      setCacheTokens(cachedData);
    } else {
      let backendIdToken = null;
      const sessionId = getSessionId();

      const delegateId = getDelegateId();
      if (delegateId) {
        backendIdToken = delegateId;
        removeDelegateId();
      } else {
        const localStorageTokens = getFromLocalStorage(TOKENS);
        const localStorageTokensAsObj = localStorageTokens
          ? JSON.parse(localStorageTokens)
          : undefined;
        backendIdToken = localStorageTokensAsObj?.backendIdToken;
      }
      setCacheTokens({
        sessionId,
        backendIdToken,
      });
    }
  }
  return {
    token: cachedAccessToken,
    backendIdToken: cachedBackendIdToken,
    sessionId: cachedSessionId,
    idToken: cachedIdToken,
    expiresAt: cachedExpiresAt,
  };
}

function getTokenResponse(scope: string): TokenResponse {
  return {
    access_token: cachedAccessToken as string,
    expires_in: (cachedExpiresAt as number) / 1000,
    prompt: 'none',
    token_type: '',
    scope,
  };
}

export const Token = {
  get,
  set,
  clear,
  getTokenResponse,
};
