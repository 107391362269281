import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BillingOptions from './BillingOptions';
import { CheckoutContextProvider } from '../../data/context/CheckoutContext';
import BillingInformation from './BillingInformation';
import PlanUpgradeSummary from './PlanUpgradeSummary';
import CannotDowngrade from './CannotDowngrade';
import LegacyUsers from './LegacyUsers';
import { useRequireUserFeature } from '../../utils/useRequireUserFeature';

const Checkout = () => {
  const { path } = useRouteMatch();
  useRequireUserFeature('canAccessWorkspaceBilling');

  return (
    <CheckoutContextProvider>
      <Switch>
        <Route path={`${path}/information`} component={BillingInformation} />
        <Route path={`${path}/legacy/users`} component={LegacyUsers} />
        <Route path={`${path}/summary`} component={PlanUpgradeSummary} />
        <Route path={`${path}/cannot-downgrade`} component={CannotDowngrade} />
        <Route
          path={[`${path}`, `${path}/options`]}
          component={BillingOptions}
        />
      </Switch>
    </CheckoutContextProvider>
  );
};

export default Checkout;
