import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { captureException } from '@sentry/minimal';
import { useHistory } from 'react-router-dom';
import { useWorkspaceContext } from '../WorkspaceContext';
import useQueryParams from '../../../utils/useQueryParams';
import { useWorkspaceActiveSubscription } from '../../hooks/useWorkspaceBilling';
import { useUsers } from './hooks/useUsers';
import { useCheckIfCheckoutPossible } from './hooks/useCheckIfCheckoutPossible';
import {
  DEFAULT_CHECKOUT_DATA,
  getDefaultInterval,
  getDefaultPlan,
} from './utils';
import { CheckoutContextType, CheckoutFormValues } from './types';
import { INTERVAL, PLAN } from '../../queryParams';
import { CHECKOUT_SESSION_FORM_KEY } from '../../constants';
import { getCheckoutFlowType } from '../../../utils/checkout';
import {
  BillingInterval,
  BillingWorkspacePlan,
} from '../../../model/workspaceBilling';

const getInitialCheckoutForm = () => {
  try {
    const sessionForm = sessionStorage.getItem(CHECKOUT_SESSION_FORM_KEY);
    if (sessionForm != null) {
      return JSON.parse(sessionForm) as CheckoutFormValues;
    }
  } catch (e) {
    captureException(new Error('Failed to parse checkout form from session'));
  }

  return DEFAULT_CHECKOUT_DATA.checkoutForm;
};

export const useConnect = (): CheckoutContextType => {
  const {
    isLoadingUsers,
    setUserPaidStatus,
    users,
    markUsersForRemoval,
    setUsersPaidStatus,
  } = useUsers();
  const queryParams = useQueryParams();
  const { replace, location } = useHistory();
  const { id, details: workspaceDetails } = useWorkspaceContext();
  const planAndCycleInitFlag = useRef<boolean>(false);

  const [checkoutForm, setCheckoutForm] = useState(getInitialCheckoutForm());
  const flags = useRef<{ [key: string]: boolean }>({});

  const {
    data: activeSubscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });

  const plan = getDefaultPlan(queryParams.get(PLAN));
  const billingInterval = getDefaultInterval(queryParams.get(INTERVAL));

  const updateQueryParams = (
    newPlan?: BillingWorkspacePlan,
    newInterval?: BillingInterval,
  ) => {
    if (newPlan) {
      queryParams.set(PLAN, newPlan);
    }
    if (newInterval) {
      queryParams.set(INTERVAL, newInterval);
    }

    replace({
      pathname: location.pathname,
      state: location.state,
      hash: location.hash,
      search: queryParams.toString(),
    });
  };
  const setPlan = (newPlan: BillingWorkspacePlan) => {
    updateQueryParams(newPlan);
  };

  const setBillingInterval = (newInterval: BillingInterval) => {
    updateQueryParams(undefined, newInterval);
  };

  useCheckIfCheckoutPossible(activeSubscription);

  useEffect(() => {
    if (activeSubscription && !planAndCycleInitFlag.current) {
      const hasPlan = queryParams.has(PLAN);
      const hasInterval = queryParams.has(INTERVAL);
      if (!hasPlan && !hasInterval) {
        updateQueryParams(
          activeSubscription.workspacePlan,
          activeSubscription.billingInterval,
        );
      } else if (!hasPlan) {
        updateQueryParams(activeSubscription.workspacePlan);
      } else if (!hasInterval) {
        updateQueryParams(undefined, activeSubscription.billingInterval);
      }

      planAndCycleInitFlag.current = true;
    }
  }, [activeSubscription]);

  const flowType = useMemo(() => {
    if (workspaceDetails) {
      return getCheckoutFlowType(plan, workspaceDetails, activeSubscription);
    }
    return DEFAULT_CHECKOUT_DATA.flowType;
  }, [plan, activeSubscription, workspaceDetails]);

  const setFlag = useCallback((name: string, value: boolean) => {
    flags.current[name] = value;
  }, []);
  const getFlag = useCallback((name: string) => flags.current[name], []);

  return {
    plan,
    setPlan,
    billingInterval,
    setBillingInterval,
    isLoading: isLoadingUsers || isLoadingActiveSubscription,
    setUserPaidStatus,
    users,
    checkoutForm,
    setCheckoutForm,
    markUsersForRemoval,
    flowType,
    setUsersPaidStatus,
    getFlag,
    setFlag,
  };
};
