import {
  Autocomplete,
  Button,
  Dialog,
  FlexContainer,
  FormControl,
  FormGroup,
  FormLabel,
  Text,
  DropdownList,
  DropdownListItem,
  Strong,
  Heading,
  TagInput,
} from '@scriptaddicts/yamm-ui-components';
import { nanoid } from 'nanoid';
import React, { FC } from 'react';
import { plurify } from 'utils/plurify';
import { useConnect } from './connect';
import { Container } from './styles';
import { Props } from './types';

function getErrorMessage(
  maxUsersReached: boolean,
  isPartial: boolean,
  limit?: number,
) {
  if (maxUsersReached && limit) {
    return `You cannot go over your current plan user limit (${limit}).`;
  }
  if (isPartial) {
    return "We can't add some email addresses. Hover over them to know why.";
  }

  return undefined;
}

export const AddUsers: FC<Props> = ({
  show,
  onClose,
  focusRef,
  onSuccess,
  limit,
  currentPaidUsersCount,
}) => {
  const {
    isLoading,
    resetForm,
    domains,
    search,
    tags,
    add,
    isMobile,
    maxUsersReached,
  } = useConnect({
    currentPaidUsersCount,
    onClose,
    onSuccess,
    limit,
  });
  return (
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) return;
        onClose();
        resetForm();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <FlexContainer flex="horizontal">
          <Heading size="large">Add users</Heading>
        </FlexContainer>
        {domains && (
          <Text type="paragraph" size="base" width="regular">
            You can add <Strong>existing</Strong> YAMM users from the following{' '}
            {plurify(domains.length, 'domain', 'domains')}:{' '}
            <Strong>{domains.join(', ')}</Strong>.
          </Text>
        )}
        <FormGroup>
          <FormLabel htmlFor="workspace-mails">Email addresses</FormLabel>
          <FormControl
            errorMessage={getErrorMessage(
              maxUsersReached,
              tags.hasError.partial,
            )}
          >
            <Autocomplete
              show={search.show}
              value={search.value}
              onChange={search.onChange}
              onOpen={search.onOpen}
              onClose={search.onClose}
              disabled={isLoading}
            >
              <DropdownList maxElement="auto" minElement={5}>
                {search.items.map((member) => (
                  <DropdownListItem key={nanoid()} value={member.email}>
                    {member.name
                      ? `${member.name} <${member.email}>`
                      : member.email}
                  </DropdownListItem>
                ))}
              </DropdownList>
              <TagInput
                innerRef={focusRef}
                id="workspace-mails"
                placeholder="Separate emails with a space or a comma"
                tagKeys={[' ', ',']}
                tags={tags.members}
                onTagAdd={tags.onTagAdd}
                onTagRemove={tags.onTagRemove}
                hasError={tags.hasError.all}
                variant="gray"
                submitClassName="autoTagCatch"
                onSubmit={() => tags.setAutoAdd(true)}
              />
            </Autocomplete>
          </FormControl>
        </FormGroup>
        <FlexContainer flex="horizontal" gap="base">
          <Button
            aria-label="add"
            variant="primary"
            className="autoTagCatch"
            onClick={add.onClick}
            disabled={isLoading || add.isValidating || add.disabled}
            loading={isLoading || add.isValidating}
          >
            {add.count
              ? `Add ${add.count} ${plurify(add.count, 'user', 'users')}`
              : 'Add user'}
          </Button>
          <Button
            aria-label="cancel"
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </FlexContainer>
      </Container>
    </Dialog>
  );
};
