import { useWorkspaceActiveSubscription } from '../../../../data/hooks/useWorkspaceBilling';
import { useWorkspaceContext } from '../../../../data/context/WorkspaceContext';
import useQueryParams from '../../../../utils/useQueryParams';
import useWorkspaceLocation from '../../../../utils/router/useWorkspaceLocation';
import { useRequireUserFeature } from '../../../../utils/useRequireUserFeature';
import { useNotificationMessages } from '../../../../data/hooks/useNotificationMessages';
import { SHOW_PLANS } from '../../../../data/queryParams';
import { useConnect as useCurrentPlanConnect } from './CurrentPlan/connect';
import { useConnect as usePurchasePlansConnect } from './PurchasePlans/connect';
import { useMe } from '../../../../data/hooks/useMe';
import { isGmailAddress } from '../../../../utils/userUtils';

export const useConnect = () => {
  const { url } = useWorkspaceLocation();
  useRequireUserFeature('canAccessWorkspaceBilling', `${url}/space/general`);

  const { data: me } = useMe();
  const params = useQueryParams();
  const requestedPlan = params.get('plan');

  const validPlans = {
    gmail: ['pro-gmail', 'PERSONAL'],
    domain: ['pro-google-workspace', 'PROFESSIONAL'],
  };
  const accountType =
    me?.email && isGmailAddress(me.email) ? 'gmail' : 'domain';
  const wrongPlanRequested =
    requestedPlan && !validPlans[accountType].includes(requestedPlan);

  const queryParams = useQueryParams();
  const { id, details } = useWorkspaceContext();
  const { data: activeSubscription } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });

  const messagesHandler = useNotificationMessages();

  const currentPlanConnect = useCurrentPlanConnect({
    messagesHandler,
  });
  const purchasePlansConnect = usePurchasePlansConnect();
  const isFree =
    !activeSubscription || activeSubscription?.workspacePlan === 'FREE';

  return {
    showPurchasePlans: queryParams.has(SHOW_PLANS),
    workspaceDetails: details,
    isFree,
    messagesHandler,
    currentPlanConnect,
    purchasePlansConnect,
    wrongPlanRequested,
    queryParams,
  };
};
