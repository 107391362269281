import React from 'react';
import { Text } from '@scriptaddicts/yamm-ui-components';
import { StyledMobileSidePadding } from '../common/styles';

const FreeUsersDisclaimer = () => {
  return (
    <StyledMobileSidePadding>
      <Text type="paragraph" width="bold" size="extraLarge">
        Select paid users
      </Text>
      <Text type="paragraph" size="base">
        Free users will remain limited to 50 recipients / day and won’t be able
        to schedule mail merges. Use them for discovering YAMM or for
        administration roles.
      </Text>
    </StyledMobileSidePadding>
  );
};

export default FreeUsersDisclaimer;
