import { getTemplates } from 'data/requests/templates';
import { transformTemplates } from 'data/transform/templates';
import { useQuery } from 'react-query';

export const useTemplates = () => {
  return useQuery('templates', () => getTemplates(), {
    select: transformTemplates,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
