import React from 'react';
import {
  TableBody,
  TableCell,
  TableCellGroup,
  TableRow,
} from '@scriptaddicts/yamm-ui-components';
import { Props } from './types';
import { RowProps } from '../types';

const Rows = ({
  rows,
  prepareRow,
  processRow,
  originalColumns,
  rowLink: RowLink,
  selectedRowIds,
  toggleRowSelected,
}: Props) => {
  return (
    <TableBody
      selected={Object.keys(selectedRowIds ?? {})}
      onSelected={toggleRowSelected}
    >
      {rows.map((row) => {
        prepareRow(row);
        const { id: rowId } = row;
        const { key: rowKey } = row.getRowProps();
        const { variant: rowVariant } = processRow
          ? processRow(row.original) ?? ({} as RowProps)
          : ({} as RowProps);
        return (
          <TableRow
            key={rowKey}
            value={rowId}
            variant={rowVariant}
            link={RowLink && <RowLink item={row.original} />}
          >
            {row.cells.map((cell, index) => {
              const { key } = cell.getCellProps();
              const {
                colSpan,
                group,
                stroke,
                variant,
                alignment,
                float,
                canSort,
              } = originalColumns[index];

              return !group ? (
                <TableCell
                  key={key}
                  align={alignment}
                  colSpan={colSpan}
                  float={float}
                  width={stroke}
                  variant={variant}
                  sortable={canSort}
                >
                  {cell.render('Cell')}
                </TableCell>
              ) : (
                <TableCellGroup
                  key={key}
                  colSpan={colSpan}
                  width={stroke}
                  variant={variant}
                >
                  {cell.render('Cell')}
                </TableCellGroup>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default Rows;
