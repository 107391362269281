import React from 'react';
import {
  Alert,
  Button,
  ButtonSet,
  FlexContainer,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import CheckoutLayout from '../Layout';
import { useConnect } from './connect';
import { StyledCard } from '../common/styles';
import {
  BillingCycleDelta,
  GmailPaidUsersDelta,
  PaidUsersDelta,
  PlanDelta,
} from './deltas';
import { MainContainerLoader } from '../../../components/MainContainer';
import { SummaryConfirmMessage } from './SummaryConfirmMessage';
import { Title } from './components';

const SupportLinkAttrs = {
  href: 'https://support.yet-another-mail-merge.com/hc/en-us/requests/new',
  rel: 'noreferrer',
  target: '_blank',
};

const PlanUpgradeSummary = () => {
  const {
    workspaceDetails,
    isLoading,
    isUpdating,
    isUpdateError,
    deltas,
    activeSubscription,
    paymentInfo,
    titleType,
    onBack,
    onConfirm,
  } = useConnect();

  const confirmButtontext = 'Confirm changes';
  return (
    <CheckoutLayout.Container>
      <CheckoutLayout.Main>
        {isLoading ||
        !workspaceDetails ||
        !activeSubscription ||
        !paymentInfo ? (
          <MainContainerLoader />
        ) : (
          <FlexContainer flex="vertical" gap="large">
            <Title type={titleType} spaceName={workspaceDetails.name || '--'} />

            {isUpdateError && (
              <Alert type="error">
                <Text type="paragraph" size="large" width="semiBold">
                  An error occurred
                </Text>
                <Text type="paragraph">
                  Please try again, if error persist contact{' '}
                  <a {...SupportLinkAttrs}>our support</a>.
                </Text>
              </Alert>
            )}

            <StyledCard variant="white" padding="big">
              <FlexContainer flex="vertical" gap="large">
                {deltas?.plan && <PlanDelta plan={deltas.plan} />}
                {deltas?.billingCycle && (
                  <BillingCycleDelta billingInterval={deltas.billingCycle} />
                )}
                {deltas?.paidUsers && (
                  <PaidUsersDelta paidUsers={deltas.paidUsers} />
                )}
                {deltas?.gmailPaidUsers && (
                  <GmailPaidUsersDelta gmailPaidUsers={deltas.gmailPaidUsers} />
                )}
                {/* {flowType === 'PURCHASE' && ( */}
                {/*  <> */}
                {/*    {deltas?.basePrice && deltas?.currency && ( */}
                {/*      <TotalPriceWithoutTaxesDelta */}
                {/*        basePrice={deltas.basePrice} */}
                {/*        currency={deltas.currency} */}
                {/*        interval={deltas.billingCycle} */}
                {/*        currentInterval={activeSubscription.billingInterval} */}
                {/*      /> */}
                {/*    )} */}
                {/*    {deltas?.taxedPrice && deltas?.currency && ( */}
                {/*      <TotalPriceDelta */}
                {/*        taxedPrice={deltas.taxedPrice} */}
                {/*        currency={deltas.currency} */}
                {/*        interval={deltas.billingCycle} */}
                {/*        currentInterval={activeSubscription.billingInterval} */}
                {/*      /> */}
                {/*    )} */}
                {/*  </> */}
                {/* )} */}
              </FlexContainer>
            </StyledCard>

            <StyledCard variant="white" padding="big">
              <FlexContainer
                flex="vertical"
                gap="large"
                style={{ alignItems: 'center' }}
              >
                <SummaryConfirmMessage
                  paymentInfo={paymentInfo}
                  deltas={deltas}
                  activeSubscription={activeSubscription}
                />
                <ButtonSet>
                  <Button
                    aria-label="Confirm changes"
                    variant="primary"
                    onClick={onConfirm}
                    loading={isUpdating}
                    disabled={isUpdating}
                    data-log-event="go_to_confirm_checkout"
                    data-log-event-data={JSON.stringify({
                      displayName: confirmButtontext,
                    })}
                  >
                    {confirmButtontext}
                  </Button>
                  <Button
                    aria-label="Back"
                    variant="secondary"
                    onClick={onBack}
                    disabled={isUpdating}
                  >
                    Back
                  </Button>
                </ButtonSet>
              </FlexContainer>
            </StyledCard>
          </FlexContainer>
        )}
      </CheckoutLayout.Main>
    </CheckoutLayout.Container>
  );
};

export default PlanUpgradeSummary;
