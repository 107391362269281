import { getSessionId } from 'utils/cookiesUtils';
import { User, UserInput } from 'model/user';
import { queryClient } from 'data/queryClient';
import { getUserIdFromEmail } from 'utils/userUtils';
import {
  OtherWorkspace,
  OtherWorkspaceDecorator,
  WorkspaceDetails,
  WorkspaceUser,
} from 'data/context/WorkspaceContext';
import { decodeBase64UUID } from 'utils/uid';
import { BillingWorkspacePlan } from 'model/workspaceBilling';
import { sendLog } from '../logToStackdriver';

export const ClickRoutePatterns = [
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/space/billing',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/checkout',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/checkout/information',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/checkout/summary',
];

export const pageViewRoutePatterns = [
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/space/billing',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/checkout',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/checkout/information',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/checkout/summary',
  '/(?<workspaceId>[^/]+)/(?<workspaceSlug>[^/]+)/space/users',
];

function getBrowserLocale(): string {
  if (navigator.languages && navigator.languages.length > 0) {
    return navigator.languages[0];
  }
  return navigator.language;
}

interface ExtractedParams {
  isMatched: boolean;
  params: Record<string, string>;
  subPath: string;
}

export const processPathParam = (param: string, value: string): string => {
  if (param === 'workspaceId') {
    return decodeBase64UUID(value) ?? value;
  }
  return value;
};

export const extractPathParams = (
  pattern: string,
  path: string,
): ExtractedParams => {
  const match = path.match(pattern);
  const isMatched = match !== null;
  const params: Record<string, string> = {};
  let subPath = path;

  if (match && match.groups) {
    Object.entries(match.groups).forEach(([key, value]) => {
      subPath = subPath.replace(`/${value}`, '');
      params[key] = processPathParam(key, value);
    });
    subPath = subPath === '/' ? '' : subPath;
  }
  return { isMatched, params, subPath };
};

export const isCheckoutRoute = (route: string) => route.includes('checkout');

interface DefaultEventParams {
  timestamp: number | undefined;
  browser_locale: string | undefined;
  session_id: string | undefined;
  user_id: string | undefined;
  user_email: string | undefined;
  domain: string | undefined;
  is_paid: boolean | undefined;
  is_legacy_paid: boolean | undefined;
}

function getDefaultEventParameters(): DefaultEventParams {
  const sessionId = getSessionId();
  const rawUser = queryClient.getQueryData(['me']) as UserInput | undefined;
  let isLegacyPaid: boolean | undefined;
  let isPaid: boolean | undefined;
  if (rawUser?.userWorkspaces) {
    isPaid = rawUser.userWorkspaces.some(
      (workspace) => workspace.userPlan !== 'FREE',
    );
    isLegacyPaid = rawUser.userWorkspaces.some(
      (workspace) =>
        workspace.workspacePlan === 'LEGACY' && workspace.userPlan !== 'FREE',
    );
  }
  return {
    timestamp: Date.now(),
    browser_locale: getBrowserLocale(),
    session_id: sessionId,
    user_id: rawUser?.userEmail && getUserIdFromEmail(rawUser?.userEmail),
    user_email: rawUser?.userEmail,
    domain: rawUser?.domain.domainName,
    is_paid: isPaid,
    is_legacy_paid: isLegacyPaid,
  };
}

interface DefaultEventParams {
  timestamp: number | undefined;
  browser_locale: string | undefined;
  session_id: string | undefined;
  user_id: string | undefined;
  user_email: string | undefined;
  domain: string | undefined;
  is_paid: boolean | undefined;
  is_legacy_paid: boolean | undefined;
}

export const logPageView = (
  pagePath: string,
  search: string | undefined,
  initialUrl: string | undefined,
  workspaceId: string | undefined,
  nbPaidUsers: number | undefined,
  nbFreeUsers: number | undefined,
) => {
  const defaultEventParams = getDefaultEventParameters();
  const event: any = {
    event_type: 'webapp_page_view',
    message: 'Navigation occurs',
    ...defaultEventParams,
    page_path: pagePath,
    space_id: workspaceId,
  };
  if (search) {
    event.search = search;
  }
  if (initialUrl) {
    event.initial_url = initialUrl;
  }
  if (nbPaidUsers) {
    event.checkout_cart_paid_users_quantity = nbPaidUsers;
  }
  if (nbFreeUsers) {
    event.checkout_cart_free_users_quantity = nbFreeUsers;
  }
  sendLog(event);
};

const hasUserPaidPlan = (
  user: WorkspaceUser | undefined,
  otherWorkspaces: OtherWorkspace[] | undefined,
) => {
  return (
    user?.plan === 'PRO' ||
    user?.plan === 'PRO_PERSONAL' ||
    otherWorkspaces?.some(
      (workspace) =>
        workspace?.workspaceUserFeature?.userPlan === 'PRO' ||
        workspace?.workspaceUserFeature?.userPlan === 'PRO_PERSONAL',
    )
  );
};

const isMemberOfLegacyPlan = (
  spaceDetails: WorkspaceDetails | undefined,
  user: WorkspaceUser | undefined,
  otherWorkspaces: OtherWorkspace[] | undefined,
) => {
  return (
    (spaceDetails?.plan === 'LEGACY' && user?.plan === 'PRO') ||
    (spaceDetails?.plan === 'LEGACY' && user?.plan === 'PRO_PERSONAL') ||
    otherWorkspaces?.some(
      (workspace) =>
        new OtherWorkspaceDecorator(workspace).hasLegacyPlan() &&
        (workspace?.workspaceUserFeature?.userPlan === 'PRO' ||
          workspace?.workspaceUserFeature?.userPlan === 'PRO_PERSONAL'),
    )
  );
};

function getClickDefaultEventParameters(
  user: User | undefined | null,
  spaceDetails: WorkspaceDetails | undefined,
  workspaceUser: WorkspaceUser | undefined,
  otherWorkspaces: OtherWorkspace[] | undefined,
): DefaultEventParams {
  const sessionId = getSessionId();
  const isPaid = hasUserPaidPlan(workspaceUser, otherWorkspaces);
  const isLegacyPaid = isMemberOfLegacyPlan(
    spaceDetails,
    workspaceUser,
    otherWorkspaces,
  );
  return {
    timestamp: Date.now(),
    browser_locale: getBrowserLocale(),
    session_id: sessionId,
    user_id: user?.id,
    user_email: user?.email,
    domain: user?.domain,
    is_paid: isPaid,
    is_legacy_paid: isLegacyPaid,
  };
}

interface LogClickEventParams {
  displayName: string | undefined | null;
  pagePath: string;
  search: string;
  actionType?: string | null;
  user?: User | null;
  workspaceId?: string;
  workspaceDetails?: WorkspaceDetails;
  workspaceUser?: WorkspaceUser;
  otherWorkspaces?: OtherWorkspace[];
  nbPaidUsers?: number;
  nbFreeUsers?: number;
  selectedPlan?: BillingWorkspacePlan;
}

export const logClickEvent = (params: LogClickEventParams) => {
  const defaultEventParams = getClickDefaultEventParameters(
    params.user,
    params.workspaceDetails,
    params.workspaceUser,
    params.otherWorkspaces,
  );
  const event: any = {
    event_type: 'webapp_click',
    message: 'User clicks on a button, checkbox, radio',
    ...defaultEventParams,
    action_type: params.actionType,
    page_path: params.pagePath,
    space_id: params.workspaceId,
  };
  if (params.displayName) {
    event.display_name = params.displayName;
  }
  if (params.search) {
    event.search = params.search;
  }
  if (params.selectedPlan) {
    event.checkout_cart_plan = params.selectedPlan;
  }
  if (params.nbPaidUsers) {
    event.checkout_cart_paid_users_quantity = params.nbPaidUsers;
  }
  if (params.nbFreeUsers) {
    event.checkout_cart_free_users_quantity = params.nbFreeUsers;
  }
  sendLog(event);
};

export const logDisplaySidebar = (
  pagePath: string,
  search: string | undefined,
  workspaceId: string | undefined,
) => {
  const defaultEventParams = getDefaultEventParameters();
  const event: any = {
    event_type: 'webapp_display_sidebar',
    message: 'Sidebar opens',
    ...defaultEventParams,
    page_path: pagePath,
    space_id: workspaceId,
  };
  if (search) {
    event.search = search;
  }
  sendLog(event);
};

interface LogErrorParams {
  errorType: string;
  pagePath: string;
  search: string;
  workspaceId?: string;
  nbPaidUsers?: number;
  nbFreeUsers?: number;
  selectedPlan?: BillingWorkspacePlan;
}

export const logError = (params: LogErrorParams) => {
  const defaultEventParams = getDefaultEventParameters();
  const event: any = {
    event_type: 'webapp_error',
    message: 'Error occurs',
    ...defaultEventParams,
    page_path: params.pagePath,
    space_id: params.workspaceId,
    error_type: params.errorType,
  };
  if (params.search) {
    event.search = params.search;
  }
  if (params.selectedPlan) {
    event.checkout_cart_plan = params.selectedPlan;
  }
  if (params.nbPaidUsers) {
    event.checkout_cart_paid_users_quantity = params.nbPaidUsers;
  }
  if (params.nbFreeUsers) {
    event.checkout_cart_free_users_quantity = params.nbFreeUsers;
  }
  sendLog(event);
};

interface LogStripeSessionCreatedEventParams {
  pagePath: string;
  search: string;
  user?: User | null;
  workspaceId?: string;
  workspaceDetails?: WorkspaceDetails;
  workspaceUser?: WorkspaceUser;
  otherWorkspaces?: OtherWorkspace[];
  nbPaidUsers?: number;
  nbFreeUsers?: number;
  selectedPlan?: BillingWorkspacePlan;
  stripeCheckoutSessionId: string;
}

export const logStripeSessionCreatedEvent = (
  params: LogStripeSessionCreatedEventParams,
) => {
  const defaultEventParams = getClickDefaultEventParameters(
    params.user,
    params.workspaceDetails,
    params.workspaceUser,
    params.otherWorkspaces,
  );
  const event: any = {
    event_type: 'stripe_checkout_session_created',
    message: 'Stripe session created after clicking Checkout button',
    ...defaultEventParams,
    page_path: params.pagePath,
    space_id: params.workspaceId,
    stripe_checkout_session_id: params.stripeCheckoutSessionId,
  };
  if (params.search) {
    event.search = params.search;
  }
  if (params.selectedPlan) {
    event.checkout_cart_plan = params.selectedPlan;
  }
  if (params.nbPaidUsers) {
    event.checkout_cart_paid_users_quantity = params.nbPaidUsers;
  }
  if (params.nbFreeUsers) {
    event.checkout_cart_free_users_quantity = params.nbFreeUsers;
  }
  sendLog(event);
};

export const logDisplayModal = (
  modalType: string,
  pagePath: string,
  search: string | undefined,
  workspaceId: string | undefined,
) => {
  const defaultEventParams = getDefaultEventParameters();
  const event: any = {
    event_type: 'webapp_display_modal',
    message: 'Modal opens',
    ...defaultEventParams,
    modal_type: modalType,
    page_path: pagePath,
    space_id: workspaceId,
  };
  if (search) {
    event.search = search;
  }
  sendLog(event);
};
