import styled from 'styled-components';

import { from } from 'styles/media';

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import { ReactComponent as XCircleIcon } from 'assets/images/icons/x-circle.svg';

export const SearchBarContainer = styled.div`
  display: flex;

  margin-right: auto;
  padding: 8px 0;

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: 6px;

  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  ${from.mobile} {
    max-width: 616px;
  }

  :focus-within {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blue200};
    border-color: ${({ theme }) => theme.colors.blue500};
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;

  padding-left: 16px;
  width: 100%;
`;

export const Icon = styled(SearchIcon)`
  height: 16px;
  width: 16px;
  position: absolute;
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;

  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray700};
  background: none;
  border: none;
  padding: 0px;
  margin-right: 6px;

  :hover:not(:focus) {
    color: ${({ theme }) => theme.colors.gray500};
  }

  :focus {
    color: ${({ theme }) => theme.colors.gray800};
  }
`;

export const ClearIcon = styled(XCircleIcon)`
  height: 20px;
  width: 20px;
`;

export const SearchInput = styled.input`
  position: relative;

  padding-left: 24px;
  padding-right: 8px;

  font-size: 14px;
  line-height: 160%;

  width: 100%;
  height: 100%;
  min-height: 100%;

  background: none;
  border: none;

  ::placeholder {
    font-size: 14px;
    font-style: italic;
    color: ${({ theme }) => theme.colors.gray500};
  }

  height: 20px;

  :focus {
    outline: none;
  }
`;
