import React from 'react';
import { ButtonLink, Card, Text } from '@scriptaddicts/yamm-ui-components';
import Strong from '../../../../../components/Strong';
import Space from '../../../../../components/Space';
import { Links } from '../../../../../data/links';

const LegacyPlanExplanation = () => (
  <Card variant="white" padding="big">
    <Text type="paragraph">
      <Strong>Your Space is currently on a legacy plan</Strong>
    </Text>
    <Text type="paragraph">
      Legacy team plans are limited to a fixed number of seats and do not
      include access to user bounce list management.
    </Text>

    <Space h={24} />
    <Text type="paragraph">
      <Strong>Why switch to a new plan?</Strong>
    </Text>
    <Text type="paragraph">
      New plans allow you to manage users more easily and enable your users to
      manage their bounce list. New features will be exclusively available in
      new plans.
    </Text>

    <Space h={24} />
    <ButtonLink
      aria-label="Contact us to switch to a new plan"
      href={Links.SWITCH_PLAN_CONTACT_US}
      target="_blank"
    >
      Contact us to switch to a new plan
    </ButtonLink>
  </Card>
);

export default LegacyPlanExplanation;
