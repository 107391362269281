import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Unsubscription from 'containers/Common/Unsubscription';
import PollAnswer from 'containers/Common/PollAnswer';

import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Login from './Login';

const Guest = () => {
  const currentUrl = window.location.pathname + window.location.search;
  return (
    <Container>
      <Helmet>
        <meta name="robots" content="all" />
      </Helmet>
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={Unsubscription} exact path="/unsubscribed" />
        <Route component={PollAnswer} exact path="/formresponse" />
        <Route path="/">
          <Redirect to={`/login?continue=${encodeURIComponent(currentUrl)}`} />
        </Route>
      </Switch>
    </Container>
  );
};

export default Guest;
