import { clientV2 } from 'data/client';
import { UserInputResponse } from 'model/user';
import { getUser } from './users';

export async function getMe() {
  return getUser({
    includeWorkspaces: true,
    userId: 'me',
  });
}

export async function getMeRollout() {
  const { data } = await clientV2.get<UserInputResponse>(
    `/users/workspace-activation-status/me`,
    {
      params: {
        checkIsRegistered: true,
        includeUserV1: false,
        includeUserV2: true,
        includeUserV2Workspace: false,
        includeFBData: false,
      },
    },
  );

  return data;
}
