import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useRemoveMember } from 'data/hooks/useWorkspaceMembers';
import { useCallback, useEffect, useMemo } from 'react';

import { Props } from './types';

export const useConnect = ({
  onClose,
  members,
  setUserAlerts,
  onCloseDrawer,
}: Props) => {
  const { id, details } = useWorkspaceContext();
  const { mutate: removeMember, status, reset } = useRemoveMember();

  const onRemove = useCallback(() => {
    removeMember({
      workspaceId: id,
      params: {
        userIds: members ? members.map(({ id: userId }) => userId) : undefined,
      },
    });
  }, [id, members]);

  const memberNames = useMemo<string[]>(
    () => (members ?? []).map(({ name }) => name),
    [members],
  );

  const hasPaidUser = useMemo<boolean>(
    () => !!members?.some(({ plan }) => plan !== 'FREE'),
    [members],
  );

  useEffect(() => {
    if (status === 'success') {
      onClose();
      setUserAlerts({
        removedMembers: memberNames,
      });
      reset();
      onCloseDrawer();
    }
  }, [setUserAlerts, reset, onClose, onCloseDrawer, status, memberNames]);

  const learnMoreUrl = 'https://google.com';

  return {
    workspaceName: details?.name,
    isLoading: status === 'loading',
    onRemove,
    learnMoreUrl,
    memberNames,
    hasPaidUser,
  };
};
