import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { captureException } from '@sentry/minimal';
import { writeCheckoutSessionUsers } from 'utils/checkoutSessionUsers';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import { useCheckoutContext } from '../../../data/context/CheckoutContext';
import { hasPaidUsersFromExternalDomain } from '../../../utils/checkout';
import { useWorkspaceActiveSubscription } from '../../../data/hooks/useWorkspaceBilling';
import useWorkspaceLocation from '../../../utils/router/useWorkspaceLocation';
import { hasChanged, getNextLink } from './utils';
import { INTERVAL, PLAN } from '../../../data/queryParams';
import { useAvailableUsers } from './hooks/useAvailableUsers';

export const useConnect = () => {
  const { url } = useWorkspaceLocation();
  const {
    plan,
    billingInterval,
    isLoading: isLoadingCheckout,
    flowType,
  } = useCheckoutContext();

  const { availableUsers } = useAvailableUsers();
  const {
    id: workspaceId,
    details: workspaceDetails,
    isLoading: isLoadingWorkspace,
  } = useWorkspaceContext();

  const {
    data: activeSubscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscription({
    workspaceId,
    includePlans: true,
  });

  const { push, replace } = useHistory();
  const [error, setError] = useState<string | undefined>(undefined);

  const onSubmit = (replacePath?: boolean) => {
    setError(undefined);

    if (
      flowType === 'UPGRADE_LEGACY_BE' ||
      flowType === 'UPGRADE_LEGACY_BE_INDIVIDUAL'
    ) {
      // no more legacy updates
      setError('server-error');
      captureException(
        new Error(
          'BillingOptions->onSubmit: workspaceId, subscriptionId or paymentInfo(?stripePlans) is missing',
        ),
      );
    } else {
      const urlParams = new URLSearchParams('');
      urlParams.set(PLAN, plan);
      urlParams.set(INTERVAL, billingInterval);
      const nextUrl = getNextLink(flowType);

      if (nextUrl) {
        const checkoutUsers = {
          users: availableUsers
            .map((u) => ({ id: u.id, isPaid: u.isPaid }))
            .filter((u) => u.isPaid),
        };
        writeCheckoutSessionUsers(checkoutUsers);
        if (replacePath) {
          replace(`${url}${nextUrl}?${urlParams.toString()}`);
        } else {
          push(`${url}${nextUrl}?${urlParams.toString()}`);
        }
      } else {
        setError('server-error');
        captureException(
          new Error('BillingOptions->onSubmit: flowType not handled properly'),
        );
      }
    }
  };

  const changed = useMemo<boolean>(
    () =>
      activeSubscription
        ? hasChanged(
            flowType,
            activeSubscription,
            plan,
            billingInterval,
            availableUsers,
          )
        : true,
    [flowType, activeSubscription, plan, billingInterval, availableUsers],
  );

  const isLoading =
    isLoadingWorkspace || isLoadingCheckout || isLoadingActiveSubscription;

  useEffect(() => {
    if (!isLoading) {
      if (plan === 'PRO' && hasPaidUsersFromExternalDomain(availableUsers)) {
        replace(`${url}/checkout/cannot-downgrade`);
      } else if (availableUsers.length === 1) {
        onSubmit(true);
      }
    }
  }, [isLoading, availableUsers, plan, url]);

  return {
    isLoading,
    changed,
    submitError: error,
    workspaceDetails,
    flowType,
    onSubmit,
  };
};
