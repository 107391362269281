import React, { createContext, FC, useContext } from 'react';
import { useConnect } from './connect';
import { DEFAULT_CHECKOUT_DATA } from './utils';

const CheckoutContext = createContext<ReturnType<typeof useConnect>>(
  DEFAULT_CHECKOUT_DATA,
);

export const useCheckoutContext = () => useContext(CheckoutContext);

export const CheckoutContextProvider: FC = ({ children }) => {
  const connect = useConnect();

  return (
    <CheckoutContext.Provider value={connect}>
      {children}
    </CheckoutContext.Provider>
  );
};
