import React, { useRef } from 'react';
import { Button, Link, Text } from '@scriptaddicts/yamm-ui-components';
import CheckoutLayout from '../Layout';
import LegacyUsersSidebar from '../LegacyUsersSidebar';
import { useConnect } from './connect';
import { MainContainerLoader } from '../../../components/MainContainer';
import {
  StyledMobileSidePadding,
  StyledTitle,
  StyledTitleWrapper,
} from '../common/styles';
import ResponsiveTable from '../../../components/ResponsiveTable';
import Empty from './table/empty';
import NotFound from './table/notFound';
import { getColumns } from './table/columns';
import { CheckoutUser } from '../../../model/checkout';
import { AddUsersTopBar, BigUsersListText } from './styles';
import { AddedUsersSuccessAlert } from './Alerts';
import { AddUsers } from '../../../widgets/AddUsers';
import { from, useMediaQuery } from '../../../styles/media';

const LegacyUsers = () => {
  const {
    onNext,
    isLoading,
    workspaceDetails,
    users,
    tableStateManager,
    selectedUsersCount,
    maxUsersCount,
    maxFreeUsersCount,
    domainName,
    showAddUsers,
    onShowAddUsers,
    addedUsersCount,
    setAddedUsersCount,
  } = useConnect();
  const ref = useRef();
  const isMobile = !useMediaQuery(from.tablet);

  if (isLoading || !workspaceDetails) {
    return <MainContainerLoader />;
  }

  return (
    <CheckoutLayout.Container>
      <CheckoutLayout.Main>
        <StyledTitleWrapper>
          <AddUsers
            show={showAddUsers}
            onClose={() => onShowAddUsers(false)}
            onSuccess={(value) => setAddedUsersCount(value.addCount)}
            focusRef={ref}
          />
          <StyledTitle>Upgrading {workspaceDetails.name}</StyledTitle>
          {addedUsersCount > 0 && (
            <AddedUsersSuccessAlert count={addedUsersCount} />
          )}
          <AddUsersTopBar>
            <Text width="bold">
              Confirm the list of users you want in your space:
            </Text>
            <Button
              aria-label="Add users"
              variant="primary"
              icon="users"
              onClick={() => onShowAddUsers(true)}
            >
              Add users
            </Button>
          </AddUsersTopBar>
        </StyledTitleWrapper>
        <ResponsiveTable<CheckoutUser>
          tableStateManager={tableStateManager}
          float="right"
          selectable
          sortable
          data={users}
          loading={isLoading}
          columns={getColumns({
            isMobile,
            domain: domainName,
            maxFreeUsers: maxFreeUsersCount,
          })}
          empty={<Empty />}
          notFound={<NotFound />}
          disabled={isLoading}
        />
        <StyledMobileSidePadding>
          <BigUsersListText size="extraLarge" type="paragraph">
            Have a big list of users to add? We can help.{' '}
            <Link
              href="https://support.yet-another-mail-merge.com/hc/en-us/requests/new"
              target="_blank"
            >
              Contact us
            </Link>
            .
          </BigUsersListText>
        </StyledMobileSidePadding>
      </CheckoutLayout.Main>
      <CheckoutLayout.Sidebar>
        <LegacyUsersSidebar
          selectedUsersCount={selectedUsersCount}
          maxUsersCount={maxUsersCount}
          onNext={onNext}
        />
      </CheckoutLayout.Sidebar>
    </CheckoutLayout.Container>
  );
};

export default LegacyUsers;
