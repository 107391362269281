import React, { FC, memo } from 'react';
import { Props } from './types';
import { Container, Spinner } from './styles';

const LoadingScreen: FC<Props> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Spinner size={50} width={3} />
    </Container>
  );
};

export default memo(LoadingScreen);
