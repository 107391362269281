import * as Yup from 'yup';

const COUNTRY_CODE_REGEX = /^[A-Z]{2}$/;

export const MAX_CHARACTERS_CUT = 30;
export const MAX_CHARACTERS_FULL = 256;

export const validationSchema = () => {
  return Yup.object().shape({
    country: Yup.string()
      .matches(COUNTRY_CODE_REGEX, 'Country is a required field')
      .length(2)
      .required('Country is a required field'),
    isBusinessPurchase: Yup.boolean().required(),
    vatNumber: Yup.string().when('isBusinessPurchase', {
      is: true,
      then: Yup.string()
        .min(2)
        .required('The VAT ID is a required field for a business purchase'),
      otherwise: Yup.string().notRequired(),
    }),
    companyName: Yup.string().when('isBusinessPurchase', {
      is: true,
      then: Yup.string()
        .max(
          MAX_CHARACTERS_FULL,
          `Company name must be at most ${MAX_CHARACTERS_FULL} characters`,
        )
        .required('Company name is a required field'),
      otherwise: Yup.string()
        .max(
          MAX_CHARACTERS_FULL,
          `Company name must be at most ${MAX_CHARACTERS_FULL} characters`,
        )
        .notRequired(),
    }),
    name: Yup.string().when('isBusinessPurchase', {
      is: true,
      then: Yup.string()
        .max(
          MAX_CHARACTERS_CUT,
          `Company name must be at most ${MAX_CHARACTERS_CUT} characters`,
        )
        .required('Name is a required field'),
      otherwise: Yup.string()
        .max(
          MAX_CHARACTERS_FULL,
          `Name must be at most ${MAX_CHARACTERS_FULL} characters`,
        )
        .required('Name is a required field'),
    }),
    street: Yup.string().required('Street is a required field'),
    city: Yup.string().min(2).required('City is a required field'),
    state: Yup.string(),
    code: Yup.string(),
  });
};
