import {
  InputTag,
  useAutocompleteTagInput,
} from '@scriptaddicts/yamm-ui-components';
import { queryClient } from 'data/queryClient';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useSuggestUser } from 'data/hooks/useUsers';
import {
  useAddMembers,
  useValidateAddMembers,
} from 'data/hooks/useWorkspaceInvite';
import {
  Dispatch,
  DispatchWithoutAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { from, useMediaQuery } from 'styles/media';

export const useConnect = ({
  limit,
  currentPaidUsersCount,
  onClose,
  onSuccess,
}: {
  currentPaidUsersCount?: number;
  limit?: number;
  onClose: DispatchWithoutAction;
  onSuccess?: Dispatch<{ addCount: number }>;
}) => {
  const [members, setMembers] = useState<InputTag[]>([]);
  const [validCount, setValidCount] = useState<number>(0);

  const {
    tagInput: { tags: addMembers, setTags: setAddMembers, ...tagInput },
    autocomplete: { setValue, search, ...autocomplete },
  } = useAutocompleteTagInput({
    autocomplete: { openOn: 2, timeout: 500 },
  });

  const { id, details } = useWorkspaceContext();

  const { data: suggestion } = useSuggestUser({
    workspaceId: id,
    searchKeyword: search,
  });

  const { mutate: add, status: addStatus, reset: resetAdd } = useAddMembers();
  const {
    data: validationData,
    isFetching: isValidating,
  } = useValidateAddMembers(id, {
    userEmails: addStatus === 'idle' ? addMembers : [],
  });

  const errors = useMemo(
    () =>
      (validationData ?? []).reduce(
        (map, { userId, errorMsg }) => ({
          ...map,
          ...(userId && addMembers.includes(userId)
            ? {
                [userId]: {
                  tip: errorMsg,
                  variant: errorMsg ? 'error' : undefined,
                },
              }
            : {}),
        }),
        [] as Record<string, any>,
      ),
    [validationData, addMembers],
  );

  // Validate on email addresses change
  useEffect(() => {
    setMembers(
      addMembers.map((member) =>
        errors[member] ? { text: member, ...errors[member] } : { text: member },
      ),
    );
  }, [addMembers, errors]);

  useEffect(() => {
    if (isValidating) return;
    setValidCount(
      members.length > 0 ? members.length - Object.keys(errors).length : 0,
    );
  }, [members, errors, isValidating]);

  const hasError = useMemo(
    () => ({
      partial: Object.keys(errors).length > 0,
      all: !!members.length && Object.keys(errors).length === members.length,
    }),
    [errors, members],
  );

  const [autoAdd, setAutoAdd] = useState<boolean>(false);
  const maxUsersReached = useMemo(() => {
    if (
      limit &&
      currentPaidUsersCount !== undefined &&
      limit > -1 &&
      currentPaidUsersCount >= 0
    ) {
      return members.length + currentPaidUsersCount > limit;
    }
    return false;
  }, [limit, currentPaidUsersCount, members]);

  const onAdd = useCallback(() => {
    if (maxUsersReached) return;

    setAutoAdd(false);
    const userEmails = addMembers.filter((email) => !errors[email]);
    if (!userEmails.length) return;

    add({
      workspaceId: id,
      params: { userEmails },
    });
  }, [add, setAutoAdd, id, addMembers, errors, maxUsersReached]);

  useEffect(() => {
    if (isValidating || !autoAdd) return;
    onAdd();
  }, [onAdd, autoAdd, isValidating]);

  const resetForm = useCallback(() => {
    setAddMembers([]);
    setValue('');
  }, [setAddMembers, setValue]);

  // Process result of invite
  useEffect(() => {
    if (addStatus === 'success') {
      resetAdd();
      if (onSuccess) {
        onSuccess({ addCount: validCount });
      }
      onClose();
      resetForm();
      queryClient.removeQueries(['users', 'suggestion']);
    }
  }, [resetAdd, onSuccess, onClose, resetForm, addStatus, validCount]);

  const isMobile = !useMediaQuery(from.tablet);

  return {
    isLoading: addStatus === 'loading' || autoAdd,
    resetForm,
    domains: details?.domains,
    search: {
      items: suggestion,
      ...autocomplete,
    },
    tags: {
      members,
      hasError,
      setAutoAdd,
      ...tagInput,
    },
    add: {
      onClick: onAdd,
      count: validCount,
      disabled: (!validCount && !search) || maxUsersReached,
      isValidating,
    },
    isMobile,
    maxUsersReached,
  };
};
