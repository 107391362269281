import { Alert, Button, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { NotificationMessagesHandlerHook } from '../../model/notificationMessage';
import { StyledContainer } from './styles';

export const NotificationMessages = ({
  handler: { messages, removeMessage },
}: {
  handler: NotificationMessagesHandlerHook;
}) => (
  <StyledContainer className={messages.length > 0 ? 'has-messages' : ''}>
    {messages.map(({ type, title, message, id, dismissible }) => (
      <Alert
        key={title}
        type={type}
        append={
          dismissible ? (
            <Button
              onClick={() => removeMessage(id)}
              aria-label="close"
              icon="x"
              variant="tertiary"
            />
          ) : undefined
        }
      >
        {title && (
          <Text type="paragraph" size="large" width="semiBold">
            {title}
          </Text>
        )}
        {message && (
          <Text type="paragraph" size="base" width="regular">
            {message}
          </Text>
        )}
      </Alert>
    ))}
  </StyledContainer>
);
