import React, { FC } from 'react';
import { Placeholder, Text } from '@scriptaddicts/yamm-ui-components';
import { useConnect } from './connect';
import {
  StyledPriceSection,
  StyledSeparator,
  StyledTotalSection,
  StyledNextButton,
  StyledStripeLogo,
  StyledTaxesSection,
  StyledTaxesText,
  StyledPriceSectionPlaceholder,
  TermsAndServicesText,
} from '../common/styles';
import {
  getFormattedPaidToday,
  getFormattedTaxAmountPaidToday,
  getFormattedTotalDue,
} from '../common/utils';
import { from, useMediaQuery } from '../../../styles/media';
import { BillingPurchasePlans, PriceFooter } from '../common/components';
import { getSubscriptionPriceWithTax } from '../../../utils/checkout';

const Link: FC<{ href: string }> = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export type BillingInformationSidebarProps = {
  isSubmitting?: boolean;
  disabled?: boolean;
};
const BillingInformationSidebar = ({
  isSubmitting,
  disabled,
}: BillingInformationSidebarProps) => {
  const {
    billingInterval,
    paymentInfo,
    isFetchingPaymentInfo,
    submitForm,
    showTaxesInfo,
    purchasePlansDisplay,
    plan,
  } = useConnect();
  const isTablet = useMediaQuery(from.tablet);
  const stripeCheckoutButtonText = 'Next: Checkout';
  return (
    <>
      <BillingPurchasePlans workspacePlan={plan} plans={purchasePlansDisplay} />

      <StyledSeparator />

      {showTaxesInfo && (
        <StyledTaxesSection>
          <StyledTaxesText type="paragraph" size="extraLarge">
            <span>Total without taxes</span>
            {!isFetchingPaymentInfo && paymentInfo ? (
              <span>{getFormattedPaidToday(paymentInfo)}</span>
            ) : (
              <Placeholder
                size="extra-large"
                type="paragraph"
                width={62}
                colSpan={0}
              />
            )}
          </StyledTaxesText>
          <StyledTaxesText type="paragraph" size="extraLarge">
            {!isFetchingPaymentInfo && paymentInfo ? (
              <>
                <span>Taxes ({paymentInfo.taxRate}%)</span>
                <span>{getFormattedTaxAmountPaidToday(paymentInfo)}</span>
              </>
            ) : (
              <>
                <span>
                  Taxes <Placeholder type="paragraph" width={34} />
                </span>
                <Placeholder
                  size="extra-large"
                  type="paragraph"
                  width={42}
                  colSpan={0}
                />
              </>
            )}
          </StyledTaxesText>
        </StyledTaxesSection>
      )}

      <StyledTotalSection>
        <Text type="paragraph" size="extraLarge" width="bold">
          Total due
        </Text>
        {!isFetchingPaymentInfo && paymentInfo ? (
          <StyledPriceSection>
            <Text type="paragraph" size="extraLarge" width="bold">
              {getFormattedTotalDue(paymentInfo)}
            </Text>
            <PriceFooter
              displayPrice={false}
              billingInterval={billingInterval}
              currency={paymentInfo.currency}
              renewalPrice={getSubscriptionPriceWithTax(paymentInfo)}
            />
          </StyledPriceSection>
        ) : (
          <StyledPriceSectionPlaceholder>
            <Placeholder size="extra-large" type="paragraph" width={66} />
            <Placeholder size="base" type="paragraph" width={91} />
          </StyledPriceSectionPlaceholder>
        )}
      </StyledTotalSection>

      <StyledNextButton
        aria-label="Next: Checkout"
        data-log-event="go_to_stripe_checkout"
        data-log-event-data={JSON.stringify({
          displayName: stripeCheckoutButtonText,
        })}
        onClick={submitForm}
        loading={isSubmitting}
        disabled={isSubmitting || disabled}
      >
        {stripeCheckoutButtonText}
      </StyledNextButton>
      {isTablet && <StyledStripeLogo />}

      <TermsAndServicesText>
        All payments are securely processed by Stripe. By clicking on &quot;Go
        to checkout&quot; and then confirming your subscription, you agree with
        Stripe&apos;s{' '}
        <Link href="https://stripe.com/legal/end-users">terms</Link> and{' '}
        <Link href="https://stripe.com/privacy">privacy policy</Link>.
      </TermsAndServicesText>
    </>
  );
};

export default BillingInformationSidebar;
