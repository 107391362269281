import { useCampaignDetail, useCampaignPoll } from 'data/hooks/useCampaign';
import { useMe } from 'data/hooks/useMe';
import { formatShortDateTime } from 'utils/dates';
import useURLDisclosure from 'utils/router/useUrlDisclosure';

export const useConnect = () => {
  const { url, params, ...dialog } = useURLDisclosure(':id');
  const campaignId = params?.id;

  const { data: me } = useMe();
  const { data: mailMerge, status } = useCampaignDetail(me?.id, campaignId);
  const { data: poll } = useCampaignPoll(
    me?.id,
    mailMerge?.mergeSourceSheetKey,
  );

  const lastEmailSent =
    mailMerge && formatShortDateTime(mailMerge.lastEmailSentTimestamp);

  return {
    isLoading: status === 'loading',
    url,
    dialog,
    mailMerge,
    lastEmailSent,
    poll,
  };
};
