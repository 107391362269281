import { useCallback, useMemo } from 'react';
import { useWorkspaceContext } from '../../../../../data/context/WorkspaceContext';
import { useWorkspaceSubscriptions } from '../../../../../data/hooks/useWorkspaceBilling';
import {
  checkIsSubscriptionCanceled,
  checkIsLastPaymentAttemptFailed,
  checkIsLastPaymentAttemptRequiresConfirmation,
  getEndDate,
  getActiveSubscription,
  getHostedInvoiceUrl,
} from './utils';
import {
  useAlertsDismiss,
  useDismissAlert,
} from '../../../../../data/hooks/useAlertsDismiss';

function getAlertKey({
  isPaymentAttemptFailed,
  isPaymentAttemptRequiresConfirmation,
  subId,
  isSubscriptionCanceled,
}: {
  isPaymentAttemptFailed: boolean;
  isPaymentAttemptRequiresConfirmation: boolean;
  isSubscriptionCanceled: boolean;
  subId?: string;
}): string | undefined {
  if (isPaymentAttemptRequiresConfirmation)
    return `is-payment-attempt-requires-confirmation-${subId}`;
  if (isPaymentAttemptFailed) return `is-payment-attempt-failed-${subId}`;
  if (isSubscriptionCanceled) return `is-subscription-canceled-${subId}`;
  return undefined;
}

export const useConnect = () => {
  const { id, urlPrefix } = useWorkspaceContext();
  const { data: subscriptions } = useWorkspaceSubscriptions({
    workspaceId: id,
    includePlans: false,
  });

  const info = useMemo(() => {
    const active = subscriptions
      ? getActiveSubscription(subscriptions)
      : undefined;
    const isSubscriptionCanceled = checkIsSubscriptionCanceled(subscriptions);
    const isPaymentAttemptFailed = checkIsLastPaymentAttemptFailed(active);
    const isPaymentAttemptRequiresConfirmation = checkIsLastPaymentAttemptRequiresConfirmation(
      active,
    );
    return {
      endDate: getEndDate(active),
      hostedInvoiceUrl: getHostedInvoiceUrl(active),
      isSubscriptionCanceled,
      isPaymentAttemptFailed,
      isPaymentAttemptRequiresConfirmation,
      alertKey: getAlertKey({
        isPaymentAttemptFailed,
        subId: active?.subscriptionId,
        isSubscriptionCanceled,
        isPaymentAttemptRequiresConfirmation,
      }),
    };
  }, [subscriptions]);

  const { data } = useAlertsDismiss(info.alertKey);
  const { mutate } = useDismissAlert();

  const onClose = useCallback((alertId: string) => {
    mutate({
      dismiss: true,
      alertId,
    });
  }, []);

  return {
    ...info,
    urlPrefix,
    onClose,
    alertKey: info.alertKey,
    displayAlert: data === false,
  };
};
