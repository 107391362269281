import React, { FC } from 'react';
import TopBar from 'components/TopBar';
import WorkspaceAlerts from 'containers/Main/Workspaces/WorkspaceAlerts';
import WorkspaceDropdown from 'containers/Main/Workspaces/WorkspaceDropdown';
import { NewWorkspace } from 'containers/Main/Workspaces/NewWorkspace';
import ResponsiveMenu from 'components/ResponsiveMenu';
import { Container, Main } from './styles';
import { useConnect } from './connect';

const Layout: FC = ({ children }) => {
  const { menu, bar, newWorkspace } = useConnect();

  return (
    <Container>
      <ResponsiveMenu
        {...menu}
        workspace={
          <WorkspaceDropdown
            onCreate={() => newWorkspace.onOpen('menu')}
            onClose={menu.onClose}
          />
        }
      />
      <Main>
        <TopBar {...bar} />
        <WorkspaceAlerts />
        <NewWorkspace {...newWorkspace} />
        {children}
      </Main>
    </Container>
  );
};

export default Layout;
