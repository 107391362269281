import useTableStateManager from 'components/ResponsiveTable/useTableStateManager';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useCampaigns } from 'data/hooks/useCampaign';
import { CampaignParams } from 'model/campaign';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { from, useMediaQuery } from 'styles/media';
import testingVar from 'utils/testingVar';
import { getDateFilter } from '../logic';
import { getColumns } from './table/columns';

export const useConnect = () => {
  const { url } = useRouteMatch();
  const { id: workspaceId } = useWorkspaceContext();

  const now = useMemo(() => new Date(), [workspaceId]);

  const {
    params,
    manager: tableStateManager,
  } = useTableStateManager<CampaignParams>({
    paginationKey: workspaceId,
    globalFilter: 'mergeSourceSheetTitle',
    filterBy: {
      lastUpdateTimestamp: {
        id: 'lastUpdateTimestamp',
        value: (value: any) => getDateFilter(now, value),
      },
    },
    sortBy: {
      lastUpdateTimestamp: 'lastUpdateTimestamp',
      title: 'mergeSourceSheetTitle',
    },
  });

  const { data: campaigns, status, fetchAll: getCampaigns } = useCampaigns(
    params,
  );

  const isMobile = !useMediaQuery(from.tablet);
  const columns = useMemo(() => getColumns({ isMobile }), [isMobile]);
  const mailMergesTestingVar = testingVar('mail-merges');

  return {
    campaigns,
    getCampaigns,
    isLoading: status === 'loading',
    tableStateManager,
    columns,
    mailMergesTestingVar,
    url,
  };
};
