import { useMemo } from 'react';
import { mapTableUserToPaidUser } from '../../../../utils/checkout/mappers';
import { CheckoutContextType } from '../../../../data/context/CheckoutContext/types';
import { WorkspaceSubscription } from '../../../../model/workspaceBilling';
import useQueryParams from '../../../../utils/useQueryParams';
import { ComputeStripePaymentInfoRequest } from '../../../../data/requests/workspaceBilling';
import { CheckoutUser } from '../../../../model/checkout';

const hasUserChanged = (user: CheckoutUser) =>
  (user.isPaid && user.plan === 'FREE') ||
  (user.plan !== 'FREE' && !user.isPaid);

export type UseSummaryPaymentInfoProps = {
  checkoutContext: CheckoutContextType;
  workspaceId?: string;
  activeSubscription?: WorkspaceSubscription;
};
export const useSummaryPaymentInfo = ({
  checkoutContext,
  activeSubscription,
}: UseSummaryPaymentInfoProps): ComputeStripePaymentInfoRequest => {
  const query = useQueryParams();
  const fromUsersSection: boolean = query.get('from') === 'users';

  return useMemo<ComputeStripePaymentInfoRequest>(() => {
    const country = activeSubscription?.additionalStripeInfo?.country;
    const changedPlan =
      activeSubscription?.workspacePlan !== checkoutContext.plan;
    const changedInterval =
      activeSubscription?.billingInterval !== checkoutContext.billingInterval;

    // company name is required for B2B purchases
    const purchaseType =
      activeSubscription?.additionalStripeInfo?.companyName == null
        ? 'B2C'
        : 'B2B';

    const userUpdates = checkoutContext.users
      .filter(
        // (user) =>
        //   !user.toBeRemoved &&
        //   ((user.isPaid && user.plan === 'FREE') ||
        //     (user.plan !== 'FREE' && !user.isPaid)),
        (user) => !user.toBeRemoved && hasUserChanged(user),
      )
      .map(mapTableUserToPaidUser);

    return {
      // read country from active subscription
      // the summary screen should only be accessible if you already have active subscription
      country,
      billingInterval: changedInterval
        ? checkoutContext.billingInterval
        : undefined,
      workspacePlan: changedPlan ? checkoutContext.plan : undefined,
      purchaseType,
      userUpdates,
    };
  }, [checkoutContext, activeSubscription, fromUsersSection]);
};
