import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Root from 'containers/Root';
import GlobalStyle from 'styles/GlobalStyle';
import '@scriptaddicts/yamm-ui-components/dist/style.css';
import theme from 'styles/themes';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'data/queryClient';
import { AuthProvider } from 'data/context/AuthContext';
import ErrorBoundary from 'containers/Common/ErrorBoundary';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';
import GoogleAuthContextProvider from 'data/context/GoogleAuth';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
let stripePromise: Promise<Stripe | null>;
if (stripePublicKey) {
  stripePromise = loadStripe(stripePublicKey);
}

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ErrorBoundary>
        <BrowserRouter>
          <GoogleAuthContextProvider>
            <AuthProvider>
              <Elements stripe={stripePromise}>
                <Root />
              </Elements>
            </AuthProvider>
          </GoogleAuthContextProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
