import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMemo } from 'react';
import { useMe } from '../../../data/hooks/useMe';

export const useConnect = () => {
  const { otherWorkspaces } = useWorkspaceContext();
  const { data: user } = useMe();

  const activeWorkspace = useMemo(
    () => otherWorkspaces.find((a) => a.id === user?.addonWorkspaceId),
    [otherWorkspaces, user?.addonWorkspaceId],
  );

  return {
    activeWorkspace,
  };
};
