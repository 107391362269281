import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';
import { ReactComponent as ErrorIconSvg } from 'assets/images/icons/alert-circle.svg';
import { ReactComponent as WarningIconSvg } from 'assets/images/icons/alert-triangle.svg';

import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { from } from 'styles/media';
import {
  Heading as DefaultHeading,
  Placeholder as DefaultPlaceholder,
  Text as DefaultText,
} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  position: relative;
  ${from.tablet} {
    min-width: 448px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 24px;
  padding-right: 0px;
  max-width: calc(100% - 24px - 20px);
  ${from.tablet} {
    max-width: calc(448px - 36px - 20px);
  }
`;

export const Title = styled(DefaultHeading).attrs({
  size: 'large',
})``;

export const TitlePlaceholder = styled(DefaultPlaceholder).attrs({
  type: 'heading',
  size: 'large',
  width: 250,
})``;

export const Error = styled.div<{
  $variant: string;
}>`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 2.25rem 1fr;
  align-items: center;
  line-height: 1.625rem;
  color: ${({ theme, $variant }) =>
    $variant === 'error' ? theme.colors.red600 : theme.colors.yellow700};
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
`;

export const ErrorIcon = styled(ErrorIconSvg)`
  color: ${({ theme }) => theme.colors.red600};
  height: 1.5rem;
  width: 1.5rem;
`;
export const WarningIcon = styled(WarningIconSvg)`
  color: ${({ theme }) => theme.colors.yellow700};
  height: 1.5rem;
  width: 1.5rem;
`;

export const Divider = styled.hr`
  margin: 0px;
  border: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const Content = styled.div`
  padding: 24px;
  overflow: auto;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
`;

export const Column = styled.div`
  flex-basis: 50%;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled(DefaultText).attrs({
  type: 'span',
  width: 'medium',
})`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.64px;

  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Data = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'extraLarge',
  width: 'medium',
})``;

export const DataPlaceholder = styled(DefaultPlaceholder).attrs({
  type: 'paragraph',
  size: 'extra-large',
  width: 64,
})``;

export const Link = styled.a`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray800};

  border-top: 1px solid ${({ theme }) => theme.colors.gray100};
  padding: 18px 24px;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray50};
  }
  &:focus-visible {
    outline-offset: -8px;
  }
`;

export const IconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.gray700};
  height: 20px;
  width: 20px;
  margin-right: 1.375rem;
  svg {
    height: 100%;
    width: 100%;
  }
`;

export const LinkIcon = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.gray200};
  margin-left: auto;
`;

export const ErrorLink = styled.a.attrs({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
})`
  color: ${({ theme }) => theme.colors.blue600};
`;

export const CancelButton = styled.button<{
  $variant: 'error' | 'info';
}>`
  display: flex;
  align-items: center;

  width: 100%;
  font-size: 16px;
  line-height: 26px;
  padding: 18px 24px;

  border: none;
  background: none;
  border-top: 1px solid ${({ theme }) => theme.colors.gray100};
  transition: background-color 0.2s ease;
  color: ${({ theme, $variant }) =>
    $variant === 'error' ? theme.colors.redDark : theme.colors.blue600};
  cursor: pointer;

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray50};
  }

  &:focus-visible {
    outline-offset: -8px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${IconWrapper} {
    color: ${({ theme, $variant }) =>
      $variant === 'error' ? theme.colors.redDark : theme.colors.blue600};
  }
`;
