import { GoogleProfileQuery } from 'data/hooks/useGoogleProfile';
import {
  SuggestUser,
  SuggestUserInput,
  User,
  UserInput,
  UserInputResponse,
  UserRolloutInput,
} from 'model/user';
import { getRestrictions } from 'utils/rollout';

export const transformMe = (user: UserInput): User => {
  return {
    id: user.userId,
    email: user.userEmail,
    name: user.userName,
    initials: user.userName
      ?.split(' ')
      .map((word) => word[0].toUpperCase())
      .slice(0, 2)
      .join(''),
    photo: user.profilePictureUrl,
    type: user.isPersonal ? 'STANDARD' : 'BUSINESS',
    installationTimestamp: Number(user.installationTimestamp),
    lastUsedTimestamp: Number(user.lastUpdateTimestamp),
    license: {},
    domain: user.domain.domainName,
    isPersonal: user.isPersonal,
    addonWorkspaceId:
      user.addonWorkspaceId || user.userWorkspaces[0]?.workspaceId,
    freeUserQuota: user.freeUserQuota,
  };
};

export const enrichMe = (
  user: User,
  googleProfile?: GoogleProfileQuery,
): User => {
  return {
    ...user,
    email: user.email ?? googleProfile?.email,
    name: user.name ?? googleProfile?.name ?? undefined,
    photo: user.photo ?? googleProfile?.imageUrl,
    initials:
      user.initials ??
      googleProfile?.name
        ?.split(' ')
        .map((word) => word[0].toUpperCase())
        .slice(0, 2)
        .join(''),
  };
};

export const transformSuggestUser = (
  suggestions: SuggestUserInput[],
): SuggestUser[] => {
  return suggestions.map((user) => ({
    email: user.userEmail,
    name: user.userName,
  }));
};

export const transformRollout = (
  response: UserInputResponse,
): UserRolloutInput => {
  return {
    isRegistered: response.isRegistered,
    isEligible: response.isEligible,
    restrictions: getRestrictions(
      response?.userV2?.userEmail,
      response.lastActivatedStep,
    ),
  };
};
