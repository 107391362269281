import React from 'react';
import {
  Button,
  Dialog,
  PromptContent,
  PromptContentButtons,
  PromptContentDescription,
  PromptContentTitle,
} from '@scriptaddicts/yamm-ui-components';
import { formatShortDateTime } from '../../../../../utils/dates';

const PROMPT_STYLES = {
  maxWidth: '500px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export type ConfirmCancelCycleChangeProps = {
  onConfirm: () => void;
  onCancel: () => void;
  cycleChangeDate: Date;
  isLoading?: boolean;
};
export const ConfirmCancelCycleChange = ({
  cycleChangeDate,
  onConfirm,
  onCancel,
  isLoading,
}: ConfirmCancelCycleChangeProps) => {
  const dismiss = () => {
    if (!isLoading) {
      onCancel();
    }
  };

  return (
    <Dialog show onClose={dismiss}>
      <PromptContent style={PROMPT_STYLES}>
        <PromptContentTitle>
          Cancel the change to monthly billing?
        </PromptContentTitle>
        <PromptContentDescription>
          You are currently billed annually, with a change to monthly scheduled
          for {formatShortDateTime(cycleChangeDate)}.
        </PromptContentDescription>
        <PromptContentButtons>
          <Button
            aria-label="Cancel change"
            onClick={onConfirm}
            disabled={isLoading}
            loading={isLoading}
          >
            Cancel change
          </Button>
          <Button
            disabled={isLoading}
            aria-label="Keep change"
            variant="secondary"
            onClick={dismiss}
          >
            Keep change
          </Button>
        </PromptContentButtons>
      </PromptContent>
    </Dialog>
  );
};
