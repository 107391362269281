import { useMemo } from 'react';
import { WorkspaceMember } from '../../../../../../model/workspaceMembers';
import { WorkspaceSubscription } from '../../../../../../model/workspaceBilling';
import { MAX_USERS_PER_SPACE } from '../../../../../../data/constants';

export type UseMaxUsersCheckProps = {
  selected: WorkspaceMember[];
  allUsers: WorkspaceMember[];
  activeSubscription?: WorkspaceSubscription;
};
export const useMaxUsersCheck = ({
  allUsers,
  activeSubscription,
  selected,
}: UseMaxUsersCheckProps) => {
  return useMemo(() => {
    const selectedFreeUsers = selected.filter((user) => user.plan === 'FREE');
    const allPaidUsers = allUsers.filter((user) => user.plan !== 'FREE');
    const isMaxUsersReached =
      allPaidUsers.length + selectedFreeUsers.length > MAX_USERS_PER_SPACE;

    return {
      isMaxUsersReached,
    };
  }, [selected, allUsers, activeSubscription]);
};
