import React, { FC } from 'react';
import { Props } from './types';

import { Header, Brand, Logo, Main } from './styles';

const StaticLayout: FC<Props> = ({ children, ...props }) => {
  return (
    <>
      <Header {...props}>
        <Brand to="/">
          <Logo />
          YAMM
        </Brand>
      </Header>
      <Main>{children}</Main>
    </>
  );
};

export default StaticLayout;
