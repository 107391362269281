const BASE64_REGEX = /^[A-Za-z0-9-_]{22}(==|)$/;

export const decodeBase64UUID = (base64UUID?: string): string | undefined => {
  if (!base64UUID) return undefined;

  if (!base64UUID.match(BASE64_REGEX)) return undefined;

  const decoded = Buffer.from(
    base64UUID.endsWith('==') ? base64UUID : `${base64UUID}==`,
    'base64',
  );

  if (decoded.length !== 16) return undefined;
  const pattern = [4, 2, 2, 2, 6];

  const result: string[] = [];
  let offset = 0;
  pattern.forEach((size) => {
    result.push(
      [...Array.from(decoded.subarray(offset, offset + size))]
        .map((val) => val.toString(16).padStart(2, '0'))
        .join(''),
    );
    offset += size;
  });

  return result.join('-');
};
