import React, { useState } from 'react';

import ISOLogo from 'assets/images/icons/ISO-compliant.svg';
import GCPLogo from 'assets/images/icons/GCP.png';
import GDPRLogo from 'assets/images/icons/GDPR.svg';

import { Nav, NavLink } from '../styles';
import { Wrapper, Container, FooterLink, Footer, Badges, Main } from './styles';
import Progress from './Progress';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { useAuth } from '../../../data/context/AuthContext';
import LoadingScreen from '../../../components/LoadingScreen';

const PROGRESS_LABELS = [
  'Create your account',
  'Install YAMM',
  'Send your first merge',
];

const SignUp = () => {
  const { token, getUserStatus, loading } = useAuth();

  const [currentStep, setCurrentStep] = useState<number>(2);
  const [visited, changeVisited] = useState<boolean>(false);

  const isSignedUp = token && getUserStatus === 'success';
  const actualStep = isSignedUp ? currentStep : 1;
  const onNext = () => {
    setCurrentStep(actualStep + 1);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <Main>
        <Container>
          <Progress
            step={actualStep}
            labels={PROGRESS_LABELS}
            setStep={setCurrentStep}
          />

          {actualStep === 1 && <StepOne />}

          {actualStep === 2 && (
            <StepTwo
              visited={visited}
              changeVisited={changeVisited}
              handleNext={onNext}
            />
          )}

          {actualStep === 3 && <StepThree />}
        </Container>
      </Main>
      <Footer>
        <div>
          <Nav>
            <NavLink href="https://support.yet-another-mail-merge.com/hc/en-us">
              Help center
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/terms-of-service/`}
            >
              Terms of service
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/privacy-policy/`}
            >
              Privacy policy
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/cookie-policy/`}
            >
              Cookie policy
            </NavLink>
            <NavLink href="https://talarian.io/data-processing-agreement">
              Data processing agreement
            </NavLink>
          </Nav>
        </div>
        <Badges>
          <FooterLink href="https://storage.googleapis.com/talarian-assets/global/ios27001-cert.pdf">
            <img src={ISOLogo} alt="iso-complient" />
          </FooterLink>
          <FooterLink href="https://cloud.withgoogle.com/partners/detail/?id=talarian">
            <img src={GCPLogo} alt="gc-partner" />
          </FooterLink>
          <FooterLink href="https://talarian.io/data-processing-agreement">
            <img src={GDPRLogo} alt="gdpr" />
          </FooterLink>
        </Badges>
      </Footer>
    </Wrapper>
  );
};

export default SignUp;
