import React from 'react';

export type SpaceProps = {
  w?: number;
  h?: number;
};
const Space = ({ w = 0, h = 0 }: SpaceProps) => (
  <div style={{ display: 'block', width: `${w}px`, height: `${h}px` }} />
);

export default Space;
