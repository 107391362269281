/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns/fp';

type DateCalculator = (diffMilliseconds: number) => number;

// https://date-fns.org/docs/format
const DATE_MONTH_DAY = 'LLL. d';
const DATE_SHORT_MONTH_DAY_YEAR = 'LLL. d, Y';
const DATE_FULL_MONTH_DAY_YEAR = 'LLLL do, Y';
const TIME_12_HOUR = 'p';
const DATE_SHORT_TIME = 'LLL. d, p';

export const formatMonthDay = format(DATE_MONTH_DAY);
export const formatShortMonthDayYear = format(DATE_SHORT_MONTH_DAY_YEAR);
export const formatLongMonthDayYear = format(DATE_FULL_MONTH_DAY_YEAR);
export const format12hTime = format(TIME_12_HOUR);
export const formatShortDateTime = format(DATE_SHORT_TIME);

/**
 * @param diffMilliseconds: difference in milliseconds
 * @returns value converted to days (can be positive, negative, int or float)
 */
export const DATE_DIFF_DAYS_CALCULATOR: DateCalculator = (
  diffMilliseconds: number,
): number => diffMilliseconds / (1000 * 3600 * 24);

/**
 * @param a: start timestamp in milliseconds
 * @param b: end timestamp in milliseconds
 * @param calculator: function that calculates difference. Default days
 * @returns difference as number (result depends on calculator implementation)
 */
export const timestampDifference = (
  a: number,
  b: number,
  calculator: DateCalculator = DATE_DIFF_DAYS_CALCULATOR,
): number => {
  return calculator(b - a);
};
