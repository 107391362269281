import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import { BillingWorkspacePlan } from '../../../model/workspaceBilling';

export const useConnect = () => {
  const { details: workspaceDetails, isLoading } = useWorkspaceContext();

  const targetDomain: string = 'addonsforgapps.com';
  const targetPlan: BillingWorkspacePlan = 'PRO';

  return {
    isLoading,
    workspaceDetails,
    targetDomain,
    targetPlan,
  };
};
