import { useMemo } from 'react';
import { useAvailableUsers } from 'containers/Checkout/BillingOptions/hooks/useAvailableUsers';
import { useWorkspaceContext } from '../../../../data/context/WorkspaceContext';
import {
  useComputeStripePaymentInfo,
  useWorkspaceActiveSubscription,
} from '../../../../data/hooks/useWorkspaceBilling';
import { useCheckoutContext } from '../../../../data/context/CheckoutContext';
import {
  getComputeParams,
  GetComputeParamsFlow,
} from '../../BillingOptionsSidebar/utils';
import { PaidUser } from '../../../../model/workspaceBilling';
import { useChangeBillingCycle } from '../../BillingOptions/hooks/useChangeBillingCycle';

function hasAtLeastOnePaidUser(updates: PaidUser[]) {
  return updates.find((update) => update.userPlan !== 'FREE') != null;
}

export const useBillingOptionsComputePaymentInfo = (
  computeParamsFlow: GetComputeParamsFlow,
) => {
  const { changeBillingCycle } = useChangeBillingCycle();

  const { id, isLoading: isLoadingContext } = useWorkspaceContext();
  const { plan, billingInterval } = useCheckoutContext();
  const { availableUsers, isLoading } = useAvailableUsers();

  const {
    data: activeSubscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });

  const [computeParams, hasPaidUsers] = useMemo(() => {
    const params = getComputeParams(
      computeParamsFlow,
      activeSubscription,
      availableUsers,
      billingInterval,
      changeBillingCycle,
      plan !== activeSubscription?.workspacePlan ? plan : undefined,
    );
    const atLeastOneUserPaid = params?.userUpdates
      ? hasAtLeastOnePaidUser(params?.userUpdates)
      : false;

    return [params, atLeastOneUserPaid];
  }, [
    activeSubscription,
    availableUsers,
    billingInterval,
    changeBillingCycle,
    plan,
  ]);

  // fetch payment info if not loading, and in case of active subscription, if there is at least one paid user
  const preventFetch =
    isLoading ||
    isLoadingActiveSubscription ||
    (!activeSubscription && !hasPaidUsers);

  const {
    data: paymentInfo,
    isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfo(
    // missing id will prevent query from loading
    preventFetch ? undefined : id,
    computeParams,
    activeSubscription != null && activeSubscription.workspacePlan === 'LEGACY',
  );

  return {
    isLoading: isLoadingContext || isLoading || isLoadingActiveSubscription,
    isFetchingPaymentInfo,
    paymentInfo,
  };
};
