import { client, clientV2 } from 'data/client';
import { Token } from 'data/context/AuthContext/token';
import {
  transformCampaigns,
  transformCampaignDetail,
  transformPollResponses,
  transformPollResults,
} from 'data/transform/campaign';
import { CampaignInput } from 'model/campaign';
import { InputParams } from 'model/parameters';
import {
  GetCampaignPollResponse,
  GetCampaignPollResult,
} from '../../model/poll';

export async function getCampaigns(params: InputParams) {
  const { idToken } = Token.get();
  const { data } = await clientV2.get<{ items: CampaignInput[] }>(
    `/users/me/campaigns`,
    {
      params,
      headers: {
        'X-ID-Token': `Bearer ${idToken}`,
      },
    },
  );
  return transformCampaigns(data.items);
}

export async function getCampaignDetail(campaignId: string) {
  const { idToken } = Token.get();
  const { data } = await clientV2.get<CampaignInput>(
    `/users/me/campaigns/${campaignId}`,
    {
      headers: {
        'X-ID-Token': `Bearer ${idToken}`,
      },
    },
  );
  return transformCampaignDetail(data);
}

export async function getCampaignPoll(mergeSourceSheetKey: string) {
  try {
    const { idToken } = Token.get();
    const { data: resultData } = await client.get<GetCampaignPollResult>(
      `/users/me/campaigns/${mergeSourceSheetKey}/pollResults`,
      {
        headers: {
          'X-ID-Token': `Bearer ${idToken}`,
        },
      },
    );
    if (resultData.items && resultData.items.length > 0) {
      return (
        resultData.items &&
        resultData.items.length > 0 &&
        transformPollResults(resultData.items)
      );
    }
  } catch (e) {
    // normal in rollout period, do nothing
  }

  const { data: responseData } = await client.get<GetCampaignPollResponse>(
    `/users/me/campaigns/${mergeSourceSheetKey}/pollResponses`,
  );
  return (
    responseData.items &&
    responseData.items.length > 0 &&
    transformPollResponses(responseData.items)
  );
}
