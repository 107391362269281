import React, { FC } from 'react';
import { Color } from '@scriptaddicts/yamm-ui-components';
import { StyledListItem, StyledPlanContainer } from './styles';
import { Card, CardTitle } from '../styles';

const CustomPlan: FC = () => {
  return (
    <StyledPlanContainer>
      <Card>
        <CardTitle>Current plan: Custom</CardTitle>
      </Card>
      <Card>
        <StyledListItem
          onClick={() =>
            window.open('mailto:sales@yet-another-mail-merge.com', '_blank')
          }
        >
          <Color variant="info">Contact sales</Color>
        </StyledListItem>
      </Card>
    </StyledPlanContainer>
  );
};

export default CustomPlan;
