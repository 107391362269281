import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { getPaymentPlans } from '../requests/paymentPlans';
import { getPrices, Prices } from '../../utils/paymentPlans';
import { PaymentPlan } from '../../model/paymentPlans';

export const usePaymentPlans = () =>
  useQuery(['paymentPlans'], getPaymentPlans, {
    staleTime: 30000,
  });

export const usePrices = () => {
  const { data, isLoading } = usePaymentPlans();

  return useMemo<{
    isLoading: boolean;
    prices?: Prices;
    paymentPlans?: PaymentPlan[];
  }>(() => {
    if (data) {
      return {
        isLoading,
        prices: getPrices(data),
        paymentPlans: data,
      };
    }
    return {
      isLoading,
    };
  }, [data, isLoading]);
};
