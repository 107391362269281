import { AxiosError } from 'axios';
import { BackendError, BackendErrorContent } from 'model/error';

export const transformError = (
  error?: AxiosError<BackendError> | null,
): BackendErrorContent | undefined => {
  if (!error) return undefined;
  if (error?.response?.data) return error.response.data.error;

  return {
    errors: [],
    code: error?.response?.status ?? error.code ? Number(error.code) : 500,
    message: error?.response?.statusText || error.message || 'Unknown error',
  };
};
