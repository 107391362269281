import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  useTable,
  useFlexLayout,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
  useRowSelect,
} from 'react-table';
import { sortDate } from 'utils/sortDate';
import { getAccessor } from 'utils/getAccessor';
import { Pagination, Table } from '@scriptaddicts/yamm-ui-components';
import { DateFilter, Props } from './types';
import {
  FilterWrapper,
  FilterContainer,
  TableWrapper,
  PaginationContainer,
} from './styles';

import Columns from './Columns';
import Rows from './Rows';
import {
  getDefaultSort,
  dateFilterFunction,
  globalFilterFunction,
} from './logic';
import Placeholder from './Placeholder';
import { Empty } from './Empty';

const ResponsiveTable = <TData extends object = any>({
  columns,
  data,
  getData,
  loading,
  empty,
  notFound,
  defaultDateFilter = 'LAST_12_MONTHS',
  disableFilter = false,
  filter: FilterComponent,
  exporter: ExportComponent,
  itemsPerPage = 25,
  rowLink,
  tableStateManager,
  processRow,
  ...props
}: PropsWithChildren<Props<TData>>) => {
  const defaultColumn = useMemo(
    () => ({
      width: 150,
    }),
    [],
  );

  const defaultSorted = useMemo(() => getDefaultSort(columns), [columns]);
  const dateColumn = useMemo(
    () => getAccessor(columns.find((column) => column.filter === 'unix')),
    [columns],
  );
  const filters = useMemo(
    () =>
      !disableFilter && dateColumn
        ? [{ id: dateColumn, value: defaultDateFilter }]
        : [],
    [disableFilter, dateColumn, defaultDateFilter],
  );

  const {
    state: {
      pageIndex,
      globalFilter,
      sortBy,
      filters: activeFilters,
      selectedRowIds,
    },
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    headers,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
    setSortBy,
    isAllRowsSelected,
    toggleRowSelected,
    toggleAllRowsSelected,
    dispatch,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: itemsPerPage,
        sortBy: defaultSorted ? [defaultSorted] : [],
        filters,
      },
      sortTypes: {
        datetime: sortDate,
      },
      manualPagination: !!tableStateManager?.manualPagination,
      manualGlobalFilter: !!tableStateManager?.manualGlobalFilter,
      manualFilters: !!tableStateManager?.manualFilters,
      manualSortBy: !!tableStateManager?.manualSortBy,
      getRowId: tableStateManager?.getRowId,
      pageCount: tableStateManager?.manualPagination ? -1 : undefined,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableFilters: disableFilter,
      globalFilter: globalFilterFunction,
      filterTypes: {
        unix: dateFilterFunction,
      },
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    if (!tableStateManager || loading) return;
    tableStateManager.feedbackPagination(page.length);
  }, [loading, page.length]);

  useEffect(() => {
    if (!tableStateManager) return;
    tableStateManager.setPaginationParams({
      pageIndex,
      itemsPerPage,
      gotoPage,
    });
  }, [pageIndex, itemsPerPage, gotoPage]);

  useEffect(() => {
    if (!tableStateManager) return;
    tableStateManager.setFilterParams({
      activeFilters,
      globalFilter,
    });
  }, [activeFilters, globalFilter]);

  useEffect(() => {
    if (!tableStateManager) return;
    tableStateManager.setSortParams({
      sortBy,
    });
  }, [sortBy]);

  useEffect(() => {
    if (!tableStateManager) return;
    tableStateManager.setSelectionParams({
      selectedRowIds,
      dispatch,
    });
  }, [selectedRowIds, dispatch]);

  const dateFilter = useCallback(
    (value: DateFilter) => {
      if (!dateColumn) return;
      setFilter(dateColumn, value);
    },
    [dateColumn],
  );

  const isLoading = loading && page.length === 0;
  const isEmpty =
    !loading && page.length === 0 && (globalFilter || '').length === 0;
  const isNotFound =
    !loading && page.length === 0 && (globalFilter || '').length > 0;

  return (
    <>
      {FilterComponent && (
        <FilterWrapper>
          <FilterContainer>
            <FilterComponent
              onChange={setGlobalFilter}
              onDateFilterChange={dateFilter}
              currentDateFilter={defaultDateFilter}
              goToPage={gotoPage}
            />
            {ExportComponent ? (
              <ExportComponent getData={getData} data={data} />
            ) : null}
          </FilterContainer>
        </FilterWrapper>
      )}
      {isLoading ? <Placeholder columns={columns} animated /> : null}
      {isEmpty || isNotFound ? (
        <Empty
          foreground={isNotFound ? notFound : empty}
          background={<Placeholder columns={columns} />}
        />
      ) : null}
      {!isLoading && !isEmpty && !isNotFound && (
        <>
          <TableWrapper>
            <Table {...props}>
              <Columns
                headers={headers}
                originalColumns={columns}
                sortBy={sortBy}
                setSortBy={setSortBy}
                isAllRowsSelected={isAllRowsSelected}
                toggleAllRowsSelected={toggleAllRowsSelected}
              />
              <Rows
                prepareRow={prepareRow}
                processRow={processRow}
                rows={page}
                originalColumns={columns}
                rowLink={rowLink}
                selectedRowIds={selectedRowIds}
                toggleRowSelected={toggleRowSelected}
              />
            </Table>
            {ExportComponent ? (
              <ExportComponent getData={getData} data={data} />
            ) : null}
          </TableWrapper>
          <PaginationContainer>
            <Pagination
              current={pageIndex}
              pages={tableStateManager?.pageCount ?? pageCount}
              hasNext={canNextPage}
              hasLast={
                (tableStateManager?.pageCount ?? pageCount) - 1 > pageIndex
              }
              hasPrevious={canPreviousPage}
              goToPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </PaginationContainer>
        </>
      )}
    </>
  );
};

export default ResponsiveTable;
