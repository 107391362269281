import { useHistory } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import {
  getPlanUpdateDeltas,
  PlanUpdateDeltas,
} from '../../../utils/checkout/planUpdateDeltas';
import { useCheckoutContext } from '../../../data/context/CheckoutContext';
import {
  useComputeStripePaymentInfo,
  useUpdateWorkspaceSubscription,
  useWorkspaceActiveSubscription,
} from '../../../data/hooks/useWorkspaceBilling';
import { mapStripePlanToUpsertPlan } from '../../../utils/checkout/mappers';
import {
  BillingWorkspacePlan,
  UpdateWorkspaceSubscriptionUserPlan,
} from '../../../model/workspaceBilling';
import { convertTableUsersToUpsertPlan } from '../../../utils/checkout';
import { CheckoutUser } from '../../../model/checkout';
import { useSummaryPaymentInfo } from './hooks/useSummaryPaymentInfo';
import { SummaryTitleType } from './types';
import { usePrices } from '../../../data/hooks/usePaymentPlans';
import {
  FROM,
  FROM_INTERVAL,
  NEW_GMAIL_USERS,
  NEW_WORKSPACE_USERS,
  TO,
  TO_INTERVAL,
} from '../../../data/queryParams';

const getNewFreeUsers = (users: CheckoutUser[]) =>
  users.filter(
    (user) => !user.toBeRemoved && !user.isPaid && user.plan !== 'FREE',
  );

const createSuccessLink = (deltas: PlanUpdateDeltas) => {
  const queryParams = new URLSearchParams('');
  if (deltas.plan) {
    queryParams.set(FROM, deltas.plan.from);
    queryParams.set(TO, deltas.plan.to);
  }

  if (deltas.gmailPaidUsers) {
    queryParams.set(
      NEW_GMAIL_USERS,
      (deltas.gmailPaidUsers.to - deltas.gmailPaidUsers.from).toString(),
    );
  }

  if (deltas.paidUsers) {
    queryParams.set(
      NEW_WORKSPACE_USERS,
      (deltas.paidUsers.to - deltas.paidUsers.from).toString(),
    );
  }

  if (deltas.billingCycle) {
    queryParams.set(FROM_INTERVAL, deltas.billingCycle.from);
    queryParams.set(TO_INTERVAL, deltas.billingCycle.to);
  }

  return queryParams.toString();
};

export const useConnect = () => {
  const { push, goBack, replace } = useHistory();
  const {
    id,
    urlPrefix,
    details: workspaceDetails,
    isLoading: isLoadingWorkspace,
  } = useWorkspaceContext();
  const checkoutContext = useCheckoutContext();
  const {
    data: activeSubscription,
    isLoading: isLoadingSubscription,
  } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });
  const { isLoading: isLoadingPrices, prices } = usePrices();

  const changedBillingInterval =
    checkoutContext.billingInterval !== activeSubscription?.billingInterval;
  const changedPlan =
    checkoutContext.plan !== activeSubscription?.workspacePlan;

  const {
    userUpdates,
    workspacePlan,
    billingInterval,
    purchaseType,
    country,
  } = useSummaryPaymentInfo({
    checkoutContext,
    activeSubscription,
  });

  const {
    isLoading: isLoadingPaymentInfo,
    data: paymentInfo,
  } = useComputeStripePaymentInfo(
    // disable while loading, or if no active subscription
    // disable also when changedBillingInterval && changedPlan, to prevent useless calls (will fail)
    checkoutContext.isLoading ||
      isLoadingSubscription ||
      (changedBillingInterval && changedPlan)
      ? undefined
      : activeSubscription?.workspaceId,
    {
      country,
      billingInterval: changedBillingInterval ? billingInterval : undefined,
      purchaseType,
      workspacePlan: changedPlan ? workspacePlan : undefined,
      userUpdates: changedBillingInterval ? undefined : userUpdates,
      upgradeLegacySubscription: activeSubscription?.workspacePlan === 'LEGACY',
    },
    true,
  );

  const {
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    mutateAsync: updateWorkspaceSubscription,
    isError: isUpdateError,
  } = useUpdateWorkspaceSubscription();

  const isLoading =
    isLoadingWorkspace ||
    isLoadingSubscription ||
    checkoutContext.isLoading ||
    isLoadingPaymentInfo ||
    isLoadingPrices;

  const deltas = useMemo<PlanUpdateDeltas | undefined>(() => {
    if (checkoutContext && activeSubscription && paymentInfo && prices) {
      return getPlanUpdateDeltas({
        paymentInfo,
        prices,
        currentSubscription: activeSubscription,
        plan: workspacePlan || activeSubscription.workspacePlan,
        users: checkoutContext.users,
      });
    }
    return undefined;
  }, [checkoutContext, activeSubscription, paymentInfo]);

  const onConfirm = async () => {
    const currentPlan: BillingWorkspacePlan | undefined =
      workspacePlan || activeSubscription?.workspacePlan;

    if (id && activeSubscription && paymentInfo && currentPlan && deltas) {
      const successLink = createSuccessLink(deltas);
      const newFreeUsers = getNewFreeUsers(checkoutContext.users);
      const hasToUpsertPlans = deltas.gmailPaidUsers || deltas.paidUsers;
      let plans: UpdateWorkspaceSubscriptionUserPlan[] = [];
      if (hasToUpsertPlans && paymentInfo.stripePlans) {
        plans = paymentInfo.stripePlans.map(mapStripePlanToUpsertPlan);
      }

      if (newFreeUsers.length > 0) {
        plans.push(convertTableUsersToUpsertPlan(newFreeUsers, true, null));
      }

      await updateWorkspaceSubscription({
        workspaceId: id,
        subscriptionId: activeSubscription.subscriptionId,
        workspaceUserPlans: plans,
        workspacePlan: deltas.plan && workspacePlan,
        billingInterval: deltas.billingCycle && billingInterval,
      });
      push(`${urlPrefix}/space/billing?${successLink}&checkout=success`);
    }
  };

  const titleType: SummaryTitleType = ((changes) => {
    if (changes) {
      if (changes.billingCycle != null) return 'CYCLE_CHANGE';
      if (changes.plan) {
        return changes.plan.from === 'PREMIUM' ? 'DOWNGRADE' : 'UPGRADE';
      }
      if (changes.basePrice) {
        return changes.basePrice.from > changes.basePrice.to
          ? 'DOWNGRADE'
          : 'UPGRADE';
      }
    }
    return 'UPGRADE';
  })(deltas);

  useEffect(() => {
    if (deltas) {
      if (
        !deltas.plan &&
        !deltas.billingCycle &&
        !deltas.paidUsers &&
        !deltas.gmailPaidUsers
      ) {
        // in case no changes, redirect to the checkout
        replace(`${urlPrefix}/checkout`);
      }
    }
  }, [deltas, urlPrefix]);

  return {
    isLoading,
    isUpdating,
    isUpdateSuccess,
    workspaceDetails,
    deltas,
    activeSubscription,
    paymentInfo,
    titleType,
    isUpdateError,
    flowType: checkoutContext.flowType,
    onBack: goBack,
    onConfirm,
  };
};
