import { queryClient } from 'data/queryClient';
import { getTeamMembers, getTeamReport } from 'data/requests/team';
import { InputParams } from 'model/parameters';
import { TeamMember, TeamMemberParams, TeamReport } from 'model/team';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { createSort, createFilter } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';

export const useTeamMembers = (
  workspaceId?: string,
  params?: TeamMemberParams,
) => {
  const { isEnabled, queryParams } = useMemo(
    () => ({
      isEnabled: !!workspaceId && !!params?.limit,
      queryParams: {
        filterBy: createFilter({
          memberStatus: ['ACTIVE', 'LEGACY'],
          ...(params?.filters ?? {}),
        }),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [workspaceId, params],
  );

  const { data, ...rest } = useQuery<TeamMember[]>(
    ['team', workspaceId, 'members', queryParams],
    () => getTeamMembers(workspaceId!, queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );

  const fetchAll = useCallback(async () => {
    const predicates = ['team', workspaceId, 'members', { limit: 30000 }];
    return queryClient.fetchQuery(
      predicates,
      () =>
        getTeamMembers(predicates[1] as string, predicates[2] as InputParams),
      {
        staleTime: Infinity,
      },
    );
  }, [queryClient, workspaceId]);

  return { data: useDefaultArray<TeamMember>(data), ...rest, fetchAll };
};

export const useTeamReport = (workspaceId?: string) => {
  return useQuery<TeamReport>(
    ['team', workspaceId, 'report'],
    () => getTeamReport(workspaceId!),
    {
      enabled: !!workspaceId,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );
};
