import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { decodeBase64UUID } from 'utils/uid';
import { queryClient } from '../../data/queryClient';
import { UserInput } from '../../model/user';

export const PATH = '/:workspaceId/:workspaceSlug';

function getWorkspace(id: string) {
  const userData = queryClient.getQueryData<UserInput>(['me']);
  return userData?.userWorkspaces?.find((s) => s.workspaceId === id);
}

export default function useWorkspaceLocation() {
  const root = useRouteMatch('*');
  const { otherWorkspaces, user } = useWorkspaceContext();
  const workspace = useRouteMatch<{
    workspaceId: string;
    workspaceSlug: string;
  }>(PATH);

  return useMemo(() => {
    const ready = otherWorkspaces.length > 0;
    const workspaceId = decodeBase64UUID(workspace?.params.workspaceId);
    const prefixed = workspaceId && getWorkspace(workspaceId) != null;
    const subpath =
      root && workspaceId
        ? root.url.replaceAll(workspace!.url ?? '', '')
        : root?.url ?? '';
    const rules = {};

    return {
      ready,
      url: prefixed ? workspace!.url : '',
      pathname: subpath === '/' ? '' : subpath,
      workspaceId: prefixed ? workspaceId : undefined,
      rules: rules as Record<string, boolean>,
    };
  }, [root, workspace, otherWorkspaces, user]);
}
