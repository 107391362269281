import { useReducer } from 'react';
import { UserAlertsProps } from './types';

export const useUserAlerts = () => {
  const [alerts, setAlerts] = useReducer(
    (_state: UserAlertsProps, action: Partial<UserAlertsProps>) =>
      ({
        ...action,
      } as UserAlertsProps),
    {} as UserAlertsProps,
  );

  return {
    alerts,
    setAlerts,
  };
};
