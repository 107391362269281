export const pushEcommerceData = (data: any) =>
  dataLayer.push({
    ...data,
    event: 'ecommerce_data_ready',
  });

export const pushSignUp = () =>
  dataLayer.push({
    event: 'signup',
  });

export const pushGoToMarketplace = () =>
  dataLayer.push({
    event: 'go_to_marketplace',
  });

export const pushFunnel = (stepName: string, stepIndex: string) =>
  dataLayer.push({
    event: 'funnel',
    'step-name': stepName,
    'step-index': stepIndex,
  });
