import styled from 'styled-components';
import { FormGroup } from '@scriptaddicts/yamm-ui-components';

export const StyledFromGroup = styled(FormGroup)`
  input {
    background-color: #f9f7f6;
  }
`;

export const OptionalLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  align-items: center;
  letter-spacing: -0.0066em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => props.theme.colors.gray400};
`;
