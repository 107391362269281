import styled from 'styled-components';
import { from } from 'styles/media';
import { ReactComponent as ExternalIcon } from 'assets/images/icons/external-link.svg';
import {
  ButtonLink as DefaultButtonLink,
  ProgressBar as DefaultProgressBar,
  TableCellValueGroup as DefaultTableCellValueGroup,
  Tabs as DefaultTabs,
} from '@scriptaddicts/yamm-ui-components';
import { MainContainerHeader as DefaultMainContainerHeader } from 'components/MainContainer';

export const MainContainerHeader = styled(DefaultMainContainerHeader)`
  ${from.tablet} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const NewButton = styled(DefaultButtonLink).attrs({
  icon: 'send',
})`
  margin-left: auto;
`;

export const Tabs = styled(DefaultTabs)`
  button:first-child {
    margin-left: 24px;
    ${from.tablet} {
      margin-left: 0px;
    }
  }
`;

export const ProgressContainer = styled(DefaultTableCellValueGroup)`
  gap: 12px !important;
`;

export const Progress = styled(DefaultProgressBar)`
  width: 40px;
  height: 2px;
  border-radius: 1px;
`;

export const SheetLink = styled.a`
  display: inline-flex;
`;

export const SheetIcon = styled(ExternalIcon)`
  --size: 1rem;
  width: var(--size);
  height: var(--size);
  color: ${({ theme }) => theme.colors.gray4};
`;
