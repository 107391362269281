import React from 'react';
import { Alert, Text } from '@scriptaddicts/yamm-ui-components';

export const AddedUsersSuccessAlert = ({ count }: { count: number }) => (
  <Alert type="success" style={{ marginTop: '16px' }}>
    <Text width="semiBold" size="large">
      {count === 1 && 'The user has been added successfully.'}
      {count > 1 && `${count} users have been added successfully`}
    </Text>
  </Alert>
);
