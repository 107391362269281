import React from 'react';

import StaticLayout from 'containers/Common/StaticLayout';
import { Prompt } from 'components/Prompt';
import { Link } from '@scriptaddicts/yamm-ui-components';

const Success = () => {
  return (
    <StaticLayout>
      <Prompt
        variant="success"
        title="Autorenewal stopped successfully"
        description={
          <>
            Thanks for using YAMM. You can always check your payments from{' '}
            <Link
              href="https://www.paypal.com/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              PayPal
            </Link>
            .
          </>
        }
      />
    </StaticLayout>
  );
};

export default Success;
