import useTableStateManager from 'components/ResponsiveTable/useTableStateManager';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useTeamMembers, useTeamReport } from 'data/hooks/useTeam';
import { TeamMemberParams } from 'model/team';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { from, useMediaQuery } from 'styles/media';
import { getColumns } from './table/columns';

export const useConnect = () => {
  const { id: workspaceId, user, details } = useWorkspaceContext();
  const { replace } = useHistory();
  const { data: report } = useTeamReport(workspaceId);

  const isMobile = !useMediaQuery(from.tablet);
  const columns = useMemo(() => getColumns({ isMobile }), [isMobile]);

  const isNotAuthorized = user && !user?.features.canAccessTeamReport;
  if (isNotAuthorized) {
    replace('/');
  }

  const legacy = useMemo(
    () =>
      details?.plan === 'LEGACY' ? { domains: details.domains } : undefined,
    [details, report],
  );
  const workspace = useMemo(
    () => (details?.plan !== 'LEGACY' ? { name: details?.name } : undefined),
    [details],
  );

  const {
    params,
    manager: tableStateManager,
  } = useTableStateManager<TeamMemberParams>({
    paginationKey: workspaceId,
    globalFilter: 'userName',
    sortBy: {
      name: 'userName',
      // lastTimeUsed: 'createTimestamp',
      'counters.totalEmailsSent': 'emailSentAllTime',
      'counters.totalEmailsSentThisMonth': 'emailSentThisMonth',
    },
  });

  const { data: users, fetchAll: getUsers, status } = useTeamMembers(
    workspaceId,
    params,
  );

  return {
    isLoading: status === 'loading',
    isNotAuthorized,
    legacy,
    workspace,
    report,
    users,
    getUsers,
    columns,
    tableStateManager,
  };
};
