import { queryClient } from 'data/queryClient';
import {
  getCampaignDetail,
  getCampaignPoll,
  getCampaigns,
} from 'data/requests/campaign';
import { Campaign, CampaignParams } from 'model/campaign';
import { InputParams } from 'model/parameters';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { createFilter, createSort } from 'utils/parameters';
import useDefaultArray from 'utils/useDefaultArray';

export const useCampaigns = (params?: CampaignParams) => {
  const { isEnabled, queryParams } = useMemo(
    () => ({
      isEnabled: !!params?.limit,
      queryParams: {
        filterBy: createFilter({
          ...(params?.filters ?? {}),
        }),
        sortBy: createSort(params?.sort),
        limit: params?.limit,
        limitOffset: params?.limitOffset,
      },
    }),
    [params],
  );

  const { data, ...rest } = useQuery(
    ['campaign', 'list', queryParams],
    () => getCampaigns(queryParams),
    {
      enabled: isEnabled,
      refetchOnMount: false,
      staleTime: 1000,
    },
  );

  const fetchAll = useCallback(async () => {
    const predicates = [
      'campaign',
      'list',
      {
        limit: 30000,
      },
    ];
    return queryClient.fetchQuery(
      predicates,
      () => getCampaigns(predicates[2] as InputParams),
      {
        staleTime: Infinity,
      },
    );
  }, [queryClient]);

  return { data: useDefaultArray<Campaign>(data), ...rest, fetchAll };
};

export const useCampaignDetail = (userId?: string, campaignId?: string) => {
  return useQuery(
    ['campaign', 'detail', userId, campaignId],
    () => getCampaignDetail(campaignId!),
    {
      enabled: !!userId && !!campaignId,
      refetchInterval: 1000 * 30,
    },
  );
};

export const useCampaignPoll = (
  userId?: string,
  mergeSourceSheetKey?: string,
) => {
  return useQuery(
    ['campaign', 'poll', userId, mergeSourceSheetKey],
    () => getCampaignPoll(mergeSourceSheetKey!),
    {
      enabled: !!userId && !!mergeSourceSheetKey,
    },
  );
};
