import { Button, Link, Strong, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { Alert } from './styles';
import {
  TCreateAlert,
  TDismissible,
  TErrorAlert,
  TJoinAlert,
  TJoinErrorAlert,
  TRemoveAlert,
  TTransferAlert,
} from './types';
import { Links } from '../../../../data/links';

export const LegacyAlert = ({ onDismiss }: TDismissible) => (
  <Alert
    type="warning"
    append={
      <Button
        aria-label="close"
        icon="x"
        variant="tertiary"
        onClick={onDismiss}
      />
    }
  >
    <Text type="paragraph" size="large" width="semiBold">
      Your space is currently on a legacy plan.
    </Text>
    <Text type="paragraph" size="base" width="regular">
      <Link href={Links.CONTACT_US} target="_blank">
        Learn more
      </Link>
    </Text>
  </Alert>
);

export const CreateAlert = ({ name }: TCreateAlert) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      {name} has been successfully created.
    </Text>
  </Alert>
);

export const RemoveAlert = ({ name, current }: TRemoveAlert) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      {name} has been successfully deleted.
    </Text>
    <Text type="paragraph" size="base" width="regular">
      You have been automatically switched to{' '}
      <Text type="span" size="base" width="semiBold">
        {current}.
      </Text>
    </Text>
  </Alert>
);

export const TransferAlert = ({ name, email }: TTransferAlert) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      {name} ownership has been transferred to {email}{' '}
    </Text>
    <Text type="paragraph" size="base" width="regular">
      You are still part of <Strong>{name}</Strong> as a regular user.
    </Text>
  </Alert>
);

export const JoinSuccessAlert = (join: TJoinAlert) => (
  <Alert type="success">
    {join.new ? (
      <>
        <Text type="paragraph" size="large" width="semiBold">
          Welcome to YAMM!
        </Text>
        <Text type="paragraph" size="base" width="regular">
          You have successfully joined <Strong>{join.name}</Strong>.
        </Text>
      </>
    ) : (
      <Text type="paragraph" size="base" width="regular">
        You have successfully joined <Strong>{join.name}</Strong>
        {join.isActive
          ? ', and you have been automatically switched to it.'
          : '.'}
      </Text>
    )}
  </Alert>
);

export const JoinErrorAlert = (join: TJoinErrorAlert) => (
  <Alert
    type="error"
    append={
      <Button
        aria-label="close"
        icon="x"
        variant="tertiary"
        onClick={join.onDismiss}
      />
    }
  >
    <Text type="paragraph" size="large" width="semiBold">
      The invitation could not be finalized.
    </Text>
    <Text type="paragraph" size="base" width="regular">
      {join.error.code === 404 && 'The invite link is invalid.'}
      {join.error.code === 403 &&
        'You are logged into a different account than the one invited.'}
      {join.error.code === 409 &&
        join.error.errors.length > 0 &&
        join.error.errors[0].reason === 'invitation_is_not_active' &&
        'The invite link has expired or been cancelled.'}
      {join.error.code === 409 &&
        join.error.errors.length > 0 &&
        join.error.errors[0].reason === 'user_already_exists' &&
        'You are already a member of this space.'}
    </Text>
  </Alert>
);

export const ErrorAlert = (error: TErrorAlert) => (
  <Alert
    type="error"
    append={
      <Button
        aria-label="close"
        icon="x"
        variant="tertiary"
        onClick={error.onDismiss}
      />
    }
  >
    <Text type="paragraph" size="large" width="semiBold">
      Something went wrong.
    </Text>
    <Text type="paragraph" size="base" width="regular">
      Please try again later.
    </Text>
  </Alert>
);
