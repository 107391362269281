import { PaymentPlan, PlanPrice } from '../../model/paymentPlans';

export const isProGmail = (plan: PaymentPlan) =>
  plan.userPlan === 'PRO_PERSONAL' && plan.workspacePlan === 'PRO';

export const isProWorkspace = (plan: PaymentPlan) =>
  plan.userPlan === 'PRO' && plan.workspacePlan === 'PRO';

export const isPremiumGmail = (plan: PaymentPlan) =>
  plan.userPlan === 'PRO_PERSONAL' && plan.workspacePlan === 'PREMIUM';

export const isPremiumWorkspace = (plan: PaymentPlan) =>
  plan.userPlan === 'PRO' && plan.workspacePlan === 'PREMIUM';

const getIntervalProperty = (plan: PaymentPlan): 'month' | 'year' =>
  plan.billingInterval === 'MONTHLY' ? 'month' : 'year';

const getUnitPrice = (paymentPlan: PaymentPlan) =>
  paymentPlan.paymentPlanTiers[0].unitAmount;

export type Prices = {
  gmail: {
    pro: PlanPrice;
    premium: PlanPrice;
  };
  workspace: {
    pro: PlanPrice;
    premium: PlanPrice;
  };
};
export const getPrices = (plans: PaymentPlan[]): Prices => {
  const prices: Prices = {
    gmail: {
      pro: {
        year: 0,
        month: 0,
      },
      premium: {
        month: 0,
        year: 0,
      },
    },
    workspace: {
      pro: {
        year: 0,
        month: 0,
      },
      premium: {
        month: 0,
        year: 0,
      },
    },
  };

  for (let i = 0; i < plans.length; i += 1) {
    const plan = plans[i];
    if (plan.status === 'ACTIVE' && plan.currency === 'USD') {
      if (isPremiumGmail(plan)) {
        // PREMIUM GMAIL
        prices.gmail.premium[getIntervalProperty(plan)] = getUnitPrice(plan);
      } else if (isPremiumWorkspace(plan)) {
        // PREMIUM WORKSPACE
        prices.workspace.premium[getIntervalProperty(plan)] = getUnitPrice(
          plan,
        );
      } else if (isProGmail(plan)) {
        // PRO GMAIL
        prices.gmail.pro[getIntervalProperty(plan)] = getUnitPrice(plan);
      } else if (isProWorkspace(plan)) {
        // PRO WORKSPACE
        prices.workspace.pro[getIntervalProperty(plan)] = getUnitPrice(plan);
      }
    }
  }

  return prices;
};
