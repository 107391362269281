import { WorkspacePlan } from 'model/workspace';
import { useMemo } from 'react';
import { useValidatePromoteMembers } from '../../../../data/hooks/useWorkspaceMembers';
import { RolloutRestrictions } from '../../../../utils/rollout';

export const useUserRestrictions = ({
  workspaceId,
  workspacePlan,
  rolloutRestrictions,
}: {
  workspaceId?: string;
  workspacePlan?: WorkspacePlan;
  rolloutRestrictions?: RolloutRestrictions;
}) => {
  const {
    data: promoteRestrictedMembers,
    isLoading: isLoadingPromoteRestrictedMembers,
  } = useValidatePromoteMembers(
    rolloutRestrictions?.MULTIPLE_PAYMENT && workspacePlan !== 'LEGACY'
      ? workspaceId
      : undefined,
  );
  const userRestrictions = useMemo(
    () =>
      (promoteRestrictedMembers ?? []).reduce(
        (map, { userId, errorCode }) => ({
          ...map,
          [userId as string]: errorCode as string,
        }),
        {} as Record<string, string>,
      ),
    [promoteRestrictedMembers],
  );

  return {
    isLoadingPromoteRestrictedMembers,
    userRestrictions,
  };
};
