import { BillingCurrency } from '../../../../../../model/workspaceBilling';
import { WorkspacePlan } from '../../../../../../model/workspace';

export type BillingPlanInformation = {
  id: WorkspacePlan;
  name: string;
  description: string;
  currency: BillingCurrency;
  canInviteUsers: boolean;
  featuresTitle: string;
  features: string[];
  supportFeatures: string[];
};

export const BILLING_PLANS: Record<
  'free' | 'pro' | 'premium',
  BillingPlanInformation
> = {
  free: {
    id: 'FREE',
    name: 'Free',
    description: 'Easy mail merge tool',
    currency: 'USD',
    canInviteUsers: false,
    featuresTitle: 'Key features:',
    features: [
      'Mass send with Gmail',
      'Personalize emails',
      'Draft in Gmail',
      'Real-time tracking in Google Sheets',
      'Unsubscribe links',
      'Send from an alias',
      'No branded footer',
    ],
    supportFeatures: [],
  },
  pro: {
    id: 'PRO',
    name: 'Pro',
    description: 'Get more recipients quota and Pro features',
    // description:
    //   'For individuals or small teams who want more quota, better tracking and advanced fine-tuning.',
    currency: 'USD',
    canInviteUsers: false,
    featuresTitle: 'Everything in Free, plus:',
    features: [
      'Schedule send',
      // 'Send from an alias',
      // 'Configure a custom reply-to address',
      'Spread out large sends over a few days',
      // 'Workspace dashboard',
      'User roles management',
      'Manage your bounce list',
    ],
    supportFeatures: ['Priority support'],
  },
  premium: {
    id: 'PREMIUM',
    name: 'Premium',
    description:
      'For teams of all sizes who want to send, track, and manage collaboratively.',
    currency: 'USD',
    canInviteUsers: true,
    featuresTitle: 'Everything in Pro, plus:',
    features: [
      'Shared workspace unsubscribes ',
      'Shared workspace bounces list',
    ],
    supportFeatures: ['Priority support', 'Video-call support'],
  },
};

export const getBillingPlan = (
  type: 'free' | 'pro' | 'premium',
  restrictions?: {
    isUsersPageRestricted: boolean;
    isTransferWorkspaceRestricted: boolean;
    isWorkspaceDashboardRestricted: boolean;
  },
): BillingPlanInformation => {
  const plan = JSON.parse(
    JSON.stringify(BILLING_PLANS[type]),
  ) as BillingPlanInformation;

  const restrictedFeatures = [] as string[];
  if (restrictions?.isUsersPageRestricted) {
    restrictedFeatures.push('User roles management');
  }
  if (restrictions?.isTransferWorkspaceRestricted) {
    restrictedFeatures.push('Account transfer');
  }
  if (restrictions?.isWorkspaceDashboardRestricted) {
    restrictedFeatures.push('Workspace dashboard');
  }
  plan.features = plan.features.filter(
    (feature) => !restrictedFeatures.includes(feature),
  );

  return plan;
};
