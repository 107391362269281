import { getSuggestUser, postUserOnActivation } from 'data/requests/users';
import { SuggestUser, SuggestUserParams } from 'model/user';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import useDefaultArray from 'utils/useDefaultArray';

export const useCreateUser = () => {
  return useMutation(postUserOnActivation);
};

export const useSuggestUser = (
  params: SuggestUserParams,
  enabled?: boolean,
) => {
  const [prevParams, setPrevParams] = useState<SuggestUserParams>(params);

  const keepPreviousData = useMemo(() => {
    if (params.workspaceId !== prevParams.workspaceId) return false;
    if (!params.searchKeyword || !prevParams.searchKeyword) return false;

    return params.searchKeyword?.startsWith(prevParams.searchKeyword);
  }, [prevParams, params]);

  const { data, ...rest } = useQuery(
    ['users', 'suggestion', params],
    () => getSuggestUser(params).catch(() => [] as SuggestUser[]),
    {
      enabled: !!enabled && !!params.workspaceId && !!params.searchKeyword,
      refetchOnMount: false,
      staleTime: 1000,
      keepPreviousData,
      onSuccess: () => setPrevParams(params),
    },
  );

  return { data: useDefaultArray<SuggestUser>(data), ...rest };
};
