export default {
  fonts: {
    inter: 'Inter',
    lexendDeca: 'Lexend Deca',
    roboto: 'Roboto',
  },
  colors: {
    // Gray scale
    white: '#ffffff',
    gray1: '#F9F7F5',
    gray2: '#DEDCDB',
    gray3: '#55504E',
    gray4: '#242221',
    black: '#000000',
    gray50: '#f9f7f6',
    gray100: '#e5e3e0',
    gray200: '#d7d5d1',
    gray300: '#c8c4be',
    gray400: '#b7b1ab',
    gray500: '#a49e97',
    gray600: '#8f8882',
    gray700: '#77716c',
    gray800: '#474340',
    gray900: '#3f3b38',

    // Primary
    orange50: '#FFF6E9',
    orange100: '#FFE3B8',
    orange200: '#FFCE80',
    orange300: '#F9AB3D',
    orange400: '#E5941F',
    orange500: '#BD7204',
    orange600: '#AD6A09',
    orange700: '#9F5F03',
    orange800: '#824D02',
    orange900: '#673E03',

    orangeRed400: '#F0703A',
    orangeRed500: '#F05B3A',

    // Secondary
    blue50: '#e2f1fe',
    blue100: '#c5e2fe',
    blue200: '#8dc5fa',
    blue300: '#5babf5',
    blue400: '#3393ee',
    blue500: '#0075e0',
    blue600: '#006ed6',
    blue700: '#0065c5',
    blue800: '#005aaf',
    blue900: '#004e98',
    blue950: '#094075',
    blue1000: '#0d375f',

    // Success
    green50: '#f1fbec',
    green100: '#c8ebb9',
    green200: '#a3e087',
    green300: '#7bcf55',
    green400: '#5db932',
    green500: '#319800',
    green600: '#31830b',
    green700: '#2a7806',
    green800: '#226304',
    green900: '#144000',

    // Warning
    yellow50: '#FDF7E3',
    yellow100: '#FAEAB7',
    yellow400: '#F2C641',
    yellow700: '#EE9E2A',

    // Error
    redLight: '#FFE8E8', // aka gray0
    redDark: '#EB3E3E',
    red50: '#ffeded',
    red100: '#ffcece',
    red200: '#ffb7b7',
    red300: '#fe8888',
    red400: '#f75f5f',
    red500: '#eb3e3e',
    red600: '#d42626',
    red700: '#b61515',
    red800: '#910b0b',
    red900: '#670505',

    // Palette
    purple: '#8876EF',
    blue: '#3DA3EA',
    green: '#60C076',
    yellow: '#F9CF58',
    orange: '#F19648',
    red: '#E5586F',
    stripedGray:
      'repeating-linear-gradient(45deg,#fff,#fff 2px,#EEEEEE 2px,#EEEEEE 5px)',

    // Google button
    googleButton: '#4285F4',

    suvaGrey: '#767574',
    whisper: '#f9f7f6',
    cowboy: '#3f3c3c',
  },
  weights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
