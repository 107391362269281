import React from 'react';
import { CHECKOUT_RESULT, useConnect } from './connect';
import {
  LegacyPlanUpgradeSuccess,
  SubscriptionStarted,
  SubscriptionUpdated,
} from './alerts';

const CheckoutResultAlerts = () => {
  const checkoutResult = useConnect();

  return (
    <>
      {checkoutResult === CHECKOUT_RESULT.LEGACY_UPGRADE && (
        <LegacyPlanUpgradeSuccess />
      )}

      {checkoutResult === CHECKOUT_RESULT.NEW_PLAN_PURCHASE && (
        <>
          <SubscriptionStarted />
        </>
      )}

      {(checkoutResult === CHECKOUT_RESULT.PLAN_CHANGED ||
        checkoutResult === CHECKOUT_RESULT.BILLING_INTERVAL_CHANGE) && (
        <SubscriptionUpdated />
      )}

      {(checkoutResult === CHECKOUT_RESULT.GMAIL_USERS_UPDATED ||
        checkoutResult === CHECKOUT_RESULT.WORKSPACE_USERS_UPDATED) && (
        <SubscriptionUpdated />
      )}
    </>
  );
};

export default CheckoutResultAlerts;
