import {
  BillingInterval,
  BillingWorkspacePlan,
  ComputeStripePaymentInfo,
  UpdateWorkspaceSubscriptionUserPlan,
  WorkspaceSubscription,
} from '../../model/workspaceBilling';
import { getUserIdFromEmail, isGmailAddress } from '../userUtils';
import { CheckoutUser } from '../../model/checkout';
import { WorkspaceDetails } from '../../data/context/WorkspaceContext';
import { getSubscriptionConsole } from '../workspace';
import { WorkspaceLegacyPlanType } from '../../model/workspace';

export const getAvailablePlanForEmail = (
  userEmail: string,
): BillingWorkspacePlan => {
  if (isGmailAddress(userEmail)) {
    return 'PRO_PERSONAL';
  }
  return 'PRO';
};

export const getPaidUsers = (users: CheckoutUser[]) =>
  users.filter((user) => user.isPaid && !user.toBeRemoved);

export const getNotRemovedUsers = (users: CheckoutUser[]) =>
  users.filter((user) => !user.toBeRemoved);

export type FlowType =
  | 'UPGRADE'
  | 'PURCHASE'
  | 'DOWNGRADE'
  | 'UPGRADE_LEGACY_US'
  | 'UPGRADE_LEGACY_BE'
  | 'UPGRADE_LEGACY_US_INDIVIDUAL'
  | 'UPGRADE_LEGACY_BE_INDIVIDUAL';

export const getCheckoutFlowType = (
  newPlan: BillingWorkspacePlan,
  workspaceDetails: WorkspaceDetails,
  activeSubscription?: WorkspaceSubscription,
): FlowType => {
  if (!activeSubscription || activeSubscription.workspacePlan === 'FREE') {
    return 'PURCHASE';
  }

  if (activeSubscription.workspacePlan === 'LEGACY') {
    const subscriptionConsole = getSubscriptionConsole(activeSubscription);
    if (workspaceDetails.legacyType === 'TEAM') {
      return subscriptionConsole === 'BE'
        ? 'UPGRADE_LEGACY_BE'
        : 'UPGRADE_LEGACY_US';
    }

    return subscriptionConsole === 'BE'
      ? 'UPGRADE_LEGACY_BE_INDIVIDUAL'
      : 'UPGRADE_LEGACY_US_INDIVIDUAL';
  }

  if (
    activeSubscription.workspacePlan === 'PREMIUM' &&
    (newPlan === 'PRO' || newPlan === 'FREE')
  ) {
    return 'DOWNGRADE';
  }

  return 'UPGRADE';
};

export const getPaidToday = (paymentInfo: ComputeStripePaymentInfo) => {
  return paymentInfo.totalAmount;
};

export const getSubscriptionBasePrice = (
  paymentInfo: ComputeStripePaymentInfo,
) => paymentInfo.totalAmount;

export const getSubscriptionPriceWithTax = (
  paymentInfo: ComputeStripePaymentInfo,
) => {
  let basePrice = getSubscriptionBasePrice(paymentInfo);
  if (paymentInfo.taxRate > 0) {
    basePrice += (basePrice * paymentInfo.taxRate) / 100;
  }

  return basePrice;
};

export const getChargedToday = (paymentInfo: ComputeStripePaymentInfo) =>
  paymentInfo.chargedToday;

const BillingIntervalsLabels = {
  noun: {
    month: 'month',
    year: 'year',
  },
  ly: {
    month: 'monthly',
    year: 'annually',
  },
};
export const getBillingIntervalLabel = (
  billingInterval: BillingInterval,
  ly?: boolean,
) => {
  const key = billingInterval === 'MONTHLY' ? 'month' : 'year';
  return BillingIntervalsLabels[ly ? 'ly' : 'noun'][key];
};

export const convertTableUsersToUpsertPlan = (
  users: CheckoutUser[],
  downgradeToFree: boolean,
  stripePriceId: string | null,
): UpdateWorkspaceSubscriptionUserPlan => ({
  stripePriceId: stripePriceId || undefined,
  userIds: users.map((user) => getUserIdFromEmail(user.email)),
  downgradeToFree,
});

export const hasPaidUsersFromExternalDomain = (
  users: CheckoutUser[],
): boolean => {
  return users.find((user) => !user.isUserInDomain && user.isPaid) != null;
};

const PlanLabels: { [key in BillingWorkspacePlan]: string } = {
  FREE: 'Free',
  PREMIUM: 'Premium',
  LEGACY: 'Legacy',
  PRO: 'Pro',
  OFFLINE: 'Offline',
  PRO_PERSONAL: 'Pro (Personal)',
};

const LegacyPlanLabels: { [key in WorkspaceLegacyPlanType]: string } = {
  PERSONAL: 'Personal',
  PROFESSIONAL: 'Professional',
  TEAM: 'Team',
  UNLIMITED: 'Unlimited',
};
export const getPlanLabel = (
  plan: BillingWorkspacePlan,
  legacyPlanType?: WorkspaceLegacyPlanType,
) => {
  if (plan === 'LEGACY' && legacyPlanType) {
    return `${LegacyPlanLabels[legacyPlanType]} (legacy)`;
  }

  return PlanLabels[plan];
};
