import { AxiosError } from 'axios';
import { queryClient } from 'data/queryClient';
import {
  deleteWorkspace,
  getWorkspace,
  postWorkspace,
  putWorkspace,
} from 'data/requests/workspace';
import { transformUserToWorkspace } from 'data/transform/workspace';
import {
  optimisticUpdateRemoveWorkspace,
  optimisticUpdateWorkspace,
} from 'data/updates/workspace';
import { BackendError } from 'model/error';
import { NewWorkspaceInput, UpdateWorkspaceParams } from 'model/workspace';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { UserInputResponse } from '../../model/user';
// eslint-disable-next-line import/no-cycle
import { useWorkspaceContext } from '../context/WorkspaceContext';
import {
  CHECKOUT,
  FROM,
  TO,
  NEW_WORKSPACE_USERS,
  NEW_GMAIL_USERS,
  FROM_INTERVAL,
  TO_INTERVAL,
} from '../queryParams';

const WHITELIST_SEARCH_PARAMS = [
  CHECKOUT,
  FROM,
  TO,
  NEW_WORKSPACE_USERS,
  NEW_GMAIL_USERS,
  FROM_INTERVAL,
  TO_INTERVAL,
];

const whitelistQueryParams = (search: string): string => {
  const searchParams = new URLSearchParams(search);
  WHITELIST_SEARCH_PARAMS.forEach((q) => searchParams.delete(q));
  return searchParams.toString();
};

export const useWorkspace = (workspaceId?: string, disabled?: boolean) => {
  const user = queryClient.getQueryData<UserInputResponse>(['me']);
  const placeholderData = useMemo(() => {
    if (!user?.userV2 || !workspaceId) return undefined;
    return transformUserToWorkspace(user.userV2, workspaceId);
  }, [workspaceId, user]);

  return useQuery(['space', workspaceId], () => getWorkspace(workspaceId!), {
    enabled: !!workspaceId && !disabled,
    refetchOnMount: false,
    placeholderData,
  });
};

export const useChangeActiveWorkspace = () => {
  const { setSelectedWorkspace } = useWorkspaceContext();
  const { replace, location } = useHistory();

  const changeWorkspace = (spaceId?: string) => {
    if (location.search) {
      replace({
        ...location,
        search: whitelistQueryParams(location.search),
      });
    }
    return setSelectedWorkspace(spaceId);
  };

  return {
    changeWorkspace,
  };
};

export const useCreateWorkspace = () => {
  const { changeWorkspace } = useChangeActiveWorkspace();

  return useMutation<
    NewWorkspaceInput,
    AxiosError<BackendError>,
    {
      workspaceName?: string;
      userId?: string;
    }
  >(
    async ({ workspaceName, userId }) => {
      if (!workspaceName) throw new Error('WorkspaceName is required');
      if (!userId) throw new Error('UserId is required');

      return postWorkspace({
        workspaceName,
        userId,
        memberInviteUser: true,
      });
    },
    {
      onSuccess: async (result) => {
        if (!result.workspaceId)
          return Promise.reject(new Error('Workspace id is missing'));

        return Promise.all([changeWorkspace(result.workspaceId)]);
      },
    },
  );
};

export const useRemoveWorkspace = () => {
  const { changeWorkspace } = useChangeActiveWorkspace();

  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      newWorkspaceId?: string;
    }
  >(
    async ({ workspaceId }) => {
      if (!workspaceId) throw new Error('WorkspaceID is required');
      return deleteWorkspace({
        workspaceId,
      });
    },
    {
      onSuccess: async (_result, { workspaceId, newWorkspaceId }) => {
        changeWorkspace(newWorkspaceId);
        optimisticUpdateRemoveWorkspace(workspaceId);
      },
    },
  );
};

export const useUpdateWorkspace = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: UpdateWorkspaceParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId) throw new Error('WorkspaceID is required');
      return putWorkspace(params);
    },
    {
      onSuccess: async (_result, { workspaceId, params }) =>
        optimisticUpdateWorkspace(workspaceId, params),
    },
  );
};
