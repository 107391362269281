import styled from 'styled-components';
import { Text, Button, Icon } from '@scriptaddicts/yamm-ui-components';

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.blue500};
  display: block;
  margin-bottom: 10px;
`;

export const StyledPrice = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 100%;
  letter-spacing: -0.015em;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const StyledAnnualPrice = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 165%;
  letter-spacing: -0.21px;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const StyledSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  margin: 26px 0;
`;

export const StyledStrike = styled.s`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StyledFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledFeatureTitle = styled(Text).attrs({
  type: 'paragraph',
})`
  color: ${({ theme }) => theme.colors.blue500};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
`;

export const StyledFeatureText = styled(Text).attrs({
  type: 'paragraph',
})`
  line-height: 18px !important;
`;

export const StyledUpgradeButton = styled(Button)`
  margin-top: 28px;
  margin-bottom: 30px;
`;

export const BilledAtLabel = styled(Text).attrs({
  type: 'paragraph',
})`
  color: ${({ theme }) => theme.colors.gray700};
  margin-top: 4px;
  b {
    font-weight: bold;
  }
`;

export const StyledInfo = styled(Icon).attrs({
  name: 'info',
})`
  margin-left: 6px;
  cursor: pointer;
`;

export const StyledUpToText = styled.span`
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
`;
