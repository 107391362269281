import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Props } from './types';
import {
  ClearButton,
  ClearIcon,
  Icon,
  Search,
  SearchBarContainer,
  SearchInput,
} from './styles';

const SearchBar = forwardRef<HTMLInputElement, Props>(
  ({ select, onChange, ...props }, ref) => {
    const [search, setSearch] = useState('');

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
      },
      [],
    );

    const clearSearch = useCallback(() => {
      setSearch('');
    }, []);

    useEffect(() => {
      if (onChange) {
        onChange(search);
      }
    }, [search, onChange]);

    const hasSearch = search.length > 0;

    return (
      <SearchBarContainer {...props}>
        <Search>
          <Icon />
          <SearchInput
            placeholder="Search..."
            type="search"
            onChange={handleChange}
            value={search}
            ref={ref}
            spellCheck={false}
          />
          {hasSearch && (
            <ClearButton onClick={clearSearch} data-testid="clear-search">
              <ClearIcon />
            </ClearButton>
          )}
        </Search>
        {select}
      </SearchBarContainer>
    );
  },
);

export default SearchBar;
