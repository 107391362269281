import React from 'react';
import testingVar from 'utils/testingVar';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerTitle,
  MainContainerSubtitle,
} from 'components/MainContainer';
import {
  Button,
  Strong,
  Text,
  Tooltip,
} from '@scriptaddicts/yamm-ui-components';
import ResponsiveTable from 'components/ResponsiveTable';
import { plurify } from 'utils/plurify';
import { Unsubscription } from 'model/unsubscription';
import { RouterLink } from 'components/Link';
import {
  Alert,
  AlertContainer,
  Info,
  ToolBar,
  UnsubscribeContainer,
  Upgrade,
  TagInput,
} from './styles';
import { useConnect } from './connect';
import Empty from './table/empty';
import Exporter from './table/exporter';
import { RemoveValidation } from './RemoveValidation';

const Unsubscriptions = () => {
  const {
    isMobile,
    description,
    unsubscribe,
    remove,
    columns,
    unsubscriptions,
    isLoading,
    tableStateManager,
    getUnsubscriptions,
    alert,
    dismissAlert,
  } = useConnect();

  return (
    <MainContainer
      data-test-id={testingVar('unsubscriptions')}
      title="Unsubscriptions"
    >
      <MainContainerHeader direction="vertical">
        <MainContainerTitle>Unsubscriptions</MainContainerTitle>
        <MainContainerSubtitle>
          {description.isSharedUnsubsRestricted ? (
            'The list of recipients who have unsubscribed from your emails.'
          ) : (
            <>
              {description.userSharedUnsubs
                ? 'The list of recipients who have unsubscribed from your emails.'
                : 'The list of recipients unsubscribed from all paid users merges in this space.'}
              &nbsp;
              <Tooltip
                tip={
                  description.userSharedUnsubs
                    ? 'This list is consolidated and shared with all paid users in this space.'
                    : 'This list is not shared with other users in your space.'
                }
                placement="right"
              >
                <Info />
              </Tooltip>
              {!description.userSharedUnsubs && (
                <>
                  {'\n'}
                  {description.isOwner ? (
                    <>
                      <Upgrade />
                      &nbsp;
                      <RouterLink to="/space/billing">
                        Upgrade to consolidate and share your unsubscribes
                        across your space
                      </RouterLink>
                    </>
                  ) : (
                    <>
                      {description.workspaceSharedUnsubs ? (
                        <>
                          Ask your space owner{' '}
                          <Strong>({description.ownerEmail})</Strong> to upgrade
                          you so you can share the same unsubscribe list as
                          other users in that space.
                        </>
                      ) : (
                        <>
                          Ask your space owner{' '}
                          <Strong>({description.ownerEmail})</Strong> to upgrade
                          so that unsubscribes from all users can be
                          consolidated and shared across this space.
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </MainContainerSubtitle>
      </MainContainerHeader>
      <ToolBar>
        <UnsubscribeContainer>
          <TagInput
            tagKeys={[' ', ',']}
            placeholder="Enter email addresses separated by commas"
            submitClassName="autoTagCatch"
            onSubmit={() => unsubscribe.setAutoUnsubscribe(true)}
            disabled={unsubscribe.isLoading}
            maxRow={6}
            {...unsubscribe.input}
          />
          <Button
            className="autoTagCatch"
            aria-label="Unsubscribe emails"
            variant="primary"
            onClick={unsubscribe.onClick}
            disabled={unsubscribe.disabled || unsubscribe.isLoading}
            loading={unsubscribe.isLoading}
          >
            Unsubscribe
          </Button>
        </UnsubscribeContainer>
        {!isMobile && (
          <Exporter data={unsubscriptions} getData={getUnsubscriptions} />
        )}
      </ToolBar>
      {!!alert && (
        <AlertContainer>
          <Alert
            type={alert?.status}
            append={
              <Button
                aria-label="dismiss"
                icon="x"
                variant="tertiary"
                size="small"
                onClick={dismissAlert}
              />
            }
          >
            <Text type="paragraph" size="base" width="regular">
              {alert.status === 'error' ? (
                'An error ocurred. Please try again.'
              ) : (
                <>
                  {alert.type === 'remove' &&
                    '1 unsubscribed email address successfully deleted.'}
                  {alert.type === 'add' && (
                    <>
                      {!alert.size
                        ? 'These email addresses were already unsubscribed.'
                        : `${alert.size} email ${plurify(
                            alert.size,
                            'address',
                            'addresses',
                          )} successfully unsubscribed.`}
                    </>
                  )}
                </>
              )}
            </Text>
          </Alert>
        </AlertContainer>
      )}

      <ResponsiveTable<Unsubscription>
        data={unsubscriptions}
        getData={getUnsubscriptions}
        loading={isLoading}
        columns={columns}
        empty={<Empty />}
        tableStateManager={tableStateManager}
        header="float"
        sortable
        float="right"
        disableFilter
      />
      <RemoveValidation {...remove} />
    </MainContainer>
  );
};

export default Unsubscriptions;
