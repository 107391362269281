import { useCheckoutContext } from 'data/context/CheckoutContext';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { readCheckoutSessionUsers } from 'utils/checkoutSessionUsers';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import { logClickEvent } from 'utils/userJourneyLogging';

const clickEventCheckoutActionTypes = [
  'go_to_stripe_checkout',
  'go_to_checkout_information',
  'go_to_checkout_summary',
  'go_to_checkout',
  'go_to_confirm_checkout',
];

function getEventTarget(
  element: HTMLElement | undefined,
  depth: number,
): HTMLElement | undefined {
  if (!element || depth === 0) {
    return undefined;
  }

  if (element.getAttribute('data-log-event')) {
    return element;
  }

  return getEventTarget(element.parentElement ?? undefined, depth - 1);
}

export const useClickEventLogger = () => {
  const { pathname } = useWorkspaceLocation();
  const { search } = useLocation();
  const { id, details, user, otherWorkspaces } = useWorkspaceContext();
  const { data: userData } = useMe();
  const { plan } = useCheckoutContext();

  useEffect(() => {
    const listener = (e: any) => {
      const target = getEventTarget(e.target as HTMLElement, 3);
      if (target && target.getAttribute('data-log-event')) {
        const event = target.getAttribute('data-log-event');
        const rawData = target.getAttribute('data-log-event-data');
        const eventData = rawData ? JSON.parse(rawData) : {};
        let checkoutUsers;
        let nbPaidUsers;
        let nbFreeUsers;
        let selectedPlan;
        if (event && clickEventCheckoutActionTypes.includes(event)) {
          checkoutUsers = readCheckoutSessionUsers()?.users || [];
          nbPaidUsers = checkoutUsers.filter((u) => u.isPaid).length;
          nbFreeUsers = checkoutUsers.filter((u) => !u.isPaid).length;
          selectedPlan = plan;
        }
        logClickEvent({
          displayName: eventData.displayName,
          actionType: event,
          pagePath: pathname,
          search,
          user: userData,
          workspaceId: id,
          workspaceDetails: details,
          workspaceUser: user,
          otherWorkspaces,
          nbPaidUsers,
          nbFreeUsers,
          selectedPlan,
        });
      }
    };

    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
    };
  }, [details, userData, pathname, search]);

  return null;
};
