export const CHANGE_BILLING_CYCLE = 'change-billing-cycle';
export const PLAN = 'plan';
export const INTERVAL = 'interval';
export const SHOW_PLANS = 'show-plans';
export const CHECKOUT = 'checkout';
export const FROM = 'from';
export const TO = 'to';
export const FROM_INTERVAL = 'fromInterval';
export const TO_INTERVAL = 'toInterval';
export const NEW_GMAIL_USERS = 'gmailUsers';
export const NEW_WORKSPACE_USERS = 'workspaceUsers';
