import { WorkspaceFeatures } from 'model/features';
import { Plan } from 'model/plan';
import { UserInput } from 'model/user';
import { UserWorkspace, Workspace, WorkspaceInput } from 'model/workspace';
import { getRoleValue, RoleValue } from '../../utils/roleValue';

export const transformUserWorkspace = (
  user: UserInput,
  workspaceId?: string,
): UserWorkspace | undefined => {
  const targetSpaceId = workspaceId ?? user.addonWorkspaceId;
  const currentWorkspace =
    user.userWorkspaces.find((w) => w.workspaceId === targetSpaceId) ??
    user.userWorkspaces[0];

  if (!currentWorkspace) return undefined;

  const roleValue = getRoleValue(currentWorkspace.userRole);
  const hasAdminFeatures = roleValue >= RoleValue.ADMIN;

  return {
    workspaceId: currentWorkspace.workspaceId,
    workspaceName: currentWorkspace.workspaceName,
    workspacePlan: currentWorkspace.workspacePlan,
    plan: currentWorkspace.userPlan as Plan,
    role: currentWorkspace.userRole,
    status: currentWorkspace.status,
    workspaces:
      user.userWorkspaces.map((workspace) => ({
        id: workspace.workspaceId,
        name: workspace.workspaceName,
        status: workspace.status,
        role: workspace.userRole,
        urlPrefix: workspace.urlPrefix,
        workspaceUserFeature: workspace.workspaceUserFeature,
      })) ?? [],
    counters: {
      totalEmailsSent:
        currentWorkspace.counters?.totalNumberOfMailsSent?.count ?? 0,
      totalEmailsSentThisMonth:
        currentWorkspace.counters?.monthlySentMails?.find(
          (monthlySentMail) =>
            monthlySentMail.year === new Date().getFullYear() &&
            monthlySentMail.month === new Date().getMonth() + 1,
        )?.count ?? 0,
    },
    features: {
      canAccessTeamReport:
        currentWorkspace.workspaceUserFeature?.otherFeatures
          .teamAnalyticsDashboard ?? false,
      canAccessWorkspaceBilling: hasAdminFeatures,
      canAccessWorkspace: roleValue >= RoleValue.MEMBER,
      hasMultiDomain:
        currentWorkspace.workspaceUserFeature?.hasMultiDomain ?? false,
      manageUserRoles:
        hasAdminFeatures &&
        !!currentWorkspace.workspaceUserFeature?.otherFeatures.manageUserRoles,
      transferOwnerShip:
        currentWorkspace.workspaceUserFeature?.otherFeatures
          .transferOwnerShip ?? false,
      sharedUnsubscribes:
        !!currentWorkspace.workspaceUserFeature?.otherFeatures
          .shareUnsubscribesAcrossWorkspace &&
        currentWorkspace.userPlan !== 'FREE',
      sharedBounces:
        !!currentWorkspace.workspaceUserFeature?.otherFeatures
          .shareBouncesAccrossWorkspace && currentWorkspace.userPlan !== 'FREE',
    },
    urlPrefix: `/${currentWorkspace.urlPrefix}`,
    legacyType: (() => {
      if (currentWorkspace.workspacePlan !== 'LEGACY') return undefined;
      const allowedUserCount =
        currentWorkspace.workspaceUserFeature?.allowedUserCount ?? -1;
      const isPersonal = currentWorkspace.workspaceUserFeature?.isPersonal;

      if (allowedUserCount === -1) return 'UNLIMITED';
      if (allowedUserCount > 1) return 'TEAM';
      if (!isPersonal) return 'PROFESSIONAL';
      return 'PERSONAL';
    })(),
  };
};

export const transformWorkspace = (workspace: WorkspaceInput): Workspace => {
  return {
    id: workspace.workspaceId,
    name: workspace.workspaceName,
    memberCount: workspace.memberCount,
    plan: workspace.workspacePlan,
    status: workspace.status,
    features: {
      ...['FREE', 'PRO', 'PRO_PERSONAL'].reduce(
        (map, userPlan) => ({
          ...map,
          [userPlan]: (() => {
            const feature = workspace.workspaceFeatures.find(
              (f) => f.userPlan === userPlan,
            );
            return {
              hasMultiDomain: feature?.hasMultiDomain ?? false,
              allowedUserCount: feature?.allowedUserCount ?? 0,
            };
          })(),
        }),
        {} as WorkspaceFeatures,
      ),
      isMemberAllowedToInvite: workspace.memberInviteUser,
    },
    domains: (workspace.workspaceDomains ?? []).map(
      ({ domainName }) => domainName,
    ),
  };
};

// For Placeholder data
export const transformUserToWorkspace = (
  user: UserInput,
  workspaceId?: string,
): Workspace | undefined => {
  const currentWorkspace = user.userWorkspaces.find(
    (w) => w.workspaceId === workspaceId,
  );
  if (!currentWorkspace) return undefined;

  return {
    id: currentWorkspace.workspaceId,
    name: currentWorkspace.workspaceName,
    memberCount: 0,
    plan: currentWorkspace.workspacePlan,
    status: currentWorkspace.status,
    features: {
      ...['FREE', 'PRO', 'PRO_PERSONAL'].reduce(
        (map, userPlan) => ({
          ...map,
          [userPlan]:
            userPlan === currentWorkspace.userPlan
              ? {
                  hasMultiDomain:
                    currentWorkspace.workspaceUserFeature?.hasMultiDomain ??
                    false,
                  allowedUserCount:
                    currentWorkspace.workspaceUserFeature?.allowedUserCount ??
                    0,
                }
              : {
                  hasMultiDomain: false,
                  allowedUserCount: 0,
                },
        }),
        {} as WorkspaceFeatures,
      ),
      isMemberAllowedToInvite: false,
    },
    domains: [user.domain.domainName],
  };
};
