import React from 'react';
import { Alert, FlexContainer, Text } from '@scriptaddicts/yamm-ui-components';
import CheckoutLayout from '../Layout';
import { StyledCard, StyledTitle, StyledTitleWrapper } from '../common/styles';
import { useConnect } from './connect';
import LoadingScreen from '../../../components/LoadingScreen';
import Strong from '../../../components/Strong';
import { getPlanLabel } from '../../../utils/checkout';

const CannotDowngrade = () => {
  const {
    workspaceDetails,
    isLoading,
    targetDomain,
    targetPlan,
  } = useConnect();

  if (isLoading || !workspaceDetails) {
    return <LoadingScreen />;
  }

  return (
    <CheckoutLayout.Container>
      <CheckoutLayout.Main>
        <StyledTitleWrapper>
          <StyledTitle>Downgrading {workspaceDetails.name}</StyledTitle>
        </StyledTitleWrapper>
        <FlexContainer flex="vertical" gap="large">
          <StyledCard variant="white" padding="big">
            <FlexContainer flex="vertical" gap="xlarge">
              <Alert type="warning">
                <Text type="paragraph" size="large" width="semiBold">
                  You have users from multiple domains in your space.
                </Text>
                <Text type="paragraph" size="base" width="regular">
                  The Pro plan only allows users from the same domain as the
                  owner.
                </Text>
              </Alert>
              <Text type="paragraph" size="small">
                To downgrade to the <Strong>{getPlanLabel(targetPlan)}</Strong>{' '}
                plan, your users must be from the{' '}
                <Strong>{targetDomain}</Strong> domain.
              </Text>
            </FlexContainer>
          </StyledCard>
        </FlexContainer>
      </CheckoutLayout.Main>
    </CheckoutLayout.Container>
  );
};

export default CannotDowngrade;
