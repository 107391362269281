import { WorkspaceMember } from 'model/workspaceMembers';
import { useMemo } from 'react';
import { PendingUsers } from '../types';

export type UsePendingUsersProps = {
  selected: WorkspaceMember[];
};

export const usePendingUsers = ({ selected }: UsePendingUsersProps) => {
  const pendingUsers = useMemo(() => {
    return selected.filter((s) => s?.status === 'PENDING');
  }, [selected]);

  return useMemo<PendingUsers>(() => {
    return {
      count: pendingUsers.length,
      list: pendingUsers,
      features: {
        resend: true,
        cancel: true,
      },
    };
  }, [pendingUsers]);
};
