import React from 'react';
import { format12hTime, formatMonthDay } from 'utils/dates';
import { Column } from 'components/ResponsiveTable/types';
import { ScheduledCampaign } from 'model/scheduledCampaign';
import {
  TableCell,
  TableCellValue,
  TableCellDateTimeValue,
} from '@scriptaddicts/yamm-ui-components';
import { SheetLink, SheetIcon } from '../../styles';
import { ErrorIcon, WarningIcon, TableCellValueGroup } from './styles';
import {
  isCampaignInterruptedBecauseOfQuota,
  isErrorStatus,
} from '../../../../../utils/scheduledCampaigns';

const getTitleContent = (
  value: string,
  scheduledCampaign: ScheduledCampaign,
) => {
  if (isCampaignInterruptedBecauseOfQuota(scheduledCampaign)) {
    return (
      <TableCellValueGroup variant="warning">
        <WarningIcon />
        <span>{value}</span>
      </TableCellValueGroup>
    );
  }
  if (isErrorStatus(scheduledCampaign.status)) {
    return (
      <TableCellValueGroup variant="error">
        <ErrorIcon />
        <span>{value}</span>
      </TableCellValueGroup>
    );
  }
  return (
    <TableCellValue width="medium" style={{ zIndex: 2 }}>
      {value}
    </TableCellValue>
  );
};

export const getColumns = ({ isMobile }: { isMobile: boolean }) =>
  [
    {
      Header: 'Name',
      accessor: 'title',
      colSpan: 2,
      group: isMobile,
      Cell: ({ value, row }) =>
        isMobile ? (
          <TableCell colSpan={2}>
            {getTitleContent(value, row.original)}
          </TableCell>
        ) : (
          getTitleContent(value, row.original)
        ),
    },
    {
      Header: 'Date',
      accessor: 'mergeDate',
      colSpan: 1,
      filter: 'unix',
      sortType: 'datetime',
      defaultSorted: 'desc',
      Cell: ({ value }) =>
        !isMobile ? (
          <TableCellDateTimeValue
            value={value}
            formatFunction={formatMonthDay}
          />
        ) : (
          <TableCellValue />
        ),
    },
    {
      Header: 'Time',
      accessor: 'mergeDateTime',
      colSpan: 1,
      filter: 'unix',
      Cell: ({ value }) =>
        !isMobile ? (
          <TableCellDateTimeValue
            value={value}
            formatFunction={format12hTime}
          />
        ) : (
          <TableCellValue />
        ),
    },
    {
      Header: 'Emails',
      accessor: 'numberOfEmailsToSend',
      alignment: 'right',
      colSpan: 1,
      Cell: ({ value }) => (
        <TableCellValue
          size={isMobile ? 'extra-small' : undefined}
          variant={isMobile ? 'gray' : undefined}
        >
          {`${value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}${
            isMobile ? ' mails' : ''
          }`}
        </TableCellValue>
      ),
    },
    ...(!isMobile
      ? [
          {
            Header: 'Sheet',
            accessor: 'spreadsheetLink',
            alignment: 'right',
            colSpan: 1,
            disableGlobalFilter: true,
            disableSortBy: true,
            canSort: false,
            Cell: ({ value }) => (
              <SheetLink href={value} target="_blank" rel="noopener noreferrer">
                <SheetIcon />
              </SheetLink>
            ),
          } as Column<ScheduledCampaign>,
        ]
      : [
          {
            accessor: 'responsiveRight',
            colSpan: 1,
            float: 'right',
            disableGlobalFilter: true,
            disableSortBy: true,
            canSort: false,
            Cell: ({ row }) => {
              const {
                mergeDate,
                mergeDateTime,
              } = row.original as ScheduledCampaign;
              return (
                <TableCellValueGroup size="extra-small" variant="gray">
                  <TableCellDateTimeValue
                    value={mergeDate}
                    formatFunction={formatMonthDay}
                  />
                  {!!mergeDate && !!mergeDateTime && <span> · </span>}
                  <TableCellDateTimeValue
                    value={mergeDateTime}
                    formatFunction={format12hTime}
                  />
                </TableCellValueGroup>
              );
            },
          } as Column,
        ]),
  ] as Column<ScheduledCampaign>[];
