export const plurify = (
  qty: number | undefined,
  singular: string,
  plural?: string,
): string => {
  if (qty === 1) {
    return singular;
  }

  if (plural) {
    return plural;
  }

  return `${singular}s`;
};
