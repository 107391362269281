import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWorkspaceContext } from '../../../../../data/context/WorkspaceContext';
import { BillingInterval } from '../../../../../model/workspaceBilling';
import { useWorkspaceActiveSubscription } from '../../../../../data/hooks/useWorkspaceBilling';
import { usePrices } from '../../../../../data/hooks/usePaymentPlans';
import { useMe } from '../../../../../data/hooks/useMe';
import { isGmailAddress } from '../../../../../utils/userUtils';
import { DEFAULT_INTERVAL } from '../../../../../data/constants';
import useQueryParams from '../../../../../utils/useQueryParams';
import { writeCheckoutSessionUsers } from '../../../../../utils/checkoutSessionUsers';

export const useConnect = () => {
  const { data: me } = useMe({
    keepPreviousData: true,
    staleTime: 60000,
  });

  const {
    id,
    urlPrefix,
    details: workspaceDetails,
    restrictions,
  } = useWorkspaceContext();

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
  } = useWorkspaceActiveSubscription({
    workspaceId: id,
    includePlans: true,
  });
  const [interval, setBillingInterval] = useState<BillingInterval>(
    DEFAULT_INTERVAL,
  );
  const queryParams = useQueryParams();
  const { push } = useHistory();
  const { prices, isLoading: isLoadingPrices } = usePrices();

  const navigate = () => {
    const fromUsers = queryParams.get('from') === 'users';

    if (!fromUsers) {
      // if not from users, upgrade all users
      writeCheckoutSessionUsers({});
    }

    if (workspaceDetails && workspaceDetails.legacyType === 'TEAM') {
      push(`${urlPrefix}/checkout/legacy/users`);
    } else {
      push(`${urlPrefix}/checkout`);
    }
  };

  return {
    isLoadingSubscription,
    isLoadingPrices,
    plan: subscription?.workspacePlan,
    interval,
    setBillingInterval,
    navigate,
    prices,
    legacyPlanType: workspaceDetails?.legacyType,
    isGmail: me ? isGmailAddress(me.email) : false,
    restrictions: {
      isMonthlyBillingRestricted: !!restrictions?.MONTHLY_BILLING,
      isPremiumPlanRestricted: !!restrictions?.PREMIUM_PLAN,
      isUsersPageRestricted: !!restrictions?.USERS_PAGE,
      isTransferWorkspaceRestricted: !!restrictions?.TRANSFER_WORKSPACE,
      isWorkspaceDashboardRestricted: !!restrictions?.WORKSPACE_DASHBOARD,
    },
  };
};
