import { client } from 'data/client';
import { Template } from 'model/template';

type GetTemplatesResponse = {
  items: Template[];
};

export async function getTemplates() {
  const { data } = await client.get<GetTemplatesResponse>('/templates');
  return data.items;
}

export type PostImportDraftDto = {
  userId: string;
  fromTemplate: string;
  source: string;
};

type PostImportDraftResponse = {
  draftId: string;
  messageId: string;
};

export async function importDraft({ userId, ...body }: PostImportDraftDto) {
  const { data } = await client.post<PostImportDraftResponse>(
    `/users/${userId}/drafts`,
    body,
  );
  return data;
}
