import styled from 'styled-components';
import { Spinner } from '@scriptaddicts/yamm-ui-components';
import ListItem from '../../../../../components/ListItem';
import { from } from '../../../../../styles/media';
import { ReactComponent as PayPalSvg } from '../../../../../assets/images/icons/paypal-billing-icon.svg';

export const StyledPlanContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const LinksSection = styled.div`
  margin-top: 16px;
`;

export const StyledListItem = styled(ListItem)`
  cursor: pointer;
`;

export const LoadingSpinner = styled(Spinner).attrs({
  size: 50,
})`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  min-height: calc(100vh - 200px);
  --spinner-color: ${({ theme }) => theme.colors.blue500};
`;

export const StyledBillingCycleChangeContainer = styled.div`
  margin-top: 16px;

  button.link {
    padding: 0;
    color: ${({ theme }) => theme.colors.blue700};
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
`;

export const PayPalIcon = styled(PayPalSvg)`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 42px;
  height: 42px;
`;
