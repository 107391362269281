import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

import { Link } from 'react-router-dom';
import {
  ButtonLink as DefaultButtonLink,
  Text as DefaultText,
  Placeholder as DefaultPlaceholder,
  Spinner as DefaultSpinner,
} from '@scriptaddicts/yamm-ui-components';

export const TemplateCardContainer = styled.article`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(152, 152, 152, 0.2);
  overflow: hidden;
`;

export const Thumb = styled(Link)`
  display: flex;
  padding-top: 100%;

  position: relative;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    opacity: 0.7;
  }

  &[href='/'] {
    pointer-events: none;
  }
`;

export const Image = styled.img<{ $loaded?: boolean; $translation?: boolean }>`
  position: absolute;
  top: 0;

  height: 100%;
  width: 100%;

  object-fit: cover;

  ${({ $loaded }) =>
    $loaded === false &&
    css`
      transition: filter 200ms ease-out;
      filter: blur(6px);
    `};
  ${({ $loaded }) =>
    $loaded === true &&
    css`
      transition: filter 200ms ease-out;
    `};
`;

export const ImagePlaceholder = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  margin: auto;
  height: 80%;
  width: 80%;

  background-color: ${({ theme }) => theme.colors.gray300};
  opacity: 0.2;
  border-radius: 5px;
`;

export const Header = styled.header`
  display: flex;
  border-top: 1px solid ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

export const Title = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'base',
  width: 'medium',
})`
  margin-left: 4px !important;
`;

export const TitlePlaceholder = styled(DefaultPlaceholder).attrs({
  type: 'paragraph',
  size: 'base',
  animate: false,
  width: 110,
})`
  --placeholder-bg: ${({ theme }) => theme.colors.gray300};
  --placeholder-opacity: 0.2;
`;

export const ButtonLink = styled(DefaultButtonLink).attrs({
  size: 'small',
})`
  font-size: 14px;
  min-width: 58px !important;
`;

export const ButtonPlaceholder = styled.span`
  background-color: ${({ theme }) => theme.colors.gray300};
  opacity: 0.2;
  border-radius: 6px;
  height: 32px;
  width: 58px;
`;

export const Spinner = styled(DefaultSpinner)`
  margin: 8px 0px;
`;
