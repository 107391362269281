import { Link } from '@scriptaddicts/yamm-ui-components';
import { EmptyTitle, EmptySubtitle } from 'components/ResponsiveTable/Empty';
import React from 'react';

const ScheduledMailMergesEmpty = () => {
  return (
    <>
      <EmptyTitle>Nothing to see here...</EmptyTitle>
      <EmptySubtitle>
        You don’t have any mail merge scheduled. Learn how to{' '}
        <Link
          href="https://support.yet-another-mail-merge.com/hc/en-us/articles/211869705-Schedule-batch-delivery-send-emails-later"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get started
        </Link>
        .
      </EmptySubtitle>
    </>
  );
};

export default ScheduledMailMergesEmpty;
