import { WorkspaceSubscription } from '../../model/workspaceBilling';

export const isOfflineSubscription = (
  subscription: WorkspaceSubscription,
): boolean => {
  return subscription.paymentSource === 'OFFLINE';
};

export const isSupportGivenSubscription = (
  subscription: WorkspaceSubscription,
): boolean => {
  return subscription.paymentSource === 'SUPPORT';
};
