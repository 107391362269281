import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { UserFeatures } from '../../model/features';
import { useWorkspaceContext } from '../../data/context/WorkspaceContext';

export const useRequireUserFeature = (
  feature: keyof UserFeatures,
  redirect: string = '/',
) => {
  const { user, isLoading } = useWorkspaceContext();
  const { replace } = useHistory();
  useEffect(() => {
    if (!isLoading && user && user.features && !user.features[feature]) {
      replace(redirect);
    }
  }, [user, redirect, isLoading]);
};
