import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import './fonts.css';

const GlobalStyle = createGlobalStyle`
  ${reset};

  html {
    font-size: 100%;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.colors.gray1};
    color: ${({ theme }) => theme.colors.gray800};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga","kern";
    font-variant-numeric: proportional-nums;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  a {
    color: inherit;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px #e5e9f2 inset !important;
    box-shadow: 0 0 0 30px #e5e9f2 inset !important;
  }
  
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #edf0f5 inset !important;
    box-shadow: 0 0 0 30px #edf0f5 inset !important;
  }
`;

export default GlobalStyle;
