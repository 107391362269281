function analytics(action: string, label?: string) {
  const data: {
    hitType: string;
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
  } = {
    hitType: 'event',
    eventCategory: 'onboarding',
    eventAction: action,
  };

  if (label) {
    data.eventLabel = label;
  }

  if (typeof (window as any).ga !== 'undefined') {
    (window as any).ga('gtm4.send', data);
  }
}

function pageView(pagePath: string, pageTitle: string) {
  if (typeof (window as any).ga !== 'undefined') {
    (window as any).ga('gtm4.send', {
      hitType: 'pageview',
      pagePath,
      pageTitle,
    });
  }
}

export const onboardingInstall = () => {
  pageView('/get-started-install', 'Onboarding - Install');
};

export const onboardingSend = () => {
  pageView('/get-started-send', 'Onboarding - Send');
};

export const stepOneLoad = () => {
  analytics('step 1 load');
};

export const stepOneSignUp = () => {
  analytics('step 1 click signup');
};

export const stepTwoLoad = () => {
  analytics('step 2 load');
};

export const stepTwoMarketplace = (label: string) => {
  analytics('step 2 click marketplace', label);
};

export const stepTwoContinue = () => {
  analytics('step 2 click continue');
};

export const stepTwoSkip = () => {
  analytics('step 2 click skip');
};

export const stepThreeLoad = () => {
  analytics('step 3 load');
};

export const stepThreeSheets = () => {
  analytics('step 3 click sheets');
};

export const stepThreeDashboard = () => {
  analytics('step 3 click dashboard');
};

export const clickTutorial = () => {
  analytics('click tutorial');
};
