import {
  computeStripePaymentInfo,
  ComputeStripePaymentInfoRequest,
  createStripeCheckoutSession,
  CreateStripeCheckoutSessionRequest,
  getManageStripeCustomerPortalData,
  getWorkspaceSubscriptions,
  removeUsersFromWorkspace,
  RemoveUsersFromWorkspaceProps,
  updateWorkspaceSubscription,
  UpdateWorkspaceSubscriptionResponse,
} from 'data/requests/workspaceBilling';
import { useMutation, useQuery } from 'react-query';
import { useMemo } from 'react';
import { captureException } from '@sentry/minimal';
import {
  BillingInterval,
  BillingWorkspacePlan,
  UpdateWorkspaceSubscriptionUserPlan,
  WorkspaceSubscription,
} from '../../model/workspaceBilling';
import { queryClient } from '../queryClient';

export type UseWorkspaceSubscriptionsProps = {
  workspaceId?: string;
  includePlans: boolean;
  retrieveAdditionalStripeInfo?: boolean;
};
export const useWorkspaceSubscriptions = (
  {
    workspaceId,
    includePlans,
    retrieveAdditionalStripeInfo = false,
  }: UseWorkspaceSubscriptionsProps,
  onSuccess?: (data?: WorkspaceSubscription[]) => void,
) => {
  return useQuery<WorkspaceSubscription[] | undefined>(
    [
      'space',
      workspaceId,
      'subscriptions',
      includePlans,
      retrieveAdditionalStripeInfo,
    ],
    () =>
      getWorkspaceSubscriptions(
        workspaceId!,
        includePlans,
        retrieveAdditionalStripeInfo,
      ),
    {
      enabled: !!workspaceId,
      staleTime: 5000,
      refetchOnMount: false,
      onSuccess,
    },
  );
};

/**
 * Only one active subscription is allowed per workspace
 */
export const useWorkspaceActiveSubscription = (
  props: UseWorkspaceSubscriptionsProps,
) => {
  const { data, ...query } = useWorkspaceSubscriptions({
    ...props,
    retrieveAdditionalStripeInfo: true,
  });

  const active = useMemo(() => {
    let activeSub: WorkspaceSubscription | undefined;

    if (data && data.length) {
      activeSub = data.find(
        (workspace) =>
          workspace.subscriptionStatus === 'ACTIVE' &&
          workspace.workspaceId === props.workspaceId,
      );
    }

    return activeSub;
  }, [data]);

  return {
    ...query,
    data: active,
  };
};

const generateComputeStripePaymentInfoKey = (
  data?: ComputeStripePaymentInfoRequest,
) =>
  data
    ? [
        data.purchaseType,
        data.country,
        data.billingInterval,
        data.workspacePlan,
        (data?.userUpdates || [])
          .map((user) => `${user.userId}:${user.userPlan}`)
          .join(', '),
      ]
    : [];

export const useComputeStripePaymentInfo = (
  workspaceId?: string,
  data?: ComputeStripePaymentInfoRequest,
  previewProratedChanges: boolean = false,
) =>
  useQuery(
    [
      'computeStripePaymentInfo',
      workspaceId,
      previewProratedChanges,
      ...generateComputeStripePaymentInfoKey(data),
    ],
    () =>
      computeStripePaymentInfo(workspaceId!!, data!!, previewProratedChanges),
    {
      enabled: workspaceId != null && data != null,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  );

export const useCreateStripeCheckoutSession = () =>
  useMutation(
    ({
      workspaceId,
      ...data
    }: CreateStripeCheckoutSessionRequest & { workspaceId: string }) =>
      createStripeCheckoutSession(workspaceId, data),
  );

export const useUpdateWorkspaceSubscription = () =>
  useMutation(
    async ({
      workspaceId,
      subscriptionId,
      workspaceUserPlans,
      workspacePlan,
      billingInterval,
      upgradeLegacySubscription,
    }: {
      workspaceId: string;
      subscriptionId: string;
      upgradeLegacySubscription?: boolean;
      workspaceUserPlans: UpdateWorkspaceSubscriptionUserPlan[];
      workspacePlan?: BillingWorkspacePlan;
      billingInterval?: BillingInterval;
    }): Promise<UpdateWorkspaceSubscriptionResponse> => {
      return updateWorkspaceSubscription(workspaceId, subscriptionId, {
        workspacePlan,
        billingInterval,
        workspaceUserPlans,
        upgradeLegacySubscription,
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['space', variables.workspaceId]);
        queryClient.invalidateQueries(['me']);
      },
      onError: (error, variables) => {
        if (error instanceof Error) {
          captureException(new Error(error.message), {
            extra: variables,
          });
        } else {
          captureException(
            new Error('Error in useUpdateWorkspaceSubscription'),
            {
              extra: variables,
            },
          );
        }
      },
    },
  );

export const useCancelScheduledBillingIntervalChange = () =>
  useMutation(
    async ({
      workspaceId,
      subscriptionId,
    }: {
      workspaceId: string;
      subscriptionId: string;
    }): Promise<UpdateWorkspaceSubscriptionResponse> =>
      updateWorkspaceSubscription(workspaceId, subscriptionId, {
        cancelScheduledBillingIntervalChange: true,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          'space',
          variables.workspaceId,
          'subscriptions',
        ]);
      },
    },
  );

export const useGetManageStripeCustomerPortalData = ({
  workspaceId,
  returnUrl,
}: {
  workspaceId?: string;
  returnUrl: string;
}) =>
  useQuery(
    ['get-manage-stripe-customer-portal-data', workspaceId, returnUrl],
    () => getManageStripeCustomerPortalData(workspaceId!, returnUrl),
    {
      enabled: workspaceId != null && returnUrl != null,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

export const useRemoveUsersFromWorkspace = () =>
  useMutation(
    (props: RemoveUsersFromWorkspaceProps) => removeUsersFromWorkspace(props),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          'space',
          variables.workspaceId,
          'subscriptions',
        ]);
      },
    },
  );
