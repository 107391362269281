import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DefaultInfoIcon } from 'assets/images/icons/info.svg';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.625rem 0.9375rem rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  overflow: hidden;
  width: 15.625rem;
`;

export const PricingCardTop = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 1.25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PricingCardBottom = styled.div`
  background-color: ${({ theme }) => theme.colors.whisper};
  border-top: 1px solid #dedcdb;
  padding: 1.25rem;

  display: flex;
  flex-direction: column;
`;

export const PriceTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.orange600};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 1rem;
  margin-top: 0.3125rem;
`;

export const PriceTargetText = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.blue900};
  font-size: 0.875rem;

  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
`;

export const CardText = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.suvaGrey};
  font-size: 0.875rem;
  line-height: 1.125rem;

  &:not(:last-of-type) {
    margin-bottom: 0.375rem;
  }

  & > b {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;

export const SubscribeLink = styled(NavLink)`
  display: block;
  background-color: ${({ theme }) => theme.colors.orange500};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.875rem 1.5625rem;
  box-shadow: 0 0.625rem 0.9375rem rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1.25rem;
  text-decoration: none;
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.375rem;
  cursor: pointer;
`;
