import React from 'react';
import { Placeholder, Text } from '@scriptaddicts/yamm-ui-components';
import { StyledBillingCycle, StyledDifferenceText } from './styles';
import Strong from '../../../components/Strong';
import { plurify } from '../../../utils/plurify';
import { PurchasePlanDisplay } from './types';
import { formatPriceSignOnly } from '../../../utils/priceFormat';
import {
  BillingCurrency,
  BillingInterval,
  BillingWorkspacePlan,
} from '../../../model/workspaceBilling';

export const BillingPurchasePlans = ({
  plans,
  workspacePlan,
}: {
  plans: PurchasePlanDisplay[] | undefined;
  workspacePlan: BillingWorkspacePlan;
}) => (
  <>
    <Text type="paragraph" size="extraLarge">
      <Strong>{workspacePlan === 'PREMIUM' ? 'Premium' : 'Pro'}</Strong> plan
    </Text>
    {!plans && (
      <StyledDifferenceText>
        <span>
          <Placeholder type="paragraph" size="extra-large" width={80} />
        </span>
        <span>
          <Placeholder type="paragraph" size="extra-large" width={62} />
        </span>
      </StyledDifferenceText>
    )}
    {plans &&
      plans.map((plan) => (
        <StyledDifferenceText
          size="extraLarge"
          key={`${plan.isGmail}-${plan.quantity}-${plan.currency}`}
        >
          <span>
            <Strong>{plan.quantity}</Strong> {plan.isGmail ? '@gmail' : ''} paid{' '}
            {plurify(plan.quantity, 'user', 'users')}
          </span>
          <span>{formatPriceSignOnly(plan.totalAmount, plan.currency, 2)}</span>
        </StyledDifferenceText>
      ))}
  </>
);

export const PriceFooter = ({
  displayPrice,
  billingInterval,
  renewalPrice,
  currency,
}: {
  displayPrice: boolean;
  billingInterval: BillingInterval;
  renewalPrice: number;
  currency: BillingCurrency;
}) => {
  if (displayPrice && renewalPrice > 0) {
    return (
      <StyledBillingCycle>
        {formatPriceSignOnly(renewalPrice, currency, 2)} billed{' '}
        {billingInterval === 'YEARLY' ? ' annually' : ' monthly'}
      </StyledBillingCycle>
    );
  }

  return (
    <StyledBillingCycle>
      Billed {billingInterval === 'YEARLY' ? ' annually' : ' monthly'}
    </StyledBillingCycle>
  );
};
