import { useCallback, useEffect, useMemo } from 'react';
import { formatShortDateTime } from 'utils/dates';
import { captureException } from '@sentry/minimal';
import { useStackDriverLogging } from 'data/context/StackDriverLogging';
import { getUserDataForLogging } from 'utils/logging';
import {
  isCampaignInterruptedBecauseOfQuota,
  isErrorStatus,
  willRetryScheduledMergeInError,
} from 'utils/scheduledCampaigns';
import { useMe } from 'data/hooks/useMe';
import {
  useCancelScheduledCampaign,
  useScheduledCampaignDetail,
} from 'data/hooks/useScheduledCampaign';
import useURLDisclosure from 'utils/router/useUrlDisclosure';
import { Props } from './types';

export const useConnect = ({ setAlert }: Props) => {
  const { url, params, ...dialog } = useURLDisclosure(':id');

  const { data: me } = useMe();
  const { logToStackDriver, getUser, getWorkspace } = useStackDriverLogging();

  const { data: scheduledMailMerge } = useScheduledCampaignDetail(
    me?.id,
    params?.id,
  );

  const {
    mutateAsync: cancel,
    status: cancelStatus,
  } = useCancelScheduledCampaign();
  const deliveryDate = useMemo(
    () =>
      scheduledMailMerge &&
      scheduledMailMerge.mergeDate &&
      formatShortDateTime(scheduledMailMerge.mergeDate),
    [scheduledMailMerge],
  );

  const onCancel = useCallback(() => {
    if (!me || !params?.id) return;
    cancel({ userId: me.email, scheduledMergeId: params.id });
  }, [me, params?.id]);

  useEffect(() => {
    if (!scheduledMailMerge) return;
    if (cancelStatus === 'success') {
      setAlert({ status: 'success' });
      try {
        const user = getUserDataForLogging(getUser(), getWorkspace());
        logToStackDriver({
          merge_id: `${scheduledMailMerge.mergeDate}_${scheduledMailMerge.spreadsheetId}`,
          campaign_start_time: scheduledMailMerge.mergeDateTime,
          created_time: scheduledMailMerge.createdDateTime,
          spreadsheet_id: scheduledMailMerge.spreadsheetId,
          spreadsheet_name: scheduledMailMerge.spreadsheetName,
          sheet_id: scheduledMailMerge.sheetId,
          sheet_name: scheduledMailMerge.sheetName,
          timestamp: Date.now(),
          nb_emails_sent: scheduledMailMerge.numberOfEmailsSent,
          nb_emails_to_send: scheduledMailMerge.numberOfEmailsToSend,
          user_id: user.user_id,
          user_installation_timestamp: user.user_installation_timestamp,
          plan: user.plan,
          is_biz_user: user.is_biz_user,
          domain: user.domain,
          event_type: 'scheduled_merge_canceled_by_user',
          message:
            'User canceled a scheduled merge (scheduled_merge_canceled_by_user)',
        });
      } catch (e) {
        captureException(e);
      }
      dialog.onClose();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (cancelStatus === 'error') {
      setAlert({ status: 'error' });
      dialog.onClose();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [setAlert, dialog.onClose, scheduledMailMerge, cancelStatus]);

  const canCancelMerge = useMemo(() => {
    if (
      scheduledMailMerge != null &&
      isErrorStatus(scheduledMailMerge.status) &&
      !willRetryScheduledMergeInError(scheduledMailMerge)
    ) {
      return false;
    }

    return true;
  }, [scheduledMailMerge]);

  const isOutOfQuota = useMemo(
    () =>
      scheduledMailMerge &&
      isCampaignInterruptedBecauseOfQuota(scheduledMailMerge),
    [scheduledMailMerge],
  );

  return {
    scheduledMailMerge,
    canCancelMerge,
    isOutOfQuota,
    deliveryDate,
    isCanceling: cancelStatus === 'loading',
    onCancel,
    url,
    dialog,
  };
};
