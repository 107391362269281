import React, { HTMLAttributes, memo } from 'react';
import { ReactComponent as Stripe } from 'assets/images/logos/stripe-logo.svg';

const StripeStamp = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <Stripe />
  </div>
);

export default memo(StripeStamp, () => true);
