import { FlexContainer, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { Card, CardTitle } from '../styles';

const FreePlan = () => (
  <Card>
    <FlexContainer flex="vertical" gap="small">
      <CardTitle>Current plan: Free</CardTitle>
      <Text type="paragraph">
        Upgrade to send more emails and unlock additional features.
      </Text>
    </FlexContainer>
  </Card>
);

export default FreePlan;
