import React, { Component } from 'react';
import { captureException } from '@sentry/minimal';
import { ReactComponent as YammLogo } from 'assets/images/brand/YAMM-Logotype.svg';
import {
  Container,
  Content,
  Subtitle,
  Link,
  Title,
  ContactMessage,
} from './styles';
import { Props, State } from './types';

// There is no way to write error boundaries with hooks as of right now
// eslint-disable-next-line react/prefer-stateless-function
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    captureException(error);
    return {
      error,
    };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <Container>
        <Content>
          <YammLogo />
          <Title>Oops...</Title>
          <Subtitle>Sorry, there was a problem.</Subtitle>
          <Link href={window.location.origin}>Try again</Link>
          <ContactMessage>If the problem persists contact us</ContactMessage>
        </Content>
      </Container>
    );
  }
}

export default ErrorBoundary;
