import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  OtherWorkspaceDecorator,
  useWorkspaceContext,
} from 'data/context/WorkspaceContext';
import { useMemo } from 'react';
import { usePageRestrictions } from '../../../data/hooks/restrictions/useRestrictions';

export const useConnect = () => {
  const { url, path } = useRouteMatch();
  const { replace } = useHistory();
  const { id, user, isLoading, otherWorkspaces } = useWorkspaceContext();
  const { userPage: isUsersPageRestricted } = usePageRestrictions();

  const selectedWorkspace = useMemo(() => {
    return otherWorkspaces.find((ws) => ws.id === id);
  }, [otherWorkspaces, id]);

  const isNotAuthorized = !isLoading && !user?.features.canAccessWorkspace;

  const isOwner = user?.role === 'OWNER';

  const canAccessUsers = useMemo(() => {
    if (selectedWorkspace === undefined) return false;
    const otherWorkspaceDecorator = new OtherWorkspaceDecorator(
      selectedWorkspace,
    );
    return otherWorkspaceDecorator.hasAccessToUsers();
  }, [selectedWorkspace]);

  if (isNotAuthorized) {
    replace('/');
  }

  return {
    url,
    path,
    isLoading,
    isNotAuthorized,
    isOwner,
    isUsersPageRestricted,
    canAccessUsers,
  };
};
