import { EmptyTitle, EmptySubtitle } from 'components/ResponsiveTable/Empty';
import React from 'react';

const UsersEmpty = () => {
  return (
    <>
      <EmptyTitle>Nothing to see here...</EmptyTitle>
      <EmptySubtitle>There are no users in this team</EmptySubtitle>
    </>
  );
};

export default UsersEmpty;
