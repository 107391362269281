import {
  TableCellValue as DefaultTableCellValue,
  TableCellGroup as DefaultTableCellGroup,
} from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';

export const TableCellValue = styled(DefaultTableCellValue)`
  z-index: 2;
`;

export const TableCellGroup = styled(DefaultTableCellGroup)`
  flex-direction: column;
  gap: 4px;
`;
