import styled from 'styled-components';
import { ProgressBar as DefaultProgressBar } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div``;

export const ProgressBar = styled(DefaultProgressBar).attrs({
  type: 'primary',
})`
  height: 4px;
`;
