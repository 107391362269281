import React, { memo } from 'react';
import { Button } from '@scriptaddicts/yamm-ui-components';
import { Props } from './types';
import { useLogic } from './logic';

function ExportToCsv<T>({
  data,
  getData,
  transformer,
  fileName,
  onDone,
  ...props
}: Props<T>) {
  const { exportData, loading } = useLogic({
    data,
    getData,
    transformer,
    fileName,
    onDone,
  });

  return (
    <Button
      {...props}
      data-test-id="submit-export"
      variant="gray"
      icon="download"
      aria-label="export to csv"
      disabled={!data.length || loading}
      loading={loading}
      onClick={exportData}
    >
      <span>Export to CSV</span>
    </Button>
  );
}

export default memo(ExportToCsv);
