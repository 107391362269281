import { useField } from 'formik';
import { useCallback } from 'react';
import { ConnectProps } from './types';

const useConnect = ({ name, onChange }: ConnectProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField<boolean>(name);
  const hasError = !!error && !!touched;

  const handleChange = useCallback(
    (val: boolean) => {
      setValue(val);
      if (onChange) {
        onChange(val);
      }
    },
    [setValue, onChange, value],
  );

  return {
    error,
    handleChange,
    hasError,
    value,
  };
};

export default useConnect;
