import { postCancelAutoRenewal } from 'data/requests/cancelRenewal';
import { useMutation } from 'react-query';

export const useCancelAutoRenewal = () => {
  return useMutation(
    async ({ subscriptionId }: { subscriptionId?: string }) => {
      if (!subscriptionId) throw new Error('Subscription Id is missing');
      return postCancelAutoRenewal({ subscriptionId });
    },
  );
};
