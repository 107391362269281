import React, { FC, memo, useEffect } from 'react';
import { Text, Icon } from '@scriptaddicts/yamm-ui-components';
import {
  Container,
  SmallCannonContainer,
  SmallCannonLink,
  SmallCannonText,
} from './styles';
import { appendUtmString } from '../../utils/smallCannonUtils';
import { Props } from './types';
import { Promotion } from '../../model/promotion';
import { useLogic } from './logic';

const SmallCannonNavBanner: FC<Props> = ({ cannon }) => {
  const { title, subtitle, logClick, logDisplay, promotions } = useLogic({
    cannon,
  });

  useEffect(() => {
    if (promotions != null) {
      logDisplay();
    }
  }, []);

  if (promotions == null) {
    return <></>;
  }

  return (
    <Container>
      <SmallCannonText>{`${title}\n${subtitle}`}</SmallCannonText>
      <SmallCannonContainer>
        {promotions.map((entry: Promotion) => (
          <SmallCannonLink
            key={entry.productId}
            onClick={() => logClick(entry)}
            href={appendUtmString(entry.url, 'sidebar')}
            target="_blank"
          >
            <Icon name="chevron-right" />
            <Text size="small">{entry.name}</Text>
          </SmallCannonLink>
        ))}
      </SmallCannonContainer>
    </Container>
  );
};

export default memo(SmallCannonNavBanner);
