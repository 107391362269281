import useQueryParams from '../../../../utils/useQueryParams';
import { CHANGE_BILLING_CYCLE } from '../../../../data/queryParams';

export const useChangeBillingCycle = () => {
  const queryParams = useQueryParams();
  const changeBillingCycle = queryParams.get(CHANGE_BILLING_CYCLE) === '1';

  return {
    changeBillingCycle,
  };
};
