import {
  Dispatch,
  DispatchWithoutAction,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ActionType, Filters, SortingRule } from 'react-table';
import {
  convertFilterToParameters,
  convertSortToParameters,
} from 'utils/parameters';
import { TableStateManager } from './types';

type PaginationParams = Partial<{
  limit: number;
  limitOffset: number;
}>;

type PaginationInput = {
  pageIndex: number;
  itemsPerPage: number;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
};

type PaginationSettings = Partial<{
  pageCount: number;
  paginationKey: any;
}>;

type PaginationState = {
  pageCount?: number;
  manualPagination: boolean;
};

type PaginationOutput = {
  resetPagination: DispatchWithoutAction;
};

const usePagination = (
  setState: Dispatch<SetStateAction<PaginationParams>>,
  settings?: PaginationSettings,
) => {
  const [innerState, setInnerState] = useState<{
    pageIndex: number;
    resetPage: DispatchWithoutAction;
  }>({
    pageIndex: 0,
    resetPage: () => {},
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const [lastInput, setLastInput] = useState<PaginationInput | undefined>(
    undefined,
  );

  const setPaginationParams = useCallback(
    (input: PaginationInput) => {
      if (!settings?.paginationKey) return;
      if (input.itemsPerPage === 0) return;
      setPageCount(
        (pC) => settings?.pageCount ?? Math.max(input.pageIndex + 1, pC),
      );
      setState((props) => ({
        ...props,
        limit: input.itemsPerPage,
        limitOffset: input.pageIndex * input.itemsPerPage,
      }));
      setInnerState({
        pageIndex: input.pageIndex,
        resetPage: () => input.gotoPage(0),
      });
      setLastInput(input);
    },
    [setState, settings],
  );

  const feedbackPagination = useCallback(
    (size: number) => {
      if (size !== 0) return;
      if (!settings?.paginationKey) return;
      if (!lastInput || lastInput.pageIndex === 0) return;

      lastInput.gotoPage(lastInput.pageIndex - 1);
      setPageCount((pC) => (pC ? pC - 1 : pC));
      setLastInput(undefined);
    },
    [settings, lastInput],
  );

  const resetPagination = useCallback(() => {
    if (!settings?.paginationKey) return;
    setPageCount(settings?.pageCount ?? 1);
    if (innerState.pageIndex !== 0) innerState.resetPage();
  }, [setPageCount, settings, innerState]);

  return {
    setPaginationParams,
    feedbackPagination,
    state: {
      pageCount: settings?.paginationKey ? pageCount : undefined,
      manualPagination: !!settings?.paginationKey,
    } as PaginationState,
    output: {
      resetPagination,
    } as PaginationOutput,
  };
};

type FilterParams = Partial<{
  filters: Record<string, any>;
}>;

type FilterInput = {
  activeFilters: Filters<object>;
  globalFilter: any;
};

type FilterSettings = Partial<{
  filterBy: Record<
    string,
    {
      id: string;
      value: (value: any) => any;
    }
  >;
  globalFilter: string;
}>;

type FilterState = {
  manualFilters: boolean;
  manualGlobalFilter: boolean;
};

const useFilter = (
  setState: Dispatch<SetStateAction<FilterParams>>,
  settings?: FilterSettings,
) => {
  const setFilterParams = useCallback(
    (input: FilterInput) => {
      if (!settings?.filterBy && !settings?.globalFilter) return;
      setState((props) => ({
        ...props,
        filters: {
          ...(settings.filterBy
            ? convertFilterToParameters(input.activeFilters, settings.filterBy)
            : {}),
          ...(settings.globalFilter
            ? {
                [settings.globalFilter]: {
                  like: input.globalFilter ?? undefined,
                },
              }
            : {}),
        },
      }));
    },
    [setState, settings],
  );

  return {
    setFilterParams,
    state: {
      manualFilters: !!settings?.filterBy,
      manualGlobalFilter: !!settings?.globalFilter,
    } as FilterState,
  };
};

type SortParams = Partial<{
  sort: Record<string, any>;
}>;

type SortInput = {
  sortBy: SortingRule<object>[];
};

type SortSettings = Partial<{
  sortBy: Record<string, string>;
}>;

type SortState = {
  manualSortBy: boolean;
};

const useSort = (
  setState: Dispatch<SetStateAction<SortParams>>,
  settings: SortSettings,
) => {
  const setSortParams = useCallback(
    (input: SortInput) => {
      if (!settings.sortBy) return;
      setState((props) => ({
        ...props,
        sort: settings.sortBy
          ? convertSortToParameters(input.sortBy, settings.sortBy)
          : undefined,
      }));
    },
    [setState, settings],
  );

  return {
    setSortParams,
    state: {
      manualSortBy: false, // This is set to false for partial sorting in Mail Merges
    } as SortState,
  };
};

type SelectInput = {
  selectedRowIds: Record<string, boolean>;
  dispatch: Dispatch<ActionType>;
};

type SelectSettings = Partial<{
  idField: string;
}>;

type SelectOutput = {
  selected: string[];
  resetSelected: DispatchWithoutAction;
  select: Dispatch<{ id: string; value: boolean }> | null;
  selectAll: Dispatch<{ value: boolean }> | null;
};

type SelectState = {
  getRowId?: (originalRow: any, relativeIndex: number) => string;
};

const useSelection = (settings: SelectSettings) => {
  const [output, setOutput] = useState<SelectOutput>({
    selected: [],
    resetSelected: () => {},
    select: null,
    selectAll: null,
  });

  const getRowId = useCallback(
    (originalRow: any, relativeIndex: number): string => {
      if (!settings.idField) return String(relativeIndex);

      const field = originalRow[settings.idField];
      if (!field) return String(relativeIndex);

      return typeof field === 'string' ? field : String(field);
    },
    [settings],
  );

  const setSelectionParams = useCallback(
    (input: SelectInput) => {
      if (!settings.idField) return;
      setOutput({
        selected: Object.entries(input.selectedRowIds)
          .filter(([id, value]) => !!id && !!value)
          .map(([id]) => id),
        resetSelected: () => input.dispatch({ type: 'resetSelectedRows' }),
        select: ({ id, value }) =>
          input.dispatch({ type: 'toggleRowSelected', id, value }),
        selectAll: ({ value }) =>
          input.dispatch({ type: 'toggleAllRowsSelected', value }),
      });
    },
    [setOutput, settings],
  );

  return {
    setSelectionParams,
    state: {
      getRowId: !settings.idField ? undefined : getRowId,
    } as SelectState,
    output,
  };
};

type TableStateManagerSettings = PaginationSettings &
  FilterSettings &
  SortSettings &
  SelectSettings;

export default function useTableStateManager<TParams = any>(
  settings: TableStateManagerSettings,
) {
  const [params, setParams] = useState<
    PaginationParams & FilterParams & SortParams
  >({});

  const {
    output: { resetPagination },
    state: paginationState,
    ...pagination
  } = usePagination(setParams, settings);
  const { state: filterState, ...filter } = useFilter(setParams, settings);
  const { state: sortState, ...sort } = useSort(setParams, settings);
  const {
    output: selectionOutput,
    state: selectionState,
    ...selection
  } = useSelection(settings);

  useEffect(() => {
    resetPagination();
  }, [params.filters, params.sort, settings.paginationKey]);

  const manager = useMemo<TableStateManager>(
    () => ({
      ...pagination,
      ...paginationState,
      ...filter,
      ...filterState,
      ...sort,
      ...sortState,
      ...selection,
      ...selectionState,
    }),
    [
      pagination,
      filter,
      sort,
      selection,
      paginationState,
      filterState,
      sortState,
      selectionState,
    ],
  );

  const state = useMemo(() => ({ selection: selectionOutput }), [
    selectionOutput,
  ]);

  return {
    params: params as TParams,
    manager,
    state,
  };
}
