import { useCallback, useEffect, useState } from 'react';
import {
  WorkspaceMember,
  WorkspaceMemberParams,
} from '../../../../model/workspaceMembers';
import { CheckoutUser } from '../../../../model/checkout';
import { useWorkspaceContext } from '../../WorkspaceContext';
import { useWorkspaceMembers } from '../../../hooks/useWorkspaceMembers';
import { FETCH_WORKSPACE_USERS_MAX } from '../../../constants';
import { readCheckoutSessionUsers } from '../../../../utils/checkoutSessionUsers';

const workspaceMembersParams: WorkspaceMemberParams = {
  filters: {
    memberStatus: ['ACTIVE', 'DISABLED', 'PENDING', 'ACCEPTED'],
  },
  limit: FETCH_WORKSPACE_USERS_MAX,
};

const useMemebersOptions = {
  refetchOnWindowFocus: false,
};

const processMembers = (members: WorkspaceMember[], users: CheckoutUser[]) => {
  const state = readCheckoutSessionUsers();
  const markAllAsPaid = !state?.users;

  const isUserMarked = (userId: string) => {
    if (state?.users) {
      return state.users.find((user) => user.id === userId)?.isPaid;
    }

    // if no users were marked, all users should be upgraded to paid
    return undefined;
  };

  return members.map((member) => {
    const existing = users.find((user) => user.id === member.id);
    const isMarked = isUserMarked(member.id);

    let isPaid = false;
    if (existing && existing.isPaid) {
      // users already where initiated
      isPaid = true;
    } else if (!existing) {
      // initialization
      if (isMarked !== undefined) {
        isPaid = isMarked;
      } else {
        isPaid = markAllAsPaid ? true : member.plan !== 'FREE';
      }
    }

    return {
      id: member.id,
      name: member.name,
      email: member.email,
      role: member.role,
      plan: member.plan,
      toBeRemoved: existing ? existing.toBeRemoved : false,
      isPaid,
      status: member.status,
      isUserInDomain: member.isUserInDomain,
    };
  });
};

export const useUsers = () => {
  const { id } = useWorkspaceContext();
  const { data: members, isLoading: isLoadingUsers } = useWorkspaceMembers(
    id,
    workspaceMembersParams,
    useMemebersOptions,
  );
  const [users, setUsers] = useState<CheckoutUser[]>([]);

  useEffect(() => {
    if (members) {
      setUsers((currentUsers) => processMembers(members, currentUsers));
    }
  }, [members]);

  const setUserPaidStatus = useCallback((userId: string, isPaid: boolean) => {
    setUsers((stateUsers) =>
      stateUsers.map((user) => ({
        ...user,
        isPaid: user.id === userId ? isPaid : user.isPaid,
      })),
    );
  }, []);

  const markUsersForRemoval = useCallback(
    (userIds: string[], remove: boolean = true) => {
      setUsers((stateUsers) =>
        stateUsers.map((user) => ({
          ...user,
          toBeRemoved: userIds.includes(user.id) ? remove : user.toBeRemoved,
        })),
      );
    },
    [],
  );

  const setUsersPaidStatus = useCallback((ids: string[], isPaid: boolean) => {
    setUsers((stateUsers) =>
      stateUsers.map((user) => {
        if (ids.includes(user.id)) {
          return {
            ...user,
            isPaid,
          };
        }
        return user;
      }),
    );
  }, []);

  return {
    setUserPaidStatus,
    users,
    isLoadingUsers,
    markUsersForRemoval,
    setUsersPaidStatus,
  };
};
