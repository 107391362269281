import styled from 'styled-components';
import { Text } from '@scriptaddicts/yamm-ui-components';

export const AddUsersTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const BigUsersListText = styled(Text)`
  margin-top: 16px !important;
`;
