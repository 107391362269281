import styled from 'styled-components';

import { from } from 'styles/media';

export const ContainerDiv = styled.div`
  margin: 0 auto;
  max-width: 58.5rem;
  padding: 0 24px;

  ${from.tablet} {
    padding: 0px;
  }
`;
