import styled from 'styled-components';
import { Text as DefaultText } from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;

  padding: 24px;
  box-shadow: 0px -1px 0px #204e7a;
`;

export const SmallCannonText = styled(DefaultText).attrs({
  type: 'paragraph',
  size: 'small',
})`
  color: ${({ theme }) => theme.colors.blue100};
  white-space: pre-wrap;
`;

export const SmallCannonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  align-items: flex-start;
`;

export const SmallCannonLink = styled.a`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 1.875px 0px;

  align-items: center;
  text-decoration: none;
  span {
    color: ${({ theme }) => theme.colors.white};
  }

  transition: box-shadow 0.1s;
  &:focus-visible {
    outline: none;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.colors.blue200};
  }

  svg {
    width: 12px;
    height: 12px;
    path {
      fill: ${({ theme }) => theme.colors.blue200};
    }
  }
`;
