import styled from 'styled-components';

export const SpacePlanBadge = styled.span`
  display: flex;
  height: 20px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;

  &.pro {
    background-color: ${({ theme }) => theme.colors.orange500};
    color: ${({ theme }) => theme.colors.orange100};
  }

  &.non-pro {
    background-color: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.blue100};
  }
`;
