import React from 'react';
import {
  TableCell,
  Icon,
  Tooltip,
  TableCellValueGroup,
} from '@scriptaddicts/yamm-ui-components';
import { Row } from 'react-table';
import { TableCellValue } from './styles';
import { CheckoutUser } from '../../../../model/checkout';
import { Column } from '../../../../components/ResponsiveTable/types';
import { plurify } from '../../../../utils/plurify';
import { WorkspaceMemberStatus } from '../../../../model/workspaceMembers';

const renderStatusCell = ({
  value,
  row,
  domain,
  maxFreeUsers,
}: {
  value: WorkspaceMemberStatus;
  row: Row<CheckoutUser>;
  domain?: string;
  maxFreeUsers?: number;
}) => {
  if (value === 'ACTIVE' || value === 'ACCEPTED') {
    return <TableCellValue variant="success">Active</TableCellValue>;
  }
  if (value === 'PENDING') {
    return <TableCellValue>Pending</TableCellValue>;
  }
  if (value === 'DISABLED') {
    return (
      <TableCellValueGroup variant="warning">
        <span>Disabled</span>
        {(() => {
          if (domain && !row.original.isUserInDomain) {
            return (
              <Tooltip
                tip={`Your current plan only allows user from domain ${domain}.`}
              >
                <Icon name="alert-triangle" />
              </Tooltip>
            );
          }
          if (maxFreeUsers !== undefined) {
            return (
              <Tooltip
                tip={`Your current plan does not allow more than ${maxFreeUsers} free ${plurify(
                  maxFreeUsers,
                  'user',
                  'users',
                )}.`}
              >
                <Icon name="alert-triangle" />
              </Tooltip>
            );
          }
          return undefined;
        })()}
      </TableCellValueGroup>
    );
  }
  return (
    <TableCellValue style={{ textTransform: 'capitalize' }}>
      {value?.toLowerCase()}
    </TableCellValue>
  );
};

const getDesktopTable = ({
  domain,
  maxFreeUsers,
}: {
  domain?: string;
  maxFreeUsers?: number;
}) =>
  [
    {
      Header: 'Email',
      accessor: 'email',
      colSpan: 6,
      Cell: ({ value }) => <TableCellValue>{value}</TableCellValue>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      float: 'right',
      colSpan: 1,
      Cell: ({ value, row }) =>
        renderStatusCell({ value, row, domain, maxFreeUsers }),
    },
  ] as Column<CheckoutUser>[];

const getMobileTable = ({
  domain,
  maxFreeUsers,
}: {
  domain?: string;
  maxFreeUsers?: number;
}) =>
  [
    {
      Header: 'User',
      accessor: 'name',
      group: true,
      Cell: ({ row }: { row: any }) => (
        <>
          <TableCell colSpan={2}>
            <TableCellValue width="semi-bold">
              {row.original.name}
            </TableCellValue>
          </TableCell>
          <TableCell colSpan={3}>{row.original.email}</TableCell>
        </>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      colSpan: 1,
      Cell: ({ value, row }) =>
        renderStatusCell({ value, row, domain, maxFreeUsers }),
    },
  ] as Column<CheckoutUser>[];

export const getColumns = ({
  isMobile,
  domain,
  maxFreeUsers,
}: {
  isMobile: boolean;
  domain?: string;
  maxFreeUsers?: number;
}) =>
  (isMobile
    ? getMobileTable({ domain, maxFreeUsers })
    : getDesktopTable({ domain, maxFreeUsers })) as Column<CheckoutUser>[];
