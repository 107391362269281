import axios, { AxiosError } from 'axios';
import { captureException } from '@sentry/minimal';

const IGNORE_STATUSES = [400, 401, 404, 409];

function captureError(error: unknown) {
  const axiosError = error as AxiosError;
  const status = axiosError.response?.status;
  const response = JSON.stringify(axiosError.response?.data);
  if (status && IGNORE_STATUSES.includes(status)) {
    // No need to log backend 40x errors
    return;
  }
  captureException(
    new Error(`${error}, code: ${axiosError.code}, response: ${response}`),
  );
}

async function authenticate(code: string): Promise<any> {
  let res;
  try {
    res = await axios.post(
      `${process.env.REACT_APP_NEW_GOOGLE_AUTH_URL}/v1/auth/token`,
      {
        code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    captureError(error);
  }
  return res;
}

async function refreshToken(
  backendIdToken: string,
  sessionId: string,
): Promise<any> {
  let res;
  try {
    res = await axios.post(
      `${process.env.REACT_APP_NEW_GOOGLE_AUTH_URL}/v1/auth/refresh-token`,
      {
        sessionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${backendIdToken}`,
        },
      },
    );
  } catch (error) {
    captureError(error);
  }
  return res;
}

async function revokeToken(
  backendIdToken: string,
  sessionId: string,
): Promise<any> {
  let res;
  try {
    res = await axios.post(
      `${process.env.REACT_APP_NEW_GOOGLE_AUTH_URL}/v1/auth/revoke`,
      {
        sessionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${backendIdToken}`,
        },
      },
    );
  } catch (error) {
    captureError(error);
  }
  return res;
}

async function getTokenInfo(accessToken: string): Promise<any> {
  let res;
  try {
    res = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    captureError(error);
  }
  return res;
}

async function getUserInfo(accessToken: string): Promise<any> {
  let res;
  try {
    res = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    captureError(error);
  }
  return res;
}

export { authenticate, refreshToken, revokeToken, getTokenInfo, getUserInfo };
