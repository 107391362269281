import testingVar from 'utils/testingVar';
import { useMe } from 'data/hooks/useMe';
import { useEffect } from 'react';
import { ClarityUtils } from 'utils/clarityUtils';
import { getSessionId } from 'utils/cookiesUtils';

export const useConnect = () => {
  const { data: me } = useMe({ keepPreviousData: true });
  const isTesting = testingVar('');
  const sessionId = getSessionId();

  useEffect(() => {
    if (me && me.email) {
      ClarityUtils.identify(me.email, sessionId);
    }
  }, [me]);

  return {
    isTesting,
    me,
  };
};
