import { useAuth } from 'data/context/AuthContext';
import testingVar from 'utils/testingVar';

export const useConnect = () => {
  const { onLogin, onSignUp, onSwitchAccount, loading, error } = useAuth();
  const isLoading = loading && !error;
  const loginTestingVar = testingVar('login');
  return {
    onLogin,
    onSignUp,
    onSwitchAccount,
    isLoading,
    error,
    loginTestingVar,
  };
};
