import { useMemo } from 'react';
import { User } from 'model/user';
import { useValidatePromoteMembers } from 'data/hooks/useWorkspaceMembers';
import { ActiveUsers } from '../types';
import {
  WorkspaceMember,
  WorkspaceMemberPromitionValidity,
} from '../../../../../../model/workspaceMembers';
import {
  WorkspaceDetails,
  WorkspaceUser,
} from '../../../../../../data/context/WorkspaceContext';
import { getRoleValue, RoleValue } from '../../../../../../utils/roleValue';
import { WorkspaceSubscription } from '../../../../../../model/workspaceBilling';
import {
  isOfflineSubscription,
  isSupportGivenSubscription,
} from '../../../../../../utils/subscriptions';

const ACTIVE_STATUS = ['ACTIVE', 'ACCEPTED'];

function allUsersSamePaidType(users: WorkspaceMember[]): boolean {
  if (users.length === 0) {
    return false;
  }

  if (users.length === 1) {
    return true;
  }

  let isTargetPaid = false;
  for (let i = 0; i < users.length; i += 1) {
    const isPaid = users[i].plan !== 'FREE';
    if (i === 0) {
      isTargetPaid = isPaid;
    } else if (isPaid !== isTargetPaid) {
      return false;
    }
  }

  return true;
}

function hasPendingDowngradeUsers(users: WorkspaceMember[]): boolean {
  return users.some(
    ({ isScheduledToBeDowngraded }) => isScheduledToBeDowngraded,
  );
}

function getTypeRestrictions(
  activeUsers: WorkspaceMember[],
  promotionRestrictedUsers?: WorkspaceMemberPromitionValidity[],
) {
  return activeUsers
    .map(({ id, email }) => ({
      email,
      errorCode: promotionRestrictedUsers?.find(({ userId }) => userId === id)
        ?.errorCode,
    }))
    .filter(({ errorCode }) => !!errorCode)
    .reduce(
      (map, { email, errorCode }) => ({
        ...map,
        [errorCode as string]: [...(map[errorCode as string] ?? []), email],
      }),
      {} as Record<string, string[]>,
    );
}

function hasSelectedAllPaidUsers(
  allUsers: WorkspaceMember[],
  selected: WorkspaceMember[],
): boolean {
  for (let i = 0; i < allUsers.length; i += 1) {
    const isPaid =
      allUsers[i].plan !== 'FREE' && !allUsers[i].isScheduledToBeDowngraded;
    const isSelected = selected.find((user) => user.id === allUsers[i].id);
    if (isPaid && !isSelected) {
      return false;
    }
  }

  return true;
}

export type UseActiveUsersProps = {
  selected: WorkspaceMember[];
  allUsers: WorkspaceMember[];
  me: User | null;
  workspaceId?: string;
  workspace?: WorkspaceDetails;
  workspaceUser?: WorkspaceUser;
  activeSubscription?: WorkspaceSubscription;
  isMultiplePaymentRestricted: boolean;
};
export const useActiveUsers = ({
  workspaceId,
  workspaceUser,
  workspace,
  me,
  selected,
  allUsers,
  activeSubscription,
  isMultiplePaymentRestricted,
}: UseActiveUsersProps) => {
  const { data: promotionRestrictedUsers } = useValidatePromoteMembers(
    isMultiplePaymentRestricted && workspace?.plan !== 'LEGACY'
      ? workspaceId
      : undefined,
  );

  return useMemo<ActiveUsers>(() => {
    const canAccessWorkspaceBilling = !!workspaceUser?.features
      .canAccessWorkspaceBilling;
    const userRoleValue = getRoleValue(workspaceUser?.role || 'MEMBER');
    const canManageRoles =
      !!workspaceUser?.features.manageUserRoles &&
      userRoleValue >= RoleValue.OWNER;
    const activeUsers = selected.filter((s) =>
      ACTIVE_STATUS.includes(s?.status),
    );
    const typeRestrictions = getTypeRestrictions(
      activeUsers,
      promotionRestrictedUsers,
    );

    const isSupportGiven =
      activeSubscription && isSupportGivenSubscription(activeSubscription);
    const isActiveOfflineSubscription =
      activeSubscription && isOfflineSubscription(activeSubscription);
    const isProOrPremium =
      workspace?.plan === 'PRO' || workspace?.plan === 'PREMIUM';
    const isLegacy = workspace?.plan === 'LEGACY';

    const allUsersSameType = allUsersSamePaidType(activeUsers);
    const hasDowngradedUsers = hasPendingDowngradeUsers(activeUsers);
    const hasPaidUsers = activeUsers.some((user) => user.plan !== 'FREE');
    const isOwnerSelected = activeUsers.some(
      ({ id: userId, role }) => userId === me?.id || role === 'OWNER',
    );

    const areAllPaidUsersSelected = hasSelectedAllPaidUsers(
      allUsers,
      activeUsers,
    );

    // display free/paid
    const canChangeType =
      workspace?.plan !== 'FREE' &&
      !isLegacy &&
      !isSupportGiven &&
      canAccessWorkspaceBilling &&
      allUsersSameType &&
      !hasDowngradedUsers;

    // display member/admin
    const canChangeRole =
      workspace?.plan !== 'FREE' &&
      canManageRoles &&
      activeUsers.every(({ role }) => role !== 'OWNER');

    return {
      count: activeUsers.length,
      user: activeUsers.length === 1 ? activeUsers[0] : undefined,
      list: activeUsers,
      // false = do not display
      features: {
        change: {
          // can change user type (free/paid)
          type: canChangeType,
          // can change user role (member/admin)
          role: canChangeRole,
        },
        remove: !isSupportGiven && userRoleValue >= RoleValue.ADMIN,
      },
      type: activeUsers[0]?.trial ? 'TRIAL' : activeUsers[0]?.plan,
      role: activeUsers[0]?.role,
      isPersonal: activeUsers[0]?.isPersonal,
      warnings: {
        type: (() => {
          if (areAllPaidUsersSelected) {
            return 'ALL_PAID_SELECTED';
          }
          if (!allUsersSameType) {
            return 'NOT_SAME_TYPE';
          }
          if (typeRestrictions.already_paid_user) {
            return 'ALREADY_PAID';
          }
          return undefined;
        })(),
        remove: isOwnerSelected ? 'CANNOT_REMOVE_OWNER' : undefined,
        subjectEmails: (() => {
          if (workspace?.plan === 'LEGACY') {
            return [];
          }
          if (!allUsersSameType) {
            return [];
          }
          if (typeRestrictions.already_paid_user) {
            return typeRestrictions.already_paid_user;
          }
          return [];
        })(),
        cannotRemovePaidUsers: isProOrPremium && hasPaidUsers,
      },
      // true = disabled (but can bi displayed)
      // to completely hide element refer to features object
      disabled: {
        change: {
          type:
            isActiveOfflineSubscription ||
            !!typeRestrictions.already_paid_user ||
            areAllPaidUsersSelected,
          role: isLegacy,
        },
        remove:
          (isProOrPremium && hasPaidUsers) || isSupportGiven || isOwnerSelected,
      },
    };
  }, [
    activeSubscription,
    selected,
    allUsers,
    promotionRestrictedUsers,
    me,
    workspaceUser,
    workspace,
  ]);
};
