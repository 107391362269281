import { Filters, SortingRule } from 'react-table';
import { FilterParameters, SortParameters } from './types';

const checkSubValue = (subValue: string | number | string[] | undefined) =>
  !subValue && subValue !== 0;

export const createFilter = (
  filters?: FilterParameters,
): string | undefined => {
  if (!filters) return undefined;

  const filterList = Object.entries(filters).reduce(
    (list, [parameter, value]) => {
      if (!value && value !== 0 && value !== false) return list;

      if (typeof value !== 'object') {
        return [...list, `${parameter}:${value}`];
      }
      if (Array.isArray(value)) {
        return [
          ...list,
          ...value.map((subValue) => `${parameter}:${subValue}`),
        ];
      }
      return [
        ...list,
        ...Object.entries(value).reduce((subList, [operation, subValue]) => {
          if (checkSubValue(subValue)) return subList;
          if (Array.isArray(subValue)) {
            return [
              ...subList,
              ...subValue.map(
                (ssubValue) => `${parameter}[${operation}]:${ssubValue}`,
              ),
            ];
          }
          return [...subList, `${parameter}[${operation}]:${subValue}`];
        }, [] as string[]),
      ];
    },
    [] as string[],
  );

  return filterList.length === 0 ? undefined : filterList.join(',');
};

export const createSort = (sorts?: SortParameters): string | undefined => {
  if (!sorts) return undefined;
  const sortList = Object.entries(sorts).reduce(
    (list, [parameter, operation]) =>
      parameter && operation ? [...list, `${parameter}:${operation}`] : list,
    [] as string[],
  );

  return sortList.length === 0 ? undefined : sortList.join(',');
};

export const convertSortToParameters = (
  rules: SortingRule<object>[],
  mapping: Record<string, string>,
): { [parameter: string]: 'asc' | 'desc' } => {
  return rules.reduce(
    (map, { id, desc }) =>
      mapping[id]
        ? {
            ...map,
            [mapping[id]]: desc ? 'desc' : 'asc',
          }
        : map,
    {},
  );
};

export const convertFilterToParameters = (
  rules: Filters<object>,
  mapping: Record<string, { id: string; value: (value: any) => any }>,
): { [parameter: string]: 'asc' | 'desc' } => {
  return rules.reduce(
    (map, { id, value }) =>
      mapping[id]
        ? {
            ...map,
            [mapping[id].id]: mapping[id].value(value),
          }
        : map,
    {},
  );
};
