import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';

import { useWorkspaceMembers } from '../../data/hooks/useWorkspaceMembers';
import { FETCH_WORKSPACE_USERS_MAX } from '../../data/constants';

export const useConnect = () => {
  const { status: meStatus } = useMe({ keepPreviousData: true });
  const { id, isLoading, otherWorkspaces } = useWorkspaceContext();
  const { data: members, status: membersStatus } = useWorkspaceMembers(id, {
    filters: {
      memberStatus: ['ACTIVE'],
      userRole: ['ADMIN', 'OWNER'],
    },
    limit: FETCH_WORKSPACE_USERS_MAX,
  });
  const ownerEmail = members.find((member) => member.role === 'OWNER')?.email;
  const adminEmails = members
    .filter((member) => member.role === 'ADMIN')
    .map((member) => member.email);
  const currentWorkspace = otherWorkspaces.find((a) => a.id === id);
  return {
    isLoading:
      isLoading || meStatus === 'loading' || membersStatus === 'loading',
    ownerEmail,
    adminEmails,
    currentWorkspace,
  };
};
