import { clientV2 } from 'data/client';
import { transformConsolidatedUnsubscriptions } from 'data/transform/unsubscription';
import { InputParams } from 'model/parameters';
import {
  ConsolidatedUnsubscriptionInput,
  DeleteUnsubscription,
  PostUnsubscriptionsInput,
  PostUnsubscriptionsParams,
} from 'model/unsubscription';

export async function getUnsubscriptions(
  workspaceId: string,
  params?: InputParams,
) {
  const { data } = await clientV2.get<{
    items: ConsolidatedUnsubscriptionInput[];
  }>(`/users/me/workspaces/${workspaceId}/unsubscriptions`, {
    params,
  });

  return transformConsolidatedUnsubscriptions(data.items);
}

export async function postUnsubscriptionsOnActivation(
  workspaceId: string,
  params: PostUnsubscriptionsParams,
) {
  const { data } = await clientV2.post<PostUnsubscriptionsInput>(
    `/users/workspace-activation-add-unsubscriptions/me`,
    { ...params, workspaceId },
  );

  return data.updated;
}

export async function postUnsubscriptions(
  workspaceId: string,
  params: PostUnsubscriptionsParams,
) {
  const { data } = await clientV2.post<PostUnsubscriptionsInput>(
    `/users/me/workspaces/${workspaceId}/unsubscriptions`,
    params,
  );

  return data.updated;
}

export async function deleteUnsubscriptionOnActivation(
  workspaceId: string,
  { emailAddress }: DeleteUnsubscription,
) {
  const { data } = await clientV2.delete(
    `/users/workspace-activation-delete-unsubscriptions/me/${workspaceId}/${encodeURIComponent(
      emailAddress,
    )}`,
  );

  return data;
}

export async function deleteUnsubscription(
  workspaceId: string,
  { emailAddress }: DeleteUnsubscription,
) {
  const { data } = await clientV2.delete(
    `/users/me/workspaces/${workspaceId}/unsubscriptions/${encodeURIComponent(
      emailAddress,
    )}`,
  );

  return data;
}
