import { captureException } from '@sentry/react';
import { sendLog } from 'utils/logToStackdriver';
import { UserInput } from '../../../model/user';
import { queryClient } from '../../queryClient';
import { UserWorkspacePlan, WorkspacePlan } from '../../../model/workspace';

export const logErrorLoginRequest = (
  error?: string,
  error_description?: string,
) => {
  try {
    sendLog({
      timestamp: Date.now(),
      message: 'Webapp login failed',
      event_type: 'webapp_login_request',
      error,
      error_description,
    });
  } catch (err) {
    captureException(`${error} / ${error_description}`);
    captureException(err);
  }
};

export const logSuccessLoginRequest = (code: string) => {
  try {
    sendLog({
      code,
      timestamp: Date.now(),
      message: 'Webapp login success',
      event_type: 'webapp_login_request',
    });
  } catch (err) {
    captureException(err);
  }
};

export const logLoginSuccess = (userId: string) => {
  const rawUser = queryClient.getQueryData(['me']) as UserInput | undefined;
  let plan: UserWorkspacePlan | undefined;
  let workspacePlan: WorkspacePlan | undefined;
  if (rawUser?.userWorkspaces) {
    const addonWorkspace = rawUser.userWorkspaces.find(
      (workspace) => workspace.workspaceId === rawUser.addonWorkspaceId,
    );
    if (addonWorkspace) {
      plan = addonWorkspace.userPlan;
      workspacePlan = addonWorkspace.workspacePlan;
    }
  }

  sendLog({
    event_type: 'webapp_login_request_successful',
    user_id: userId,
    user_email: rawUser?.userEmail,
    domain: rawUser?.domain.domainName,
    plan,
    workspace_plan: workspacePlan,
    timestamp: Date.now(),
    ui_type: 'spaces',
  });
};
