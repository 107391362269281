import React from 'react';
import { add } from 'date-fns';
import { ScheduledCampaignErrorType } from './types';
import { ErrorLink } from './styles';
import { format12hTime } from '../../../../../utils/dates';
import {
  ERROR_MESSAGES,
  willRetryScheduledMergeInError,
} from '../../../../../utils/scheduledCampaigns';

const ScheduledCampaignError: ScheduledCampaignErrorType = {
  [ERROR_MESSAGES.TOO_COMPLEX_REGEXP]: () => <>The draft is too long.</>,
  [ERROR_MESSAGES.SPREADSHEET_DELETED]: () => (
    <div>
      The spreadsheet used by your mail merge could not be found. Create a new
      spreadsheet to send another mail merge.
    </div>
  ),
  [ERROR_MESSAGES.INVALID_DRAFT]: () => (
    <div>The draft used by your mail merge couldn’t be properly processed.</div>
  ),
  [ERROR_MESSAGES.UNKNOWN_ERROR]: (scheduledCampaign) =>
    scheduledCampaign.recoverableErrorLastRetryTimestamp &&
    willRetryScheduledMergeInError(scheduledCampaign) ? (
      <div>
        An unexpected error occurred. Sending will be attempted again{' '}
        <b>
          today at{' '}
          {format12hTime(
            add(scheduledCampaign.recoverableErrorLastRetryTimestamp, {
              hours: 1,
            }),
          )}
        </b>
        .
      </div>
    ) : (
      <div>
        An unexpected error occurred. If you still want to send it, please
        schedule a new merge.
      </div>
    ),
  [ERROR_MESSAGES.DRAFT_NOT_FOUND]: () => (
    <div>
      The draft used by your mail merge was deleted. If you still want to send
      it, create a new draft, then schedule a new mail merge.
    </div>
  ),
  [ERROR_MESSAGES.NAMED_RANGE_DELETED]: (scheduledCampaign) =>
    scheduledCampaign.recoverableErrorLastRetryTimestamp &&
    willRetryScheduledMergeInError(scheduledCampaign) ? (
      <div>
        The email address column has been altered. Open{' '}
        <ErrorLink href={scheduledCampaign.spreadsheetLink}>
          this sheet
        </ErrorLink>{' '}
        and then open YAMM and that’s it, it will solve the issue. Sending will
        be attempted again{' '}
        <b>
          today at{' '}
          {format12hTime(
            add(scheduledCampaign.recoverableErrorLastRetryTimestamp, {
              hours: 1,
            }),
          )}
        </b>
        .
      </div>
    ) : (
      <div>
        The email address column has been altered. If you still want to send it,
        please schedule a new merge.
      </div>
    ),
  [ERROR_MESSAGES.USER_PROPERTY_NOT_FOUND]: () => (
    <div>
      An unrecoverable error related to your user account has occurred and this
      mail merge cannot be sent anymore. It will be deleted when you create a
      new scheduled mail merge.
    </div>
  ),
  [ERROR_MESSAGES.SHEET_DELETED]: (scheduledCampaign) =>
    scheduledCampaign.recoverableErrorLastRetryTimestamp &&
    willRetryScheduledMergeInError(scheduledCampaign) ? (
      <div>
        The sheet used by your mail merge could not be found. Sending will be
        attempted again{' '}
        <b>
          today at{' '}
          {format12hTime(
            add(scheduledCampaign.recoverableErrorLastRetryTimestamp, {
              hours: 1,
            }),
          )}
        </b>
        . Make sure you restore the sheet “{scheduledCampaign.spreadsheetName}”
        before then. <br />
        <ErrorLink href="https://support.google.com/a/users/answer/9308971">
          Learn how to restore your sheet
        </ErrorLink>
      </div>
    ) : (
      <div>
        The sheet used by your mail merge could not be found. All attempts to
        send failed. If you still want to send it, please restore your sheet and
        then schedule a new merge.
      </div>
    ),
};

export default ScheduledCampaignError;
