import React, { useRef } from 'react';
import { Text, Tooltip } from '@scriptaddicts/yamm-ui-components';
import { BillingInterval } from '../../../../../../model/workspaceBilling';
import {
  StyledTitle,
  StyledPrice,
  StyledSeparator,
  StyledFeatureText,
  StyledFeatureTitle,
  StyledUpgradeButton,
  StyledInfo,
  StyledUpToText,
  StyledFeaturesContainer,
  StyledAnnualPrice,
} from './styles';
import { formatPriceSignOnly } from '../../../../../../utils/priceFormat';
import Space from '../../../../../../components/Space';
import { BillingPlanInformation } from '../data/billingPlans';
import { useSyncRefHeight } from '../../../../../../utils/useSyncHeight';
import { Card } from '../../styles';
import { from, useMediaQuery } from '../../../../../../styles/media';
import { PlanPrice } from '../../../../../../model/paymentPlans';
import { WorkspacePlan } from '../../../../../../model/workspace';

type ActionButtonProps = {
  isCurrentPlan: boolean;
  isDowngrade: boolean;
  isLegacy: boolean;
  onClick: () => void;
};

export type PurchasePlanCardProps = BillingPlanInformation &
  ActionButtonProps & {
    interval: BillingInterval;
    price: PlanPrice;
    gmailPrice?: PlanPrice;
    isGmailUser: boolean;
  };

const getRecipientsLabel = (isGmail: boolean, plan: WorkspacePlan): string =>
  isGmail && plan === 'PRO' ? '400' : '1,500';

const ActionButton = ({
  isDowngrade,
  isCurrentPlan,
  isLegacy,
  onClick,
}: ActionButtonProps) => {
  if (isDowngrade) {
    const downgradeButtonText = 'Downgrade';
    return (
      <StyledUpgradeButton
        onClick={onClick}
        aria-label="Downgrade"
        variant="primary"
        size="extraLarge"
        data-log-event="go_to_checkout"
        data-log-event-data={JSON.stringify({
          displayName: downgradeButtonText,
        })}
      >
        {downgradeButtonText}
      </StyledUpgradeButton>
    );
  }

  if (isCurrentPlan) {
    return (
      <StyledUpgradeButton
        aria-label="Current plan"
        variant="secondary"
        size="extraLarge"
        disabled
      >
        Current plan
      </StyledUpgradeButton>
    );
  }
  const upgradeButtonText = isLegacy ? 'Choose this plan' : 'Upgrade';
  const clickActionType = isLegacy
    ? 'go_to_checkout_legacy_users'
    : 'go_to_checkout';
  return (
    <StyledUpgradeButton
      onClick={onClick}
      aria-label="Upgrade"
      variant="primary"
      size="extraLarge"
      data-log-event={clickActionType}
      data-log-event-data={JSON.stringify({
        displayName: upgradeButtonText,
      })}
    >
      {upgradeButtonText}
    </StyledUpgradeButton>
  );
};

const PurchasePlanCard = ({
  id,
  name,
  description,
  price,
  gmailPrice,
  // interval,
  currency,
  featuresTitle,
  features,
  supportFeatures,
  isCurrentPlan,
  isDowngrade,
  onClick,
  isLegacy,
  isGmailUser,
}: PurchasePlanCardProps) => {
  const featuresRef = useRef(null);
  const descriptionRef = useRef(null);
  const supportFeaturesRef = useRef(null);
  const isMobile = !useMediaQuery(from.tablet);
  // const isYear = interval === 'YEARLY';

  useSyncRefHeight(
    [
      ['purchase-plan-card-description', descriptionRef],
      ['purchase-plan-card-features', featuresRef],
      ['purchase-plan-card-support-features', supportFeaturesRef],
    ],
    !isMobile,
    [isMobile],
  );

  return (
    <Card>
      <StyledTitle>{name}</StyledTitle>
      <Text ref={descriptionRef} type="paragraph">
        {description}
      </Text>
      <Space h={20} />
      <Text
        type="paragraph"
        style={{ display: 'flex', alignItems: 'flex-end' }}
      >
        <StyledPrice>
          {formatPriceSignOnly(price.year / 12, currency, 2)}
        </StyledPrice>{' '}
        <span style={{ display: 'flex', alignItems: 'center' }}>
          / user / month
          {gmailPrice && gmailPrice.month > 0 && (
            <Tooltip
              placement="right"
              tip={`$${gmailPrice.year} / @gmail.com user / year`}
            >
              <StyledInfo />
            </Tooltip>
          )}
        </span>
      </Text>
      <Text>
        <StyledAnnualPrice>
          <span style={{ fontWeight: 600 }}>
            {formatPriceSignOnly(price.year, currency)}
          </span>{' '}
          billed annually
        </StyledAnnualPrice>
      </Text>
      {/* {interval === 'YEARLY' && ( */}
      {/*  <BilledAtLabel> */}
      {/*    Billed at <s>${price.month * 12}</s> $<b>{price.year}</b> /year */}
      {/*  </BilledAtLabel> */}
      {/* )} */}

      <ActionButton
        isLegacy={isLegacy}
        onClick={onClick}
        isCurrentPlan={isCurrentPlan}
        isDowngrade={isDowngrade}
      />

      <Text type="paragraph">
        <StyledUpToText>
          Up to {getRecipientsLabel(isGmailUser, id)} recipients / user / day
          {id === 'PREMIUM' && (
            <Tooltip
              tip="Up to 400 recipients / @gmail.com user / day"
              placement="right"
            >
              <StyledInfo />
            </Tooltip>
          )}
        </StyledUpToText>
      </Text>
      {/* <Text> */}
      {/*  {canInviteUsers ? ( */}
      {/*    <> */}
      {/*      Invite users from <u>different domains</u> */}
      {/*    </> */}
      {/*  ) : ( */}
      {/*    <StyledStrike>Invite users from different domains</StyledStrike> */}
      {/*  )} */}
      {/* </Text> */}

      <StyledSeparator />

      <StyledFeaturesContainer ref={featuresRef}>
        <StyledFeatureTitle>{featuresTitle}</StyledFeatureTitle>
        {features.map((feature) => (
          <StyledFeatureText key={feature}>{feature}</StyledFeatureText>
        ))}
      </StyledFeaturesContainer>

      <StyledSeparator />

      <StyledFeaturesContainer ref={supportFeaturesRef}>
        {/* <StyledFeatureText> */}
        {/*  Up to{' '} */}
        {/*  <Strong> */}
        {/*    <u>10</u> */}
        {/*  </Strong>{' '} */}
        {/*  free users in your space */}
        {/* </StyledFeatureText> */}
        {supportFeatures.map((supportFeature) => (
          <StyledFeatureText key={supportFeature}>
            {supportFeature}
          </StyledFeatureText>
        ))}
      </StyledFeaturesContainer>
    </Card>
  );
};

export default PurchasePlanCard;
