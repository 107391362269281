import { useMemo } from 'react';
import { useCheckoutContext } from '../../../data/context/CheckoutContext';
import { getPaidUsers } from '../../../utils/checkout';
import { CheckoutUser } from '../../../model/checkout';
import { usePrices } from '../../../data/hooks/usePaymentPlans';
import { getPurchasePlansDisplay } from '../common/utils';
import { useBillingOptionsComputePaymentInfo } from '../common/hooks/useBillingOptionsComputePaymentInfo';
import { useAvailableUsers } from '../BillingOptions/hooks/useAvailableUsers';

export const useConnect = () => {
  const { plan, billingInterval, flowType } = useCheckoutContext();
  const { paymentPlans } = usePrices();

  const { availableUsers } = useAvailableUsers();
  const paidUsers = useMemo<CheckoutUser[]>(
    () => getPaidUsers(availableUsers),
    [availableUsers],
  );

  const {
    paymentInfo,
    isFetchingPaymentInfo,
    isLoading: isLoadingPaymentInfo,
  } = useBillingOptionsComputePaymentInfo('OPTIONS');

  const purchasePlansDisplay = useMemo(() => {
    if (paidUsers.length < 1) return [];

    if (paymentPlans && paymentInfo && paymentInfo.stripePlans) {
      return getPurchasePlansDisplay(
        paymentInfo.stripePlans,
        paymentPlans,
        paymentInfo.currency,
      );
    }

    return undefined;
  }, [paymentPlans, paymentInfo, paidUsers]);

  return {
    paymentInfo,
    isLoading: isLoadingPaymentInfo,
    isFetchingPaymentInfo,
    flowType,
    paidUsersCount: paidUsers.length,
    billingInterval,
    purchasePlansDisplay,
    plan,
  };
};
