import React from 'react';

import Container from 'components/Container';
import TrustpilotScore from 'assets/images/icons/trustpilot-score-white.png';
import GoogleReviews from 'assets/images/icons/google-reviews.png';
import testingVar from 'utils/testingVar';
import { GoogleButton, Strong, Text } from '@scriptaddicts/yamm-ui-components';
import LoadingScreen from 'components/LoadingScreen';
import {
  Main,
  Nav,
  NavLink,
  Title,
  ImageWrapper,
  YammImage,
  LinkWrapper,
  LoginCard,
  SingUpCard,
} from '../styles';
import {
  Aside,
  AsideBackdrop,
  Footer,
  LoginContainer,
  Subtitle,
  ErrorWrapper,
  ErrorContainer,
  Badges,
  QuoteBlock,
  Quote,
  QuotationIcon,
  QuoteHighlight,
} from './styles';
import { useConnect } from './connect';
import TosAgreementText from '../TosAgreementText';

const Login = () => {
  const {
    onLogin,
    onSignUp,
    onSwitchAccount,
    isLoading,
    error,
    loginTestingVar,
  } = useConnect();
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <LoginContainer>
      <Main>
        <Container>
          <LoginCard>
            <ImageWrapper>
              <YammImage />
            </ImageWrapper>
            {error && error.type !== 'no-user' && (
              <ErrorWrapper>
                <ErrorContainer>
                  {error.type === 'session-expired' ? (
                    <Text
                      type="paragraph"
                      size="base"
                      width="regular"
                      data-test-id={testingVar('session-expired')}
                    >
                      Your session has expired, please sign in again
                    </Text>
                  ) : null}
                  {error.type === 'google' ? (
                    <Text type="paragraph" size="base" width="regular">
                      There was an error using Google&apos;s sign in method
                    </Text>
                  ) : null}
                  {error.type === 'can-not-create-user' ? (
                    <Text type="paragraph" size="base" width="regular">
                      There was an error creating your account
                    </Text>
                  ) : null}
                </ErrorContainer>
              </ErrorWrapper>
            )}
            {error && error.type === 'no-user' ? (
              <>
                <ErrorWrapper>
                  <ErrorContainer>
                    <Text
                      type="paragraph"
                      size="base"
                      width="regular"
                      data-test-id={testingVar('no-user')}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      <Strong width="medium">{error.payload}</Strong> doesn’t
                      have a YAMM account yet.{'\n'}Click below to create a new
                      account:
                    </Text>
                  </ErrorContainer>
                </ErrorWrapper>
                <GoogleButton
                  data-test-id={testingVar('submit-login')}
                  onClick={onSignUp}
                >
                  Create a new account with Google
                </GoogleButton>
                <TosAgreementText />
              </>
            ) : (
              <>
                <Title>Welcome back!</Title>
                <GoogleButton
                  data-test-id={testingVar('submit-login')}
                  onClick={onLogin}
                >
                  Sign in with Google
                </GoogleButton>
                {loginTestingVar ? (
                  <span data-test-id={loginTestingVar} />
                ) : null}
              </>
            )}
          </LoginCard>

          <SingUpCard>
            {error && error.type === 'no-user' ? (
              <>
                <Subtitle>Did you want to use another account?</Subtitle>
                <GoogleButton
                  data-test-id={testingVar('submit-signup')}
                  onClick={onSwitchAccount}
                >
                  Sign in with another Google account
                </GoogleButton>
              </>
            ) : (
              <>
                <Subtitle>Don&apos;t have a YAMM account yet?</Subtitle>
                <GoogleButton
                  data-test-id={testingVar('submit-login')}
                  onClick={onSignUp}
                >
                  Create a new account with Google
                </GoogleButton>
                <TosAgreementText />
              </>
            )}
          </SingUpCard>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Nav>
            <NavLink href="https://support.yet-another-mail-merge.com/hc/en-us">
              Help center
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/terms-of-service/`}
            >
              Terms of service
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/privacy-policy/`}
            >
              Privacy policy
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/cookie-policy/`}
            >
              Cookie policy
            </NavLink>
            <NavLink href="https://talarian.io/data-processing-agreement">
              Data processing agreement
            </NavLink>
          </Nav>
        </Container>
      </Footer>
      <Aside>
        <AsideBackdrop />

        <QuoteBlock>
          <Quote className="right">
            <QuotationIcon />
            YAMM is easy to use and has greater success with{' '}
            <QuoteHighlight>
              delivering emails to inboxes instead of spam folders
            </QuoteHighlight>{' '}
            compared with other email services.
          </Quote>
          <Quote className="left">
            <QuotationIcon />
            <QuoteHighlight>
              This add-on has saved me hours of time, many dollars of postage,
              several trees-worth of paper.
            </QuoteHighlight>{' '}
            It&apos;s easy to get set up. I&apos;m so thankful!
          </Quote>
          <Quote className="right">
            <QuotationIcon />
            <QuoteHighlight>
              It has been a game changer for our business.
            </QuoteHighlight>{' '}
            Being able to send mass emails and you can track them. We love it!
          </Quote>
        </QuoteBlock>

        <Badges>
          <LinkWrapper href="https://workspace.google.com/marketplace/app/yet_another_mail_merge_mail_merge_for_gm/52669349336">
            <img alt="google-reviews" src={GoogleReviews} />
          </LinkWrapper>
          <LinkWrapper href="https://www.trustpilot.com/review/yamm.com">
            <img alt="trustpilot-score" src={TrustpilotScore} />
          </LinkWrapper>
        </Badges>
      </Aside>
    </LoginContainer>
  );
};

export default Login;
