import styled, { css } from 'styled-components';
import {
  Card as DefaultCard,
  PromptContent as DefaultPromptContent,
  Heading as DefaultHeading,
  Text as DefaultText,
} from '@scriptaddicts/yamm-ui-components';
import { from } from 'styles/media';

export const Card = styled(DefaultCard).attrs({
  variant: 'white',
})`
  display: flex;
  flex-direction: column;
  gap: 32px;

  box-shadow: none;
  border: none;
  border-radius: 10px;

  padding: 27.5px;

  ${from.tablet} {
    box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.1);
    padding: 80px;
    max-width: 480px;
  }
`;

export const PromptContent = styled(DefaultPromptContent)`
  display: flex;
  flex-direction: column;

  gap: 16px;
  padding: 0px;
  height: 100%;
`;

export const PromptTitle = styled(DefaultHeading)`
  text-align: center;
`;

export const PromptDescription = styled(DefaultText).attrs({
  type: 'paragraph',
})`
  text-align: center;
`;

export const IconWrapper = styled.div<{
  $variant?: 'warning' | 'success' | 'error';
}>`
  align-self: center;

  border-radius: 50%;
  height: 80px;
  width: 80px;
  flex-shrink: 0;
  padding: 23.33px;

  ${({ $variant }) => {
    switch ($variant) {
      case 'warning':
        return css`
          background-color: ${({ theme }) => theme.colors.orange100};
          > i > svg > path {
            fill: ${({ theme }) => theme.colors.orange700} !important;
          }
        `;
      case 'error':
        return css`
          background-color: ${({ theme }) => theme.colors.red100};
          > i > svg > path {
            fill: ${({ theme }) => theme.colors.green700} !important;
          }
        `;
      case 'success':
      default:
        return css`
          background-color: ${({ theme }) => theme.colors.green100};
          > i > svg > path {
            fill: ${({ theme }) => theme.colors.green700} !important;
          }
        `;
    }
  }}

  > i {
    height: 33.3px;
    width: 33.3px;
    > svg {
      height: 33.3px;
      width: 33.3px;
    }
  }
`;
