import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCheckoutContext } from '../../../data/context/CheckoutContext';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import useTableStateManager from '../../../components/ResponsiveTable/useTableStateManager';
import { useMe } from '../../../data/hooks/useMe';

export const useConnect = () => {
  const { push } = useHistory();
  const [showAddUsers, setShowAddUsers] = useState(false);
  const [addedUsersCount, setAddedUsersCount] = useState(-1);
  const { data: me } = useMe({ keepPreviousData: true });
  const flag = useRef<boolean>(false);
  const {
    users,
    plan,
    billingInterval,
    isLoading: isLoadingCheckoutContext,
    markUsersForRemoval,
  } = useCheckoutContext();
  const {
    isLoading: isLoadingWorkspaceContext,
    details,
    urlPrefix,
  } = useWorkspaceContext();

  const {
    state: { selection },
    manager: tableStateManager,
  } = useTableStateManager({
    idField: 'email',
  });

  useEffect(() => {
    if (details && details.plan !== 'LEGACY') {
      push(`${urlPrefix}/space`);
    }
  }, [details]);

  useEffect(() => {
    if (selection.select && !flag.current) {
      flag.current = true;
      const selectedUsers = users.filter((user) => !user.toBeRemoved);
      for (let i = 0; i < selectedUsers.length; i += 1) {
        selection.select({
          id: selectedUsers[i].email,
          value: true,
        });
      }
    }
  }, [users, selection.select]);

  const onNext = () => {
    // todo: optimize marking process
    markUsersForRemoval(selection.selected, false);
    markUsersForRemoval(
      users
        .filter((user) => !selection.selected.includes(user.email))
        .map((user) => user.id),
      true,
    );
    const urlParams = new URLSearchParams('');
    urlParams.set('plan', plan);
    urlParams.set('cycle', billingInterval);
    push(`${urlPrefix}/checkout?${urlParams.toString()}`);
  };

  const onShowAddUsers = (value: boolean) => {
    if (value) {
      setAddedUsersCount(-1);
    }
    setShowAddUsers(value);
  };

  const domainName = details?.features.FREE.hasMultiDomain
    ? undefined
    : me?.domain;

  return {
    users,
    selectedUsersCount: selection.selected.length,
    maxUsersCount: details ? details.features.PRO.allowedUserCount : 0,
    maxFreeUsersCount: details ? details.features.FREE.allowedUserCount : 0,
    domainName,
    tableStateManager,
    workspaceDetails: details,
    isLoading: isLoadingWorkspaceContext || isLoadingCheckoutContext,
    onNext,
    showAddUsers,
    onShowAddUsers,
    addedUsersCount,
    setAddedUsersCount,
  };
};
