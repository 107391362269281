import React from 'react';
import { formatMonthDay } from 'utils/dates';
import { Column } from 'components/ResponsiveTable/types';
import { Campaign } from 'model/campaign';
import { percentage } from 'utils/percentage';
import {
  TableCell,
  TableCellValue,
  TableCellDateTimeValue,
} from '@scriptaddicts/yamm-ui-components';
import {
  ProgressContainer,
  Progress,
  SheetLink,
  SheetIcon,
} from '../../styles';

const getCellValueSize = (isMobile: boolean) =>
  isMobile ? 'extra-small' : undefined;

const getCellValueVariant = (isMobile: boolean) =>
  isMobile ? 'gray' : undefined;

const getSentValue = (value: any, isMobile: boolean) => {
  return `${value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}${
    isMobile ? ' sent' : ''
  }`;
};

export const getColumns = ({ isMobile }: { isMobile: boolean }) =>
  [
    {
      Header: 'Name',
      accessor: 'title',
      colSpan: 3,
      group: isMobile,
      Cell: ({ value }) =>
        isMobile ? (
          <TableCell colSpan={3}>
            <TableCellValue width="medium" style={{ zIndex: 2 }}>
              {value}
            </TableCellValue>
          </TableCell>
        ) : (
          <TableCellValue width="medium" style={{ zIndex: 2 }}>
            {value}
          </TableCellValue>
        ),
    },
    {
      Header: 'Date',
      accessor: 'lastUpdateTimestamp',
      colSpan: 1,
      float: 'right',
      filter: 'unix',
      sortType: 'datetime',
      defaultSorted: 'desc',
      Cell: ({ value }) => (
        <TableCellDateTimeValue
          size={getCellValueSize(isMobile)}
          variant={getCellValueVariant(isMobile)}
          value={value}
          formatFunction={formatMonthDay}
        />
      ),
    },
    {
      Header: 'Sent',
      accessor: 'trackingInfos.sent',
      colSpan: 1,
      canSort: false,
      Cell: ({ value }) => (
        <TableCellValue
          size={getCellValueSize(isMobile)}
          variant={getCellValueVariant(isMobile)}
        >
          {getSentValue(value, isMobile)}
        </TableCellValue>
      ),
    } as Column,
    {
      Header: 'Opened',
      accessor: 'trackingInfos.opened',
      colSpan: 2,
      canSort: false,
      Cell: ({ value, row }) => {
        const base =
          (row.original as Campaign).trackingInfos.sent -
          (row.original as Campaign).trackingInfos.bounced;
        const asPercentage = percentage(value, base);

        return isMobile ? (
          <TableCellValue size="extra-small" variant="gray">
            {!!value && `${asPercentage}% opened`}
          </TableCellValue>
        ) : (
          <ProgressContainer>
            <Progress fill={asPercentage} type="success" />
            <span>{asPercentage}%</span>
          </ProgressContainer>
        );
      },
    } as Column,
    {
      Header: 'Clicked',
      canSort: false,
      accessor: 'trackingInfos.clicked',
      colSpan: 2,
      Cell: ({ value, row }) => {
        const base =
          (row.original as Campaign).trackingInfos.sent -
          (row.original as Campaign).trackingInfos.bounced;
        const asPercentage = percentage(value, base);

        return isMobile ? (
          <TableCellValue size="extra-small" variant="gray">
            {!!value && `${asPercentage}% clicked`}
          </TableCellValue>
        ) : (
          <ProgressContainer>
            <Progress fill={asPercentage} type="success" />
            <span>{asPercentage}%</span>
          </ProgressContainer>
        );
      },
    } as Column,
    ...(!isMobile
      ? [
          {
            Header: 'Sheet',
            accessor: 'spreadsheetLink',
            alignment: 'right',
            colSpan: 1,
            disableGlobalFilter: true,
            disableSortBy: true,
            canSort: false,
            Cell: ({ value }) => (
              <SheetLink href={value} target="_blank" rel="noopener noreferrer">
                <SheetIcon />
              </SheetLink>
            ),
          } as Column<Campaign>,
        ]
      : []),
  ] as Column<Campaign>[];
