import { FlowType } from '../../../../utils/checkout';

export type PathInfo = {
  title: string;
  suffix: string;
  test: (path: string) => boolean;
};

export type GetPathMappingProps = {
  usersCount: number;
};

export const PathsMapping: {
  [key in FlowType]: (props: GetPathMappingProps) => PathInfo[];
} = {
  PURCHASE: ({ usersCount }) => {
    const paths: PathInfo[] = [
      {
        title: 'Billing information',
        suffix: '/checkout/information',
        test: (path) => path.endsWith('/information'),
      },
      {
        title: 'Checkout',
        suffix: 'summary',
        test: (path) => path.endsWith('/summary'),
      },
    ];

    if (usersCount > 1) {
      // add Billing options as first element
      paths.unshift({
        title: 'Billing options',
        suffix: '/checkout',
        test: (path) => path.endsWith('/checkout') || path.endsWith('/options'),
      });
    }
    return paths;
  },
  UPGRADE: () => [
    {
      title: 'Review',
      suffix: '/checkout/summary',
      test: (path) => path.endsWith('/summary'),
    },
  ],
  DOWNGRADE: () => [
    {
      title: 'Billing options',
      suffix: '/checkout',
      test: (path) => path.endsWith('/checkout') || path.endsWith('/options'),
    },
    {
      title: 'Review',
      suffix: '/checkout/summary',
      test: (path) => path.endsWith('/summary'),
    },
  ],
  UPGRADE_LEGACY_US: () => [],
  UPGRADE_LEGACY_BE: () => [],
  UPGRADE_LEGACY_US_INDIVIDUAL: () => [],
  UPGRADE_LEGACY_BE_INDIVIDUAL: () => [],
};
