import React from 'react';
import useQueryParams from 'utils/useQueryParams';
import Success from './Success';
import Error from './Error';

const Unsubscription = () => {
  const query = useQueryParams();
  const success = query.get('success');

  if (success === '0') {
    return <Error />;
  }

  return <Success />;
};

export default Unsubscription;
