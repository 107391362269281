import { useMemo } from 'react';
import { DisabledUsers } from '../types';
import { WorkspaceMember } from '../../../../../../model/workspaceMembers';

export type UseDisabledUsersProps = {
  selected: WorkspaceMember[];
  allUsers: WorkspaceMember[];
  limit: {
    freeUsersMax?: number;
  };
};
export const useDisabledUsers = ({
  selected,
  limit,
  allUsers,
}: UseDisabledUsersProps) => {
  return useMemo<DisabledUsers>(() => {
    const allFreeUsers = allUsers.filter(
      (s) => s?.status === 'ACTIVE' && s?.plan === 'FREE',
    );
    const selectedDisabled = selected.filter((s) => s?.status === 'DISABLED');

    const projectedUsersCount = selectedDisabled.length + allFreeUsers.length;
    const maxReached =
      // eslint-disable-next-line eqeqeq
      limit?.freeUsersMax != undefined && limit?.freeUsersMax >= 0
        ? projectedUsersCount > limit.freeUsersMax
        : false;

    const multiDomain = selectedDisabled.some(
      ({ isUserInDomain }) => !isUserInDomain,
    );

    return {
      count: selectedDisabled.length,
      list: selectedDisabled,
      features: {
        activate: !maxReached && !multiDomain,
        remove: true,
      },
      warnings: {
        activate: (() => {
          if (multiDomain) return 'MULTI_DOMAIN';
          if (maxReached) return 'FREE_USERS_MAX_REACHED';
          return undefined;
        })(),
      },
      disabled: {
        activate: maxReached || multiDomain,
      },
    };
  }, [selected, allUsers, limit]);
};
