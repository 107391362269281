import {
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  endOfMonth,
} from 'date-fns';

export const getDateFilter = (now: Date, value: string) => {
  switch (value) {
    case 'LAST_30_DAYS':
      return {
        gte: subDays(now, 30).getTime(),
      };
    case 'LAST_MONTH': {
      const lastMonth = subMonths(now, 1);
      const firstOfLastMonth = startOfMonth(lastMonth);
      const lastOfLastMonth = endOfMonth(lastMonth);
      return {
        gte: firstOfLastMonth.getTime(),
        lte: lastOfLastMonth.getTime(),
      };
    }
    case 'LAST_12_MONTHS':
      return {
        gte: subMonths(now, 12).getTime(),
      };
    case 'THIS_MONTH':
      return {
        gte: startOfMonth(now).getTime(),
      };
    case 'THIS_YEAR':
      return {
        gte: startOfYear(now).getTime(),
      };
    default:
      return undefined;
  }
};
