import styled from 'styled-components';
import {
  Overlay as DefaultOverlay,
  Spinner as DefaultSpinner,
} from '@scriptaddicts/yamm-ui-components';

export const Container = styled(DefaultOverlay)`
  background-color: ${({ theme }) => theme.colors.gray1};
`;

export const Spinner = styled(DefaultSpinner)`
  --spinner-color: ${({ theme }) => theme.colors.blue800};
`;
