import styled from 'styled-components';
import { Alert as DefaultAlert } from '@scriptaddicts/yamm-ui-components';
import { from } from 'styles/media';

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin: 16px 24px 0px 24px;

  ${from.tablet} {
    margin: 16px 0px 0px 0px;
  }
`;

export const Alert = styled(DefaultAlert).attrs({
  stroke: '300',
  size: 'small',
})``;
