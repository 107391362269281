import { Selectbox as DefaultSelect } from '@scriptaddicts/yamm-ui-components';
import styled from 'styled-components';

export const SelectContainer = styled.div`
  max-width: 160px;
  min-width: 160px;
`;

export const Select = styled(DefaultSelect).attrs({
  maxElement: 'auto',
  minElement: 5,
})`
  border: none !important;
  border-radius: 0px;
  border-left: 1px solid ${({ theme }) => theme.colors.gray200} !important;
  padding-left: 14px;
  box-shadow: none !important;
  max-height: 20px;

  button::before {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ~ div {
    margin-top: 14px !important;
    border: none;
  }
`;
