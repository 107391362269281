import styled, { css } from 'styled-components';
import {
  Spinner as DefaultSpinner,
  Alert as DefaultAlert,
  Icon as DefaultIcon,
  TagInput as DefaultTagInput,
} from '@scriptaddicts/yamm-ui-components';
import { from } from 'styles/media';

export const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  margin: 0px 24px;
  margin-top: 16px;

  ${from.tablet} {
    margin: 0px;
    margin-top: 16px;
  }
`;

export const DeleteButton = styled.button<{ $size: 'small' | 'large' }>`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;

  :focus-visible {
    outline-offset: 2px;
  }

  :disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray600};
  }

  > svg {
    width: 16px;
    height: 16px;
  }

  ${({ $size }) =>
    $size === 'large' &&
    css`
      > svg {
        width: 20px;
        height: 20px;
      }
    `};
`;

export const Spinner = styled(DefaultSpinner)`
  margin-bottom: 3.3333px;
`;

export const TagInput = styled(DefaultTagInput)`
  max-width: 400px;
`;

export const UnsubscribeContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-right: auto;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin: 16px 24px 0px 24px;

  ${from.tablet} {
    margin: 16px 0px 0px 0px;
  }
`;

export const Alert = styled(DefaultAlert).attrs({
  stroke: '300',
  size: 'small',
})``;

export const Info = styled(DefaultIcon).attrs({
  name: 'info',
})`
  vertical-align: text-top;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Upgrade = styled(DefaultIcon).attrs({
  name: 'rocket',
})`
  vertical-align: text-top;
  svg {
    width: 16px;
    height: 16px;
  }
`;
