import { DispatchWithoutAction, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { up } from 'utils/Paths';

interface Output {
  show: boolean;
  onClose: DispatchWithoutAction;
  params?: Record<string, string>;
  url: string;
}

export default function useURLDisclosure(path: string): Output {
  const { push, goBack } = useHistory();

  const { url } = useRouteMatch();
  const matches = useRouteMatch<Record<string, string>>(`${url}/${path}`);

  const onClose = useCallback(() => {
    const currentUrl = matches?.url;
    if (!currentUrl) {
      goBack();
    } else {
      push(up(currentUrl));
    }
  }, [goBack, push, matches?.url]);

  return {
    show: !!matches,
    onClose,
    params: matches?.params,
    url: matches?.url ?? '',
  };
}
