import { AxiosError } from 'axios';
import { queryClient } from 'data/queryClient';
import {
  postInviteMembers,
  putInviteMembers,
  deleteInviteMembers,
  postAddMembers,
  putAcceptInvite,
} from 'data/requests/workspaceInvite';
import { transformError } from 'data/transform/error';
import { BackendError } from 'model/error';
import {
  InviteMembersParams,
  ResendInviteMembersParams,
  CancelInviteMembersParams,
  AddMembersParams,
  AcceptInvite,
  AddMember,
  InviteMember,
} from 'model/workspaceInvite';
import { useMutation, useQuery } from 'react-query';
import { optimisticUpdateWorkspaceMemberCount } from 'data/updates/workspace';

export const useInviteMembers = () => {
  return useMutation<
    InviteMember[],
    AxiosError<BackendError>,
    { workspaceId?: string; params?: InviteMembersParams }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return postInviteMembers(params);
    },
    {
      onSuccess: async (_result, params) =>
        Promise.all([
          queryClient.invalidateQueries([
            'space',
            params.workspaceId,
            'members',
          ]),
          optimisticUpdateWorkspaceMemberCount(
            params.workspaceId,
            params.params?.userEmails?.length,
          ),
        ]),
    },
  );
};

export const useValidateInviteMembers = (
  workspaceId?: string,
  params?: InviteMembersParams,
) => {
  return useQuery(
    ['space', workspaceId, 'members', 'invite', params],
    () =>
      postInviteMembers({
        workspaceId,
        params,
        onlyCheckValidity: true,
      }).catch(() => []),
    {
      enabled: !!workspaceId && (params?.userEmails ?? []).length > 0,
      refetchOnMount: false,
      refetchOnWindowFocus: ({ state }) => {
        return !state.data || !!state.data.length;
      },
      staleTime: 1000,
      keepPreviousData: true,
    },
  );
};

export const useResendInviteMembers = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: ResendInviteMembersParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return putInviteMembers(params);
    },
    {
      onSuccess: async (_result, params) =>
        queryClient.invalidateQueries(['space', params.workspaceId, 'members']),
    },
  );
};

export const useCancelInviteMembers = () => {
  return useMutation<
    any,
    AxiosError<BackendError>,
    {
      workspaceId?: string;
      params?: CancelInviteMembersParams;
    }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return deleteInviteMembers(params);
    },
    {
      onSuccess: async (_result, params) =>
        Promise.all([
          queryClient.invalidateQueries([
            'space',
            params.workspaceId,
            'members',
          ]),
          optimisticUpdateWorkspaceMemberCount(
            params.workspaceId,
            -1 * (params.params?.invitedUserEmails?.length ?? 0),
          ),
        ]),
    },
  );
};

export const useAddMembers = () => {
  return useMutation<
    AddMember[],
    AxiosError<BackendError>,
    { workspaceId?: string; params?: AddMembersParams }
  >(
    async (params) => {
      if (!params.workspaceId || !params.params) return Promise.resolve([]);
      return postAddMembers(params);
    },
    {
      onSuccess: async (_result, params) =>
        Promise.all([
          queryClient.invalidateQueries([
            'space',
            params.workspaceId,
            'members',
          ]),
          optimisticUpdateWorkspaceMemberCount(
            params.workspaceId,
            params.params?.userEmails?.length,
          ),
        ]),
    },
  );
};

export const useValidateAddMembers = (
  workspaceId?: string,
  params?: AddMembersParams,
) => {
  return useQuery(
    ['space', workspaceId, 'members', 'add', params],
    () =>
      postAddMembers({
        workspaceId,
        params,
        onlyCheckValidity: true,
      }).catch(() => []),
    {
      enabled: !!workspaceId && (params?.userEmails ?? []).length > 0,
      refetchOnMount: false,
      refetchOnWindowFocus: ({ state }) => {
        return !state.data || !!state.data.length;
      },
      staleTime: 1000,
      keepPreviousData: true,
    },
  );
};

export const useAcceptInvite = () => {
  return useMutation<
    AcceptInvite,
    AxiosError<BackendError>,
    { invitationToken?: string }
  >(
    async (params) => {
      if (!params.invitationToken) {
        throw new Error('InvitationToken is required');
      }
      return putAcceptInvite(params);
    },
    {
      onSuccess: async () => {
        queryClient.refetchQueries(['me']);
      },
      retry: (failedCount, axiosError) => {
        if (failedCount === 3) return false;
        const error = transformError(axiosError);

        return error?.code === 500;
      },
    },
  );
};
